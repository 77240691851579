import styled from "@emotion/styled"
import { SortableNestedListItem } from "common/nested-list/SortableNestedListItem"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { Appointment, Exhibit, ExhibitItem } from "../types"
import { Button, svgIconClasses } from "@mui/material"
import { PartitionListHeader } from "exhibit-builder/Exhibit/PartitionListHeader"
import { PartitionListItem } from "exhibit-builder/Exhibit/PartitionListItem"

const gridTemplateColumns = "1fr 150px 200px"

export const ExhibitListContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
})

export const ExhibitListControls = styled("div")({
  alignSelf: "flex-end",
})

export const ExhibitList = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(-3),
}))

export const PartitionsTable = styled("div")(({ theme }) => ({
  background: theme.palette.common.white,
  marginTop: theme.spacing(2),
  borderRadius: "4px",
  width: "100%",
}))

export const StyledExhibitPartitionGroupContent = styled(PartitionListHeader)(({ theme }) => ({
  gridTemplateColumns,
  padding: theme.spacing(1, 3),
  border: `1px solid ${theme.palette.divider}`,
}))

export const StyledExhibitPartitionItem = styled(PartitionListItem<Appointment["dateOfService"]>)(
  ({ theme }) => ({
    gridTemplateColumns,
    padding: theme.spacing(1.5, 3),
  })
)

export const Footer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns,
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  borderTop: 0,
}))

export const TotalPages = styled("div")({
  textAlign: "right",
})

export const PartitionsFile = styled("div")(({ theme }) => ({
  verticalAlign: "middle",
  display: "inline-flex",
  alignItems: "center",
  gap: theme.spacing(1),

  "& svg": {
    transform: "translateY(-1px)",
  },
}))

export const StyledExhibitOpenLink = styled("span")(() => ({
  "&:hover": {
    cursor: "pointer",
    textDecoration: "underline",
  },
}))

export const ExhibitControls = styled("div")(({ theme }) => ({
  display: "flex",
  paddingRight: theme.spacing(3.5),
  alignItems: "flex-end",
  flexDirection: "column",
}))

export const CollapseButton = styled(Button)<{ collapsed: boolean }>(({ theme, collapsed }) => ({
  transform: "translateX(25px)",
  fontWeight: "bold",
  fontSize: 14,
  letterSpacing: -0.17,
  margin: theme.spacing(-0.5),

  [`& .${svgIconClasses.root}`]: {
    transition: theme.transitions.create("transform", { duration: 150 }),
    transform: `rotate(${collapsed ? 0 : -180}deg)`,
  },
}))

CollapseButton.defaultProps = {
  color: "primary",
  size: "small",
  variant: "text",
  disableFocusRipple: true,
  endIcon: <KeyboardArrowDownIcon />,
}

export const StyledExhibitListItem = styled(SortableNestedListItem<ExhibitItem>)<{ state: Exhibit["state"] }>(
  ({ theme, state }) => ({
    "& > div > [data-type='list-item']": {
      // TODO: discuss about moving this color to theme
      background: "#FBFCFE",

      border: 0,
      borderRadius: 0,
      boxShadow: `0px 0px 0px 1px ${theme.palette.blue.bannerStroke}`,

      padding: theme.spacing(1.5, 0),
      margin: theme.spacing(4, 0),

      ...(state === "reordered"
        ? {
            background: "#edf7ff",
            boxShadow: `0px 0px 0px 1px #9db7ff`,

            "& [aria-roledescription='draggable'] svg, & [data-role='exhibit-name']": {
              "&, &:hover": {
                color: "#1e88e5",
              },
            },
          }
        : null),

      ...(state === "error"
        ? {
            boxShadow: `0px 0px 0px 1px #d3302f`,

            "& [aria-roledescription='draggable'] svg, & [data-role='exhibit-name']": {
              "&, &:hover": {
                color: "#D32F2F",
              },
            },
          }
        : null),

      "& > [data-type='list-item-content']": {
        padding: theme.spacing(0, 4, 0, 3),
      },

      "& > [data-type='list-item-children']:not(:empty)": {
        margin: theme.spacing(0, 4),

        "&:before, &:after": {
          content: `""`,
          display: "block",
          height: 0,
          overflow: "hidden",
        },

        "& [data-type='list-item']": {
          border: 0,
          paddingRight: 0,
        },
      },
    },

    "& [data-type='empty-placeholder']": {
      minHeight: theme.spacing(6),
      marginTop: theme.spacing(3),
    },

    "& [data-type='drop-indicator']": {
      transform: `translateY(${theme.spacing(-2)})`,
    },
  })
)

export const StyledButtonWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: theme.spacing(2),
}))
