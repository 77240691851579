import { ExhibitBuilder } from "exhibit-builder/store/exhibitBuilder"
import { MedicalBill, MedicalRecord } from "exhibit-builder/store/types"
import { groupBy } from "lodash"

type PageRange = { startPage: number; endPage: number }

// sort and merge page ranges to get rid of overlapping ranges
// e.g. [{ startPage: 1, endPage: 3 }, { startPage: 2, endPage: 4 }] => [{ startPage: 1, endPage: 4 }]
function mergePageRanges(pageRanges: PageRange[]) {
  if (!pageRanges?.length) {
    return []
  }

  const sortedPageRanges = [...pageRanges].sort((a, b) => a.startPage - b.startPage)
  const mergedPageRanges: PageRange[] = [sortedPageRanges[0]]

  sortedPageRanges.slice(1).forEach(pageRange => {
    const lastMerged = mergedPageRanges.at(-1)!
    if (lastMerged.endPage >= pageRange.startPage) {
      lastMerged.endPage = Math.max(lastMerged.endPage, pageRange.endPage)
    } else {
      mergedPageRanges.push(pageRange)
    }
  })

  return mergedPageRanges
}

function getRecordsAndBillsMap(state: ExhibitBuilder) {
  const records = Object.values(state.recordsAndBillsMap)
  return groupBy(records, "fileId")
}

// return all page ranges that are not used in any exhibit partition
export function getDeletedPageRanges(state: ExhibitBuilder) {
  const partitions = groupBy(Object.values(state.exhibitPartitionMap), "fileId")
  const recordsByFileId = getRecordsAndBillsMap(state)

  const deletedPartitions: {
    pageRange: PageRange | null
    fileId: string
    droppedRelations: (MedicalRecord | MedicalBill)[]
  }[] = []

  Object.values(state.files).forEach(file => {
    if (file.unableToParse) {
      return
    }

    const mergedPageRanges = mergePageRanges(
      partitions[file.id]?.map(partition => ({ startPage: partition.startPage, endPage: partition.endPage }))
    )
    let startPage = 1

    mergedPageRanges.forEach(pageRange => {
      // if there are gaps between the last partition and the current one, they are deleted
      if (pageRange.startPage > startPage) {
        deletedPartitions.push({
          pageRange: { startPage, endPage: pageRange.startPage - 1 },
          fileId: file.id,
          droppedRelations: getDroppedRelations(
            recordsByFileId,
            { startPage, endPage: pageRange.startPage - 1 },
            file.id
          ),
        })
      }
      startPage = pageRange.endPage + 1
    })

    // if there are pages left after the last partition, they are deleted
    if (file.numberOfPages && startPage <= file.numberOfPages) {
      deletedPartitions.push({
        pageRange: { startPage, endPage: file.numberOfPages },
        fileId: file.id,
        droppedRelations: getDroppedRelations(
          recordsByFileId,
          { startPage, endPage: file.numberOfPages },
          file.id
        ),
      })
    }

    if (!file.numberOfPages && !file.unableToParse) {
      deletedPartitions.push({
        pageRange: null,
        fileId: file.id,
        droppedRelations: [],
      })
    }
  })

  return deletedPartitions
}

function getDroppedRelations(
  recordsByFileId: ReturnType<typeof getRecordsAndBillsMap>,
  pageRange: PageRange,
  fileId: string
) {
  const { startPage, endPage } = pageRange

  const droppedRelations = []

  for (const record of recordsByFileId[fileId] || []) {
    if (!record) {
      continue
    }

    const recordStartPage = record.startPage
    const recordEndPage = record.endPage || recordStartPage

    if (
      (endPage >= recordStartPage && startPage <= recordEndPage) ||
      (recordStartPage >= startPage && recordStartPage <= endPage) ||
      (recordEndPage >= startPage && recordEndPage <= endPage)
    ) {
      droppedRelations.push(record)
    }
  }

  return droppedRelations
}
