import { useMemo } from "react"
import { useController, useFormContext, useWatch } from "react-hook-form"
import { StateSelect } from "common"
import { SelectInput } from "common/form-components"
import { StyledEntityWrapper, StyledEntityRow } from "./styled"
import { RequestFormModule } from "./RequestFormModule"
import { DOCUMENT_TYPES } from "requests/enums"
import { useRequestContext } from "requests/context"
import { CASE_TYPES } from "requests/constants"
import { CaseInformationProps, RequestFormModuleProps } from "../types"
import { validators } from "common/form-components/formValidationRules"
import { RequestFields } from "requests/RequestForm/types"
import { useDateField } from "hooks/useDateField"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { DATE_FORMAT_DISPLAY, DEFAULT_MIN_DATE } from "utils/dateHelpers"

export const getCaseTypeOptions = (demandType: DOCUMENT_TYPES) => {
  return Object.values(CASE_TYPES).filter(
    ({ demandTypes, active }) => active && demandTypes.some(type => type === demandType)
  )
}

const requiredField = validators.required()

const DateOfIncidentField = () => {
  // useMemo() ensures stable reference for validation rules
  const validationRules = useMemo(() => {
    return {
      ...requiredField,
      validate: {
        dateInPast: validators.dateInPast(),
        isDate: validators.isDate(),
        minimumDate: validators.minimumDate(),
      },
    }
  }, [])

  const { field, fieldState } = useController({
    name: "date_of_incident",
    rules: validationRules,
  })

  const { parsedDate, handleDateChange } = useDateField(field.value, field.onChange)

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        value={parsedDate}
        onChange={handleDateChange}
        label="Date of Incident"
        format={DATE_FORMAT_DISPLAY}
        disableFuture
        minDate={DEFAULT_MIN_DATE}
        slotProps={{
          textField: {
            size: "small",
            helperText: fieldState?.error?.message,
            error: !!fieldState.error,
            fullWidth: true,
            required: true,
          },
          field: {
            clearable: true,
          },
          openPickerIcon: {
            "data-test": "date-of-incident-calendar-icon",
          },
        }}
        inputRef={field.ref}
      />
    </LocalizationProvider>
  )
}

export const CaseInformation = ({
  readOnly,
  includeCaseType = true,
  includeStateOfIncident = true,
}: RequestFormModuleProps & CaseInformationProps) => {
  const { requestId } = useRequestContext()
  const { control } = useFormContext()

  const demandType = useWatch<RequestFields, "type">({ name: "type", defaultValue: DOCUMENT_TYPES.STANDARD })

  const options = useMemo(() => getCaseTypeOptions(demandType), [demandType])

  if (!requestId) {
    return <></>
  }

  return (
    <RequestFormModule title="Case Information">
      <StyledEntityWrapper>
        <StyledEntityRow>
          {includeCaseType && (
            <SelectInput
              name="case_type"
              data-test="case-type"
              options={options}
              label="Case Type"
              control={control}
              readOnly={readOnly}
              size="small"
              required
              rules={{ ...requiredField }}
            />
          )}
          <DateOfIncidentField />
          {includeStateOfIncident && (
            <StateSelect
              control={control}
              selectName={`state_of_incident`}
              label="State of Incident"
              required={false}
              errors={undefined}
              readOnly={readOnly}
              size="small"
            />
          )}
        </StyledEntityRow>
      </StyledEntityWrapper>
    </RequestFormModule>
  )
}
