import { useParams, useSearchParams } from "react-router-dom"
import { Helmet } from "react-helmet"
import { PdfRenderer } from "common/pdf-renderer"
import { PinControl } from "./PinControl"
import {
  PDF_FILENAME_QUERY_PARAM,
  PDF_PAGE_NUMBER_ADJUSTMENT_QUERY_PARAM,
  PDF_PAGE_QUERY_PARAM,
} from "./constants"
import { exhibitService } from "api/services/exhibit"
import { getUrl } from "apiHelper"
import invariant from "invariant"

export function UserExhibitPartitionPreviewPage(): JSX.Element {
  const { exhibitId, partitionId } = useParams()
  const [searchParams] = useSearchParams()
  const filename = searchParams.get(PDF_FILENAME_QUERY_PARAM)
  const page = Number(searchParams.get(PDF_PAGE_QUERY_PARAM))
  const pageNumberAdjustment = Number(searchParams.get(PDF_PAGE_NUMBER_ADJUSTMENT_QUERY_PARAM))

  invariant(exhibitId, "Exhibit id should be passed")
  invariant(partitionId, "Partition id should be passed")
  invariant(filename, "Filename should be passed")

  const url = getUrl(exhibitService.getExhibitBuilderPartitionPath({ exhibitId, partitionId }))

  return (
    <>
      <Helmet>
        <title>{filename}</title>
      </Helmet>
      {url && (
        <PdfRenderer
          key={url}
          filename={filename}
          currentFileName={filename ?? undefined}
          url={url}
          page={page}
          pageNumberAdjustment={pageNumberAdjustment}
          withCredentials
          rightControls={<PinControl />}
          exhibitId={exhibitId}
        />
      )}
    </>
  )
}
