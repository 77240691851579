import { DocumentData } from "./types"
import { Partition } from "documents/types"

export type PartitionsSlice = Pick<DocumentData, "partitions">

export const partitionsSlice: PartitionsSlice = {
  partitions: {},
}

export const partitionsSelectors = {
  getPartitionById:
    (id: Partition["id"]) =>
    (state: PartitionsSlice): Partition | undefined =>
      state.partitions[id],
}
