import { isNil } from "lodash"

import { ExhibitCitation } from "common/citation/ExhibitCitation"
import { useUserExhibitPreviewById } from "documents/exhibits/useUserExhibitPreview"
import { ExhibitPartition } from "exhibit-builder/store/types"
import { useDocumentStore } from "documents/store"
import { FlagRelationBaseComponentProps } from "../types"

interface FlagRelationUserExhibitComponentProps extends FlagRelationBaseComponentProps {
  partition: ExhibitPartition
}

export const FlagRelationUserExhibitComponent = ({
  relation,
  partition,
}: FlagRelationUserExhibitComponentProps) => {
  const userExhibitOrder = useDocumentStore(state => state.userExhibitOrder)
  const index = userExhibitOrder.indexOf(partition.userExhibitId)

  const openUserExhibitPreview = useUserExhibitPreviewById(partition.userExhibitId)
  const handleOpenPreview = () => openUserExhibitPreview(partition, relation.page)

  const pages = isNil(relation.page) ? [] : [relation.page]

  return <ExhibitCitation index={index} pages={pages} onClick={handleOpenPreview} />
}
