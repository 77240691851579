import { ExhibitBuilderData, Provider } from "../store/types"
import { SetState } from "./types"
import { exhibitBuilderService } from "../api/service"
import { documentActions, DocumentSlice } from "./document"
import { GetState } from "review/store/types"
import { FilesSlice } from "./files/filesSlice"
import { omit } from "lodash"

export type ProvidersSlice = Pick<ExhibitBuilderData, "providers">

export const providersSlice: ProvidersSlice = {
  providers: {},
}

export const providerSelectors = {
  // get providers that are being used by any partition
  getProvidersInUse: (state: ProvidersSlice & FilesSlice) => {
    const exhibitBuilderProviderIds = new Set()
    Object.values(state.recordsAndBillsOrder)
      .flat()
      .forEach(recordOrBillId => {
        if (!recordOrBillId) return

        const recordOrBill = state.recordsAndBillsMap[recordOrBillId]

        if (recordOrBill?.providerId) {
          exhibitBuilderProviderIds.add(recordOrBill.providerId)
        }
      })

    Object.values(state.userExhibitMap).forEach(userExhibit => {
      if (userExhibit.sortingProviderId) {
        exhibitBuilderProviderIds.add(userExhibit.sortingProviderId)
      }
    })
    return exhibitBuilderProviderIds
  },

  getSortedProviders: (state: ProvidersSlice) => {
    return Object.values(state.providers).sort((a, b) => a.name.localeCompare(b.name))
  },
}

export const providersActions = (
  set: SetState<ProvidersSlice & DocumentSlice>,
  get: GetState<ProvidersSlice & DocumentSlice>
) => {
  const createProvider = async (name: string) => {
    const newProvider = await exhibitBuilderService.createProvider({
      name,
      documentId: get().document.documentId,
    })

    set(state => ({ providers: { ...state.providers, [newProvider.id]: newProvider } }))

    return newProvider
  }

  const renameProvider = async ({
    providerId,
    name,
    toggleSummaries,
  }: {
    providerId: Provider["id"]
    name: string
    toggleSummaries?: boolean
  }) => {
    const updatedProvider = await exhibitBuilderService.renameProvider({
      providerId,
      name,
      documentId: get().document.documentId,
    })

    set(state => ({ providers: { ...state.providers, [providerId]: updatedProvider } }))
    if (toggleSummaries) {
      documentActions(set).toggleUpdateSummaries(true)
    }

    return updatedProvider
  }

  const deleteProvider = async (providerId: Provider["id"]) => {
    await exhibitBuilderService.deleteProvider({
      documentId: get().document.documentId,
      providerId,
    })

    set(state => ({ providers: omit(state.providers, providerId) }))
  }

  return {
    createProvider,
    renameProvider,
    deleteProvider,
  }
}
