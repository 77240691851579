import { BaseAnalyticsEvent } from "infrastructure/apm/types"
import { DOCUMENT_TYPES } from "requests/enums"

export enum RequestAnalyticsEventTypes {
  RequestSubmitted = "Request Submitted",
  CreatedNewRequest = "Created New Request",
  UploadedDocumentsToRequest = "Uploaded Documents to Request",
  UploadedDocumentsToCase = "Uploaded Documents to Case",
  ViewedRequest = "Viewed Request",
  RequestSaved = "Request Saved",
  OpenedIntakeRequestPanel = "Opened Intake Request Panel",
  SawCreditOverageAlert = "Saw Credit Overage Alert",
  DemandPacketDownloaded = "Demand Packet Downloaded",
  DeferredFromCreditOverage = "Deferred from Credit Overage",
  CreatedRequestFromIntegration = "Created Request From Integration",
  ReceiptDownloadStarted = "Receipt Download Started",
  ReceiptDownloadComplete = "Receipt Download Complete",
  ReceiptDownloadError = "Receipt Download Error",
  RequestMultipleDocumentsClicked = "Request Multiple Documents Clicked",
  AddPolicyClicked = "Add Policy Clicked",
  MultiDocRequestWaitlistClicked = "Multi-doc Request Waitlist Clicked",
  CollaboratorsUpdated = "Collaborators Updated",
}

type BaseAnalyticsFields = {
  firm_id: PrimaryKey
  firm_name: string
  request_id: PrimaryKey
  request_type: DOCUMENT_TYPES
}

export type MultipleDocumentsSurveyEventFields = Partial<BaseAnalyticsFields> & {
  user_email: string
  user_role: string
}

export class RequestAnalyticEvent<T extends RequestAnalyticsEventTypes> extends BaseAnalyticsEvent {
  constructor(
    readonly name: T,
    readonly fields: RequestAnalyticsEventDetails[T]
  ) {
    super()
  }
}

// Add new request event types here
export type RequestAnalyticsEventDetails = {
  [RequestAnalyticsEventTypes.RequestSubmitted]: {
    credit_total: number
    firm_id: string
    firm_name: string
    plaintiff_name: string
    request_id: string
    request_type: string
  }
  [RequestAnalyticsEventTypes.CreatedNewRequest]: {
    firm_id: number
    firm_name: string
    plaintiff_name: string
    request_id: string | number
    request_type: DOCUMENT_TYPES
    case_id: string
  }
  [RequestAnalyticsEventTypes.UploadedDocumentsToRequest]: {
    plaintiff_name: string
    request_id: string
    matter_id?: string
    request_type: DOCUMENT_TYPES
  }
  [RequestAnalyticsEventTypes.UploadedDocumentsToCase]: {
    plaintiff_name: string
    matter_id: string
  }
  [RequestAnalyticsEventTypes.ViewedRequest]: {
    plaintiff_name: string
    request_id: string
    request_type: DOCUMENT_TYPES
    utm_campaign?: string
    utm_medium?: string
  }
  [RequestAnalyticsEventTypes.RequestSaved]: BaseAnalyticsFields & { case_id: string }
  [RequestAnalyticsEventTypes.OpenedIntakeRequestPanel]: {
    demand_id: string
    request_id: string
    request_type: DOCUMENT_TYPES
  }
  [RequestAnalyticsEventTypes.SawCreditOverageAlert]: {
    credit_total: number
    plaintiff_name: string
    request_id: number
    request_type: DOCUMENT_TYPES
  }
  [RequestAnalyticsEventTypes.DemandPacketDownloaded]: {
    request_id: number
  }
  [RequestAnalyticsEventTypes.DeferredFromCreditOverage]: {
    request_id: string
    request_type: DOCUMENT_TYPES
  }
  [RequestAnalyticsEventTypes.CreatedRequestFromIntegration]: {
    request_id: string
    integration_source: string
    plaintiff_name: string
  }
  [RequestAnalyticsEventTypes.ReceiptDownloadStarted]: {
    request_id: string
    plaintiff_name: string
  }
  [RequestAnalyticsEventTypes.ReceiptDownloadComplete]: {
    request_id: string
    plaintiff_name: string
  }
  [RequestAnalyticsEventTypes.ReceiptDownloadError]: {
    request_id: string
    plaintiff_name: string
  }
  [RequestAnalyticsEventTypes.RequestMultipleDocumentsClicked]: MultipleDocumentsSurveyEventFields
  [RequestAnalyticsEventTypes.AddPolicyClicked]: MultipleDocumentsSurveyEventFields
  [RequestAnalyticsEventTypes.MultiDocRequestWaitlistClicked]: MultipleDocumentsSurveyEventFields
  [RequestAnalyticsEventTypes.CollaboratorsUpdated]: Partial<BaseAnalyticsFields> & {
    collaborator_ids: PrimaryKey[]
    case_id: string
  }
}
