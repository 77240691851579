import styled from "@emotion/styled"
import { SortableNestedListItem } from "common/nested-list/SortableNestedListItem"
import { ExhibitName } from "exhibit-builder/Exhibit/styled"
import { DndExhibitPartitionData } from "exhibit-builder/store/types"
import { grey } from "@mui/material/colors"
import { Typography } from "@mui/material"

export const StyledSortableNestedListItem = styled(SortableNestedListItem<DndExhibitPartitionData>)(
  ({ theme }) => ({
    "& [data-type='list-item']": {
      border: 0,
      padding: 0,
    },

    "& [data-type='list-item-content']": {
      position: "relative",
      padding: theme.spacing(0, 2),
      backgroundColor: grey[100],
      borderRadius: theme.spacing(0.5),
      border: `1px solid ${theme.palette.divider}`,

      [`${ExhibitName}`]: {
        color: theme.palette.blue.primary,
        textDecoration: "underline",
        fontWeight: "bold",
      },
    },

    "& [data-type='list-item-children']": {
      padding: "0 1px",
    },

    "& [data-type=empty-placeholder]": {
      backgroundColor: "white",
      margin: 0,
      padding: 0,
    },
  })
)

export const StyledMedChronPartitionItem = styled(SortableNestedListItem<DndExhibitPartitionData>)(
  ({ theme }) => ({
    "& [data-type='list-item']": {
      border: 0,
      padding: 0,
      width: "87%",
      position: "relative",
    },

    "& [data-type='list-item-content']": {
      position: "relative",
      padding: theme.spacing(0, 2),
      backgroundColor: grey[100],
      borderRadius: theme.spacing(0.5),
      border: `1px solid ${theme.palette.divider}`,
      alignItems: "start",

      [`${ExhibitName}`]: {
        color: theme.palette.blue.primary,
        textDecoration: "underline",
        fontWeight: "bold",
      },
    },

    "& [data-type='list-item-children']": {
      padding: "0 1px",
    },

    "& [data-type=empty-placeholder]": {
      backgroundColor: "white",
      margin: 0,
      padding: 0,
    },
  })
)

export const StyledRecordPagesListContainer = styled("div")({
  position: "absolute",
  top: 0,
  right: "-15%",
  width: "13%",
})

export const StyledRecordPagesListHeader = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  display: "inline-block",
  marginTop: theme.spacing(2),

  "> *": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}))

export const StyledRecordPagesList = styled("ul")({
  listStyleType: "none",
  padding: 0,
  margin: 0,
})

export const StyledRecordPages = styled("li")(({ theme }) => ({
  padding: theme.spacing(1.2, 0, 2, 0),
  height: "65px",
  display: "flex",
  alignItems: "center",
}))

export const UserExhibitLink = styled(Typography)<{ disabled?: boolean }>({
  "&:hover": {
    cursor: "pointer",
    textDecoration: "underline",
  },
  pointerEvents: "auto",
})
