import styled from "@emotion/styled"
import { Alert, IconButton, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useState } from "react"

const StyledAlert = styled(Alert)(({ theme }) => ({
  border: `solid 1px ${theme.palette.error.main}`,
  width: "fit-content",
  color: theme.palette.text.primary,
}))

export function NoProviderDataAlert({ numberOfProviders }: { numberOfProviders: number }) {
  const [show, setShow] = useState(true)

  if (!show) return null

  return (
    <StyledAlert
      severity="error"
      action={
        <IconButton size="small" onClick={() => setShow(false)}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      <Typography variant="body2" mr={8}>
        {`${numberOfProviders} Provider${numberOfProviders !== 1 ? "s" : ""} ${numberOfProviders !== 1 ? "have" : "has"} no provider data assigned. Please review.`}
      </Typography>
    </StyledAlert>
  )
}
