import { ProviderSummarySection } from "./ProviderSummarySection"
import { ProviderSummaryAppointments } from "./ProviderSummaryAppointments"
import { getSummaryItem } from "./utils"
import { SummaryItemKey } from "documents/store/types"
import { useDocumentStore } from "documents/store"
import { providersSelectors } from "documents/store/providers"
import { appointmentsSelectors } from "documents/store/appointments"

interface ProviderSummaryTableItemProps {
  itemKey: SummaryItemKey
  isLast: boolean
  readOnly?: boolean
  displayReviewActions?: boolean
  exhibitBuilderOn?: boolean
}

export function ProviderSummaryTableItem({
  itemKey,
  readOnly,
  isLast,
  displayReviewActions,
  exhibitBuilderOn,
}: ProviderSummaryTableItemProps) {
  const [type, providerId, appointmentId] = getSummaryItem(itemKey)

  const provider = useDocumentStore(providersSelectors.getProviderById(providerId))
  const appointment = useDocumentStore(
    state => appointmentId && appointmentsSelectors.getAppointmentById(appointmentId)(state)
  )

  // checking if item exists before rendering
  // see https://github.com/petyosi/react-virtuoso/issues/980
  if (type === "provider" && provider) {
    return (
      <ProviderSummarySection
        readOnly={readOnly}
        providerId={providerId}
        exhibitBuilderOn={exhibitBuilderOn}
      />
    )
  }

  if (type === "appointment" && appointment && provider) {
    return (
      <ProviderSummaryAppointments
        isLast={isLast}
        readOnly={readOnly}
        displayReviewActions={displayReviewActions}
        providerId={providerId}
        appointmentId={appointmentId}
      />
    )
  }

  return null
}
