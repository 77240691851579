import { useEffect, useState } from "react"
import { Box } from "@mui/material"
import { AssigneeSelector } from "./AssigneeSelector"
import { RequestViewDto } from "./types"
import { getObjectIds } from "utils"
import useUser from "hooks/useUser"
import { useSaveInternalAssigneesRelationMutation } from "./hooks/useSaveInternalAssigneesRelationMutation"
import { UserDto } from "common/models/user"

interface RequestAssigneeSelectorProps {
  request: RequestViewDto
  handleSelfAssign: () => void
}

export function RequestAssigneeSelector({
  request,
  handleSelfAssign,
}: RequestAssigneeSelectorProps): JSX.Element {
  const [assignedSelf, setAssignedSelf] = useState(false)
  const [internalAssignees, setInternalAssignees] = useState(request?.internal_assignees)
  const { user } = useUser()

  useEffect(() => {
    if (request) {
      const ids = request.internal_assignees ? getObjectIds(request.internal_assignees) : []
      setInternalAssignees(request.internal_assignees)
      setAssignedSelf(user.isAuthorized && ids.includes(user.id))
    }
  }, [user, request])

  const handleInternalAssigneesChange = ({ newValues: values }: { newValues: UserDto[] }) => {
    const ids = values.map(value => value.pk)
    saveInternalAssigneesRelationMutation.mutate({ requestId: request.pk, data: ids })
  }

  const saveInternalAssigneesRelationMutation = useSaveInternalAssigneesRelationMutation({
    assignedSelf,
    setAssignedSelf,
  })

  return (
    <Box>
      <AssigneeSelector
        assignedSelf={assignedSelf}
        internalAssignees={internalAssignees ?? []}
        user={user}
        onChange={handleInternalAssigneesChange}
        handleSelfAssign={handleSelfAssign}
      />
    </Box>
  )
}
