import { isNil } from "lodash"

import { ExhibitCitation } from "common/citation/ExhibitCitation"
import { Partition } from "documents/types"
import { useExhibitPreview } from "documents/exhibits/useExhibitPreview"
import { useDocumentStore } from "documents/store"
import { FlagRelationBaseComponentProps } from "../types"

interface FlagRelationComponentProps extends FlagRelationBaseComponentProps {
  partition: Partition
}

export const FlagRelationComponent = ({ relation, partition }: FlagRelationComponentProps) => {
  const exhibitsOrder = useDocumentStore(state => state.exhibitsOrder)
  const index = exhibitsOrder.indexOf(partition.exhibitId)

  const openPreview = useExhibitPreview(partition.exhibitId)
  const handleOpenPreview = () => openPreview(partition, relation.page)

  const pages = isNil(relation.page) ? [] : [relation.page]

  return <ExhibitCitation index={index} pages={pages} onClick={handleOpenPreview} />
}
