import { MouseEvent } from "react"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { PreviewDemandIcon } from "common/assets"
import { IcdCode, Partition } from "documents/types"
import { BlackTooltip } from "common/Tooltip"
import { useDocumentStore } from "documents/store"
import { partitionsSelectors } from "documents/store/partitions"
import { userExhibitPartitionsSelectors } from "documents/store/userExhibitPartitions"
import { useExhibitPreview } from "documents/exhibits/useExhibitPreview"
import { useUserExhibitPreviewById } from "documents/exhibits/useUserExhibitPreview"
import { ExhibitReferenceButton } from "./styled"
import { ReferenceChip } from "./IcdCodeTable/styled"
import { ExhibitPartition } from "exhibit-builder/store/types"

interface IcdCodeBaseReferenceProps {
  page: Nullable<number>
}
interface IcdCodeReferenceProps extends IcdCodeBaseReferenceProps {
  partitionId: Partition["id"]
}

interface IcdCodeUserExhibitReferenceProps extends IcdCodeBaseReferenceProps {
  partitionId: ExhibitPartition["id"]
}

type IcdCodeReferenceContentProps = Pick<IcdCodeReferenceProps, "page"> & {
  partition: Partition
}

type IcdCodeUserExhibitReferenceContentProps = Pick<IcdCodeUserExhibitReferenceProps, "page"> & {
  partition: ExhibitPartition
}

function IcdCodeReferenceContent({ partition, page }: IcdCodeReferenceContentProps) {
  const openPreview = useExhibitPreview(partition?.exhibitId)

  return (
    <ExhibitReferenceButton size="small" color="primary" onClick={() => openPreview(partition, page)}>
      <PreviewDemandIcon />
    </ExhibitReferenceButton>
  )
}

function IcdCodeUserExhibitReferenceContent({ partition, page }: IcdCodeUserExhibitReferenceContentProps) {
  const openPreview = useUserExhibitPreviewById(partition.userExhibitId)

  return (
    <ExhibitReferenceButton size="small" color="primary" onClick={() => openPreview(partition, page)}>
      <PreviewDemandIcon />
    </ExhibitReferenceButton>
  )
}

function IcdCodeReference({ partitionId, page }: IcdCodeReferenceProps) {
  const partition = useDocumentStore(partitionsSelectors.getPartitionById(Number(partitionId)))

  if (!partition) return null

  return <IcdCodeReferenceContent partition={partition} page={page} />
}

function IcdCodeUserExhibitReference({ partitionId, page }: IcdCodeUserExhibitReferenceProps) {
  const partition = useDocumentStore(userExhibitPartitionsSelectors.getUserExhibitPartitionById(partitionId))

  if (!partition) return null

  return <IcdCodeUserExhibitReferenceContent partition={partition} page={page} />
}

interface IcdCodeProps {
  icdCode: IcdCode
  isEditing?: boolean
  onClick: (event: MouseEvent<HTMLDivElement>, icdCodeId: IcdCode["id"]) => void
  onDelete: (icdCodeId: IcdCode["id"]) => void
  readOnly?: boolean
  isUserExhibit: boolean
}

export function IcdCodeComponent({
  icdCode,
  isEditing = false,
  onClick,
  onDelete,
  readOnly,
  isUserExhibit,
}: IcdCodeProps): JSX.Element {
  const { id } = icdCode

  return (
    <>
      <ReferenceChip
        key={id}
        onClick={readOnly ? undefined : e => onClick(e, id)}
        onDelete={readOnly ? undefined : () => onDelete(id)}
        label={
          <BlackTooltip arrow title={icdCode.description}>
            <Box display="flex" gap={1} alignItems="center">
              {icdCode.code}
              {isEditing && <Typography fontWeight={600}>Edit</Typography>}
            </Box>
          </BlackTooltip>
        }
      />
      {icdCode.relations.map(relation =>
        isUserExhibit ? (
          <IcdCodeUserExhibitReference
            key={`${relation.partitionId}_${relation.page}`}
            partitionId={String(relation.partitionId)}
            page={relation.page}
          />
        ) : (
          <IcdCodeReference
            key={`${relation.partitionId}_${relation.page}`}
            partitionId={relation.partitionId}
            page={relation.page}
          />
        )
      )}
    </>
  )
}
