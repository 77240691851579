import React, { useMemo } from "react"
import { RenderElementProps, useSlate } from "slate-react"
import { INLINE_ELEMENTS, LIST_BLOCK_ELEMENTS, LIST_ITEM_BLOCK_ELEMENTS } from "./elements"
import {
  ListItem,
  ListItemContent,
  OrderedList,
  Paragraph,
  SoftLineBreak,
  UnorderedList,
  Variable,
} from "./render"
import { Citation } from "./render/Citation"
import { EmptySpace } from "./render/EmptySpace"
import { isBlockNode } from "./queries"
import { BlockElement } from "./CustomEditor"
import { isEmpty, omit } from "lodash"

type BlockElementDOMAttributes = RenderElementProps["attributes"] & Partial<Record<string, string | boolean>>

function getBlockExtraAttributes(
  element: BlockElement,
  attributes: BlockElementDOMAttributes
): BlockElementDOMAttributes {
  const fields = omit(element, ["type", "children"])

  if (isEmpty(fields)) {
    return attributes
  }

  return {
    ...attributes,
    ...Object.fromEntries(
      Object.entries(fields).map(([key, value]) => [
        `data-${key}`,
        typeof value === "boolean" ? value : String(value),
      ])
    ),
  }
}

export const Element: React.FC<RenderElementProps> = React.memo(function Element({
  attributes,
  children,
  element,
}) {
  const editor = useSlate()
  const elementAttributes = useMemo(
    () => (isBlockNode(editor, element) ? getBlockExtraAttributes(element, attributes) : attributes),
    [editor, element, attributes]
  )

  switch (element.type) {
    case INLINE_ELEMENTS.VARIABLE:
      return (
        <Variable attributes={elementAttributes} element={element}>
          {children}
        </Variable>
      )
    case INLINE_ELEMENTS.CITATION:
      return (
        <Citation attributes={elementAttributes} element={element}>
          {children}
        </Citation>
      )
    case INLINE_ELEMENTS.SOFT_LINE_BREAK:
      return (
        <SoftLineBreak attributes={elementAttributes} element={element}>
          {children}
        </SoftLineBreak>
      )
    case INLINE_ELEMENTS.EMPTY_SPACE:
      return (
        <EmptySpace attributes={elementAttributes} element={element}>
          {children}
        </EmptySpace>
      )
    case LIST_BLOCK_ELEMENTS.ORDERED_LIST:
      return (
        <OrderedList attributes={elementAttributes} element={element}>
          {children}
        </OrderedList>
      )
    case LIST_BLOCK_ELEMENTS.UNORDERED_LIST:
      return (
        <UnorderedList attributes={elementAttributes} element={element}>
          {children}
        </UnorderedList>
      )
    case LIST_ITEM_BLOCK_ELEMENTS.LIST_ITEM:
      return (
        <ListItem attributes={elementAttributes} element={element}>
          {children}
        </ListItem>
      )
    case LIST_ITEM_BLOCK_ELEMENTS.LIST_ITEM_CONTENT:
      return (
        <ListItemContent attributes={elementAttributes} element={element}>
          {children}
        </ListItemContent>
      )
    default:
      return (
        <Paragraph attributes={elementAttributes} element={element}>
          {children}
        </Paragraph>
      )
  }
})
