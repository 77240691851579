import { exhibitBuilderService } from "exhibit-builder/api/service"
import { documentActions, documentSlice } from "./document"
import { FiltersSlice, filtersActions, filtersSlice } from "./filters"
import { filesSlice } from "./files/filesSlice"
import { plaintiffsSlice } from "./plaintiffs"
import { providersSlice } from "./providers"
import { docTypesSlice } from "./docTypes"
import { EB_DOC_TYPE } from "exhibit-builder/types"
import { SetState } from "./types"

export const createState = () => ({
  ...documentSlice,
  ...filesSlice,
  ...plaintiffsSlice,
  ...providersSlice,
  ...docTypesSlice,
  ...filtersSlice,
})

export type ExhibitBuilder = ReturnType<typeof createState>

export const actions = (set: SetState<ExhibitBuilder>) => {
  const reset = () => set(createState())

  const initialize = ({
    documentId,
    caseId,
    type,
    filters: applyFilters,
  }: {
    documentId: string
    caseId?: string
    type: EB_DOC_TYPE | null
    filters?: Partial<FiltersSlice["filters"]>
  }) => {
    if (!documentId) {
      return
    }

    reset()
    documentActions(set).setIsLoading(true)
    documentActions(set).setDocumentId(documentId)
    documentActions(set).setExhibitBuilderType(type)
    if (caseId) {
      documentActions(set).setCaseId(caseId)
    }

    filtersActions(set).clearFilters()

    if (applyFilters) {
      set(({ filters }) => ({ filters: { ...filters, ...applyFilters } }))
    }

    return exhibitBuilderService.getExhibitBuilderData({ documentId }).then(data => {
      set(data)
      if (type === EB_DOC_TYPE.MEDCHRON) {
        documentActions(set).checkShouldRegenerateAppointments({ documentId })
      }
      documentActions(set).setIsLoading(false)
    })
  }

  return {
    initialize,
    reset,
  }
}
