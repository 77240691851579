import styled from "@emotion/styled"
import { inputAdornmentClasses, outlinedInputClasses, selectClasses, svgIconClasses } from "@mui/material"
import { EditableText } from "evenup-ui/EditableText"
import { DateField as BaseDateField } from "common/form-components"
import DescriptionIcon from "@mui/icons-material/DescriptionOutlined"
import { ReactNode } from "react"

interface ExhibitLinkProps {
  disabled?: boolean
}

export const ExhibitLink = styled("span")<ExhibitLinkProps>(({ disabled }) => ({
  "&:hover": {
    cursor: disabled ? "default" : "pointer",
    textDecoration: disabled ? "none" : "underline",
  },
  pointerEvents: disabled ? "none" : "auto",
}))

export const Container = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0.5, 0),
  gap: theme.spacing(2),
}))

export const ExhibitNameContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  alignItems: "center",
  flex: 1,
}))

export const FileName = styled(EditableText)({
  fontWeight: 500,

  "& svg": {
    display: "none",
  },

  "&:hover svg": {
    display: "block",
  },
})

export const ExhibitNumber = styled("div")({
  fontWeight: "bold",
})

export const Dropdowns = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  padding: theme.spacing(1.5, 3, 0),
  borderTop: `1px solid ${theme.palette.divider}`,
}))

export const DropdownContainer = styled("div")({
  [`& .${selectClasses.select}`]: {
    padding: 0,
    fontSize: "14px",
  },

  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: "none",
  },
})

const grid = {
  display: "grid",
  gridTemplateColumns: "minmax(350px, 1fr) 200px 250px 150px",
  alignItems: "center",
}

export const HeaderContainer = styled("div")(({ theme }) => ({
  ...grid,
  fontSize: "12px",
  color: "#00000099",
  padding: theme.spacing(0.5, 0),
}))

const medChronGrid = {
  display: "grid",
  gridTemplateColumns: "minmax(38%, 38%) minmax(10%, 10%) minmax(24%, 24%) minmax(19%, 19%)",
  alignItems: "center",
  gap: "3%",
}

export const MedChronHeaderContainer = styled("div")<{ renderCustomContent?: () => ReactNode }>(({
  theme,
  renderCustomContent,
}) => {
  const baseStyle = {
    padding: theme.spacing(0.5, 0),
  }

  if (renderCustomContent) {
    return {
      ...baseStyle,
      display: "flex",
      justifyContent: "space-between",
    }
  }

  return {
    ...baseStyle,

    [`${ExhibitName}`]: {
      marginBottom: theme.spacing(1),
    },
  }
})

export const MedChronGridHeader = styled("div")({
  ...medChronGrid,
  alignItems: "start",
  "> *": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
})

export const MedChronRecordRow = styled("div")(({ theme }) => ({
  ...medChronGrid,
  gridTemplateColumns: "minmax(38%, 38%) minmax(11%, 11%) minmax(24%, 24%) minmax(20%, 20%)",
  padding: theme.spacing(1.5, 3, 1.5, 4.75),
  border: `1px solid ${theme.palette.divider}`,
  borderTop: 0,
  borderRadius: theme.spacing(0, 0, 0.5, 0.5),

  [`& .${outlinedInputClasses.root}`]: {
    padding: 0,
  },

  [`& .${selectClasses.icon}`]: {
    marginLeft: 0,
  },

  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: "none",
  },
}))

export const FileIcon = styled(DescriptionIcon)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
}))

export const ExhibitName = styled("div")({
  display: "flex",
  alignItems: "center",
  fontWeight: "bold",
})

export const MedChronCustomContent = styled("div")({
  gridColumn: "span 3",
})

const rowGap = "15px" // TODO: Should remove when we make the DEMAND Row more responsive and use percentage widths

export const Row = styled("div")(({ theme }) => ({
  ...grid,
  padding: theme.spacing(1.5, 3),
  border: `1px solid ${theme.palette.divider}`,
  borderTop: 0,
  borderRadius: theme.spacing(0, 0, 0.5, 0.5),
  gap: rowGap,
}))

export const DateField = styled(BaseDateField)({
  margin: 0,

  transform: "translateX(-10px)",

  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: 0,
  },

  [`& .${outlinedInputClasses.root}`]: {
    flexDirection: "row-reverse",
  },

  [`& .${outlinedInputClasses.input}`]: {
    paddingLeft: 0,
    fontSize: "14px",
  },

  [`& .${inputAdornmentClasses.root}`]: {
    marginLeft: 0,

    "& > button": {
      margin: 0,

      [`& .${svgIconClasses.root}`]: {
        fontSize: "20px",
      },
    },
  },
})
