import { green, grey, lightBlue, orange, pink } from "@mui/material/colors"

export enum USER_EXHIBIT_TAG {
  EXTRACTED = "extracted",
  DUPLICATED = "duplicated",
  COMBINED = "combined",
  NEW_UPLOAD = "new_upload",
  RECOVERED = "recovered",
  PLACEHOLDER = "placeholder",
}

export const TAG_MAP = {
  [USER_EXHIBIT_TAG.EXTRACTED]: {
    label: "Extracted",
    color: orange[500],
  },
  [USER_EXHIBIT_TAG.DUPLICATED]: {
    label: "Duplicated Exhibits",
    color: pink[300],
  },
  [USER_EXHIBIT_TAG.COMBINED]: {
    label: "Combined",
    color: green[500],
  },
  [USER_EXHIBIT_TAG.NEW_UPLOAD]: {
    label: "New Upload",
    color: lightBlue[400],
  },
  [USER_EXHIBIT_TAG.RECOVERED]: {
    label: "Recovered",
    color: lightBlue[800],
  },
  [USER_EXHIBIT_TAG.PLACEHOLDER]: {
    label: "Placeholder",
    color: grey[600],
  },
}

export const USER_EXHIBIT_FILE_TYPES = {
  PDF: "pdf",
}
