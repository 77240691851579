import { PartitionListHeader } from "exhibit-builder/Exhibit/PartitionListHeader"
import { filesSelectors } from "exhibit-builder/store/files/filesSelectors"
import { useExhibitBuilderStore } from "exhibit-builder/store"
import { memo } from "react"
import { useShallow } from "zustand/react/shallow"
import { useExhibitPartitionPreview } from "exhibit-builder/useUserExhibitPreview"
import { EB_DOC_TYPE } from "exhibit-builder/types"

export const ExhibitPartitionContent = memo(function ExhibitPartitionContent({ id }: { id: string }) {
  const type = useExhibitBuilderStore(useShallow(state => state.exhibitBuilderType))
  const partition = useExhibitBuilderStore(filesSelectors.getExhibitPartitionById(id))
  const exhibitFile = useExhibitBuilderStore(filesSelectors.getExhibitFileById(partition.fileId))
  const annotationStatus = useExhibitBuilderStore(
    useShallow(state => state.annotationStatus[partition.fileId]?.status)
  )

  const isMedChron = type === EB_DOC_TYPE.MEDCHRON

  const preview = useExhibitPartitionPreview({ partitionId: partition.id, showFileNamePrefix: isMedChron })
  const isNotPdf = !exhibitFile.name.toLowerCase().endsWith(".pdf")

  return (
    <PartitionListHeader
      fileName={exhibitFile.name}
      startPage={partition.startPage}
      endPage={partition.endPage}
      totalPages={exhibitFile.numberOfPages}
      // exhibit with no annotations will not have any records or bills so we don't show the date of service
      hideDateOfService={annotationStatus === null}
      onPreview={preview}
      disabled={isNotPdf}
      type={type}
    />
  )
})
