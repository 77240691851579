import React, { useState } from "react"
import Collapse from "@mui/material/Collapse"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import styled from "@emotion/styled"
import { makeStyles } from "tss-react/mui"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"

import { useMutation } from "@tanstack/react-query"

import MissingDocumentForm from "./MissingDocumentForm"
import ConfirmationModal from "./ConfirmationModal"
import AttachedFile from "./AttachedFile"
import ActionButton, { ActionButtonContainer } from "./ActionButton"

import { updateMissingExhibit, updateRequestFile, getExhibitByQuestionnaireFileId } from "../api"
import { INSTRUCTIONS, SECTIONS } from "./constants"
import { AttachedFileInterface, MissingExhibit, StatusProperties } from "./interfaces"
import { queryClient } from "../react-query/queryClient"
import { queryKeys } from "../react-query/constants"
import { buildPropertiesObject, getColourByImportance } from "./utils"
import { formatDate } from "../utils"
import { MissingDocContainer, Status } from "./styled"
import { theme } from "app/theme"

const useStyles = makeStyles()({
  collapseContainer: {
    margin: "auto 0",
  },
})

const ListContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  maxWidth: "66%",
})

const Header = styled(Box)({
  display: "flex",
  flexDirection: "row",
})

const Title = styled(Box)({
  fontWeight: 700,
})

const Importance = styled(Box)((props: { color: string }) => ({
  color: props.color,
  display: "inline",
  marginLeft: "4px",
}))

const Subtitle = styled(Box)({
  display: "flex",
  flexDirection: "row",
})

const DescriptionContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  height: "inherit",
  transition: "height 1.35s ease-in-out",
  fontSize: "0.9rem",
  lineHeight: "1rem",
}))

interface Props {
  section: SECTIONS
  missingDoc: MissingExhibit
  caseId: number
  requestId: number
  showActions?: boolean
  showFiles?: boolean
  showStatus?: boolean
  canEditExistingProvider?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onApproveCallback?: Nullable<(data: any) => void>
}

const MissingDocumentItem = ({
  missingDoc,
  section,
  requestId,
  caseId,
  showActions = true,
  showFiles = true,
  showStatus = false,
  canEditExistingProvider = true,
  onApproveCallback = null,
}: Props): JSX.Element => {
  const { classes } = useStyles()

  const pk = missingDoc?.pk
  const name = missingDoc?.name
  const importanceLevel = missingDoc.importance
  const createdDate = formatDate(missingDoc.created_at)
  const createdBy = missingDoc.creator.first_name + " " + missingDoc.creator.last_name
  const description = missingDoc.description
  const isResolved = missingDoc.is_resolved
  const files: AttachedFileInterface[] = missingDoc.questionnaire_files
  const provider = missingDoc.provider

  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [showUnresolvedModal, setShowUnresolvedModal] = useState<boolean>(false)
  const [showResolvedModal, setShowResolvedModal] = useState<boolean>(false)
  const [showApproveDocumentModal, setShowApproveDocumentModal] = useState<boolean>(false)

  const [pendingFileId, setPendingFileId] = useState<Nullable<number>>(null)

  const approveFileMutation = useMutation(updateRequestFile, {
    onSuccess: async questionnaireFile => {
      queryClient.invalidateQueries([queryKeys.missingExhibits])
      queryClient.invalidateQueries([queryKeys.missingExhibitEvents])
      queryClient.invalidateQueries([queryKeys.request])
      invalidateQueriesBySection(section)

      if (section === SECTIONS.PROVIDERS && onApproveCallback) {
        const exhibit = await getExhibitByQuestionnaireFileId(requestId, questionnaireFile.pk)
        onApproveCallback(exhibit)
      }
    },
  })

  const markAsUnresolvedMutation = useMutation(updateMissingExhibit, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.missingExhibits])
      invalidateQueriesBySection(section)
    },
  })

  const getPropertiesByStatus = (instruction: INSTRUCTIONS, is_resolved: boolean): StatusProperties => {
    const WHITE = "#FFFFFF"
    if (instruction === INSTRUCTIONS.PROCEED_WITHOUT) {
      return buildPropertiesObject(WHITE, theme.palette.label.ignore, "Proceed Without")
    } else if (instruction === INSTRUCTIONS.WAIT) {
      return buildPropertiesObject(WHITE, theme.palette.label.postpone, "Will Upload")
    } else if (instruction === INSTRUCTIONS.UPLOAD) {
      return buildPropertiesObject(WHITE, theme.palette.label.action, "Uploaded")
    }

    if (is_resolved) {
      return buildPropertiesObject(WHITE, theme.palette.label.info, "Resolved")
    } else if (is_resolved === false) {
      return buildPropertiesObject(WHITE, theme.palette.label.warning, "Unresolved")
    }

    return buildPropertiesObject(WHITE, theme.palette.label.pending, "Pending")
  }

  const invalidateQueriesBySection = (section: SECTIONS) => {
    queryClient.invalidateQueries([queryKeys.missingExhibits])

    switch (section) {
      case SECTIONS.CASE_FACTS:
        return queryClient.invalidateQueries([queryKeys.facts])
      case SECTIONS.HOUSEHOLD_LOSS:
        return queryClient.invalidateQueries([queryKeys.household])
      case SECTIONS.INCOME_LOSS:
        return queryClient.invalidateQueries([queryKeys.incomeLoss])
      default:
        return null
    }
  }

  const statusProperties = getPropertiesByStatus(missingDoc.instructions, isResolved)
  const showExpandIcon = !!(description || (files.length && showFiles))

  return (
    <>
      <MissingDocContainer data-test="missing-document-item">
        <Collapse in={!isEditing} timeout={300} className={classes.collapseContainer}>
          <Header>
            {showStatus && (
              <Status
                backgroundColor={statusProperties.backgroundColor}
                fontColor={statusProperties.fontColor}
                label={statusProperties.display}
              />
            )}
            <ListContainer>
              {provider && <Title>Provider: {provider.name}</Title>}
              <Title data-test="item-name">
                {name} &#124; Importance:{" "}
                <Importance color={getColourByImportance(importanceLevel)} data-test="item-importance">
                  {importanceLevel.toUpperCase()}
                </Importance>
              </Title>
              <Subtitle>
                {createdDate} by {createdBy}
              </Subtitle>
            </ListContainer>
            <Box display="flex" ml="auto" mr={showExpandIcon ? 0 : 6} alignItems="center">
              {showActions && (
                <ActionButtonContainer>
                  <ActionButton
                    onClick={() => {
                      setIsEditing(true)
                    }}
                    data-test="edit-button"
                  >
                    Edit
                  </ActionButton>
                  {isResolved ? (
                    <ActionButton
                      onClick={() => {
                        setShowUnresolvedModal(true)
                      }}
                      data-test="mark-as-unresolved-button"
                    >
                      Mark as Unresolved
                    </ActionButton>
                  ) : (
                    <ActionButton
                      onClick={() => {
                        setShowResolvedModal(true)
                      }}
                      data-test="mark-as-resolved-button"
                    >
                      Mark as Resolved
                    </ActionButton>
                  )}
                </ActionButtonContainer>
              )}
              {showExpandIcon && (
                <IconButton
                  sx={{ width: "30px", height: "30px" }}
                  onClick={() => {
                    setIsExpanded(!isExpanded)
                  }}
                  data-test="expand-missing-doc"
                >
                  {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              )}
            </Box>
          </Header>

          <Collapse in={isExpanded}>
            <DescriptionContainer>{description}</DescriptionContainer>
            {showFiles && !!files.length && (
              <>
                <Divider variant="fullWidth" />
                {files.map((file: AttachedFileInterface, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <AttachedFile
                        key={`file-${file.pk}`}
                        file_url={file.file_url}
                        pk={file.pk}
                        accepted={file.accepted}
                        date_created={file.date_created}
                        name={file.name}
                        allowDownload={true}
                        questionnaire_id={file.questionnaire_id}
                        onApprovedClick={() => {
                          setPendingFileId(file.pk)
                          setShowApproveDocumentModal(true)
                        }}
                      />
                      {files.length - 1 !== index && <Divider />}
                    </React.Fragment>
                  )
                })}
              </>
            )}
          </Collapse>
        </Collapse>

        {showActions && (
          <Collapse in={isEditing}>
            <MissingDocumentForm
              missingExhibitId={pk}
              section={section}
              name={name}
              importance={importanceLevel}
              description={description}
              callback={() => {
                setIsEditing(false)
              }}
              caseId={caseId}
              providerId={provider?.pk ?? null}
              canEditExistingProvider={canEditExistingProvider}
            />
          </Collapse>
        )}
      </MissingDocContainer>

      <ConfirmationModal
        title={"Mark as Unresolved?"}
        description={"Are you sure you want to mark this missing document as unresolved?"}
        show={showUnresolvedModal}
        onClose={() => {
          setShowUnresolvedModal(false)
        }}
        onConfirm={() => {
          markAsUnresolvedMutation.mutate({
            caseId: caseId,
            missingExhibitId: pk,
            data: {
              is_resolved: false,
            },
          })
          setShowUnresolvedModal(false)
        }}
      />

      <ConfirmationModal
        title={"Mark as Resolved?"}
        description={"Are you sure you want to mark this missing document as resolved?"}
        show={showResolvedModal}
        onClose={() => {
          setShowResolvedModal(false)
        }}
        onConfirm={() => {
          markAsUnresolvedMutation.mutate({
            caseId: caseId,
            missingExhibitId: pk,
            data: {
              is_resolved: true,
            },
          })
          setShowResolvedModal(false)
        }}
      />

      <ConfirmationModal
        title={"Approve Document?"}
        description={"Are you sure you want to mark this document as approved?"}
        show={showApproveDocumentModal}
        onClose={() => {
          setShowApproveDocumentModal(false)
          setPendingFileId(null)
        }}
        onConfirm={() => {
          approveFileMutation.mutate({
            requestId: requestId,
            fileId: pendingFileId,
            data: {
              accepted: true,
              name: files.find(file => file.pk === pendingFileId)?.name,
            },
          })
          setPendingFileId(null)
          setShowApproveDocumentModal(false)
        }}
      />
    </>
  )
}

export { MissingDocumentItem as default }
