import { useCallback, useMemo, useRef } from "react"
import { exhibitBuilderActions, useExhibitBuilderStore } from "../store"
import { providerSelectors } from "../store/providers"
import { useShallow } from "zustand/react/shallow"
import { useHandleMessages } from "common/messages/useHandleMessages"
import * as Sentry from "@sentry/react"
import { useAlertModal } from "exhibit-builder/AlertModal"

export function useProviders() {
  const providersInUse = useExhibitBuilderStore(useShallow(providerSelectors.getProvidersInUse))
  const providers = useExhibitBuilderStore(useShallow(providerSelectors.getSortedProviders))
  const { showErrorMessage } = useHandleMessages()
  const providerIdRef = useRef<string | null>(null)

  const handleDeleteProvider = useCallback(async () => {
    if (!providerIdRef.current) return

    try {
      await exhibitBuilderActions.deleteProvider(providerIdRef.current)
    } catch (err) {
      Sentry.captureException(err, { extra: { providerId: providerIdRef.current } })
      showErrorMessage("Failed to delete provider")
    }
  }, [showErrorMessage])

  const { openModal } = useAlertModal({
    title: "Delete Provider",
    content: "Are you sure you want to delete this provider?",
    confirmLabel: "Delete",
    onConfirm: handleDeleteProvider,
  })

  const handleClick = useCallback(
    async (providerId: string) => {
      providerIdRef.current = providerId
      openModal()
    },
    [openModal]
  )

  const canDeleteProvider = useCallback(
    (providerId: string) => {
      return !providersInUse.has(providerId)
    },
    [providersInUse]
  )

  return useMemo(
    () => ({ canDeleteProvider, deleteProvider: handleClick, providers }),
    [canDeleteProvider, handleClick, providers]
  )
}
