import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded"
import { Box, ButtonProps } from "@mui/material"
import { theme } from "app/theme"
import { GENERATED_SUMMARY_STATUSES } from "api/services/documents/types"
import { formatDate } from "utils"

export const getDeleteAppointmentDialogProps = ({
  providerName,
  dateOfService,
}: {
  providerName: string
  dateOfService: string
}) => ({
  formContent: (
    <Box mt={3}>
      Please confirm if you would like to <b>delete</b> the following appointment:
      <Box mt={1.5} color="#464545">
        {providerName}, {formatDate(dateOfService, "MM/dd/yyyy", true)}
      </Box>
    </Box>
  ),
  PaperProps: { style: { borderRadius: 0, padding: "40px 30px 24px" } },
  header: (
    <Box alignItems="center" display="flex" gap={1}>
      <WarningAmberRoundedIcon color="warning" />
      <b>Appointment Deletion Confirmation</b>
    </Box>
  ),
  confirmationButtonProps: { sx: { background: theme.palette.blue.primary } } as ButtonProps,
  cancelButtonProps: { variant: "outlined", color: "secondary" } as ButtonProps,
  confirmationButtonContent: "Confirm Deletion",
})

export const revertChangesDialogProps = {
  formContent: (
    <Box mt={3}>
      Please note, this action will revert the summary back to the original generated version. All new text
      edits will be lost.
    </Box>
  ),
  PaperProps: { style: { borderRadius: 0, padding: "40px 30px 24px" } },
  header: (
    <Box alignItems="center" display="flex" gap={1}>
      <WarningAmberRoundedIcon color="warning" />
      <b>Revert to Generated Version</b>
    </Box>
  ),
  confirmationButtonProps: { sx: { background: theme.palette.blue.primary } } as ButtonProps,
  cancelButtonProps: { variant: "outlined", color: "secondary" } as ButtonProps,
  confirmationButtonContent: "Confirm",
}

export const REFETCH_SUMMARY_STATUSES = new Set([GENERATED_SUMMARY_STATUSES.PENDING])

export const UPDATE_SUMMARIES_MODAL_DESCRIPTION = "Please note that updates can take time to process."
