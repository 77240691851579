import { useMutation } from "@tanstack/react-query"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { RequestAnalyticEvent, RequestAnalyticsEventTypes } from "infrastructure/apm/events/requestEvents"
import { RequestViewDto } from "requests/ViewRequest/types"
import { useFormContext } from "react-hook-form"
import {
  CreateRequestParams,
  UpdateRequestAPICallParams,
  createRequest,
  updateRequest,
} from "api/services/request/api"
import { getFirstPlaintiffName } from "requests/plaintiffUtils"
import { STEP_CODES } from "../constants"
import { cloneDeep, isEqual, merge } from "lodash"
import { RequestFields } from "../types"
import { queryClient } from "react-query/queryClient"
import { queryKeyFetchCase } from "cases/api/caseView"

export const useIntakeRequestMutation = (canEdit: boolean) => {
  const { setValue, resetField, getValues } = useFormContext<RequestFields>()

  return useMutation(
    (data: CreateRequestParams | UpdateRequestAPICallParams): Promise<RequestViewDto> => {
      if (!canEdit) return Promise.reject(new Error("Form is not editable"))
      if ("requestId" in data) {
        return updateRequest(data)
      } else {
        return createRequest(data)
      }
    },
    {
      onSuccess: async (
        data: RequestViewDto,
        variables: CreateRequestParams | UpdateRequestAPICallParams
      ) => {
        const isCreate = !("requestId" in variables)
        const requestId = data.pk
        if (data.matter_id) {
          queryClient.invalidateQueries(queryKeyFetchCase(data.matter_id))
        }

        // ensure we always override the pks from the backend otherwise they will be lost if the form is dirty
        setValue("pk", requestId)
        data.plaintiffs.forEach((plaintiff, index) => {
          setValue(`plaintiffs.${index}.pk`, plaintiff.pk)
        })
        if (data.adjuster_address) {
          setValue("adjuster_address.pk", data.adjuster_address.pk)
        }

        // intake_status is not part of the form, so we need to set it manually
        setValue("intake_status", data.intake_status)

        // updated_at is not part of the form, so we need to set it manually
        setValue("updated_at", data.updated_at)

        // reset the form state by looping through all incoming data and resetting each field manually
        // this allows us to keep only some fields dirty if the user has made changes
        const formValues = cloneDeep(getValues())
        for (const k of Object.keys(data)) {
          const key = k as keyof RequestViewDto
          const dataValue = data[key]
          const formValue = formValues[key]
          // keep the field dirty if the current data is different from the backend data
          const keepDirty = key in formValues && !isEqual(formValue, dataValue)
          let value
          if (keepDirty) {
            if (dataValue !== null && (Array.isArray(dataValue) || typeof dataValue === "object")) {
              // merge the current data on top of the backend data, to avoid loosing in-flight changes
              value = cloneDeep(dataValue)
              merge(value, formValue)
            } else {
              value = formValue
            }
          } else {
            value = dataValue
          }
          resetField(key, {
            keepDirty,
            defaultValue: value,
            keepError: true,
            keepTouched: true,
          })
        }

        if (isCreate) {
          const { fullName: plaintiffName } = getFirstPlaintiffName(data)
          amplitudeApm.trackEvent(
            new RequestAnalyticEvent(RequestAnalyticsEventTypes.CreatedNewRequest, {
              firm_id: data.firm_id,
              firm_name: data.firm.name,
              plaintiff_name: plaintiffName,
              request_id: requestId,
              case_id: data.matter_id ?? "",
              request_type: data.type,
            })
          )
          const activeStep = STEP_CODES.CLIENT_INFORMATION
          // avoid using useNavigate hook here because it causes a full page reload
          window.history.replaceState({ activeStep }, "", `/requests/${requestId}/edit`)
        } else {
          amplitudeApm.trackEvent(
            new RequestAnalyticEvent(RequestAnalyticsEventTypes.RequestSaved, {
              firm_id: data.firm_id,
              firm_name: data.firm.name,
              request_id: requestId,
              request_type: data.type,
              case_id: data.matter_id ?? "",
            })
          )
        }
      },
    }
  )
}
