import { format, isValid, parse } from "date-fns"
import { RegisterOptions, Validate } from "react-hook-form"
import { DATE_FORMAT_DISPLAY, DEFAULT_MIN_DATE } from "utils/dateHelpers"

// Interface for validator configuration
interface ValidatorConfig {
  message?: string
}

// Validators
export const validators = {
  /**
   * Required field validator.
   * Ensures the field is not empty.
   *
   * @param config - Configuration object containing an optional custom message.
   * @returns RegisterOptions for react-hook-form.
   */
  required: ({ message = "This field is required" }: ValidatorConfig = {}): RegisterOptions => ({
    required: { value: true, message },
  }),

  /**
   * Numeric validator generator.
   * Creates a validation function based on a numeric condition.
   *
   * @param condition - A function that takes a number and returns a boolean.
   * @param config - Configuration object containing an optional custom message.
   * @returns A validation function for react-hook-form.
   */
  numeric:
    (condition: (value: number) => boolean, { message }: ValidatorConfig): Validate<unknown, unknown> =>
    value => {
      if (typeof value !== "string" || value.trim() === "") return true
      const num = parseFloat(value)
      return condition(num) || message || "Invalid number"
    },

  /**
   * Integer validator.
   * Ensures the field value is an integer.
   *
   * @param config - Configuration object containing an optional custom message.
   * @returns A validation function for react-hook-form.
   */
  integer: (config: ValidatorConfig = { message: "Please enter a integer" }): Validate<unknown, unknown> =>
    validators.numeric(Number.isInteger, config),

  /**
   * Positive number validator.
   * Ensures the field value is a positive number.
   *
   * @param config - Configuration object containing an optional custom message.
   * @returns A validation function for react-hook-form.
   */
  positiveNumber: (
    config: ValidatorConfig = { message: "Please enter number greater than 0" }
  ): Validate<unknown, unknown> => validators.numeric(num => num > 0, config),

  /**
   * Date in the past validator.
   * Ensures the date is not in the future.
   *
   * @param config - Configuration object containing an optional custom message.
   * @returns A validation function for react-hook-form.
   */
  dateInPast:
    ({ message = "Date cannot be in the future" }: ValidatorConfig = {}): Validate<Date | string, unknown> =>
    value => {
      if (isNaN(Date.parse(String(value)))) return true
      return new Date(value) <= new Date() || message
    },

  /**
   * Date validator.
   * Ensures the date is valid.
   *
   * @param config - Configuration object containing an optional custom message.
   * @returns A validation function for react-hook-form.
   */
  isDate:
    ({ message = "Please enter a valid date" }: ValidatorConfig = {}): Validate<
      string | undefined | null,
      unknown
    > =>
    value => {
      if (value === null || value === undefined || value === "") return true
      const date = parse(value, "yyyy-MM-dd", new Date())
      return isValid(date) || message
    },

  /**
   * Minimum date validator.
   * Ensures the date is not before the minimum date.
   *
   * @param config - Configuration object containing an optional custom message and minimum allowed date.
   * @returns A validation function for react-hook-form.
   */
  minimumDate:
    ({
      message = `Date cannot be before ${format(DEFAULT_MIN_DATE, DATE_FORMAT_DISPLAY)}`,
      minAllowedDate = DEFAULT_MIN_DATE,
    }: ValidatorConfig & { minAllowedDate?: Date } = {}): Validate<string | undefined | null, unknown> =>
    value => {
      if (value === null || value === undefined || value === "") return true
      const date = parse(value, "yyyy-MM-dd", new Date())
      return date >= minAllowedDate || message
    },
}
