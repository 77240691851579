// Global app constants
import {
  ALL_ROLES,
  EXTERNAL_ROLES,
  EXTERNAL_ROLES_VALUES,
  INTERNAL_ROLES,
  USER_ROLES,
} from "common/models/roles"
import { MESSAGE_TOPIC } from "message-broker/topics"

export const getRoleOptionsForRole = (
  role: ALL_ROLES,
  selectedOption: Nullable<ALL_ROLES>
): FilterOption[] => {
  const userRolesOptions = Object.values(USER_ROLES)

  let availableRoles: FilterOption[]
  switch (role) {
    case INTERNAL_ROLES.LEGALOPS_ADMIN:
      availableRoles = userRolesOptions
      break
    case INTERNAL_ROLES.LEGALOPS_MANAGER:
      availableRoles = userRolesOptions.filter(role => EXTERNAL_ROLES_VALUES.includes(role.key))
      break
    case EXTERNAL_ROLES.FIRM_ADMIN:
      availableRoles = userRolesOptions.filter(role => EXTERNAL_ROLES_VALUES.includes(role.key))
      break
    case EXTERNAL_ROLES.OFFICE_ADMIN:
      availableRoles = userRolesOptions.filter(
        role =>
          EXTERNAL_ROLES_VALUES.includes(role.key) &&
          role.key !== EXTERNAL_ROLES.FIRM_ADMIN &&
          role.key !== EXTERNAL_ROLES.OFFICE_ADMIN
      )
      break
    case EXTERNAL_ROLES.ATTORNEY:
    case EXTERNAL_ROLES.SUPPORT:
    case INTERNAL_ROLES.LEGALOPS:
    default:
      availableRoles = []
  }
  if (selectedOption && !availableRoles.find(role => role.key === selectedOption)) {
    availableRoles.push(USER_ROLES[selectedOption])
  }
  return availableRoles
}

export const FILTER_OPTION_ALL: FilterOption = {
  key: 0,
  display: "All",
}

export enum States {
  ALABAMA = "AL",
  ALASKA = "AK",
  ARIZONA = "AZ",
  ARKANSAS = "AR",
  CALIFORNIA = "CA",
  COLORADO = "CO",
  CONNECTICUT = "CT",
  DELAWARE = "DE",
  DISTRICT_OF_COLUMBIA = "DC",
  FLORIDA = "FL",
  GEORGIA = "GA",
  HAWAII = "HI",
  IDAHO = "ID",
  ILLINOIS = "IL",
  INDIANA = "IN",
  IOWA = "IA",
  KANSAS = "KS",
  KENTUCKY = "KY",
  LOUISIANA = "LA",
  MAINE = "ME",
  MARYLAND = "MD",
  MASSACHUSETTS = "MA",
  MICHIGAN = "MI",
  MINNESOTA = "MN",
  MISSISSIPPI = "MS",
  MISSOURI = "MO",
  MONTANA = "MT",
  NEBRASKA = "NE",
  NEVADA = "NV",
  NEW_HAMPSHIRE = "NH",
  NEW_JERSEY = "NJ",
  NEW_MEXICO = "NM",
  NEW_YORK = "NY",
  NORTH_CAROLINA = "NC",
  NORTH_DAKOTA = "ND",
  OHIO = "OH",
  OKLAHOMA = "OK",
  OREGON = "OR",
  PENNSYLVANIA = "PA",
  RHODE_ISLAND = "RI",
  SOUTH_CAROLINA = "SC",
  SOUTH_DAKOTA = "SD",
  TENNESSEE = "TN",
  TEXAS = "TX",
  UTAH = "UT",
  VERMONT = "VT",
  VIRGINIA = "VA",
  WASHINGTON = "WA",
  WEST_VIRGINIA = "WV",
  WISCONSIN = "WI",
  WYOMING = "WY",
}

export const INT4_MAX = 2147483647

export const HEADER_NAV_HEIGHT_SPACE_MULTIPLIER = 10
export const HEADER_NAV_SCROLLTO_SPACE_MULTIPLIER = 12

export const FOOTER_HEIGHT_MULTIPLIER = 4

export const FOOTER_MARGIN_TOP_MULTIPLIER = 2

export const FOOTER_MARGIN_BOTTOM_MULTIPLIER = 1

export const FOOTER_TOTAL_HEIGHT_MULTIPLIER =
  FOOTER_HEIGHT_MULTIPLIER + FOOTER_MARGIN_TOP_MULTIPLIER + FOOTER_MARGIN_BOTTOM_MULTIPLIER

export const HEADER_NAV_Z_INDEX = 2

export const APP_ID = "app-id"

export const APP_HEADER_ID = "app-header-id"

export const APP_FOOTER_ID = "app-footer-id"

export const APP_CONTENT_ID = "app-content-id"

export const DEFAULT_WINDOW_CONFIGURATIONS = "toolbar=0,location=0,menubar=0"

export const DEFAULT_PREVIEW_WIDTH = 800

export const MESSAGE_TOPIC_ROUTES: Partial<Record<MESSAGE_TOPIC, string>> = {
  [MESSAGE_TOPIC.RENDER_PDF]: "/exhibit-preview",
  [MESSAGE_TOPIC.PREVIEW_EXHIBIT]: "/exhibit-preview",
  [MESSAGE_TOPIC.PREVIEW_USER_EXHIBIT]: "/exhibit-preview",
  [MESSAGE_TOPIC.PREVIEW_USER_EXHIBIT_PARTITION]: "/exhibit-preview",
}

window.__IMAGE_RC__ = import.meta.env.VITE_IMAGE_RC
// eslint-disable-next-line no-console
console.info("EvenUp build", window.__IMAGE_RC__)

export const APP_VERSION = import.meta.env.VITE_BUILD_VERSION

export const BUILD_TIMESTAMP = __BUILD_TIMESTAMP__

export const AUTHENTICATE_ROUTE = "/authenticate"
