import { useState, useEffect } from "react"
import { Tooltip } from "@mui/material"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { UserExhibitProcessingStatus } from "exhibit-builder/store/types"
import { ComponentPropsWithRef, useCallback } from "react"
import { DialogActions, DialogContent } from "@mui/material"
import * as Sentry from "@sentry/react"
import Button from "evenup-ui/Button"
import { StyledDialog, StyledDialogDescription, StyledDialogHeader } from "./styled"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { useQueryClient } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { useAlertModal } from "exhibit-builder/AlertModal"
import { usePublish } from "message-broker/usePublish"
import { MESSAGE_TOPIC } from "message-broker/topics"
interface UpdateSummariesButtonProps extends ComponentPropsWithRef<typeof Button> {
  size?: "small" | "medium" | "large"
  documentId: string
  showDisabledTooltip?: boolean
  modalDescription?: string
}

type UpdateSummariesConfirmationDialogProps = {
  open: boolean
  onClose: () => void
  navigateToMedicalSummary: () => void
  documentId: string
  onRegenerateSummariesComplete: () => void
}

export const useRegenerateSummariesComplete = (documentId: string) => {
  const queryClient = useQueryClient()
  return useCallback(() => {
    queryClient.invalidateQueries([queryKeys.documentData, documentId])
  }, [queryClient, documentId])
}

export const UpdateSummariesConfirmationDialog = ({
  open,
  onClose,
  documentId,
  navigateToMedicalSummary,
  onRegenerateSummariesComplete,
}: UpdateSummariesConfirmationDialogProps) => {
  const confirmAction = useCallback(() => {
    exhibitBuilderActions.regenerateSummaries({ documentId, onRegenerateSummariesComplete })
    navigateToMedicalSummary()
  }, [documentId, navigateToMedicalSummary, onRegenerateSummariesComplete])

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <StyledDialogHeader variant="dialogHeader" component="h3">
          Confirm to Update Summaries
        </StyledDialogHeader>
        <StyledDialogDescription component="p">
          Some changes made require appointments to be updated on the Medical Summary page. Confirm to update
          appointments and proceed to the Medical Summary.
        </StyledDialogDescription>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button color="primary" onClick={confirmAction}>
          Confirm Updates
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export function UpdateSummariesButton({
  size = "medium",
  documentId,
  showDisabledTooltip,
  modalDescription,
  ...rest
}: UpdateSummariesButtonProps) {
  const shouldUpdateSummaries = useExhibitBuilderStore(state => state.shouldUpdateSummaries)
  const userExhibitMap = useExhibitBuilderStore(state => state.userExhibitMap)
  const userExhibitProcessingStatuses = Object.values(userExhibitMap).map(
    ({ processingStatus }) => processingStatus
  )

  const userExhibitsProcessing = userExhibitProcessingStatuses.length
    ? userExhibitProcessingStatuses.some(
        status =>
          status === UserExhibitProcessingStatus.NOT_STARTED ||
          status === UserExhibitProcessingStatus.IN_PROGRESS
      )
    : false

  const shouldRegenerate = shouldUpdateSummaries && !userExhibitsProcessing

  const [isDisabled, setIsDisabled] = useState(false)
  const { showErrorMessage } = useHandleMessages()
  const onRegenerateSummariesComplete = useRegenerateSummariesComplete(documentId)

  const disabledTooltip = userExhibitsProcessing
    ? "Please wait for all exhibits to finish processing"
    : "Import Provider and Date of Service changes require updates to Medical Summaries"

  const handleClick = useCallback(async () => {
    if (!shouldRegenerate) return

    setIsDisabled(true)
    try {
      await exhibitBuilderActions.regenerateSummaries({ documentId, onRegenerateSummariesComplete })
    } catch (error) {
      showErrorMessage("Unable to update the summaries. Please try again.")
      Sentry.captureException(error)
    } finally {
      setIsDisabled(false)
    }
  }, [shouldRegenerate, documentId, showErrorMessage, onRegenerateSummariesComplete])

  const publish = usePublish()

  useEffect(() => {
    // Sync data with the Medical Summaries page
    publish(MESSAGE_TOPIC.MEDICAL_CHRONOLOGY_NEEDS_REGENERATION, {
      documentId,
      shouldUpdateSummaries: shouldRegenerate,
    }).catch(e =>
      Sentry.captureException("Could not publish message", {
        extra: {
          topic: MESSAGE_TOPIC.MEDICAL_CHRONOLOGY_NEEDS_REGENERATION,
          documentId,
          shouldUpdateSummaries: shouldRegenerate,
          error: e,
        },
      })
    )
  }, [publish, documentId, shouldRegenerate])

  const { openModal } = useAlertModal({
    title: "Update Summaries",
    content: (
      <StyledDialogDescription component="p">
        {modalDescription ||
          "Ensure all exhibit actions are finalized before updating summaries. Updates can take time to process."}
      </StyledDialogDescription>
    ),
    confirmLabel: "Update Summaries",
    closeLabel: "Cancel",
    onConfirm: handleClick,
  })

  const button = (
    <Button
      disabled={isDisabled || !shouldUpdateSummaries || userExhibitsProcessing}
      color="primary"
      size={size}
      onClick={openModal}
      {...rest}
    >
      Update Summaries
    </Button>
  )

  return (
    <>
      {!shouldRegenerate && showDisabledTooltip ? (
        <Tooltip title={disabledTooltip}>
          <div>{button}</div>
        </Tooltip>
      ) : (
        <>{button}</>
      )}
    </>
  )
}
