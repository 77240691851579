import { useCallback } from "react"

import { ExhibitPartition } from "exhibit-builder/store/types"
import { openWindow } from "common/windows"
import { useDocumentStore } from "documents/store"
import { userExhibitsSelectors } from "documents/store/userExhibits"
import { usePublish } from "message-broker/usePublish"
import { PreviewUserExhibitPayload } from "message-broker/types"
import { MESSAGE_TOPIC } from "message-broker/topics"
import { getUserExhibitPreviewPath } from "pdf-preview/utils"
import { UserExhibit } from "exhibit-builder/store/types"

export type UseUserExhibitPreviewReturn = (userExhibitId: UserExhibit["id"], page?: Nullable<number>) => void
type UseExhibitPreviewByIdReturn = (partition?: ExhibitPartition, page?: Nullable<number>) => void

export function useUserExhibitPreview(): UseUserExhibitPreviewReturn {
  const publish = usePublish()

  const openPreview = useCallback<UseUserExhibitPreviewReturn>(
    (userExhibitId, page) => {
      const userExhibit = userExhibitsSelectors.getUserExhibitById(userExhibitId)(useDocumentStore.getState())

      if (!userExhibit) return

      const payload: PreviewUserExhibitPayload = {
        userExhibitId: userExhibit?.id,
        fileName: userExhibit?.name,
        page: page,
      }

      publish(MESSAGE_TOPIC.PREVIEW_USER_EXHIBIT, payload).catch(() => {
        const path = getUserExhibitPreviewPath(payload)
        if (userExhibit?.id && path) openWindow(`${path.base}/${path.route}`)
      })
    },
    [publish]
  )

  return openPreview
}

export function useUserExhibitPreviewById(userExhibitId: UserExhibit["id"]): UseExhibitPreviewByIdReturn {
  const userExhibitOrder = useDocumentStore(state => state.userExhibitOrder)
  const userExhibit = useDocumentStore(userExhibitsSelectors.getUserExhibitById(userExhibitId))
  const { name } = userExhibit ?? {}
  const index = userExhibitOrder.findIndex(id => userExhibitId === id)
  const publish = usePublish()

  const openPreview = useCallback(
    (partition?: ExhibitPartition, page?: Nullable<number>) => {
      const payload: PreviewUserExhibitPayload = {
        userExhibitId,
        fileName: `Exhibit ${index + 1} - ${name}`,
        page: page ?? partition?.startPage,
      }

      publish(MESSAGE_TOPIC.PREVIEW_USER_EXHIBIT, payload).catch(() => {
        const path = getUserExhibitPreviewPath(payload)

        if (path) openWindow(`${path.base}/${path.route}`)
      })
    },
    [publish, name, userExhibitId, index]
  )

  return openPreview
}
