import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { BulletPointResult, PROVIDER_SUMMARY_STATUS_LITERALS, Result } from "api/services/summaries/types"
import { exhibitService } from "api/services/exhibit"
import { useQuery } from "@tanstack/react-query"
import { queryKeys, SILENT_QUERY_PARAMS } from "react-query/constants"

import CommaEntryList from "./LineEntryList"

import { DateTitle, Header, ImportantMedicalPassageContainer, InjuryDetailContainer } from "./styled"
import { useStyles } from "../styled"

import { ImportantMedicalPassage, InjuryDetail } from "./types"
import { Entry } from "../types"
import ContainerLabel from "../ContainerLabel"
import { formatDate } from "utils"
import { AskAIButton } from "common/AskAI"
import { AskAISection } from "demand/components/CaseEditor"
import { Alert, Skeleton, Tab, Tabs, Typography } from "@mui/material"
import { useTheme } from "@emotion/react"
import BulletPointSummary from "./BulletPointSummary"
import { useUserPermissions } from "permissions/usePermissions"
import { FEATURES, useFeatures } from "../../../../../hooks/useFeatures"

function a11yProps(index: number) {
  return {
    id: `injurydetails-tab-${index}`,
    "aria-controls": `injurydetails-tabpanel-${index}`,
  }
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`injurydetails-tabpanel-${index}`}
      aria-labelledby={`injurydetails-tab-${index}`}
      sx={{ marginTop: 2 }}
    >
      {children}
    </Box>
  )
}

export enum VisitType {
  FIRST = "FIRST",
  SUBSEQUENT = "SUBSEQUENT",
  LAST = "LAST",
}

function hasSummaryOrError(status?: PROVIDER_SUMMARY_STATUS_LITERALS) {
  return status === "success" || status === "error"
}

interface InjuryDetailRecordProps {
  date: string
  injuryDetails: InjuryDetail
  onEntryClick: (entry: Entry) => void
  open: boolean
  onToggleOpen: (date: string) => void
  annotationExtractUpdated: boolean
  caseId: PrimaryKey
  providerName: string
  visitType: VisitType
  generatorKeys?: string[]
}

const InjuryDetailRecord = ({
  date,
  injuryDetails,
  onEntryClick,
  open,
  onToggleOpen,
  annotationExtractUpdated,
  caseId,
  providerName,
  visitType,
  generatorKeys,
}: InjuryDetailRecordProps): JSX.Element => {
  const { isFeatureEnabled } = useFeatures()
  const removeImpsEnabled = isFeatureEnabled(FEATURES.REMOVE_IMPS)
  const { classes } = useStyles()
  const theme = useTheme()
  const [openAskAi, setOpenAskAi] = useState(false)
  const [activeTab, setActiveTab] = useState(injuryDetails.dos_summary ? 0 : 1)
  const [bulletPoints, setBulletPoints] = useState<Nullable<BulletPointResult[]>>(null)
  const [parseError, setParseError] = useState<boolean>(false)
  const { bulletPointSummaryEnabled } = useUserPermissions({ suspense: false })
  const [summaryError, setSummaryError] = useState<Nullable<string>>(null)
  const refetchCounter = useRef(0)

  const {
    medical_professionals = [],
    statements_on_causality = [],
    important_medical_passages = [],
    medical_passages = [],
    referrals = [],
  } = injuryDetails

  const handleEntryClick = useCallback(
    (entry: Entry) => {
      onEntryClick(entry)
    },
    [onEntryClick]
  )
  const handleToggleOpen = useCallback(() => {
    onToggleOpen(date)
  }, [onToggleOpen, date])

  const handleAskAi = useCallback(() => {
    setOpenAskAi(!openAskAi)
  }, [setOpenAskAi, openAskAi])

  const handleActiveTabChange = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      setActiveTab(newValue)
    },
    [setActiveTab]
  )

  const parseBulletPoints = (result: Nullable<Result>) => {
    if (result?.result) {
      try {
        if (typeof result.result === "string") {
          const parsedResult = JSON.parse(result.result)

          if (parsedResult === null) {
            setBulletPoints(null)
          } else {
            setBulletPoints(Array.isArray(parsedResult) ? parsedResult : [parsedResult])
          }
        } else {
          setBulletPoints(result.result)
        }
      } catch (e) {
        setParseError(true)
      }
    }
    return
  }

  const { data: summaryStatus } = useQuery(
    [queryKeys.dosSummaryStatus, injuryDetails.dos_summary.pk],
    () => exhibitService.getDOSSummaryStatus(injuryDetails.dos_summary.pk),
    {
      ...SILENT_QUERY_PARAMS,
      enabled: !hasSummaryOrError(injuryDetails.dos_summary.summary?.result?.status),
      refetchInterval(data) {
        refetchCounter.current += 1
        // 2 minutes
        if (refetchCounter.current > 60) {
          setSummaryError("The summary is taking longer than expected to generate. Please try again later.")
          return false
        }
        return hasSummaryOrError(data?.status) ? false : 2000
      },
    }
  )

  const { data: dosSummary, isFetching: isDosSummaryLoading } = useQuery(
    [queryKeys.exhibits, injuryDetails.dos_summary.pk],
    () => exhibitService.getDOSSummary(injuryDetails.dos_summary.pk),
    {
      ...SILENT_QUERY_PARAMS,
      enabled: hasSummaryOrError(summaryStatus?.status),
      placeholderData: injuryDetails.dos_summary,
    }
  )

  const summaryResult = useMemo(() => {
    if (dosSummary?.error || dosSummary?.summary?.result?.status === "error") {
      return dosSummary?.summary?.result || null
    }
    if (dosSummary?.summary?.result?.result && dosSummary.summary.result.status === "success") {
      return dosSummary.summary.result
    }

    return null
  }, [dosSummary])

  const getDetails = () => {
    const details = []
    if (medical_passages.length > 0) {
      bulletPoints?.forEach(bulletPoint => {
        if (!bulletPoint.values?.length) {
          return
        }
        details.push(bulletPoint.header)
        bulletPoint.values?.forEach(value => {
          details.push(`- ${value.text}`)
        })
      })
    } else if (important_medical_passages.length > 0) {
      if (medical_professionals.length > 0) {
        details.push(`Attended by: ${medical_professionals.map(({ value }) => value).join(", ")}`)
      }
      if (statements_on_causality.length > 0) {
        details.push(
          `Statement on Causality: ${statements_on_causality.map(({ value }) => value).join(", ")}`
        )
      }
      if (referrals.length > 0) {
        details.push(`Referrals: ${referrals.map(({ value }) => value).join(", ")}`)
      }
      details.push(
        // add extra new line if there are details before important medical passages
        `${details.length > 0 ? "\n" : ""}${important_medical_passages.map(({ value }) => value).join("\n")}`
      )
    }
    return details.join("\n")
  }

  const IMPSection = important_medical_passages.map(
    (importantMedicalPassage: ImportantMedicalPassage, index) => {
      const { value, entries } = importantMedicalPassage
      return (
        <ImportantMedicalPassageContainer
          mb={2}
          key={`${index}_${value}`}
          className={classes.entry}
          onClick={() => {
            handleEntryClick(entries[0])
          }}
        >
          {value}
        </ImportantMedicalPassageContainer>
      )
    }
  )
  const IMPTabs = (
    <Box>
      <Tabs
        indicatorColor="secondary"
        value={activeTab}
        onChange={handleActiveTabChange}
        aria-label={`View Summary${!removeImpsEnabled ? " or IMP" : ""}`}
        sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
      >
        <Tab sx={{ textTransform: "none" }} label="Summary" {...a11yProps(0)} />
        {!removeImpsEnabled && !!important_medical_passages.length && (
          <Tab sx={{ textTransform: "None" }} label="IMPs" {...a11yProps(1)} />
        )}
      </Tabs>
      <TabPanel value={activeTab} index={0}>
        {summaryError && (
          <Alert variant="outlined" severity="error" sx={{ paddingX: 4, paddingY: 10 }}>
            {summaryError}
          </Alert>
        )}
        {!summaryError && summaryResult?.status === "success" && bulletPoints && (
          <BulletPointSummary caseId={caseId} parseError={parseError} bulletPoints={bulletPoints} />
        )}
        {(isDosSummaryLoading || !hasSummaryOrError(dosSummary?.summary?.result?.status)) && (
          <BulletPointSummaryLoading />
        )}
      </TabPanel>
      {!removeImpsEnabled && (
        <TabPanel value={activeTab} index={1}>
          {IMPSection}
        </TabPanel>
      )}
    </Box>
  )

  useEffect(() => {
    requestAnimationFrame(() => {
      parseBulletPoints(summaryResult)
    })

    setSummaryError(
      !!dosSummary?.error || dosSummary?.summary?.result?.status === "error"
        ? dosSummary.error ??
            dosSummary.summary?.result?.error ??
            "Something went wrong while generating the summary."
        : null
    )
  }, [summaryResult, dosSummary])

  return (
    <InjuryDetailContainer>
      <Header onClick={handleToggleOpen}>
        <DateTitle>{formatDate(date, "MMMM dd, yyyy", true)}</DateTitle>
        <Box sx={{ display: "flex" }}>
          <AskAIButton size="small" onClick={handleAskAi} />
          <Box mr={2} alignSelf="center">
            <ContainerLabel includeAnnotationExtractUpdated={annotationExtractUpdated} />
          </Box>
          <IconButton>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
        </Box>
      </Header>
      <AskAISection
        open={openAskAi}
        caseId={caseId}
        onClose={handleAskAi}
        defaultText={getDetails()}
        generatorKeys={generatorKeys}
        fieldDefaults={
          new Map([
            ["date_of_service", formatDate(date, "yyyy-MM-dd", true)],
            ["medical_provider", providerName],
            ["visit_type", visitType],
            // TODO(TTX-17935): Once 0-click Summaries models are deployed, these
            //   fields are deprecated
            ["visit_date", formatDate(date, "yyyy-MM-dd", true)],
            ["provider_name", providerName],
          ])
        }
        additionalInfo={medical_passages.length == 0 && bulletPointSummaryEnabled && IMPTabs}
      />
      <Collapse in={open && !openAskAi}>
        <Box sx={{ lineHeight: 1.5 }}>
          {!!medical_professionals.length && (
            <Box mt={2}>
              <CommaEntryList
                data={medical_professionals}
                title={"Attended by:"}
                onEntryClick={onEntryClick}
                separator={","}
              />
            </Box>
          )}
          {!!statements_on_causality.length && (
            <Box mt={2}>
              <CommaEntryList
                data={statements_on_causality}
                title={"Statement on Causality:"}
                onEntryClick={onEntryClick}
                separator={";"}
              />
            </Box>
          )}
          {!!referrals.length && (
            <Box mt={2}>
              <CommaEntryList
                data={referrals}
                title={"Referrals:"}
                onEntryClick={onEntryClick}
                separator={";"}
              />
            </Box>
          )}

          {bulletPointSummaryEnabled && (!!important_medical_passages.length || !!medical_passages.length) ? (
            <Box mt={1}>{IMPTabs}</Box>
          ) : (
            !removeImpsEnabled && <Box mt={2}>{IMPSection}</Box>
          )}
        </Box>
      </Collapse>
    </InjuryDetailContainer>
  )
}

const BulletPointSummaryLoading = () => {
  return (
    <Box data-test="loading-summary">
      <Skeleton variant="text" animation="wave" width="180px" />
      <Box sx={{ paddingLeft: 1 }}>
        <Skeleton variant="text" animation="wave" />
        <Skeleton variant="text" animation="wave" />
        <Skeleton variant="text" animation="wave" />
      </Box>
      <Box sx={{ textAlign: "center", marginTop: 2 }}>
        <Typography fontSize="14px" fontWeight={700}>
          We are currently generating your AI summary...
        </Typography>
        <Typography fontSize="12px">This will take a few minutes to process.</Typography>
      </Box>
    </Box>
  )
}

export { InjuryDetailRecord as default }
