import { ProjectionItemWithChildren } from "common/nested-list/types"
import { ListItem } from "./types"
import { UserExhibit } from "exhibit-builder/store/types"
import { useId, useMemo } from "react"
import { useExhibitBuilderStore } from "exhibit-builder/store"
import { useShallow } from "zustand/react/shallow"
import { ITEM_REF } from "common/nested-list/constants"
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  inputLabelClasses,
} from "@mui/material"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import styled from "@emotion/styled"
import { isPlaceholderUserExhibit } from "exhibit-builder/store/utils"

const ListItemContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(3),
}))

const DropdownFormControl = styled(FormControl)<{ isAnchor: boolean }>(({ isAnchor }) => ({
  width: "460px",

  ...(!isAnchor && {
    "& legend > span": {
      display: "none",
    },
    [`& .${inputLabelClasses.root}`]: {
      transition: "none",
    },
    [`& .${inputLabelClasses.shrink}`]: {
      opacity: 0,
      transition: "none",
      animation: "none",
      transform: "none",
    },
  }),
}))

const ANCHOR_EXHIBIT_LABEL = "Anchor Exhibit"
const DROPDOWN_LABEL = "Select"

interface ExhibitListItemProps {
  item: ProjectionItemWithChildren<ListItem>
  selectedItems: ListItem[]
  onChange: (itemId: string, userExhibitId: UserExhibit["id"]) => void
  onDelete: (itemId: string) => void
}

export function ExhibitListItem({ item, selectedItems, onChange, onDelete }: ExhibitListItemProps) {
  const labelId = useId()
  const userExhibitMap = useExhibitBuilderStore(useShallow(state => state.userExhibitMap))
  const userExhibitOrder = useExhibitBuilderStore(useShallow(state => state.userExhibitOrder))
  const listItem = item[ITEM_REF]
  const isAnchor = selectedItems[0].id === listItem.id
  const handleChange = (event: SelectChangeEvent<string>) => {
    const userExhibitId = event.target.value as UserExhibit["id"]
    onChange(listItem.id, userExhibitId)
  }
  const handleDelete = () => {
    onDelete(listItem.id)
  }

  const options = useMemo(
    () => userExhibitOrder.filter(id => !isPlaceholderUserExhibit(userExhibitMap[id])),
    [userExhibitMap, userExhibitOrder]
  )

  return (
    <ListItemContainer data-test="exhibit-list-item">
      <DropdownFormControl isAnchor={isAnchor}>
        <InputLabel size="small" id={labelId}>
          {isAnchor ? ANCHOR_EXHIBIT_LABEL : DROPDOWN_LABEL}
        </InputLabel>
        <Select
          data-test={isAnchor ? "anchor-dropdown" : "exhibit-dropdown"}
          size="small"
          label={isAnchor ? ANCHOR_EXHIBIT_LABEL : DROPDOWN_LABEL}
          labelId={labelId}
          value={listItem.userExhibitId}
          onChange={handleChange}
          disabled={listItem.disabled}
          required
        >
          {options.map(id => (
            <MenuItem key={id} value={id} disabled={selectedItems.some(item => item.userExhibitId === id)}>
              Exhibit {userExhibitMap[id].index + 1} - {userExhibitMap[id].name}
            </MenuItem>
          ))}
        </Select>
      </DropdownFormControl>
      {!listItem.disabled && (
        <IconButton aria-label="delete exhibit" onClick={handleDelete}>
          <HighlightOffIcon />
        </IconButton>
      )}
    </ListItemContainer>
  )
}
