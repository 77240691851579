import { UpdateCitationStatus, UserExhibit } from "exhibit-builder/store/types"
import { DocumentData, GetState, SetState } from "./types"

export type UserExhibitsSlice = Pick<
  DocumentData,
  | "userExhibitMap"
  | "userExhibitOrder"
  | "userExhibitPartitionMap"
  | "userExhibitPartitionOrder"
  | "updateCitationStatus"
>

export const userExhibitsSlice: UserExhibitsSlice = {
  userExhibitMap: {},
  userExhibitOrder: [],
  userExhibitPartitionMap: {},
  userExhibitPartitionOrder: {},
  updateCitationStatus: UpdateCitationStatus.NOT_STARTED,
}

export const userExhibitsSelectors = {
  getUserExhibitById:
    (id: UserExhibit["id"]) =>
    (state: UserExhibitsSlice): UserExhibit | undefined =>
      state.userExhibitMap[id],
  getUserExhibitsList: (state: UserExhibitsSlice) =>
    state.userExhibitOrder
      .map(id => userExhibitsSelectors.getUserExhibitById(id)(state))
      .filter(Boolean) as UserExhibit[],
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const userExhibitsActions = (set: SetState<UserExhibitsSlice>, get: GetState<UserExhibitsSlice>) => {
  const setUserExhibitData = (data: UserExhibitsSlice) => set(data)
  return {
    setUserExhibitData,
  }
}
