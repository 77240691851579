import { useDocumentStore } from "documents/store"
import { partitionsSelectors } from "documents/store/partitions"
import { userExhibitPartitionsSelectors } from "documents/store/userExhibitPartitions"
import { PastMedicalVisitRelationComponent, PastMedicalVisitRelationUserExhibitComponent } from "./components"
import { PastMedicalVisitReferenceProps } from "./types"

export const PastMedicalVisitReference = ({ relation, isUserExhibit }: PastMedicalVisitReferenceProps) => {
  const userExhibitPartition = useDocumentStore(
    userExhibitPartitionsSelectors.getUserExhibitPartitionById(String(relation.partitionId))
  )
  const partition = useDocumentStore(partitionsSelectors.getPartitionById(relation.partitionId))

  if (isUserExhibit) {
    if (!userExhibitPartition) return null

    return (
      <PastMedicalVisitRelationUserExhibitComponent
        page={userExhibitPartition.startPage}
        partition={userExhibitPartition}
      />
    )
  } else {
    if (!partition) return null

    return <PastMedicalVisitRelationComponent page={partition.startPage} partition={partition} />
  }
}
