import { useEffect, useState } from "react"
import { Box, useTheme } from "@mui/material"
import AssigneePicker, { Segments } from "requests/AssigneePicker"
import { RequestViewDto } from "./types"
import { ObjectWithPk } from "utils/common"
import { useSaveOperatorMutation } from "./hooks/useSaveOperatorMutation"
import useUser from "hooks/useUser"
import { UserDto } from "common/models/user"

interface RequestOperatorsProps {
  request: RequestViewDto
  handleSelfAssign: () => void
}

type OperatorField = "drafted_by" | "reviewed_by" | "reworked_by"

// An operator is someone who has either drafted, reviewed, or reworked a request
export function RequestOperators({ request }: RequestOperatorsProps): JSX.Element {
  const theme = useTheme()
  const { user } = useUser()

  const [operators, setOperators] = useState<Record<OperatorField, UserDto | null>>({
    drafted_by: request.drafted_by ?? null,
    reviewed_by: request.reviewed_by ?? null,
    reworked_by: request.reworked_by ?? null,
  })

  useEffect(() => {
    setOperators({
      drafted_by: request.drafted_by,
      reviewed_by: request.reviewed_by,
      reworked_by: request.reworked_by,
    })
  }, [request])

  const requestOperatorMutation = useSaveOperatorMutation()
  const handleOperatorChange =
    (fieldName: OperatorField) =>
    ({ newValues: value }: { newValues: ObjectWithPk }) => {
      requestOperatorMutation.mutate({ requestId: request.pk, [fieldName]: value ? value.pk : null })

      setOperators(prev => ({
        ...prev,
        [fieldName]: value,
      }))
    }

  if (!user.isInternal) {
    return <></>
  }

  return (
    <Box
      display="grid"
      gap={theme.spacing(1.5)}
      gridTemplateColumns="1fr 1fr 1fr"
      marginTop={theme.spacing(1.5)}
    >
      <AssigneePicker
        singleAssignee
        label="Drafted By"
        value={operators.drafted_by}
        segment={Segments.INTERNAL}
        onChange={handleOperatorChange("drafted_by")}
      />
      <AssigneePicker
        singleAssignee
        label="Reviewed By"
        value={operators.reviewed_by}
        segment={Segments.INTERNAL}
        onChange={handleOperatorChange("reviewed_by")}
      />
      <AssigneePicker
        singleAssignee
        label="Reworked By"
        value={operators.reworked_by}
        segment={Segments.INTERNAL}
        onChange={handleOperatorChange("reworked_by")}
      />
    </Box>
  )
}
