import styled from "@emotion/styled"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  autocompleteClasses,
  Box,
  inputLabelClasses,
  outlinedInputClasses,
  Skeleton,
  tabClasses,
  Tabs,
  tabsClasses,
} from "@mui/material"
import { PROVIDER_SUMMARY_STATUS } from "api/services/summaries/types"
import { theme } from "app/theme"

export const SummariesActionsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(1),
}))

export const SummariesContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1.5),
}))

export const SummaryItemContainer = styled("div")(({ theme }) => ({
  border: "1px solid rgba(0,0,0,0.12)",
  padding: theme.spacing(1),
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
}))

export const SummaryItemSkeleton = styled(Skeleton)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: "10px",
  height: theme.spacing(5),
  transform: "none",
}))

export const SummaryItemHeader = styled("div")(({ theme }) => ({
  marginTop: "2px",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  cursor: "pointer",
}))

export const ExpandIcon = styled(ExpandMoreIcon)<{ open: boolean }>(({ open }) => ({
  width: "20px",
  height: "20px",
  transition: "transform 0.3s ease",
  transform: `rotate(-${open ? 180 : 0}deg)`,
}))

const COLOR_MAP: Record<PROVIDER_SUMMARY_STATUS, string> = {
  [PROVIDER_SUMMARY_STATUS.PENDING]: theme.palette.warning.light,
  [PROVIDER_SUMMARY_STATUS.IN_PROGRESS]: theme.palette.warning.light,
  [PROVIDER_SUMMARY_STATUS.SUCCESS]: theme.palette.success.light,
  [PROVIDER_SUMMARY_STATUS.ERROR]: theme.palette.red[600],
  [PROVIDER_SUMMARY_STATUS.HUNG]: theme.palette.red[600],
}

export const StatusMarker = styled("span")<{ status: PROVIDER_SUMMARY_STATUS }>(({ status }) => ({
  width: "10px",
  height: "10px",
  borderRadius: "5px",
  display: "block",

  backgroundColor: COLOR_MAP[status],
}))

export const SummaryTabsContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 0.5),
}))

export const SummaryTabs = styled(Tabs)(({ theme }) => ({
  minHeight: theme.spacing(4),
  padding: theme.spacing(0.5, 0, 1.5),

  [`.${tabsClasses.flexContainer}`]: {
    gap: theme.spacing(2.5),
  },

  [`.${tabsClasses.indicator}`]: {
    height: "3px",
  },

  [`.${tabClasses.selected}`]: {
    fontWeight: 700,
  },

  [`.${tabClasses.root}`]: {
    padding: 0,
    minHeight: "18px",
    minWidth: 0,
    marginTop: theme.spacing(1.125),
    marginBottom: theme.spacing(1.5),
  },
}))

export const SummaryForm = styled(Box)(({ theme }) => ({
  maxWidth: "150px",
  flex: "1 0 auto",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1.5),

  margin: theme.spacing(1.125, 0),

  [`.${inputLabelClasses.outlined}`]: {
    fontSize: "9.5px",
    lineHeight: "14px",
    color: "#49454F",
    letterSpacing: "-0.12px",

    [`&.${inputLabelClasses.shrink}`]: {
      top: "3px",
      textOverflow: "unset",
    },
  },

  [`.${outlinedInputClasses.notchedOutline} legend`]: {
    fontSize: "8px",
    letterSpacing: "-0.5px",
  },

  [`.${autocompleteClasses.root}`]: {
    minWidth: "0 !important",
  },

  [`.${outlinedInputClasses.notchedOutline}`]: {
    borderWidth: "1px !important",
  },

  [`.${outlinedInputClasses.input}`]: {
    fontSize: "10.5px",
    letterSpacing: "-0.1px",
  },

  ".base-FormControl-root": {
    margin: 0,
  },
}))

export const StyledAutocompleteOption = styled("li")(({ theme }) => ({
  "&&": {
    padding: theme.spacing(0.5, 1.5),
    fontSize: "12px",
  },
}))

export const SummaryTabContent = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(3.5),
}))

export const EditorContainer = styled("div")<{ empty: boolean }>(({ theme, empty }) => ({
  border: "1px solid rgba(0,0,0,0.24)",
  borderRadius: "5px",

  background: empty ? "none" : theme.palette.background.default,
  height: empty ? "100px" : "auto",

  padding: theme.spacing(1.5, 2.5),

  fontSize: "12px",
  lineHeight: "16px",

  "ul, ol": {
    margin: theme.spacing(0.5, 0),
    paddingLeft: theme.spacing(1.5),
  },

  p: {
    marginBlockStart: theme.spacing(0.5),
    marginBlockEnd: theme.spacing(0.5),
  },
}))

export const SummaryButtonsContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  display: "flex",
  gap: theme.spacing(2),
}))

export const InlineRegenerateButton = styled("button")({
  textDecoration: "underline",
  fontWeight: "bold",
  border: 0,
  background: "none",
  color: "inherit",
  cursor: "pointer",
  padding: 0,
})

export const CitationsContainer = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(1),

  ul: {
    margin: 0,
    paddingLeft: theme.spacing(4),
  },
}))

export const ProviderWrapper = styled("div")({
  "[data-summary_error='true']": {
    position: "relative",

    "&:before": {
      content: '""',
      position: "absolute",
      zIndex: -1,
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
      background: "#FEEBEE",
    },

    "&:is(li):before": {
      left: "-24px",
    },
  },
})
