import { Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { filesSelectors } from "exhibit-builder/store/files/filesSelectors"
import { ExhibitFile, UserExhibit } from "exhibit-builder/store/types"
import { FormEventHandler, useMemo, useState } from "react"
import { useShallow } from "zustand/react/shallow"
import { SortableRangeInput } from "../../../../evenup-ui/SortableRangeInput/SortableRangeInput"
import { RangeItem } from "../../../../evenup-ui/SortableRangeInput/types"
import { BottomButtonGroup } from "../BottomButtonGroup"
import { ExhibitFileDropdown } from "../ExhibitFileDropdown"
import { ActionButton, useAction } from "../../../ActionButton"

const FILE_INPUT_LABEL = "Extraction from file:"

export function Extract({ id, onClose }: { id: UserExhibit["id"]; onClose: () => void }) {
  const partitions = useExhibitBuilderStore(useShallow(filesSelectors.getUserExhibitPartitions(id)))
  const [selectedPartition, setSelectedPartition] = useState<string | undefined>(partitions[0]?.id)
  const [deleteOriginal, setDeleteOriginal] = useState(true)
  const [combineExtraction, setCombineExtraction] = useState(false)
  const [selectedPageRanges, setSelectedPageRanges] = useState<RangeItem[]>([])

  const handlePartitionChange = (id: ExhibitFile["id"]) => {
    setSelectedPartition(id)
  }

  const toggleDeletePagesAfterExtraction = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeleteOriginal(event.target.checked)
  }

  const toggleCombineExtraction = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCombineExtraction(event.target.checked)
  }

  const handleRangeChange = (ranges: RangeItem[]) => {
    setSelectedPageRanges(ranges)
  }

  const { handleAction, isLoading } = useAction({
    name: "extract partition",
    action: async () => {
      if (!selectedPartition) {
        return
      }

      const newUserExhibits = await exhibitBuilderActions.extractPartition({
        userExhibitId: id,
        partitionId: selectedPartition,
        pageRanges: selectedPageRanges.map(range => ({
          start_page: Number(range.startPage),
          end_page: Number(range.endPage),
        })),
        deleteOriginal,
        combineExtraction,
      })

      if (newUserExhibits.length) {
        exhibitBuilderActions.setScrollToIndex(newUserExhibits[0].index)
      }
    },
  })

  const isValid =
    selectedPageRanges.length > 0 &&
    selectedPageRanges.every(range => {
      return range.startPage && range.endPage && Object.values(range.errors).every(error => !error)
    })

  const extract: FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault()

    if (!isValid) {
      return
    }

    await handleAction()
    onClose()
  }

  const selectedPartitionRange = useMemo(() => {
    const partition = partitions.find(partition => partition.id === selectedPartition)
    return { start: partition?.startPage ?? 1, end: partition?.endPage ?? 1 }
  }, [partitions, selectedPartition])

  return (
    <form onSubmit={extract} data-test="extract-form">
      <ExhibitFileDropdown
        partitions={partitions}
        selectedPartition={selectedPartition || ""}
        label={FILE_INPUT_LABEL}
        onChange={handlePartitionChange}
      />
      <SortableRangeInput
        key={selectedPartition}
        onChange={handleRangeChange}
        range={selectedPartitionRange}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={deleteOriginal}
              onChange={toggleDeletePagesAfterExtraction}
              data-test="delete-original-checkbox"
            />
          }
          label={<Typography variant="body2">Delete pages from main file after extraction</Typography>}
        />
        {selectedPageRanges.length > 1 && (
          <FormControlLabel
            control={<Checkbox size="small" value={combineExtraction} onChange={toggleCombineExtraction} />}
            label={<Typography variant="body2">Combine extraction pages ranges into one exhibit</Typography>}
          />
        )}
      </FormGroup>
      <BottomButtonGroup
        onClose={onClose}
        actionButton={
          <ActionButton
            data-test="extract-button"
            label="Extract"
            disabled={!isValid}
            isLoading={isLoading}
          />
        }
      />
    </form>
  )
}
