import { isEmpty } from "lodash"
import { documentStoreSelectors, useDocumentStore } from "../store"
import { FlagsTable } from "./FlagsTable"
import { flagsDataSelectors } from "documents/store/flags"

type FlagsListProps = {
  exhibitBuilderOn: boolean
}

export const FlagsList = ({ exhibitBuilderOn }: FlagsListProps) => {
  const allFlags = useDocumentStore(flagsDataSelectors.getFlags)
  const flags = useDocumentStore(documentStoreSelectors.filteredFlags)

  if (isEmpty(allFlags)) return null

  return <FlagsTable flags={flags} displayControls canCollapse exhibitBuilderOn={exhibitBuilderOn} />
}
