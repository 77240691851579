import { isUndefined, pick } from "lodash"
import { handleEmptyResponse } from "api/utils"
import { withRequestSerializer, withResponseSerializer } from "api/withSerializers"

import { ApiServiceType } from "../types"
import { apiService } from "../ApiService"
import {
  FlagDeserializer,
  FlagSerializer,
  MatterDeserializer,
  MatterSerializer,
  ReviewRequestDeserializer,
  ReviewRequestSerializer,
  FileUploadServiceDeserializer,
  CaseQADeserializer,
} from "./serializers"
import { SummaryRequestDeserializer } from "api/services/summaries/serializers"
import { PaginatedOptions } from "api/PaginatedList"
import { EmptyResponse } from "apiHelper"
import { getQuery } from "../utils"
import { Flag } from "documents/flags/types"
import { FlagDTO, ReviewItemDTO } from "./types"
import { ReviewArgs, ReviewRun, ReviewInternalStatus } from "review/store/types"
import * as Sentry from "@sentry/browser"

enum MATTER_API_PATHS {
  BASE = "matter",
  SUMMARY_REQUEST = "summary-request",
  REVIEW_REQUEST = "review-request",
  REVIEW_ITEM = "review-item",
  FLAG = "flag",
  CHAT = "chats",
  FILE_UPLOAD = "file-upload",
}

interface MatterServiceOptions {
  matterId: string
}

interface FlagServiceOptions {
  documentId: string
}

class MatterService {
  constructor(private readonly apiService: ApiServiceType) {}

  private getPath(
    options?: MatterServiceOptions,
    paths: MATTER_API_PATHS | (MATTER_API_PATHS | PrimaryKey | string)[] = []
  ): string {
    const pathParts = [
      "",
      MATTER_API_PATHS.BASE,
      options?.matterId,
      ...(Array.isArray(paths) ? paths : [paths]),
    ]
    return pathParts.filter(i => !isUndefined(i)).join("/")
  }

  // Matter
  getMatterList = withResponseSerializer(
    MatterDeserializer.fromPaginatedListJSON,
    (options: PaginatedOptions) => {
      return this.apiService.getPaginatedList(this.getPath(), null, options)
    }
  )

  getMattersWithSummaryRequestList = withResponseSerializer(
    MatterDeserializer.fromPaginatedListJSON,
    (options: PaginatedOptions) => {
      return this.apiService.getPaginatedList(
        this.getPath(),
        `?${new URLSearchParams({ linked_to: "summary_request" })}`,
        options
      )
    }
  )

  getMatterById = withResponseSerializer(
    MatterDeserializer.definitionFromJSON,
    (options: MatterServiceOptions) => {
      return handleEmptyResponse(this.apiService.get(null, this.getPath(options)))
    }
  )

  createMinimalMatter = withRequestSerializer(
    MatterSerializer.minimalCreateToJson,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    withResponseSerializer(MatterDeserializer.fromMinimalCreationResponse, ({ data, ...options }: any) => {
      return handleEmptyResponse(this.apiService.create(data, this.getPath(options)))
    })
  )

  updateMinimalMatter = withRequestSerializer(
    MatterSerializer.minimalCreateToJson,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    withResponseSerializer(MatterDeserializer.fromMinimalCreationResponse, ({ data, ...options }: any) => {
      return handleEmptyResponse(this.apiService.update(data, this.getPath(options)))
    })
  )

  deleteMatter = (options: MatterServiceOptions) => {
    return this.apiService.delete<null, EmptyResponse>(null, this.getPath(options))
  }

  getSummaryRequestFromMatter = withResponseSerializer(
    SummaryRequestDeserializer.fromListJSON,
    (options: MatterServiceOptions) => {
      return handleEmptyResponse(
        this.apiService.get(null, this.getPath(options, MATTER_API_PATHS.SUMMARY_REQUEST))
      )
    }
  )

  // Flags
  getFlags = withResponseSerializer(FlagDeserializer.fromListJSON, (options: FlagServiceOptions) => {
    const query = getQuery({
      ["document_id"]: [options.documentId],
    })

    return handleEmptyResponse(
      this.apiService.get(null, this.getPath(undefined, MATTER_API_PATHS.FLAG), query)
    )
  })

  createFlag = withRequestSerializer(
    FlagSerializer.newFlagToJSON,
    withResponseSerializer(FlagDeserializer.fromJSON, ({ data }: { data: Omit<Flag, "id"> }) => {
      return handleEmptyResponse(
        this.apiService.create(data, this.getPath(undefined, [MATTER_API_PATHS.FLAG]))
      )
    })
  )

  updateFlag = withRequestSerializer(
    FlagSerializer.flagToJSON,
    withResponseSerializer(FlagDeserializer.fromJSON, ({ data }: { data: FlagDTO }) => {
      return handleEmptyResponse(
        this.apiService.update(data, this.getPath(undefined, [MATTER_API_PATHS.FLAG, data.pk]))
      )
    })
  )

  removeFlag = ({ flagId }: { flagId: string }) => {
    return this.apiService.delete({}, this.getPath(undefined, [MATTER_API_PATHS.FLAG, flagId]))
  }

  // Reviews
  getReviewByArgs = async ({
    matterId,
    reviewRequestId,
    ...args
  }: MatterServiceOptions & ReviewArgs & { reviewRequestId: string }) => {
    const queryParams = new URLSearchParams({
      template_type: args.templateType,
    })

    if (args.plaintiffId) {
      queryParams.append("plaintiff_id", String(args.plaintiffId))
    }

    if (args.templateType === "document") {
      queryParams.append("section_type", args.sectionType)
    } else {
      queryParams.append("provider_id", String(args.providerId))
    }

    return ReviewRequestDeserializer.fromSearchJSON(
      await handleEmptyResponse(
        this.apiService.get(
          null,
          this.getPath({ matterId }, [MATTER_API_PATHS.REVIEW_REQUEST]),
          `?${queryParams.toString()}`
        )
      ),
      reviewRequestId
    )
  }

  getReviewStatus = ({
    matterId,
    reviewRequestId,
  }: MatterServiceOptions & { reviewRequestId: string }): Promise<{ status: ReviewInternalStatus }> => {
    return handleEmptyResponse(
      this.apiService.get(
        null,
        this.getPath({ matterId }, [MATTER_API_PATHS.REVIEW_REQUEST, reviewRequestId, "status"])
      )
    )
  }

  getReview = withResponseSerializer(
    ReviewRequestDeserializer.fromJSON,
    ({ matterId, reviewRequestId }: MatterServiceOptions & { reviewRequestId: string }) => {
      return handleEmptyResponse(
        this.apiService.get(
          null,
          this.getPath({ matterId }, [MATTER_API_PATHS.REVIEW_REQUEST, reviewRequestId])
        )
      )
    }
  )

  completeReview = ({ matterId, review }: MatterServiceOptions & { review: ReviewRun }) => {
    return handleEmptyResponse(
      this.apiService.create(
        {
          json_content: review.resultContent || review.content?.children,
        },
        this.getPath({ matterId }, [MATTER_API_PATHS.REVIEW_REQUEST, review.reviewRequestId, "complete"])
      )
    )
  }

  cancelReview = ({ matterId, reviewRequestId }: MatterServiceOptions & { reviewRequestId: string }) => {
    return handleEmptyResponse(
      this.apiService.create(
        null,
        this.getPath({ matterId }, [MATTER_API_PATHS.REVIEW_REQUEST, reviewRequestId, "unlock"])
      )
    )
  }

  createReview = withResponseSerializer(
    ReviewRequestDeserializer.fromCreateJSON,
    ({ matterId, ...args }: MatterServiceOptions & ReviewArgs) => {
      const data = {
        template_type: args.templateType,
        section_type: args.sectionType,
        plaintiff_id: args.plaintiffId,
        provider_id: args.providerId,
      }

      return handleEmptyResponse(
        this.apiService.create(data, this.getPath({ matterId }, [MATTER_API_PATHS.REVIEW_REQUEST]))
      )
    }
  )

  updateReviewItemStatus = withRequestSerializer(
    ReviewRequestSerializer.reviewItemToJSON,
    ({
      matterId,
      reviewRequestId,
      data: reviewItem,
    }: MatterServiceOptions & {
      reviewRequestId: string
      data: Pick<ReviewItemDTO, "id"> & Partial<ReviewItemDTO>
    }) => {
      return handleEmptyResponse(
        this.apiService.replace(
          reviewItem,
          this.getPath({ matterId }, [
            MATTER_API_PATHS.REVIEW_REQUEST,
            reviewRequestId,
            MATTER_API_PATHS.REVIEW_ITEM,
            reviewItem.id,
          ])
        )
      )
    }
  )

  updateReviewItemData = withRequestSerializer(
    ReviewRequestSerializer.reviewItemToJSON,
    withResponseSerializer(
      ReviewRequestDeserializer.fromJSON,
      ({
        matterId,
        reviewRequestId,
        data: reviewItem,
      }: MatterServiceOptions & {
        reviewRequestId: string
        data: Pick<ReviewItemDTO, "id"> & Partial<ReviewItemDTO>
      }) => {
        return handleEmptyResponse(
          this.apiService.create(
            pick(reviewItem, "id", "status", "user_modified_text"),
            this.getPath({ matterId }, [
              MATTER_API_PATHS.REVIEW_REQUEST,
              reviewRequestId,
              MATTER_API_PATHS.REVIEW_ITEM,
              reviewItem.id,
              "save_user_text",
            ])
          )
        )
      }
    )
  )

  // Chats

  getChat = withResponseSerializer(
    CaseQADeserializer.fromJSON,
    ({ matterId, chatId }: MatterServiceOptions & { chatId: string }) => {
      const query = getQuery({ ["chat_id"]: [chatId] })

      return handleEmptyResponse(
        this.apiService.get(null, this.getPath({ matterId }, MATTER_API_PATHS.CHAT), query)
      )
    }
  )

  getAllChats = withResponseSerializer(
    CaseQADeserializer.fromQuestionListJson,
    ({ matterId }: MatterServiceOptions) => {
      return handleEmptyResponse(this.apiService.get(null, this.getPath({ matterId }, MATTER_API_PATHS.CHAT)))
    }
  )

  createChat = withResponseSerializer(
    CaseQADeserializer.fromJSON,
    ({ matterId, question }: MatterServiceOptions & { question: string }) => {
      const body = {
        question,
      }

      return handleEmptyResponse(
        this.apiService.create(body, this.getPath({ matterId }, MATTER_API_PATHS.CHAT))
      )
    }
  )

  updateFeedback = withResponseSerializer(
    CaseQADeserializer.fromJSON,
    ({
      matterId,
      isHelpful,
      comment,
      chatId,
    }: MatterServiceOptions & { isHelpful: boolean | null; comment?: string; chatId: string }) => {
      const body = {
        is_helpful: isHelpful,
        ...(comment && { comment }),
      }
      return handleEmptyResponse(
        this.apiService.update(body, this.getPath({ matterId }, MATTER_API_PATHS.CHAT), `${chatId}/`)
      )
    }
  )

  // File Upload Service

  getFileDataFromFileUploadService = withResponseSerializer(
    FileUploadServiceDeserializer.fromJSON,
    async ({
      matterId,
      fileUploadId,
      pageNumber,
    }: MatterServiceOptions & { fileUploadId: string; pageNumber: number }) => {
      const query = getQuery({ ["file_upload_id"]: [fileUploadId], ["page_number"]: [pageNumber] })
      try {
        return await handleEmptyResponse(
          this.apiService.get(null, this.getPath({ matterId }, MATTER_API_PATHS.FILE_UPLOAD), query)
        )
      } catch (error) {
        Sentry.captureException(error)
        // Ensures we still get a response that way we can go to our fallback plan of just getting the filename from annotations.
        const emptyData = { results: [], meta: { original_file: { filename: "", number_of_pages: 0 } } }
        return Promise.resolve(emptyData)
      }
    }
  )
}

export const matterApiService = new MatterService(apiService)
