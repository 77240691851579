import { ReactNode, useCallback, useMemo, useState } from "react"
import TextButton from "common/buttons/TextButton"
import { Box, ClickAwayListener, FormControl, InputAdornment, Popover, SxProps } from "@mui/material"
import { Theme } from "@emotion/react"
import { StyledMedicalProfessionalFullName, StyledTextField } from "./styled"
import { queryClient } from "react-query/queryClient"
import { useMutation } from "@tanstack/react-query"
import { documentsService } from "api/services/documents"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { useDocumentStore } from "documents/store"
import { MedicalProfessional, NewMedicalProfessional } from "api/services/documents/types"
import { MedicalProfessionalRow } from "./MedicalProfessionalRow"
import { MedicalProfessionalEditRow } from "./MedicalProfessionalEditRow"
import { queryKeys } from "react-query/constants"

export type MedicalProfessionalKeys = "firstName" | "lastName" | "title"

interface MedicalProfessionalSelectProps {
  medicalProfessionals: MedicalProfessional[]
  selectedIds: MedicalProfessional["id"][]
  onChangeSelection: (ids: MedicalProfessional["id"][]) => void
  onSave?: (ids: MedicalProfessional["id"][]) => void
  selectOnly?: boolean
  label?: string
  searchLabel?: string
  formControlSx?: SxProps<Theme>

  controlRenderer?: (props: {
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void
    selectedIds: MedicalProfessional["id"][]
    readOnly?: boolean
  }) => ReactNode
  readOnly?: boolean
}

export function MedicalProfessionalSelect({
  label,
  searchLabel,
  formControlSx,
  medicalProfessionals,
  selectedIds,
  onChangeSelection,
  onSave,
  controlRenderer,
  readOnly,
  selectOnly,
}: MedicalProfessionalSelectProps): ReactNode {
  const { showErrorMessage } = useHandleMessages()
  const documentId = useDocumentStore(state => state.documentId)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [search, setSearch] = useState("")
  const [editingId, setEditingId] = useState<Nullable<string>>(null)
  const [showNewForm, setShowNewForm] = useState(false)
  const open = Boolean(anchorEl)
  const value = useMemo(
    () =>
      selectedIds
        .map(id => {
          const medicalProfessional = medicalProfessionals.find(({ id: currentId }) => id === currentId)

          if (medicalProfessional) {
            return medicalProfessional.name
          }

          return ""
        })
        .filter(Boolean)
        .join(", "),
    [medicalProfessionals, selectedIds]
  )

  const sortedMedicalProfessionals = useMemo(() => {
    const alphabeticallySorted = medicalProfessionals.sort((a, b) => a.firstName.localeCompare(b.firstName))

    if (!search) return alphabeticallySorted

    return alphabeticallySorted.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()))
  }, [medicalProfessionals, search])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
    setSearch("")
    setEditingId(null)
  }, [])

  const handleClickOnField = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (readOnly) return

      setAnchorEl(event.currentTarget)
    },
    [readOnly]
  )

  const handleSelect = useCallback(
    (id: MedicalProfessional["id"]) => {
      selectedIds.includes(id)
        ? onChangeSelection(selectedIds.filter(selectedId => selectedId !== id))
        : onChangeSelection([...selectedIds, id])
    },
    [selectedIds, onChangeSelection]
  )

  const handleEdit = useCallback((id: MedicalProfessional["id"]) => {
    setEditingId(id)
  }, [])

  const { mutate: create } = useMutation(documentsService.createMedicalProfessional, {
    onError: () => {
      showErrorMessage("There was an error creating medical professional")
      setShowNewForm(false)
    },
    onSuccess: newMedicalProfessional => {
      queryClient.setQueryData([queryKeys.documentMedicalProfessionals, documentId], () => ({
        medicalProfessionals: [...medicalProfessionals, newMedicalProfessional],
      }))
      setShowNewForm(false)
    },
  })

  const { mutate: update } = useMutation(documentsService.updateMedicalProfessional, {
    onError: () => {
      showErrorMessage("There was an error updating medical professional")
      setEditingId(null)
    },
    onSuccess: updatedMedicalProfessional => {
      queryClient.setQueryData([queryKeys.documentMedicalProfessionals, documentId], () => ({
        medicalProfessionals: medicalProfessionals.map(medicalProfessional =>
          medicalProfessional.id === updatedMedicalProfessional.id
            ? updatedMedicalProfessional
            : medicalProfessional
        ),
      }))
      setEditingId(null)
    },
  })

  const handleCreate = useCallback(
    (data: Pick<MedicalProfessional, MedicalProfessionalKeys>) => {
      create({ data, documentId })
    },
    [create, documentId]
  )

  const handleUpdate = useCallback(
    (data: NewMedicalProfessional) => {
      if (!editingId) return

      update({ data, documentId, id: editingId })
    },
    [update, documentId, editingId]
  )

  const handleCancelCreate = useCallback(() => {
    setShowNewForm(false)
  }, [])

  const handleCancelEdit = useCallback(() => {
    setEditingId(null)
  }, [])

  const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value)
  }, [])

  const showNewEntityForm = useCallback(() => {
    setShowNewForm(true)
  }, [])

  return (
    <>
      <FormControl sx={{ ...formControlSx }}>
        {controlRenderer ? (
          controlRenderer({ selectedIds, onClick: handleClickOnField, readOnly })
        ) : (
          <StyledTextField
            inputProps={{ style: { textOverflow: "ellipsis" } }}
            label={label}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ExpandMoreIcon />{" "}
                </InputAdornment>
              ),
            }}
            value={value}
            onClick={handleClickOnField}
          />
        )}
      </FormControl>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 15, horizontal: 40 }}
        onClose={onSave}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box p={2.5}>
            <StyledTextField
              value={search}
              onChange={handleSearchChange}
              autoFocus
              label={searchLabel}
              sx={{ width: "300px" }}
            />
            {!!sortedMedicalProfessionals.length && (
              <Box mt={1} width={editingId ? undefined : "330px"}>
                {sortedMedicalProfessionals.map(medicalProfessional => (
                  <MedicalProfessionalRow
                    key={medicalProfessional.id}
                    checked={selectedIds.includes(medicalProfessional.id)}
                    disabledEditing={!!editingId || showNewForm}
                    handleClick={() => handleSelect(medicalProfessional.id)}
                    handleEdit={() => handleEdit(medicalProfessional.id)}
                    hideEditButton={!!editingId || readOnly || selectOnly}
                  >
                    {medicalProfessional.id === editingId ? (
                      <MedicalProfessionalEditRow
                        key={medicalProfessional.id}
                        medicalProfessional={medicalProfessional}
                        onCancel={handleCancelEdit}
                        onSave={handleUpdate}
                      />
                    ) : (
                      <StyledMedicalProfessionalFullName
                        onClick={() => handleSelect(medicalProfessional.id)}
                        sx={{ fontWeight: selectedIds.includes(medicalProfessional.id) ? 600 : 400 }}
                      >
                        {medicalProfessional.name}
                      </StyledMedicalProfessionalFullName>
                    )}
                  </MedicalProfessionalRow>
                ))}
              </Box>
            )}
            <Box display="flex" gap={1} mt={1} ml={-0.5}>
              {!readOnly && !showNewForm && !selectOnly && (
                <TextButton disabled={!!editingId} size="small" onClick={showNewEntityForm}>
                  + Create new medical professional
                </TextButton>
              )}
              {showNewForm && (
                <MedicalProfessionalEditRow onCancel={handleCancelCreate} onSave={handleCreate} />
              )}
            </Box>
          </Box>
        </ClickAwayListener>
      </Popover>
    </>
  )
}
