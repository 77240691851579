import { isNil } from "lodash"

import { ExhibitCitation } from "common/citation/ExhibitCitation"
import { Partition } from "documents/types"
import { useExhibitPreview } from "documents/exhibits/useExhibitPreview"
import { useDocumentStore } from "documents/store"
import { PastMedicalVisitRelationBaseComponentProps } from "../types"

interface PastMedicalVisitRelationComponentProps extends PastMedicalVisitRelationBaseComponentProps {
  partition: Partition
}

export const PastMedicalVisitRelationComponent = ({
  page,
  partition,
}: PastMedicalVisitRelationComponentProps) => {
  const exhibitsOrder = useDocumentStore(state => state.exhibitsOrder)
  const index = exhibitsOrder.indexOf(partition.exhibitId)

  const openPreview = useExhibitPreview(partition.exhibitId)
  const handleOpenPreview = (page: Nullable<number>) => openPreview(partition, page)

  const pages = isNil(page) ? [1] : [page]

  return <ExhibitCitation index={index} pages={pages} onClick={handleOpenPreview} />
}
