import styled from "@emotion/styled"
import { Dialog, Typography } from "@mui/material"
import { HEADER_NAV_HEIGHT_SPACE_MULTIPLIER } from "app/constants"
import { Headings } from "./types"

export const CollapseButtonContainer = styled("div")(({ theme }) => ({
  textAlign: "right",
  right: 0,
  top: theme.spacing(3),
}))

export const StyledHeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "top",
  "> *": {
    flex: "1 1 auto",
  },
})

export const StyledMedChronHeaderActions = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  "> *": {
    height: "40px",
  },
  "> :not(:last-child)": {
    marginRight: theme.spacing(2),
  },
}))

export const StickyHeaderActions = styled("div")<{ show?: boolean }>(({ theme, show }) => ({
  height: "60px",
  backgroundColor: theme.palette.common.white,
  boxShadow: "rgba(0, 0, 0, 0.08) 0px 1px 12px",
  transform: show ? `translateY(${theme.spacing(HEADER_NAV_HEIGHT_SPACE_MULTIPLIER)})` : "translateY(0)",
  transition: "transform 0.2s ease-in-out",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: theme.spacing(2),
  padding: theme.spacing(0, 20),
}))

export const StyledDialog = styled(Dialog)({
  "& .MuiDialogContent-root": {
    padding: "40px 30px 30px",
  },
  "& .MuiDialogActions-root": {
    padding: "0 30px 27px",
  },
})

export const StyledDialogHeader = styled(Typography)<{ component: Headings }>(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
}))

export const StyledDialogDescription = styled(Typography)<{ component: "p" }>(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
}))
