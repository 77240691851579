import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import { useMutation } from "@tanstack/react-query"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"

import { createLitifyRequest } from "api"
import { JSONparseWithDefaultValue } from "utils"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { RequestAnalyticEvent, RequestAnalyticsEventTypes } from "infrastructure/apm/events/requestEvents"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { usePermissions } from "permissions/usePermissions"
import SalesforceOAuth from "./SalesforceOAuth"
import { LitifyMissingFieldsError } from "./LitifyMissingFieldsError"
import { ApiError } from "apiHelper"

const useStyles = makeStyles()(theme => ({
  wrapper: {
    display: "grid",
    placeItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  contact: {
    marginTop: theme.spacing(1),
  },
  dividerWrapper: {
    padding: theme.spacing(1, 0),
  },
  error: {
    fontFamily: "monospace",
    fontSize: "0.8rem",
    marginTop: theme.spacing(1),
  },
}))

export default function Litify(): JSX.Element {
  const { classes } = useStyles()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const { showErrorMessage } = useHandleMessages()
  const [requiresAuth, setRequiresAuth] = useState(false)
  const [missingFields, setMissingFields] = useState(null)

  const matterId = params.get("matterId")
  let carrierId = params.get("carrierId")
  if (carrierId == "null") {
    carrierId = ""
  }
  const dest = params.get("dest")
  const firmId = params.get("firmId")
  const token = params.get("token")
  const env = params.get("env")
  const domain = window.location.origin
  let path = `${domain}/litify?matterId=${matterId}&carrierId=${carrierId}`
  if (firmId) {
    path = path + `&firmId=${firmId}`
  }
  if (env) {
    path = path + `&env=${env}`
  }

  const { preDemandCaseViewEnabled } = usePermissions({ firmId, suspense: true })

  const { mutate: createRequest } = useMutation(createLitifyRequest, {
    onSuccess: data => {
      amplitudeApm.trackEvent(
        new RequestAnalyticEvent(RequestAnalyticsEventTypes.CreatedRequestFromIntegration, {
          integration_source: "litify",
          plaintiff_name: data.plaintiff_name || "",
          request_id: data.questionnaire_id,
        })
      )
      if (dest == "sdr") {
        navigate(`/requests/`)
      } else if (preDemandCaseViewEnabled && data.case_id) {
        navigate(`/cases/${data.case_id}`)
      } else {
        const basePath = `/requests/${data.questionnaire_id}`
        const targetPath = data.view ? basePath : `${basePath}/edit`
        navigate(targetPath)
      }
    },
    onError: (error: ApiError) => {
      const parsedError = JSONparseWithDefaultValue(error.message)
      if ([401, 403].includes(error.response.status)) {
        setRequiresAuth(true)
      } else if (error.response.status === 400 && parsedError.missingFields) {
        setMissingFields(parsedError.missingFields)
      } else {
        const errMessage = `There was an error while importing your Litify Matter.`
        showErrorMessage({
          message: errMessage,
          error,
        })
        navigate(`/requests/`)
      }
    },
  })

  useEffect(() => {
    createRequest({
      matterId: matterId,
      carrierId: carrierId,
      token: token,
      firmId: firmId,
      env: env,
      dest: dest,
    })
  }, [matterId, carrierId, token, firmId, env, dest, createRequest])

  if (requiresAuth) {
    return (
      <Box className={classes.wrapper}>
        <Box className={classes.container}>
          <SalesforceOAuth loginRedirectPathUrl={path} />
        </Box>
      </Box>
    )
  } else if (missingFields) {
    return (
      <Box className={classes.wrapper}>
        <Box className={classes.container}>
          <LitifyMissingFieldsError missingFields={missingFields} />
        </Box>
      </Box>
    )
  } else {
    return (
      <Box className={classes.wrapper}>
        <Box className={classes.container}>
          <Typography>Importing case to EvenUp</Typography>
          <CircularProgress />
        </Box>
      </Box>
    )
  }
}
