import { useSuspenseQuery } from "@suspensive/react-query"
import { withSuspense } from "common/withSuspense"
import { getInternalUsers, getExternalUsers, getUsers } from "../api"
import { SILENT_QUERY_PARAMS, queryKeys } from "../react-query/constants"

import MultiAutocompleteSelector from "../common/MultiAutocompleteSelector"

export const Segments = {
  INTERNAL: "INTERNAL",
  EXTERNAL: "EXTERNAL",
  ALL: "ALL",
}

const getQueryKeyBySegment = segment => {
  switch (segment.toUpperCase()) {
    case Segments.INTERNAL:
      return queryKeys.internalUsers
    case Segments.EXTERNAL:
      return queryKeys.externalUsers
    case Segments.ALL:
      return queryKeys.allUsers
  }
}

const getUserMethodBySegment = segment => {
  switch (segment.toUpperCase()) {
    case Segments.INTERNAL:
      return getInternalUsers
    case Segments.EXTERNAL:
      return getExternalUsers
    case Segments.ALL:
      return getUsers
  }
}

const optionizeValue = (value, singleAssignee, getUserDisplayName, showEmail) => {
  if (!value) {
    return undefined
  }

  const optionize = assignee => ({
    ...assignee,
    label: assignee?.label ?? getUserDisplayName(assignee, showEmail),
  })

  if (singleAssignee) {
    return optionize(value)
  }

  return value.map(optionize)
}

const AssigneePicker = withSuspense(
  ({
    onChange,
    value,
    segment = Segments.ALL,
    label = "Assignee(s)",
    variant = "standard",
    showEmail = false,
    singleAssignee = false,
  }) => {
    const usersKey = getQueryKeyBySegment(segment)
    const getUsersMethod = getUserMethodBySegment(segment)
    const { data: usersData } = useSuspenseQuery(
      [usersKey],
      async () => getUsersMethod({ minimal: true }),
      SILENT_QUERY_PARAMS
    )
    const users = usersData.data ?? usersData

    const getUserDisplayName = (user, showEmail) => {
      const fullName = `${user.first_name} ${user.last_name}`.trim()
      const displayName = fullName || user.email

      if (showEmail) {
        return `${displayName} (${user.email})`
      }

      return displayName
    }

    const optionizedValue = optionizeValue(value, singleAssignee, getUserDisplayName, showEmail)

    // Get a list of options that are not already selected
    const options = users
      .map(user => {
        return {
          ...user,
          label: getUserDisplayName(user, showEmail),
        }
      })
      .filter(option => {
        if (!optionizedValue || singleAssignee) {
          return true
        }
        return !optionizedValue.find(value => {
          return value.pk === option.pk
        })
      })

    return (
      <MultiAutocompleteSelector
        dataTest="assignee-selector"
        options={options}
        value={optionizedValue}
        label={label}
        onChange={onChange}
        variant={variant}
        multiple={!singleAssignee}
      />
    )
  },
  <MultiAutocompleteSelector options={[]} loading />
)

export { AssigneePicker as default }
