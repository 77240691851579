import React, { ComponentPropsWithoutRef, useCallback, useState } from "react"
import { LinkButton } from "./styled"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { config } from "common/config"
import { useNavigate } from "react-router-dom"
import { useExhibitBuilderStore } from "exhibit-builder/store"
import {
  UpdateSummariesConfirmationDialog,
  useRegenerateSummariesComplete,
} from "exhibit-builder/UpdateSummariesButton"

interface KiliButtonProps extends ComponentPropsWithoutRef<typeof LinkButton> {
  documentId: string | undefined
  title?: string
}

export const KiliButton = ({ documentId, title = "Annotation Tool", ...otherProps }: KiliButtonProps) => {
  if (!documentId) return <></>

  return (
    <LinkButton
      endIcon={<OpenInNewIcon />}
      href={`${config.api.host}/documents/${documentId}/kili/`}
      {...otherProps}
    >
      {title}
    </LinkButton>
  )
}

export const MedicalSummaryButton = ({ documentId, ...otherProps }: KiliButtonProps) => {
  const shouldUpdateSummaries = useExhibitBuilderStore(state => state.shouldUpdateSummaries)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const onRegenerateSummariesComplete = useRegenerateSummariesComplete(documentId as string)

  const navigateToMedicalSummary = useCallback(() => {
    navigate(`/documents/${documentId}/medical-summary/`)
  }, [documentId, navigate])

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()

    if (shouldUpdateSummaries) {
      setIsDialogOpen(true)
    } else {
      navigateToMedicalSummary()
    }
  }

  if (!documentId) return <></>

  return (
    <>
      <LinkButton {...otherProps} onClick={handleClick}>
        Medical Summary
      </LinkButton>
      <UpdateSummariesConfirmationDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        documentId={documentId}
        navigateToMedicalSummary={navigateToMedicalSummary}
        onRegenerateSummariesComplete={onRegenerateSummariesComplete}
      />
    </>
  )
}
