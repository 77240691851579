import { isDevEnvironment, isTestEnvironment } from "infrastructure/env/getEnvironment"
import { create } from "zustand"
import { ProviderSummaryStoreType } from "./types"
import { devtools } from "zustand/middleware"
import { logger } from "utils"
import { createActions } from "./actions"
import { createContext, memo, PropsWithChildren, useContext, useEffect, useMemo } from "react"
import { usePermissions } from "permissions/usePermissions"
import { ProviderWrapper } from "../styled"

function createInitialState(caseId: PrimaryKey, providerId: PrimaryKey): ProviderSummaryStoreType {
  return {
    caseId,
    providerId,

    medicalSummariesState: "initial",
    medicalSummaries: new Map(),

    injuryDetailsState: "initial",
    injuryDetails: new Map(),
  }
}

function createStore(caseId: PrimaryKey, providerId: PrimaryKey) {
  if (isDevEnvironment() && !isTestEnvironment()) {
    return create<ProviderSummaryStoreType>()(
      devtools(logger<ProviderSummaryStoreType>(() => createInitialState(caseId, providerId)))
    )
  }

  return create<ProviderSummaryStoreType>()(() => createInitialState(caseId, providerId))
}

function createProviderSummariesStore(caseId: PrimaryKey, providerId: PrimaryKey) {
  const store = createStore(caseId, providerId)
  const actions = createActions(store.getState, store.setState)

  return {
    store,
    actions,
  }
}

const ProviderSummariesContext = createContext(createProviderSummariesStore(-1, -1))

export const ProviderSummariesStore = memo(function ProviderSummariesStore({
  caseId,
  providerId,
  enabled,
  children,
}: PropsWithChildren<{ caseId: PrimaryKey; providerId: PrimaryKey; enabled: boolean }>) {
  const store = useMemo(() => createProviderSummariesStore(caseId, providerId), [caseId, providerId])
  const { enableZeroClickSummariesEnabled } = usePermissions()

  useEffect(() => {
    if (enabled && enableZeroClickSummariesEnabled) {
      const abortForcePollingController = new AbortController()

      store.actions.fetchInjuryDetails()
      store.actions.fetchMedicalSummaries(abortForcePollingController.signal)

      return () => {
        abortForcePollingController.abort()
        store.actions.cancelPolling()
      }
    }
  }, [store, enabled, enableZeroClickSummariesEnabled])

  return (
    <ProviderSummariesContext.Provider value={store}>
      <ProviderWrapper>{children}</ProviderWrapper>
    </ProviderSummariesContext.Provider>
  )
})

export function useProviderSummariesStore<T>(selector: (state: ProviderSummaryStoreType) => T) {
  const { store: useStore } = useContext(ProviderSummariesContext)

  return useStore(selector)
}

export function useProviderSummariesActions() {
  const { actions } = useContext(ProviderSummariesContext)
  return actions
}
