import { useCallback } from "react"
import { ExhibitContainer } from "exhibit-builder/Exhibit/ExhibitContainer"
import { filesSelectors } from "exhibit-builder/store/files/filesSelectors"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { UserExhibit } from "exhibit-builder/store/types"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { CollapseButton } from "./styled"
import { useShallow } from "zustand/react/shallow"
import { CollapsedDisplayName } from "./CollapsedDisplayName"
import { UserExhibitInfo } from "./UserExhibitInfo"
import { ActionPopupButton } from "../ActionPopup/ActionPopupButton"
import { useUserExhibitPreview } from "exhibit-builder/useUserExhibitPreview"
import { useCollapseStore } from "exhibit-builder/Collapse/collapse"
import { USER_EXHIBIT_FILE_TYPES } from "exhibit-builder/constants"
import { isPlaceholderUserExhibit } from "exhibit-builder/store/utils"
import { DeleteUserExhibitButton } from "./DeleteUserExhibitButton"

export function UserExhibitContent({ id }: { id: UserExhibit["id"] }) {
  const isReadOnly = useExhibitBuilderStore(useShallow(state => state.isReadOnly))
  const userExhibit = useExhibitBuilderStore(useShallow(filesSelectors.getUserExhibitById(id)))
  const handleRename = useCallback(
    (nextFileName: string) => exhibitBuilderActions.updateUserExhibit({ id, name: nextFileName }),
    [id]
  )
  const collapsed = useCollapseStore(state => state.collapseStates[id])
  const setCollapseState = useCollapseStore(state => state.setCollapseState)
  const openPreview = useUserExhibitPreview(id)

  const handleCollapse = useCallback(() => {
    setCollapseState(id, !collapsed)
  }, [id, collapsed, setCollapseState])

  return (
    <ExhibitContainer
      editable={!collapsed && !isReadOnly}
      displayName={collapsed ? <CollapsedDisplayName id={id} /> : userExhibit.name}
      exhibitName={userExhibit.name}
      index={userExhibit.index}
      onUserExhibitRename={handleRename}
      onPreview={() => openPreview()}
      exhibitReadyToBeViewed={
        userExhibit.fileType === USER_EXHIBIT_FILE_TYPES.PDF && userExhibit.processingStatus === "complete"
      }
      renderControls={() => (
        <>
          {collapsed && <UserExhibitInfo id={id} />}
          {!collapsed &&
            !isReadOnly &&
            (isPlaceholderUserExhibit(userExhibit) ? (
              <DeleteUserExhibitButton id={id} />
            ) : (
              <ActionPopupButton id={id} />
            ))}
          <CollapseButton
            data-test="exhibit-collapse-button"
            size="small"
            onClick={handleCollapse}
            style={{
              transform: !collapsed ? "rotate(180deg)" : "rotate(0deg)",
            }}
          >
            <KeyboardArrowDownIcon />
          </CollapseButton>
        </>
      )}
    />
  )
}
