import styled from "@emotion/styled"
import { Box, IconButton, inputLabelClasses, outlinedInputClasses, svgIconClasses } from "@mui/material"
import ApartmentIcon from "@mui/icons-material/Apartment"
import { green, lightBlue } from "@mui/material/colors"

export const StyledApartmentIcon = styled(ApartmentIcon)({
  top: "50%",
  transform: "translateY(-50%)",
  left: 0,
  position: "absolute",
  fontSize: "20px",
  color: "rgba(0, 0, 0, 0.56)",
})

export const InputBox = styled(Box)({
  [`.${outlinedInputClasses.notchedOutline}`]: {
    top: 0,
  },
  "& legend": {
    display: "none",
  },
  [`& .${inputLabelClasses.root}`]: {
    transition: "none",
  },
  [`& .${inputLabelClasses.shrink}`]: {
    opacity: 0,
    transition: "none",
    animation: "none",
    transform: "none",
  },
})

export const AddIcdCodeIconButton = styled(IconButton)(({ theme }) => ({
  height: theme.spacing(4.5),
  padding: theme.spacing(1),

  [`& .${svgIconClasses.root}`]: {
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
  },
}))

const StyledBaseWrapper = styled("div")(({ theme }) => ({
  display: "inline-flex",
  alignContent: "center",
  alignItems: "center",
  marginRight: "auto",
  height: "auto",
  gap: theme.spacing(1.5),
  marginBottom: theme.spacing(1.5),
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(1, 6),
}))

export const StyledWarningWrapper = styled(StyledBaseWrapper)(() => ({
  background: "#FFF4E5",
  color: "#663C00",
}))

export const StyledErrorWrapper = styled(StyledBaseWrapper)(() => ({
  background: "rgb(255, 236, 240)",
  color: "rgb(102, 27, 42)",
}))

export const StyledSuccessWrapper = styled(StyledBaseWrapper)(() => ({
  background: "#EDF7ED",
  color: "#1E4620",
}))

export const StyledUpdateButton = styled("span")(({ theme }) => ({
  textDecoration: "underline",
  marginLeft: theme.spacing(0.5),
  cursor: "pointer",
  fontWeight: 700,
  color: "#1E4620",
}))
export const StyledErrorButton = styled(StyledUpdateButton)(() => ({
  color: "#661b2a",
}))

export const ExhibitReferenceButton = styled(IconButton)(({ theme }) => ({
  height: theme.spacing(4.5),
  width: theme.spacing(4.5),
  padding: theme.spacing(0.5),
  margin: `${theme.spacing(-0.5)} !important`,

  [`& > svg`]: {
    height: theme.spacing(2.5),
  },
}))

export const StyledTextField = styled("input")(({ theme, readOnly }) => ({
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(0.5, 1),
  position: "relative",
  top: theme.spacing(-0.5),
  left: theme.spacing(-1),
  fontSize: "14px",
  fontFamily: "inherit",
  marginRight: "auto",
  border: "none",
  background: "none",
  cursor: readOnly ? undefined : "pointer",

  ":hover": {
    background: readOnly ? undefined : "#091E420F",
  },
}))

export const StyledAppointmentTagLinksList = styled(Box)({
  listStyleType: "none",
  flexDirection: "column",
  display: "flex",
  paddingLeft: "0",
  overflowY: "auto",
  height: "calc(100vh - 740px)", // 740px accounts for height of header, filters above, and add summary button below
})

export const StyledAppointmentTagItem = styled("li")({
  cursor: "pointer",

  ":hover": {
    textDecoration: "underline",
  },
})

export const Circle = styled("span")<{
  reviewed?: boolean
  completed?: boolean
  top?: string
}>(({ theme, reviewed, completed, top }) => {
  let borderColor = theme.palette.disabled.darker
  let backgroundColor = "#00000032"
  const offset: React.CSSProperties = top ? { position: "relative", top } : {}

  if (reviewed) {
    borderColor = "transparent"
    backgroundColor = lightBlue[700]
  } else if (completed) {
    borderColor = "transparent"
    backgroundColor = green[600]
  }

  return {
    ...offset,
    display: "inline-block",
    marginRight: theme.spacing(2),
    marginLeft: "0",
    width: "12px",
    height: "12px",
    borderRadius: "100%",
    border: `2px solid ${borderColor}`,
    backgroundColor,
  }
})
