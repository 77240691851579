import { useCallback, useMemo, useEffect, useRef, memo, useContext } from "react"
import { useIsMutating, useMutation, useQuery } from "@tanstack/react-query"
import Tooltip from "@mui/material/Tooltip"
import Collapse from "@mui/material/Collapse"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"
import { Draggable } from "react-beautiful-dnd"
import { useHandleMessages } from "common/messages/useHandleMessages"
import {
  deleteExhibit,
  downloadExhibit,
  downloadPartition,
  updateExhibit,
  updatePartition,
  unlinkPartitionFromProvider,
  getProvider,
} from "api"
import { useDebouncedCallback } from "use-debounce"
import {
  APPEND_EXHIBIT,
  SET_EXHIBITS,
  DELETE_EXHIBIT,
  UPDATE_PROVIDER,
  CLOSE_EDITING_PROVIDER,
  DELETE_PARTITION,
} from "demand/Providers/store/reducer"
import { useFormContext } from "demand/context"
import usePrompt from "hooks/usePrompt"
import { usePublish } from "message-broker/usePublish"
import { MESSAGE_TOPIC } from "message-broker/topics"
import { getExhibitPreviewPath } from "pdf-preview/utils"
import { openWindow } from "common/windows"
import { queryClient } from "react-query/queryClient"
import { queryKeys, SILENT_QUERY_PARAMS, STALE_TIMEOUT } from "react-query/constants"
import { ProviderForm } from "./ProviderForm/ProviderForm"
import ProviderDetails from "./ProviderDetails"
import { ProviderCollapsedRow } from "./ProviderCollapsedRow"
import {
  shouldUpdateWithDebounce,
  shouldUpdateImmediately,
  shouldUpdateImmediatelyInBackground,
} from "./utils"
import { StyledConflictIndicator } from "./styled"
import { PARTITIONED_EXHIBIT } from "../constants"
import { defer } from "lodash"
import useCase from "hooks/useCase"
import { previewExhibitPartition } from "exhibit-builder/useUserExhibitPreview"
import { useExhibitBuilderStore } from "exhibit-builder/store"
import { ProvidersStoreContext } from "../store/context"
import { ProviderSummariesStore } from "./Summaries/store"

const DEBOUNCE_TIME = 2000

const useStyles = makeStyles()((theme, { hasConflict }, classes) => ({
  providerContainer: {
    border: `1px solid ${hasConflict ? theme.palette.error.main : theme.palette.grey[300]}`,
    marginTop: "-1px",
    [`.${classes.activeProviderContainer} + &`]: {
      borderTopColor: theme.palette.blue.main,
    },
  },
  providerContainerDragHappening: {
    borderColor: `${theme.palette.grey[300]} !important`,
  },
  activeProviderContainer: {
    borderColor: `${theme.palette.blue.main} !important`,
  },
  providerWithConflict: {
    zIndex: 1,
    position: "relative",
  },
}))

export const ProviderListItem = memo(
  function ProviderListItemComponent({
    providerId,
    active,
    index,
    anyProviderBeingDragged,
    validationErrors,
    uploadExhibit,
    onUpdate,
    onDelete,
    onDeleteExhibit,
    providerDataToFormData,
    hasCollateralSourceRule,
    missingExhibits,
    onUpdateInBackground,
    onUpdateWhenBlurred,
    updates,
    documentStructureContentComponents,
    isEmpty,
  }) {
    const useProvidersStore = useContext(ProvidersStoreContext)
    const provider = useProvidersStore(state => state.providers.get(providerId))
    const { dispatch } = useProvidersStore
    const providerMissingExhibits = useMemo(() => {
      return (
        missingExhibits?.filter(missingExhibit => {
          if (!missingExhibit.provider) return false
          return missingExhibit.provider.pk === providerId
        }) ?? []
      )
    }, [missingExhibits, providerId])
    const previousProvider = useRef(provider)
    const { caseId, request } = useFormContext()

    const { isFetching } = useQuery(
      [queryKeys.providers, caseId, provider.pk],
      () => getProvider({ caseId, providerId: provider.pk }).then(providerDataToFormData),
      {
        ...SILENT_QUERY_PARAMS,
        staleTime: STALE_TIMEOUT.PERMANENT,
        onSuccess: newProvider => {
          dispatch({
            type: UPDATE_PROVIDER,
            payload: {
              newProvider: {
                ...newProvider,
                open: provider.open,
                saving: false,
              },
            },
          })
        },
      }
    )

    const isMutating = useIsMutating({ mutationKey: [queryKeys.providers, caseId, provider.pk] }) > 0

    const updateExhibitMutation = useMutation(updateExhibit)
    const deleteExhibitMutation = useMutation(deleteExhibit)
    const updatePartitionMutation = useMutation(updatePartition)
    const downloadExhibitMutation = useMutation(downloadExhibit)
    const downloadPartitionedExhibitMutation = useMutation(downloadPartition)
    const unlinkPartitionMutation = useMutation(unlinkPartitionFromProvider)

    const isReviewNeeded = provider?.templated_sections?.[0]?.user_action_required
    const hasConflict = !active && isReviewNeeded
    const { classes, cx } = useStyles({ hasConflict })

    const { showSuccessMessage, showErrorMessage } = useHandleMessages()

    useEffect(() => {
      return () => queryClient.removeQueries([queryKeys.providers, caseId, provider.pk])
    }, [caseId, provider.pk])

    const handleExhibitReorder = newExhibits => {
      newExhibits.forEach((exhibit, exhibitIndex) => {
        if (exhibit.exhibitType === PARTITIONED_EXHIBIT) {
          updatePartitionMutation.mutate({
            exhibitId: exhibit.exhibit_id,
            partitionId: exhibit.pk,
            data: {
              section_index: exhibitIndex,
            },
          })
        } else {
          updateExhibitMutation.mutate({
            caseId,
            exhibitId: exhibit.pk,
            data: {
              case_id: caseId,
              pk: exhibit.pk,
              section_index: exhibitIndex,
            },
          })
        }
      })
      dispatch({
        type: SET_EXHIBITS,
        payload: { caseId, providerPk: provider.pk, exhibits: newExhibits },
      })
    }

    const debouncedUpdate = useDebouncedCallback(newProvider => {
      const result = { ...newProvider, filesToUpload: undefined }
      onUpdateInBackground(result, index)
    }, DEBOUNCE_TIME)

    const handleProviderChange = useCallback(
      newProvider => {
        dispatch({ type: UPDATE_PROVIDER, payload: { newProvider, caseId } })
      },
      [dispatch, caseId]
    )

    const handleExhibitDelete = exhibitToDelete => {
      if (
        provider.bills?.some(bill => bill.exhibit_id === exhibitToDelete.pk) &&
        !confirm(
          "One or more bills have this file attached. If you delete it you will need to choose a new file for those bills. Would you like to delete this file?"
        )
      ) {
        return
      }

      deleteExhibitMutation.mutate({
        caseId,
        providerId: provider.pk,
        exhibitId: exhibitToDelete.pk,
      })

      dispatch({
        type: DELETE_EXHIBIT,
        payload: { caseId, providerPk: provider.pk, exhibitPk: exhibitToDelete.pk },
      })

      queryClient.invalidateQueries([queryKeys.exhibits])

      showSuccessMessage(`Deleted ${exhibitToDelete.name}`)
      onDeleteExhibit(exhibitToDelete.pk)
    }

    const handleExhibitDownload = async exhibitToDownload => {
      let result

      try {
        result = await downloadExhibitMutation.mutateAsync({
          caseId,
          exhibitId: exhibitToDownload.pk,
          fileName: exhibitToDownload.name,
          downloadFile: true,
        })
      } catch (error) {
        showErrorMessage({
          message:
            "Error updating provider. Please try again shortly and if your problem persists contact a dev.",
          error,
        })
      }

      return result
    }

    const handlePartitionDelete = partitionToDelete => {
      if (
        provider.bills?.some(bill => bill.partition_id === partitionToDelete.pk) &&
        !confirm(
          "One or more bills have this file attached. If you delete it you will need to choose a new file for those bills. Would you like to delete this file?"
        )
      ) {
        return
      }

      unlinkPartitionMutation.mutate({
        partitionId: partitionToDelete.pk,
        exhibitId: partitionToDelete.exhibit_id,
        data: {},
      })

      dispatch({
        type: DELETE_PARTITION,
        payload: {
          caseId,
          providerPk: provider.pk,
          partitionPk: partitionToDelete.pk,
        },
      })

      showSuccessMessage(`Deleted ${partitionToDelete.name}`)
      onDeleteExhibit(partitionToDelete.pk)
    }

    const handlePartitionedExhibitDownload = async partitionedExhibitToDownload => {
      let result

      try {
        result = await downloadPartitionedExhibitMutation.mutateAsync({
          caseId,
          exhibitId: partitionedExhibitToDownload.exhibit_id,
          partitionId: partitionedExhibitToDownload.pk,
        })
      } catch (error) {
        showErrorMessage({
          message:
            "Error downloading partition. Please try again shortly and if your problem persists contact a dev.",
          error,
        })
      }

      return result
    }

    const handleSave = () => {
      handleBlur()

      onUpdate(provider, index)
    }

    const handleCancel = () => {
      handleBlur()

      dispatch({
        type: CLOSE_EDITING_PROVIDER,
        payload: {
          caseId,
          pk: provider.pk,
          request_id: request.pk,
          request_type: request.type,
          provider_name: provider.name,
        },
      })
    }

    const handleAppendExhibit = useCallback(
      (exhibit, providerId) => {
        dispatch({
          type: APPEND_EXHIBIT,
          payload: {
            caseId,
            providerId: providerId,
            exhibit: exhibit,
          },
        })
      },
      [caseId, dispatch]
    )

    const wrapperClassName = useMemo(() => {
      // if anything is being dragged, set non-active element border colors to grey
      const className = active
        ? classes.activeProviderContainer
        : anyProviderBeingDragged && classes.providerContainerDragHappening

      const providerWithConflictClassName = hasConflict && classes.providerWithConflict
      return cx(classes.providerContainer, className, providerWithConflictClassName)
    }, [active, anyProviderBeingDragged, classes, cx, hasConflict])

    const handleBlur = useCallback(() => {
      defer(() => {
        if (debouncedUpdate.isPending()) {
          debouncedUpdate.flush()
        }
      })
    }, [debouncedUpdate])

    const disabled = !!(
      isMutating ||
      isFetching ||
      provider.saving ||
      provider.deleting ||
      provider.filesToUpload?.some(({ uploading }) => Boolean(uploading))
    )

    usePrompt(
      "You are currently editing a provider, if you leave this page any unsaved changes will be lost. Would you like to leave the page?",
      active && (debouncedUpdate.isPending() || disabled)
    )

    useEffect(() => {
      if (shouldUpdateImmediately(previousProvider.current, provider)) {
        onUpdateWhenBlurred(provider)
      } else if (shouldUpdateWithDebounce(previousProvider.current, provider)) {
        debouncedUpdate(provider)
      } else if (shouldUpdateImmediatelyInBackground(previousProvider.current, provider)) {
        onUpdateInBackground(provider, index)
      }

      previousProvider.current = provider
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [provider])

    const publish = usePublish()
    const { caseObj } = useCase(caseId)

    const openPDFViewerWindow = useCallback(
      ({ exhibitId, partitionId, page }) => {
        if (caseObj?.use_exhibit_builder) {
          const store = useExhibitBuilderStore.getState()
          const partition = store.exhibitPartitionMap[partitionId]
          const exhibitFile = store.files[exhibitId]

          return previewExhibitPartition({ publish, partition, exhibitFile, page })
        }

        const fileName = partitionId
          ? provider.exhibits.find(
              exhibit => exhibit.exhibitType === "partitioned_exhibit" && exhibit.pk === partitionId
            )?.name
          : provider.exhibits.find(exhibit => exhibit.exhibitType === "exhibit" && exhibit.pk === exhibitId)
              ?.name

        const payload = {
          caseId,
          exhibitId,
          partitionId: partitionId || undefined,
          page: page || undefined,
          fileName,
        }

        publish(MESSAGE_TOPIC.PREVIEW_EXHIBIT, payload).catch(() => {
          const path = getExhibitPreviewPath(payload)
          if (path) {
            openWindow(`${path.base}/${path.route}`)
          }
        })
      },
      [publish, caseId, provider, caseObj?.use_exhibit_builder]
    )

    return (
      <Draggable key={String(provider.pk)} draggableId={String(provider.pk)} index={index}>
        {draggableProvided => (
          <Box
            data-test="provider"
            className={wrapperClassName}
            {...draggableProvided.draggableProps}
            ref={draggableProvided.innerRef}
          >
            {hasConflict && (
              <Tooltip title="New template selected. Ensure injury details are modified appropriately and click the resolve button.">
                <StyledConflictIndicator />
              </Tooltip>
            )}
            <form onBlur={handleBlur}>
              <ProviderSummariesStore
                caseId={caseId}
                providerId={providerId}
                enabled={!!provider.partition_provider && provider.open}
              >
                <ProviderCollapsedRow
                  dispatch={dispatch}
                  provider={provider}
                  active={active}
                  dragHandleProps={draggableProvided.dragHandleProps}
                  updates={updates}
                  isEmpty={isEmpty}
                />
                <Collapse in={provider.open} mountOnEnter unmountOnExit={!active}>
                  {active ? (
                    <ProviderForm
                      disabled={disabled}
                      provider={provider}
                      uploadExhibit={uploadExhibit}
                      validationErrors={validationErrors}
                      onChange={handleProviderChange}
                      onExhibitReorder={handleExhibitReorder}
                      onExhibitDelete={handleExhibitDelete}
                      onExhibitDownload={handleExhibitDownload}
                      onPartitionDelete={handlePartitionDelete}
                      onPartitionDownload={handlePartitionedExhibitDownload}
                      onSave={handleSave}
                      onBlur={handleBlur}
                      onCancel={handleCancel}
                      onDelete={onDelete}
                      providerDataToFormData={providerDataToFormData}
                      hasCollateralSourceRule={hasCollateralSourceRule}
                      missingExhibits={providerMissingExhibits}
                      onAppendExhibit={handleAppendExhibit}
                      dispatch={dispatch}
                      updateExhibitMutation={updateExhibitMutation}
                      updatePartitionMutation={updatePartitionMutation}
                      openPDFViewerWindow={openPDFViewerWindow}
                      updates={updates}
                      documentStructureContentComponents={documentStructureContentComponents}
                      isEmpty={isEmpty}
                    />
                  ) : (
                    <ProviderDetails
                      provider={provider}
                      onExhibitReorder={handleExhibitReorder}
                      onExhibitDelete={handleExhibitDelete}
                      onExhibitDownload={handleExhibitDownload}
                      onPartitionDelete={handlePartitionDelete}
                      onPartitionDownload={handlePartitionedExhibitDownload}
                      hasCollateralSourceRule={hasCollateralSourceRule}
                      missingExhibits={providerMissingExhibits}
                      onAppendExhibit={handleAppendExhibit}
                      openPDFViewerWindow={openPDFViewerWindow}
                      caseId={caseId}
                      updates={updates}
                      documentStructureContentComponents={documentStructureContentComponents}
                    />
                  )}
                </Collapse>
              </ProviderSummariesStore>
            </form>
          </Box>
        )}
      </Draggable>
    )
  },
  (prevProps, thisProps) => {
    const unequalProp = Object.keys(thisProps).find(key => {
      if (
        [
          // ignoring the updaters as they are recreated all the times
          "uploadExhibit",
          "onUpdate",
          "onDelete",
          "onUpdateInBackground",
          "onUpdateWhenBlurred",
          "onDeleteExhibit",
        ].includes(key)
      ) {
        return false
      }

      return prevProps[key] !== thisProps[key]
    })

    return !unequalProp
  }
)
