import { memo, useMemo } from "react"
import { ProviderMedicalProfessional } from "api/services/provider-summaries/types"
import { uniq } from "lodash"
import { Control, Controller } from "react-hook-form"
import { StyledAutocompleteOption, SummaryForm } from "./styled"
import { InputField } from "common/form-components"
import { Autocomplete, Box, TextField, Typography } from "@mui/material"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import { useProviderSummariesStore } from "./store"
import { CitationButton } from "./CitationButton"

type MedicalProfessionalFormProps = {
  control: Control<ProviderMedicalProfessional>
  dateOfService: string
  setMedicalProfessional: (item: ProviderMedicalProfessional) => void
}

export const MedicalProfessionalForm = memo(function MedicalProfessionalForm({
  control,
  dateOfService,
  setMedicalProfessional,
}: MedicalProfessionalFormProps) {
  const medicalProfessionals = useProviderSummariesStore(
    state => state.medicalSummaries.get(dateOfService)?.medicalProfessionalOptions
  )
  const hasMedicalSummary = useProviderSummariesStore(state => !!state.medicalSummaries.get(dateOfService))
  const caseId = useProviderSummariesStore(state => state.caseId)
  const medicalProfessionalTitles = useMemo(
    () => uniq((medicalProfessionals ?? []).map(({ title }) => title).filter(Boolean)),
    [medicalProfessionals]
  )

  return (
    <SummaryForm>
      <Controller
        control={control}
        name="title"
        rules={{ required: true, minLength: 1 }}
        disabled={!hasMedicalSummary}
        render={({ field, fieldState }) => (
          <Autocomplete
            options={medicalProfessionalTitles}
            freeSolo
            clearOnBlur={false}
            blurOnSelect
            {...field}
            onChange={(_, value) => field.onChange({ target: { value: value ?? "" } })}
            onInputChange={(_, value) => field.onChange({ target: { value: value ?? "" } })}
            renderOption={(props, option) => (
              <StyledAutocompleteOption {...props}>{option}</StyledAutocompleteOption>
            )}
            renderInput={params => (
              <TextField
                {...params}
                error={!!fieldState.error}
                label="Medical Professional Title"
                disabled={!hasMedicalSummary}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
                variant="outlined"
                size="small"
              />
            )}
          />
        )}
      />
      <InputField
        rules={{ required: true, minLength: 1 }}
        control={control}
        name="firstName"
        label="Medical Professional First Name"
        size="small"
        disabled={!hasMedicalSummary}
      />
      <InputField
        rules={{ required: true, minLength: 1 }}
        control={control}
        name="lastName"
        label="Medical Professional Last Name"
        size="small"
        disabled={!hasMedicalSummary}
      />
      <InputField
        rules={{ required: true, minLength: 1 }}
        control={control}
        name="designation"
        label="Medical Professional Designation"
        size="small"
        disabled={!hasMedicalSummary}
      />

      {medicalProfessionals && medicalProfessionals.length > 1 && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
          <Box sx={{ fontSize: "10px", lineHeight: "14px" }}>* Multiple Medical Professionals Detected:</Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            {medicalProfessionals.map((item, index) => (
              <Box display="flex" key={`${item.firstName}-${item.lastName}-${index}`}>
                <Box
                  key={`${item.title}_${item.firstName}_${item.lastName}_${item.designation}`}
                  sx={{ display: "flex", gap: 1.25, alignItems: "center", cursor: "pointer" }}
                  onClick={() =>
                    setMedicalProfessional({
                      title: "",
                      firstName: "",
                      lastName: "",
                      designation: "",
                      citations: [],
                      ...item,
                    })
                  }
                >
                  <ArrowUpwardIcon sx={{ width: "12px", height: "12px" }} color="secondary" />
                  <Typography variant="helper" sx={{ fontSize: "10px" }}>
                    {item.title} {item.firstName} {item.lastName}
                    {""}
                    {item.designation ? `, ${item.designation}` : ""}
                  </Typography>
                </Box>
                <Box ml={1} gap={1} my="auto">
                  {item.citations?.map(citation => {
                    if (!citation.user_exhibit_id && !citation.exhibit_id) return null

                    return (
                      <CitationButton
                        key={`${item.firstName}-${item.lastName}-${citation.page}-${citation.exhibit_id}`}
                        caseId={caseId}
                        exhibitId={citation.exhibit_id}
                        partitionId={citation.partition_id}
                        page={citation.page}
                        userExhibitId={citation.user_exhibit_id}
                      />
                    )
                  })}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </SummaryForm>
  )
})
