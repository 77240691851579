import { useRef, useState } from "react"
import { Navigate, Outlet, Route } from "react-router-dom"
import { ApmRouter as Routes } from "infrastructure/apm/ApmRouter"
import Home from "../common/Home"
import RequireAuth from "../common/RequireAuth"
import RestrictAuth from "../common/RestrictAuth"
import AccessDenied from "../common/AccessDenied"
import { CaseList } from "../case"
import { Case } from "../case/Case"
import { StartCase } from "../case/StartCase"
import { Search } from "../search"
import {
  AnnotationList,
  AnnotationRequest,
  AnnotationStatsRequest,
  NewAnnotationRequest,
} from "../annotation"
import Litify from "../integrations/Litify"
import {
  Attorney,
  ChangeEmail,
  Firm,
  FirmEdit,
  FirmList,
  NewAttorney,
  NewFirm,
  NewUserAccount,
  Profile,
  Settings,
  SETTINGS_ROUTE_PREFIX,
  UserAccount,
  UserAccounts,
} from "../settings"
import {
  CarrierForm,
  CaseFactsPage,
  DamagesSections,
  Demand,
  DemandFlow,
  FavoriteCases,
  FutureExpenses,
  HouseholdServices,
  IncomeLoss,
  NewDemand,
  NonEconomic,
  PlaintiffInfo,
  Introduction,
  ProviderList,
  RelevantCases,
  Review,
  Conclusion,
  PainAndSuffering,
} from "../demand"
import Files from "../demand/Files/Files"
import MissingDocuments from "../demand/MissingDocuments"
import { formSectionsToRoutes } from "../demand/constants"
import { Confirmation, RequestsList, RequestForm, ViewRequest } from "../requests"
import { EXTERNAL_ROLES, EXTERNAL_ROLES_VALUES, INTERNAL_ROLES, OSF } from "../common/models/roles"
import ExhibitPage from "../demand/ExhibitPage"
import LoginWithStytch from "../PasswordlessLogin"
import Authenticate from "../Authenticate"
import { Helmet } from "react-helmet"
import { REQUEST_VIEW_TABS } from "../requests/ViewRequest/enums"
import { REQUEST_VIEW_URL_PREFIX } from "../requests/ViewRequest/constants"
import { Library } from "settings/Library"
import DemandHome from "demand/DemandHome"
import { FORM_SECTION_ROUTES } from "demand/steps/constants"
import { CASE_ATTRIBUTES_STEP } from "demand/steps/types"
import { Templates } from "demand/Templates"
import { PdfPreviewPage, ExhibitPreviewPage, PreviewPage, UserExhibitPreviewPage } from "pdf-preview"
import { RestrictedCredits } from "settings/Credits/RestrictedCredits"
import { Global } from "@emotion/react"
import { disableHeaderFooterStyles } from "./styled"
import { Loading } from "common"
import { FeaturePermissions } from "settings/FeaturePermissions/"
import { RequestContainer } from "requests/RequestContainer"
import { ImportData } from "settings/ImportData/ImportData"
import { DocumentExhibits } from "documents/exhibits"
import { MedicalSummary } from "documents/medical-summary"
import { Document } from "documents/Document"
import { BasePage } from "common/pages/BasePage"
import SettlementsPageLoader from "settlements/SettlementsPageLoader"
import CasesRoutesLoader from "cases/CasesRoutesLoader"
import PortalBaseLayout from "./PortalBaseLayout"
import { MedicronCalendarDataWrapper } from "documents/exhibits/MedicronCalendar/MedicronCalendarDataWrapper"
import { MessageBroker } from "message-broker"
import Billing from "settings/Billing"
import { ViewMedicalChronology } from "documents/view-medical-chronology"
import { PageLoader as ExecutiveAnalyticsPageLoader } from "executive-analytics/PageLoader"
import { UserExhibitPartitionPreviewPage } from "pdf-preview/UserExhibitPartitionPreviewPage"
import { AUTHENTICATE_ROUTE } from "./constants"
import { ExhibitManagement } from "documents/exhibits/ExhibitManagement"

const DemandLastVisited = ({ lastVisited, defaultPath }) => (
  <Navigate to={lastVisited.current || defaultPath} replace />
)

export default function AppRoutes() {
  const lastVisited = useRef(null)
  const [lastSearchParams, setLastSearchParams] = useState(null)

  return (
    <Routes>
      <Route path="diagrams" element={<Outlet />}>
        <Route path="calendar" element={<MedicronCalendarDataWrapper />} />
      </Route>
      <Route
        element={
          <MessageBroker>
            <PortalBaseLayout />
          </MessageBroker>
        }
      >
        <Route
          path="/"
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
        <Route
          path="demands"
          element={
            <RequireAuth
              restrictAccess={[
                INTERNAL_ROLES.LEGALOPS,
                INTERNAL_ROLES.LEGALOPS_MANAGER,
                INTERNAL_ROLES.LEGALOPS_ADMIN,
                OSF,
              ]}
            >
              <Outlet />
            </RequireAuth>
          }
        >
          <Route
            index
            element={
              <>
                <Helmet>
                  <title>Demands - EvenUp</title>
                </Helmet>
                <CaseList setLastSearchParams={setLastSearchParams} />
              </>
            }
          />
          <Route path="new" element={<NewDemand />} />
          <Route path=":id" element={<DemandFlow />}>
            <Route index element={<DemandHome />} />
            <Route path="files" element={<Files />} />
            <Route path="files-virtualized" element={<Files virtualized />} />
            <Route path="form" element={<Demand />}>
              {/* default to plaintiff section if no section is provided */}
              <Route
                index
                element={
                  <DemandLastVisited lastVisited={lastVisited} defaultPath={formSectionsToRoutes.plaintiff} />
                }
              />
              <Route
                path={formSectionsToRoutes.plaintiff}
                element={<PlaintiffInfo lastVisited={lastVisited} />}
              />
              <Route
                path={formSectionsToRoutes.introduction}
                element={<Introduction lastVisited={lastVisited} />}
              />
              <Route
                path={formSectionsToRoutes.conclusion}
                element={<Conclusion lastVisited={lastVisited} />}
              />
              <Route
                path={formSectionsToRoutes.carrier}
                element={<CarrierForm lastVisited={lastVisited} />}
              />
              <Route
                path={formSectionsToRoutes.facts}
                element={<CaseFactsPage lastVisited={lastVisited} />}
              />
              <Route
                path={formSectionsToRoutes.providers}
                element={<ProviderList lastVisited={lastVisited} />}
              />
              <Route
                path={formSectionsToRoutes.future_expenses}
                element={<FutureExpenses lastVisited={lastVisited} />}
              />
              <Route
                path={formSectionsToRoutes.non_economic}
                element={<NonEconomic lastVisited={lastVisited} />}
              />
              <Route
                path={formSectionsToRoutes.income_loss}
                element={<IncomeLoss lastVisited={lastVisited} />}
              />
              <Route
                path={formSectionsToRoutes.household_loss}
                element={<HouseholdServices lastVisited={lastVisited} />}
              />
              <Route
                path={formSectionsToRoutes.pain_and_suffering}
                element={<PainAndSuffering lastVisited={lastVisited} />}
              />
              <Route
                path={formSectionsToRoutes.damages_sections}
                element={<DamagesSections lastVisited={lastVisited} />}
              />
              <Route
                path={FORM_SECTION_ROUTES[CASE_ATTRIBUTES_STEP]}
                element={<Templates lastVisited={lastVisited} />}
              />

              <Route
                path={formSectionsToRoutes.missing_docs}
                element={
                  <RequireAuth
                    restrictAccess={[
                      INTERNAL_ROLES.LEGALOPS,
                      INTERNAL_ROLES.LEGALOPS_MANAGER,
                      INTERNAL_ROLES.LEGALOPS_ADMIN,
                    ]}
                  >
                    <MissingDocuments lastVisited={lastVisited} />
                  </RequireAuth>
                }
              />
              <Route path="exhibit" element={<ExhibitPage />} />
            </Route>
            <Route
              path="search"
              element={
                <>
                  <Helmet>
                    <title>Search - EvenUp</title>
                  </Helmet>
                  <Search lastSearchParams={lastSearchParams} setLastSearchParams={setLastSearchParams} />
                </>
              }
            />
            <Route path="relevant" element={<RelevantCases />} />
            <Route path="favorite" element={<FavoriteCases />} />
            <Route path="review" element={<Review />} />
          </Route>
        </Route>
        <Route
          path="/case/new"
          element={
            <RequireAuth
              restrictAccess={[
                INTERNAL_ROLES.LEGALOPS,
                INTERNAL_ROLES.LEGALOPS_MANAGER,
                INTERNAL_ROLES.LEGALOPS_ADMIN,
              ]}
            >
              <StartCase />
            </RequireAuth>
          }
        />
        <Route
          path="/case/:id"
          element={
            <RequireAuth
              restrictAccess={[
                INTERNAL_ROLES.LEGALOPS,
                INTERNAL_ROLES.LEGALOPS_MANAGER,
                INTERNAL_ROLES.LEGALOPS_ADMIN,
              ]}
            >
              <Case />
            </RequireAuth>
          }
        />
        {/* SEARCH */}
        <Route
          path="search"
          element={
            <RequireAuth
              restrictAccess={[
                INTERNAL_ROLES.LEGALOPS,
                INTERNAL_ROLES.LEGALOPS_MANAGER,
                INTERNAL_ROLES.LEGALOPS_ADMIN,
              ]}
            >
              <Outlet />
            </RequireAuth>
          }
        >
          <Route
            index
            element={<Search lastSearchParams={lastSearchParams} setLastSearchParams={setLastSearchParams} />}
          />
          <Route
            path=":id"
            element={<Search lastSearchParams={lastSearchParams} setLastSearchParams={setLastSearchParams} />}
          />
        </Route>
        {/* SETTINGS */}
        <Route
          path="settings"
          element={
            <>
              <Helmet>
                <title>Settings - EvenUp</title>
              </Helmet>
              <RequireAuth>{<Settings />}</RequireAuth>
            </>
          }
        >
          <Route index element={<Navigate to={SETTINGS_ROUTE_PREFIX.PROFILE} replace />} />
          <Route
            path={SETTINGS_ROUTE_PREFIX.BILLING}
            element={
              <RequireAuth restrictAccess={[EXTERNAL_ROLES.FIRM_ADMIN, INTERNAL_ROLES.LEGALOPS_ADMIN]}>
                <Billing />
              </RequireAuth>
            }
          />
          <Route
            path={SETTINGS_ROUTE_PREFIX.FIRMS}
            element={
              <RequireAuth
                restrictAccess={[
                  INTERNAL_ROLES.LEGALOPS,
                  INTERNAL_ROLES.LEGALOPS_MANAGER,
                  INTERNAL_ROLES.LEGALOPS_ADMIN,
                ]}
              >
                <Outlet />
              </RequireAuth>
            }
          >
            <Route index element={<FirmList />} />
            <Route
              path="new"
              element={
                <RequireAuth restrictAccess={[INTERNAL_ROLES.LEGALOPS_ADMIN]}>
                  <NewFirm />
                </RequireAuth>
              }
            />
            <Route path=":id/edit" element={<FirmEdit />} />
            <Route path=":id" element={<Firm />}>
              <Route path="attorneys/:attorneyId" element={<Attorney />} />
              <Route path="attorneys/new-attorney" element={<NewAttorney />} />
            </Route>
          </Route>
          <Route path={SETTINGS_ROUTE_PREFIX.PROFILE} element={<Outlet />}>
            <Route index element={<Profile />} />
            <Route path="change-email" element={<ChangeEmail />} />
          </Route>
          <Route
            path={SETTINGS_ROUTE_PREFIX.ACCOUNTS}
            element={
              <RequireAuth
                restrictAccess={[
                  INTERNAL_ROLES.LEGALOPS_ADMIN,
                  INTERNAL_ROLES.LEGALOPS_MANAGER,
                  EXTERNAL_ROLES.FIRM_ADMIN,
                  EXTERNAL_ROLES.OFFICE_ADMIN,
                ]}
              >
                <Outlet />
              </RequireAuth>
            }
          >
            <Route index element={<UserAccounts />} />
            <Route path="new" element={<NewUserAccount />} />
            <Route path=":id" element={<Outlet />}>
              <Route index element={<UserAccount />} />
            </Route>
          </Route>
          <Route
            path={SETTINGS_ROUTE_PREFIX.CREDITS}
            element={
              <RequireAuth restrictAccess={EXTERNAL_ROLES_VALUES}>
                <RestrictedCredits restricted={<Navigate to="/access-denied" replace />} />
              </RequireAuth>
            }
          />
          <Route
            path={SETTINGS_ROUTE_PREFIX.LIBRARY}
            element={
              <RequireAuth
                restrictAccess={[
                  INTERNAL_ROLES.LEGALOPS_ADMIN,
                  INTERNAL_ROLES.LEGALOPS_MANAGER,
                  INTERNAL_ROLES.LEGALOPS,
                ]}
              >
                <Library />
              </RequireAuth>
            }
          />

          <Route
            path={SETTINGS_ROUTE_PREFIX.FEATURE_PERMISSIONS}
            element={
              <RequireAuth restrictAccess={[INTERNAL_ROLES.LEGALOPS_ADMIN]}>
                <FeaturePermissions />
              </RequireAuth>
            }
          />

          <Route
            path={SETTINGS_ROUTE_PREFIX.IMPORT}
            element={
              <RequireAuth
                restrictAccess={[EXTERNAL_ROLES.FIRM_ADMIN, EXTERNAL_ROLES.ATTORNEY, EXTERNAL_ROLES.SUPPORT]}
              >
                <ImportData />
              </RequireAuth>
            }
          />
        </Route>
        {/* CLIENT / INTAKE ROUTES */}
        <Route
          path="requests"
          element={
            <RequireAuth>
              <>
                <RequestContainer>
                  <Outlet />
                </RequestContainer>
              </>
            </RequireAuth>
          }
        >
          <Route
            index
            element={
              <>
                <Helmet>
                  <title>Requests - EvenUp</title>
                </Helmet>
                <RequestsList />
              </>
            }
          />
          <Route path="new" element={<Outlet />}>
            <Route
              index
              element={
                <RequireAuth>
                  <RequestForm />
                </RequireAuth>
              }
            />
          </Route>
          <Route path=":id" element={<Outlet />}>
            <Route index element={<ViewRequest />} />
            <Route path="edit" element={<Outlet />}>
              <Route
                index
                element={
                  <RequireAuth>
                    <RequestForm />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="success" element={<Confirmation />} />
            <Route
              path={REQUEST_VIEW_URL_PREFIX.MISSING_DOCS}
              element={<ViewRequest tab={REQUEST_VIEW_TABS.MISSING_DOCS} />}
            />
            <Route
              path={REQUEST_VIEW_URL_PREFIX.REVISION}
              element={<ViewRequest tab={REQUEST_VIEW_TABS.REVISION} />}
            />
          </Route>
        </Route>
        {/* Annotation */}
        <Route
          path="/annotation"
          element={
            <RequireAuth
              restrictAccess={[
                INTERNAL_ROLES.LEGALOPS,
                INTERNAL_ROLES.LEGALOPS_MANAGER,
                INTERNAL_ROLES.LEGALOPS_ADMIN,
              ]}
            >
              <AnnotationList />
            </RequireAuth>
          }
        />
        <Route
          path="/annotation/:id"
          element={
            <RequireAuth
              restrictAccess={[
                INTERNAL_ROLES.LEGALOPS,
                INTERNAL_ROLES.LEGALOPS_MANAGER,
                INTERNAL_ROLES.LEGALOPS_ADMIN,
              ]}
            >
              <AnnotationRequest />
            </RequireAuth>
          }
        />
        <Route
          path="/annotation/new"
          element={
            <RequireAuth
              restrictAccess={[
                INTERNAL_ROLES.LEGALOPS,
                INTERNAL_ROLES.LEGALOPS_MANAGER,
                INTERNAL_ROLES.LEGALOPS_ADMIN,
              ]}
            >
              <NewAnnotationRequest />
            </RequireAuth>
          }
        />
        <Route
          path="/annotation/stats"
          element={
            <RequireAuth
              restrictAccess={[
                INTERNAL_ROLES.LEGALOPS,
                INTERNAL_ROLES.LEGALOPS_MANAGER,
                INTERNAL_ROLES.LEGALOPS_ADMIN,
              ]}
            >
              <AnnotationStatsRequest />
            </RequireAuth>
          }
        />
        {/* exhibit preview */}
        <Route
          path="exhibit-preview"
          element={
            <RequireAuth
              restrictAccess={[
                INTERNAL_ROLES.LEGALOPS,
                INTERNAL_ROLES.LEGALOPS_MANAGER,
                INTERNAL_ROLES.LEGALOPS_ADMIN,
                EXTERNAL_ROLES.FIRM_ADMIN,
                EXTERNAL_ROLES.OFFICE_ADMIN,
                EXTERNAL_ROLES.ATTORNEY,
                EXTERNAL_ROLES.SUPPORT,
                OSF,
              ]}
            >
              <>
                <Global styles={disableHeaderFooterStyles} />
                <PreviewPage />
              </>
            </RequireAuth>
          }
        >
          <Route index element={<Loading show label="Waiting for file..." />} />
          <Route path=":caseId/exhibit/:exhibitId" element={<ExhibitPreviewPage />} />
          <Route path="user-exhibit/:userExhibitId" element={<UserExhibitPreviewPage />} />
          <Route path=":caseId/exhibit/:exhibitId/partition/:partitionId" element={<ExhibitPreviewPage />} />
          <Route path="documents/:documentId/exhibit/:exhibitId" element={<ExhibitPreviewPage />} />
          <Route
            path="documents/:documentId/exhibit/:exhibitId/partition/:partitionId"
            element={<ExhibitPreviewPage />}
          />
          <Route
            path="exhibit-builder/exhibit/:exhibitId/partition/:partitionId"
            element={<UserExhibitPartitionPreviewPage />}
          />
          <Route path="pdf-preview" element={<PdfPreviewPage />} />
        </Route>

        {/* DOCUMENTS */}
        <Route
          path="/documents"
          element={
            <RequireAuth
              restrictAccess={[
                INTERNAL_ROLES.LEGALOPS,
                INTERNAL_ROLES.LEGALOPS_MANAGER,
                INTERNAL_ROLES.LEGALOPS_ADMIN,
                EXTERNAL_ROLES.FIRM_ADMIN,
                EXTERNAL_ROLES.OFFICE_ADMIN,
                EXTERNAL_ROLES.ATTORNEY,
                EXTERNAL_ROLES.SUPPORT,
              ]}
            >
              <BasePage />
            </RequireAuth>
          }
        >
          <Route path=":id" element={<Outlet />}>
            <Route index element={<DocumentExhibits />}></Route>
            <Route path="exhibit-builder" element={<ExhibitManagement />}></Route>
            <Route
              path="exhibit-management"
              element={
                <RequireAuth
                  restrictAccess={[
                    INTERNAL_ROLES.LEGALOPS,
                    INTERNAL_ROLES.LEGALOPS_MANAGER,
                    INTERNAL_ROLES.LEGALOPS_ADMIN,
                  ]}
                >
                  <DocumentExhibits />
                </RequireAuth>
              }
            ></Route>
            <Route
              path="medical-summary"
              element={
                <Document>
                  <RequireAuth
                    restrictAccess={[
                      INTERNAL_ROLES.LEGALOPS,
                      INTERNAL_ROLES.LEGALOPS_MANAGER,
                      INTERNAL_ROLES.LEGALOPS_ADMIN,
                    ]}
                  >
                    <MedicalSummary />
                  </RequireAuth>
                </Document>
              }
            ></Route>
            <Route
              path="view-medical-chronology"
              element={
                <Document>
                  <RequireAuth
                    restrictAccess={[
                      INTERNAL_ROLES.LEGALOPS,
                      INTERNAL_ROLES.LEGALOPS_MANAGER,
                      INTERNAL_ROLES.LEGALOPS_ADMIN,
                      EXTERNAL_ROLES.FIRM_ADMIN,
                      EXTERNAL_ROLES.OFFICE_ADMIN,
                      EXTERNAL_ROLES.ATTORNEY,
                      EXTERNAL_ROLES.SUPPORT,
                    ]}
                  >
                    <ViewMedicalChronology />
                  </RequireAuth>
                </Document>
              }
            ></Route>
          </Route>
        </Route>

        {/* SETTLEMENTS */}
        {/* "/*" is needed because there are nested <Routes> inside of the SettlementsPage that are lazy loaded */}
        <Route path="/settlements/*" element={<SettlementsPageLoader />} />
        {/* Exec Analytics */}
        {/* "/*" is needed because there are nested <Routes> inside of the ExecutiveOverviewPage that are lazy loaded */}
        <Route path="/executive-analytics/*" element={<ExecutiveAnalyticsPageLoader />} />

        {/* CASES */}
        <Route path="cases/*" element={<CasesRoutesLoader />} />

        {/* LOGIN */}
        <Route path="/access-denied" element={<AccessDenied />} replace />
        <Route
          path="/login"
          element={
            <RestrictAuth>
              <LoginWithStytch />
            </RestrictAuth>
          }
        />
        <Route path={AUTHENTICATE_ROUTE} element={<Authenticate />} />
        <Route
          path="/litify"
          element={
            <RequireAuth>
              <Litify />
            </RequireAuth>
          }
        />
        {/* CATCH ALL */}
        <Route path="*" element={<AccessDenied />} replace />
      </Route>
    </Routes>
  )
}
