import { EditableSelect } from "evenup-ui/EditableSelect"
import { Row, DateField, ExhibitLink, MedChronRecordRow } from "./styled"
import { useCallback } from "react"
import { Item } from "evenup-ui/EditableSelect/type"
import { DateRangePicker } from "./DateRangePicker/DateRangePicker"
import { EB_DOC_TYPE } from "exhibit-builder/types"

type DateInput = Nullable<string> | { start: string; end: string }

export interface PartitionListItemProps<T extends DateInput> {
  providers: Item[]
  provider: Nullable<string>
  documentType?: string
  startPage: number
  endPage: number
  onPreview: () => void
  onProviderChange?: (newProviderId: Nullable<string>) => void
  onProviderCreate?: (name: string) => Promise<Item>
  onProviderRename?: (providerId: string, newName: string) => void
  dateOfService: T
  onDateChange: (date: this["dateOfService"]) => void
  readOnly?: boolean
  type?: Nullable<EB_DOC_TYPE>
  onProviderDelete?: (providerId: string) => Promise<void>
  canDeleteProvider?: (providerId: string) => boolean
}

export function PartitionListItem<T extends DateInput>({
  provider,
  providers,
  documentType,
  startPage,
  endPage,
  dateOfService,
  onPreview,
  onProviderChange,
  onProviderCreate,
  onProviderRename,
  onDateChange,
  onProviderDelete,
  canDeleteProvider,
  readOnly,
  type = EB_DOC_TYPE.DEMAND,
  ...props
}: PartitionListItemProps<T>) {
  const createProvider = useCallback((name: string) => onProviderCreate!(name), [onProviderCreate])
  const handleProviderCreate = onProviderCreate ? createProvider : undefined
  const handleProviderRename = useCallback(
    (providerId: string, name: string) => onProviderRename?.(providerId, name),
    [onProviderRename]
  )
  const handleProviderChange = useCallback(
    (providerId: Nullable<string>) => onProviderChange?.(providerId),
    [onProviderChange]
  )

  const renderCells = useCallback(() => {
    return (
      <>
        <EditableSelect
          itemType="provider"
          label={type === EB_DOC_TYPE.DEMAND ? "Import Provider" : undefined}
          value={provider}
          emptyValueLabel="None to Exclude"
          onChange={handleProviderChange}
          onCreate={handleProviderCreate}
          onRename={handleProviderRename}
          onDelete={onProviderDelete}
          canDelete={canDeleteProvider}
          options={providers}
          readOnly={readOnly}
          sx={{ width: "100%" }}
        />
        {documentType && <div>{documentType}</div>}
        {!dateOfService || typeof dateOfService === "string" ? (
          <DateField
            dataTest="partition-list-item-date"
            required
            initialValue={dateOfService}
            onChange={onDateChange as (date: Nullable<string>) => void}
            fieldProps={{ name: "start_date", size: "small", margin: "normal" }}
            readOnly={readOnly}
          />
        ) : (
          <DateRangePicker
            startDate={dateOfService.start}
            endDate={dateOfService.end}
            onChange={onDateChange as (date: { start: string; end: string }) => void}
            readOnly={readOnly}
          />
        )}
        <ExhibitLink onClick={onPreview}>
          {startPage}-{endPage}
        </ExhibitLink>
      </>
    )
  }, [
    dateOfService,
    documentType,
    endPage,
    handleProviderChange,
    handleProviderCreate,
    handleProviderRename,
    onDateChange,
    onPreview,
    provider,
    providers,
    readOnly,
    startPage,
    type,
    onProviderDelete,
    canDeleteProvider,
  ])

  if (type === EB_DOC_TYPE.DEMAND) {
    return (
      <div data-test="demand-partition-list-item">
        <Row {...props}>{renderCells()}</Row>
      </div>
    )
  }

  if (type === EB_DOC_TYPE.MEDCHRON) {
    return (
      <div data-test="medchron-partition-list-item">
        <MedChronRecordRow {...props}>{renderCells()}</MedChronRecordRow>
      </div>
    )
  }
}
