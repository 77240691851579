import styled from "@emotion/styled"
import { Box, Typography } from "@mui/material"
import { UserExhibit, UserExhibitProcessingStatus } from "exhibit-builder/store/types"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import { red } from "@mui/material/colors"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { ActionButton, useAction } from "exhibit-builder/ActionButton"
import { useQuery } from "@tanstack/react-query"
import { queryKeys } from "react-query/constants"
import { useEffect, useRef, useState } from "react"

const Container = styled(Box)<{ status: UserExhibit["processingStatus"] }>(({ theme, status }) => ({
  color: status === UserExhibitProcessingStatus.ERROR ? red[800] : "#663C00",
  backgroundColor: status === UserExhibitProcessingStatus.ERROR ? "#FDEDED" : "#FFF4E5",
  padding: theme.spacing(0.5, 5),
  display: "inline-flex",
  alignItems: "center",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))

const RetryButton = styled(ActionButton)({
  background: "none",
  border: "none",
  cursor: "pointer",
  textDecoration: "underline",
  padding: 0,
  color: "inherit",
  display: "inline-flex",
  minWidth: "auto",
  lineHeight: "normal",
  height: "auto",
  "&:hover": {
    background: "none",
    textDecoration: "underline",
  },
})

export function PDFStatus({ id }: { id: UserExhibit["id"] }) {
  const processingStatus = useExhibitBuilderStore(state => state.userExhibitMap[id].processingStatus)
  const [refetchError, setRefetchError] = useState(false)
  const timeoutRef = useRef<number>()

  const { isLoading, handleAction } = useAction({
    name: "Generate PDF",
    action: () => {
      setRefetchError(false)
      return exhibitBuilderActions.regenerateUserExhibitPDF(id)
    },
  })

  const shouldCheckStatus =
    processingStatus !== UserExhibitProcessingStatus.COMPLETE &&
    processingStatus !== UserExhibitProcessingStatus.ERROR

  useEffect(() => {
    if (shouldCheckStatus) {
      timeoutRef.current = window.setTimeout(() => {
        setRefetchError(shouldCheckStatus)
      }, 120_000)
    }

    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [shouldCheckStatus])

  useQuery({
    enabled: shouldCheckStatus,
    queryKey: [queryKeys.userExhibitPDFStatus, id],
    queryFn: () => exhibitBuilderActions.checkUserExhibitPDFStatus(id),
    refetchInterval: shouldCheckStatus ? 2000 : false,
    cacheTime: 0,
  })

  if (!processingStatus || processingStatus === UserExhibitProcessingStatus.COMPLETE) {
    return null
  }

  const retryButton = (
    <RetryButton
      data-test="retry-button"
      onClick={handleAction}
      label={
        <Typography variant="caption" fontWeight="bold">
          Try again
        </Typography>
      }
      isLoading={isLoading}
    />
  )

  return (
    <Container status={processingStatus} data-test="pdf-status">
      {processingStatus === UserExhibitProcessingStatus.ERROR ? (
        <>
          <ErrorOutlineIcon htmlColor={red[600]} fontSize="small" />
          <Typography variant="caption" display="flex" gap={0.5}>
            <>Error loading generated PDF.{retryButton}</>
          </Typography>
        </>
      ) : (
        <>
          <AccessTimeIcon htmlColor="#ED6C02" fontSize="small" />
          <Typography variant="caption" display="flex" gap={0.5}>
            {refetchError ? (
              <>PDF is taking longer than expected to generate. {retryButton}</>
            ) : (
              "PDF Generating"
            )}
          </Typography>
        </>
      )}
    </Container>
  )
}
