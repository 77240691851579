import { useMemo } from "react"

import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { useTheme } from "@mui/material/styles"
import { makeStyles } from "tss-react/mui"
import { StytchLogin } from "@stytch/react"
import { OAuthProviders, Products } from "@stytch/vanilla-js"

const useStyles = makeStyles()(theme => ({
  loginWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1rem",
    gap: theme.spacing(3),
    margin: theme.spacing(4, 0),
  },
  copyContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  copyMain: {
    fontWeight: "bold",
  },
}))

interface SalesforceOAuthProps {
  loginRedirectPathUrl: string
}

const SalesforceOAuth = ({ loginRedirectPathUrl }: SalesforceOAuthProps) => {
  const { classes } = useStyles()
  const theme = useTheme()

  const stytchProps = useMemo(
    () => ({
      products: [Products.oauth],
      oauthOptions: {
        loginRedirectURL: loginRedirectPathUrl,
        providers: [
          {
            type: OAuthProviders.Salesforce,
            custom_scopes: ["id", "api", "refresh_token"],
          },
        ],
      },
    }),
    [loginRedirectPathUrl]
  )

  const overrideStyles = {
    fontFamily: '"Inter", "Manrope", "Helvetica New", Helvetica, sans-serif',
    width: "321px",
    primaryColor: theme.palette.secondary.main,
    primaryTextColor: theme.palette.primary.dark,
    hideHeaderText: true,
  }

  return (
    <Box className={classes.loginWrapper}>
      <Box className={classes.loginContainer}>
        <Typography variant="h4" component="h1">
          Access to Litify Data required
        </Typography>
        <Box className={classes.copyContainer}>
          <Typography variant="body1" component="p" className={classes.copyMain}>
            To create your EvenUp Request, we need access to your Litify data. Click &lsquo;Allow
            Access&lsquo; in the following screen after logging in.
          </Typography>
        </Box>
        <StytchLogin config={stytchProps} styles={overrideStyles} />
      </Box>
    </Box>
  )
}

export default SalesforceOAuth
