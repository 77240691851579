import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { Actions } from "./Actions/Actions"
import { useShallow } from "zustand/react/shallow"
import { CollapseButtons } from "./Collapse/CollapseButtons"
import { useExhibitBuilderStore, exhibitBuilderActions } from "./store"
import { UserExhibitList } from "./UserExhibitList/UserExhibitList"
import { CSSProperties, PropsWithChildren, ReactNode, useEffect, useMemo, useState } from "react"
import { ExhibitBuilderSkeleton } from "./ExhibitBuilderSkeleton"
import { DeleteFiles } from "./DeletedFiles/DeletedFiles"
import { UnableToParse } from "./UnableToParse/UnableToParse"
import { StickyHeaderActions, StyledHeaderContainer, StyledMedChronHeaderActions } from "./styled"
import { MedicalSummaryButton } from "documents/Buttons"
import { EB_DOC_TYPE } from "./types"
import { ErrorBoundary } from "@sentry/react"
import { GenericErrorForSuspense } from "common"
import { useListData } from "./UserExhibitList/useListData"
import { CollapseProvider } from "./Collapse/collapse"
import { AlertModal, AlertModalProvider } from "./AlertModal"
import { createPortal } from "react-dom"
import { UpdateSummariesButton } from "./UpdateSummariesButton"
import { useSearchParams } from "react-router-dom"
import { FiltersSlice } from "./store/filters"
import { AddProvider } from "./AddProvider/AddProvider"
import { ImportProviders } from "./Actions/ImportProviders/ImportProviders"
import { useStickyActionsBar } from "exhibit-builder/hooks/useStickyActionsBar"

type ContainerProps = {
  width: string
  position: CSSProperties["position"]
  ["data-test"]: string
  margin?: string
  maxWidth?: number
}

function ExhibitBuilder({
  caseId,
  documentId,
  type,
  children,
  headerActions,
}: PropsWithChildren<{ caseId?: string; documentId: string; type: EB_DOC_TYPE; headerActions?: ReactNode }>) {
  const userExhibitMap = useExhibitBuilderStore(useShallow(state => state.userExhibitMap))
  const userExhibitIds = useMemo(() => Object.keys(userExhibitMap), [userExhibitMap])
  const isLoading = useExhibitBuilderStore(state => state.document.loading)
  const listData = useListData(useExhibitBuilderStore(useShallow(state => state.filters)))
  const [searchParams] = useSearchParams()
  const providerId = searchParams.get("providerId")
  const plaintiffId = searchParams.get("plaintiffId")
  const exactProviderMatch = searchParams.get("exactProviderMatch")
  const [showAddProvider, setShowAddProvider] = useState(false)

  useEffect(() => {
    const filters: Partial<FiltersSlice["filters"]> = {}

    if (providerId) filters.selectedProviders = [providerId]
    if (plaintiffId) filters.selectedPlaintiffs = [plaintiffId]
    if (exactProviderMatch) filters.exactProviderMatch = exactProviderMatch === "true"

    exhibitBuilderActions
      .initialize({
        caseId,
        documentId,
        type,
        ...(Object.keys(filters).length > 0 && { filters }),
      })
      ?.then(() => setShowAddProvider(true))
  }, [caseId, documentId, type, providerId, plaintiffId, exactProviderMatch])

  const containerProps: ContainerProps = {
    width: "100%",
    position: "relative",
    ["data-test"]: "exhibit-builder",
  }

  if (type === EB_DOC_TYPE.MEDCHRON) {
    containerProps.margin = "0"
  }

  if (type === EB_DOC_TYPE.DEMAND) {
    containerProps.margin = "0 auto"
    containerProps.maxWidth = 1200
  }

  return (
    <ErrorBoundary fallback={<GenericErrorForSuspense />}>
      <AlertModalProvider>
        <CollapseProvider ids={userExhibitIds}>
          <AlertModal />
          <Box {...containerProps}>
            <Box mb={2} data-test={`${type}-exhibit-builder`}>
              <StyledHeaderContainer>
                <div>
                  <Box
                    mb={1.5}
                    sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                  >
                    <Typography variant="h6">Exhibit Builder</Typography>
                    {caseId && <ImportProviders />}
                  </Box>
                  <Typography variant="body2" mb={1.5}>
                    File count: {userExhibitIds.length}
                  </Typography>
                </div>
                {headerActions}
              </StyledHeaderContainer>
              {children}
            </Box>

            {isLoading ? (
              <ExhibitBuilderSkeleton />
            ) : (
              <>
                {showAddProvider && <AddProvider />}
                <CollapseButtons />
                <UserExhibitList listData={listData} />
                <DeleteFiles />
                <UnableToParse />
              </>
            )}
          </Box>
        </CollapseProvider>
      </AlertModalProvider>
    </ErrorBoundary>
  )
}

export function DemandExhibitBuilder({ caseId, documentId }: { caseId: string; documentId: string }) {
  return (
    <ExhibitBuilder caseId={caseId} documentId={documentId} type={EB_DOC_TYPE.DEMAND}>
      <Actions caseId={caseId} type={EB_DOC_TYPE.DEMAND} />
    </ExhibitBuilder>
  )
}

export function MedchronExhibitBuilder({ documentId }: { documentId: string }) {
  const { showStickyHeader } = useStickyActionsBar()

  return (
    <ExhibitBuilder
      documentId={documentId}
      type={EB_DOC_TYPE.MEDCHRON}
      headerActions={
        <>
          {createPortal(
            <StickyHeaderActions show={showStickyHeader}>
              <UpdateSummariesButton documentId={documentId} size="small" showDisabledTooltip />
              <MedicalSummaryButton size="small" documentId={documentId} />
            </StickyHeaderActions>,
            document.querySelector(".sticky-header-container") as HTMLElement
          )}
          <StyledMedChronHeaderActions>
            <UpdateSummariesButton documentId={documentId} showDisabledTooltip />
            <MedicalSummaryButton documentId={documentId} />
          </StyledMedChronHeaderActions>
        </>
      }
    >
      <Actions type={EB_DOC_TYPE.MEDCHRON} />
    </ExhibitBuilder>
  )
}
