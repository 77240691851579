import { ITEM_REF } from "common/nested-list/constants"
import { ProjectionItemWithChildren } from "common/nested-list/types"
import { DndUserExhibitListData } from "exhibit-builder/store/types"
import { ExhibitPartitionChildrenPlaceholder } from "./ExhibitPartition/ExhibitPartitionChildrenPlaceholder"

export function UserExhibitListPlaceholder({
  item,
}: {
  item: ProjectionItemWithChildren<DndUserExhibitListData>
}) {
  const itemData = item[ITEM_REF]

  switch (itemData.type) {
    case "userExhibit":
      // show empty div to allow drag and drop
      return <div />

    case "exhibitPartition":
      return <ExhibitPartitionChildrenPlaceholder partitionId={itemData.id} />

    default:
      return null
  }
}
