import { Tab, TabsOwnProps } from "@mui/material"
import { useCallback, useState } from "react"
import { SummaryTabs } from "./styled"
import { MedicalSumaryTab } from "./MedicalSummaryTab"
import { CitationsTab } from "./CitationsTab"

export function SummaryItemTabs({
  dateOfService,
  showStatus,
  readonly,
}: {
  dateOfService: string
  showStatus: boolean
  readonly: boolean
}) {
  const [currentTabIdx, setCurrentTabIdx] = useState(0)

  const handleTabChange = useCallback<NonNullable<TabsOwnProps["onChange"]>>((_, nextTabIdx) => {
    setCurrentTabIdx(nextTabIdx)
  }, [])

  return (
    <>
      <SummaryTabs value={currentTabIdx} onChange={handleTabChange} indicatorColor="secondary">
        <Tab label="Summary Details" />
        <Tab label="Citations" />
      </SummaryTabs>
      {currentTabIdx === 0 && (
        <MedicalSumaryTab dateOfService={dateOfService} showStatus={showStatus} readonly={readonly} />
      )}
      {currentTabIdx === 1 && <CitationsTab dateOfService={dateOfService} />}
    </>
  )
}
