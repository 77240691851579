import Typography from "@mui/material/Typography"
import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"

interface LitifyMissingFieldsErrorProps {
  missingFields: string
}

export const LitifyMissingFieldsError = ({ missingFields }: LitifyMissingFieldsErrorProps) => {
  return (
    <Alert severity="error">
      <AlertTitle>
        We could not import your Litify Matter because it is missing one or more fields.
      </AlertTitle>
      <br />
      <Typography variant="body1">Missing fields: {missingFields}</Typography>
      <br />
      <Typography variant="body1">
        You can either manually create your Demand here in EvenUp or retry from Litify:
      </Typography>
      <Typography variant="body1">
        <ul>
          <li>Enter the {missingFields} in your Litify Matter</li>
          <li>
            Click the <q>EvenUp AI Demand</q> and then the <q>Next</q> button. It will say that the Demand has
            already been created but the information will update!
          </li>
        </ul>
      </Typography>
    </Alert>
  )
}
