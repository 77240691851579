import React, { useCallback } from "react"
import DescriptionIcon from "@mui/icons-material/DescriptionOutlined"
import {
  Footer,
  PartitionsFile,
  PartitionsTable,
  StyledExhibitOpenLink,
  StyledExhibitPartitionGroupContent,
  StyledExhibitPartitionItem,
  TotalPages,
} from "./styled"
import { Appointment, Exhibit, Partition, Provider } from "documents/types"
import { useExhibitPreview } from "./useExhibitPreview"
import { Box, Typography } from "@mui/material"
import { documentActions, useDocumentStore } from "documents/store"
import { appointmentsSelectors } from "documents/store/appointments"
import { providersSelectors } from "documents/store/providers"
import { partitionsSelectors } from "documents/store/partitions"
import { exhibitSelectors } from "documents/store/exhibits"
import { useShallow } from "zustand/react/shallow"

interface ExhibitPartitionRowProps {
  partitionId: Partition["id"]
  onPreview: (partition?: Partition) => void
}

type ExhibitAppointmentRowProps = Omit<ExhibitPartitionRowProps, "partitionId"> & {
  appointmentId: Appointment["id"]
} & Pick<Partition, "startPage" | "endPage">

function ExhibitAppointmentRow({
  appointmentId,
  onPreview,
  startPage,
  endPage,
}: ExhibitAppointmentRowProps): JSX.Element {
  const appointment = useDocumentStore(appointmentsSelectors.getAppointmentById(appointmentId))
  const providers = useDocumentStore(useShallow(providersSelectors.getProviders))

  const handleProviderChange = useCallback(
    (providerId: Appointment["providerId"]) =>
      documentActions.setAppointmentProvider({ appointmentId, providerId }),
    [appointmentId]
  )

  const handleProviderRename = useCallback(
    (id: Provider["id"], name: Provider["name"]) => documentActions.renameProvider({ id, name }),
    []
  )

  const handleDateChange = useCallback(
    (dateOfService: Nullable<Appointment["dateOfService"]>) => {
      if (dateOfService) {
        documentActions.setAppointmentDate({ appointmentId, dateOfService })
      }
    },
    [appointmentId]
  )

  const handleProviderCreate = useCallback((name: string) => documentActions.createProvider({ name }), [])

  return (
    <StyledExhibitPartitionItem
      provider={appointment.providerId}
      providers={providers}
      dateOfService={appointment.dateOfService}
      startPage={startPage}
      endPage={endPage}
      onPreview={onPreview}
      onDateChange={handleDateChange}
      onProviderChange={handleProviderChange}
      onProviderCreate={handleProviderCreate}
      onProviderRename={handleProviderRename}
    />
  )
}

function ExhibitPartitionRowGroup({
  partitionId,
  onPreview,
  ...props
}: ExhibitPartitionRowProps): JSX.Element {
  const partition = useDocumentStore(partitionsSelectors.getPartitionById(partitionId))
  const { appointmentIds, startPage = 1, endPage = 1 } = partition ?? {}
  const handlePreview = useCallback(() => onPreview(partition), [onPreview, partition])

  if (!appointmentIds?.length) return <></>

  return (
    <Box>
      {appointmentIds.map(appointmentId => (
        <ExhibitAppointmentRow
          key={appointmentId}
          {...props}
          onPreview={handlePreview}
          appointmentId={appointmentId}
          startPage={startPage}
          endPage={endPage}
        />
      ))}
    </Box>
  )
}

interface ExhibitPartitionsFileProps {
  exhibit: Exhibit
}

export function ExhibitFile({ exhibit }: ExhibitPartitionsFileProps) {
  const openPreview = useExhibitPreview(exhibit.id)

  return (
    <PartitionsFile>
      <DescriptionIcon fontSize="small" />
      <StyledExhibitOpenLink onClick={() => openPreview()}>
        {exhibit.fileName ?? exhibit.name}
      </StyledExhibitOpenLink>
    </PartitionsFile>
  )
}

interface ExhibitPartitionsProps {
  exhibitId: Exhibit["id"]
}

export const ExhibitPartitions = React.memo(function ExhibitPartitions({
  exhibitId,
}: ExhibitPartitionsProps): JSX.Element {
  const exhibit = useDocumentStore(exhibitSelectors.getExhibitById(exhibitId))
  const openPreview = useExhibitPreview(exhibitId)

  if (!exhibit.partitionIds.length) {
    return (
      <PartitionsTable>
        <Typography variant="body2" pt={2} pb={2} pl={2.5}>
          No medical records found
        </Typography>

        <Footer>
          <TotalPages>Total pages:{"\u00A0"}</TotalPages>
          <div>{exhibit.pageCount}</div>
          <div></div>
        </Footer>
      </PartitionsTable>
    )
  }

  return (
    <PartitionsTable>
      <StyledExhibitPartitionGroupContent
        fileName={exhibit.fileName || ""}
        onPreview={openPreview}
        hideDocumentType
        startPage={1}
        endPage={exhibit.pageCount === "N/A" ? 1 : exhibit.pageCount}
      />
      {exhibit.partitionIds.map(partitionId => (
        <ExhibitPartitionRowGroup key={partitionId} partitionId={partitionId} onPreview={openPreview} />
      ))}
      <Footer>
        <TotalPages>Total pages:{"\u00A0"}</TotalPages>
        <div>{exhibit.pageCount}</div>
        <div></div>
      </Footer>
    </PartitionsTable>
  )
})
