import { Link as RouterLink } from "react-router-dom"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import SearchIcon from "@mui/icons-material/Search"
import VisibilityIcon from "@mui/icons-material/Visibility"
import DeleteIcon from "@mui/icons-material/Delete"
import styled from "@emotion/styled"

import { useMutation, useQueryClient } from "@tanstack/react-query"
import { queryKeys } from "../../react-query/constants"
import { deleteCase } from "../../api"

import { formatDate } from "../../utils"
import { Column } from "common/tables/types"
import { Case } from "case/types"
import { TableCell } from "@mui/material"

const NOT_APPLICABLE = "N/A"

const StyledLink = styled(RouterLink)({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
})

const CellComponentWithLink = ({ record, children }: { record: Case; children?: React.ReactNode }) => {
  return (
    <TableCell sx={{ position: "relative" }}>
      <StyledLink to={`/demands/${record.pk}`} />
      {children}
    </TableCell>
  )
}

const CaseNameCell = ({ record }: { record: Case }) => {
  return record.name
}

const FirmCell = ({ record }: { record: Case }) => {
  return record?.firm?.name || NOT_APPLICABLE
}

const CreatedByCell = ({ record }: { record: Case }) => {
  return record?.creator ? `${record.creator?.first_name} ${record.creator?.last_name}` : NOT_APPLICABLE
}

const ActionsCell = ({ record }: { record: Case }) => {
  const queryClient = useQueryClient()
  const { mutate: handleDelete } = useMutation(deleteCase, {
    onSuccess: () => queryClient.invalidateQueries([queryKeys.cases]),
  })

  return (
    <>
      <IconButton component={RouterLink} to={"/demands/" + record.pk}>
        <VisibilityIcon color="primary" />
      </IconButton>
      <IconButton component={RouterLink} to={`/demands/${record.pk}/search`}>
        <SearchIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          if (confirm("Delete the case?")) {
            handleDelete(record.pk)
          }
        }}
      >
        <DeleteIcon />
      </IconButton>
    </>
  )
}

export const columns: Column<Case>[] = [
  {
    id: "case_name",
    text: "Case Name",
    align: "left",
    cellContentComponent: CaseNameCell,
    tableCellComponent: CellComponentWithLink,
  },
  {
    id: "firm",
    text: "Firm",
    align: "left",
    cellContentComponent: FirmCell,
    tableCellComponent: CellComponentWithLink,
  },
  {
    id: "created_by",
    text: "Created By",
    align: "left",
    cellContentComponent: CreatedByCell,
    tableCellComponent: CellComponentWithLink,
  },
  {
    id: "state",
    text: "State",
    align: "left",
    cellContentComponent: ({ record }) => {
      return record.state ?? NOT_APPLICABLE
    },
    tableCellComponent: CellComponentWithLink,
  },
  {
    id: "county",
    text: "County",
    align: "left",
    cellContentComponent: ({ record }) => {
      return record.county ?? NOT_APPLICABLE
    },
    tableCellComponent: CellComponentWithLink,
  },
  {
    id: "created_at",
    text: "Date Created",
    sortable: true,
    cellContentComponent: ({ record }) => {
      return <Typography noWrap={true}>{formatDate(record.created_at) ?? NOT_APPLICABLE}</Typography>
    },
    tableCellComponent: CellComponentWithLink,
  },
  {
    id: "actions",
    text: "Actions",
    align: "right",
    cellContentComponent: ActionsCell,
  },
]
