import styled from "@emotion/styled"
import { LoadingButton } from "@mui/lab"
import { PartitionListHeader } from "exhibit-builder/Exhibit/PartitionListHeader"
import { PartitionListItem } from "exhibit-builder/Exhibit/PartitionListItem"
import { ExhibitName } from "exhibit-builder/Exhibit/styled"

const gridTemplateColumns = "minmax(350px, 1fr) 150px 250px 150px 200px"

export const DeletedPartition = styled(PartitionListHeader)(({ theme }) => ({
  border: `1px solid ${theme.palette.error.main}`,
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(0.25, 1.5),
  gridTemplateColumns,
  [`${ExhibitName}`]: {
    color: theme.palette.blue.primary,
    textDecoration: "underline",
    lineHeight: "32px",
  },
}))

export const DeletedRecord = styled(PartitionListItem)(({ theme }) => ({
  border: `1px solid ${theme.palette.red.warningBackground}`,
  borderTop: "none",
  gridTemplateColumns,
}))

export const RecoverButton = styled(LoadingButton)(({ theme }) => ({
  color: theme.palette.blue.primary,
}))
