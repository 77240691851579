import styled from "@emotion/styled"
import { Box, Button, Typography, Table, TableCell, Paper, buttonBaseClasses } from "@mui/material"

export const DocumentTitle = styled(Typography)(() => ({
  fontSize: "30px",
  fontWeight: "700",
  lineHeight: "1",
  display: "flex",
  alignItems: "center",
  gap: "10px",
}))
DocumentTitle.defaultProps = {
  variant: "subtitle1",
}

export const StyledTag = styled(Typography)(({ theme }) => ({
  background: theme.palette.blue.light,
  borderRadius: "100px",
  padding: "0 7px",
}))

export const DocumentHeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(4.5),
  alignItems: "center",
  justifyContent: "space-between",

  [theme.breakpoints.down("xl")]: {
    flexDirection: "column",
    gap: theme.spacing(2),
    alignItems: "start",
  },
}))

type LinkButtonProps = { target?: string }
export const LinkButton = styled(Button)<LinkButtonProps>(({ theme, size = "medium" }) => ({
  color: theme.palette.blue.primary,
  borderColor: theme.palette.secondary.main,
  borderRadius: "6px",
  height: theme.spacing(size === "medium" ? 5 : 4),
}))
LinkButton.defaultProps = {
  variant: "outlined",
  disableElevation: true,
  target: "_blank",
}

export const MedChronButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  color: theme.palette.common.white,
  borderColor: theme.palette.blue.primary,
  background: theme.palette.blue.primary,

  ":hover": {
    background: theme.palette.blue.primary,
  },

  [`&.${buttonBaseClasses.disabled}`]: {
    color: theme.palette.disabled.main,
    background: theme.palette.disabled.buttonBackground,
  },
}))

export const StyledMedChronButton = MedChronButton as typeof Button

export const FloatingIndicator = styled(Paper)(({ theme }) => ({
  position: "fixed",
  bottom: 20,
  padding: theme.spacing(1, 2),
  margin: theme.spacing(0, -2),

  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.secondary.contrastText,
}))

export const StyledTableHeadCell = styled(TableCell)`
  color: #636c79;
`

export const StyledViewTable = styled(Table)`
  margin-bottom: ${({ theme }) => theme.spacing(3.5)};
  border-radius: 4px;
  box-shadow: 0 0 0 1px #d8d8d8;

  thead td {
    border-bottom: 1px solid #d8d8d8;
  }

  tbody td {
    border-bottom-color: rgba(0, 0, 0, 0.05);
    vertical-align: top;
  }

  tbody > tr:last-of-type > td {
    border-bottom-width: 0px;
  }
`
StyledViewTable.defaultProps = {
  size: "medium",
}

export const ActionsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  whiteSpace: "nowrap",
  alignItems: "center",
}))
