import React, { useCallback, useState } from "react"
import {
  Box,
  Button,
  Menu,
  TableCell,
  TableRow,
  Typography,
  DialogContent,
  DialogActions,
} from "@mui/material"
import FlagIcon from "@mui/icons-material/Flag"
import MoreVertIcon from "@mui/icons-material/MoreVert"

import { documentActions } from "documents/store"
import { formatDate } from "utils"

import { FlagRelation } from "./FlagRelation"
import {
  StyledDateFlagButton,
  StyledMenuItem,
  StyledKabob,
  StyledDialog,
  StyledConfirmButton,
} from "./styled"
import { FlagTableObject } from "./types"

type FlagsTableRowProps = {
  flag: FlagTableObject
  displayControls: boolean
  exhibitBuilderOn: boolean
}

type DeleteFlagConfirmationDialogProps = {
  flag: FlagTableObject
  open: boolean
  onClose: () => void
}

const DeleteFlagConfirmationDialog = ({ open, onClose, flag }: DeleteFlagConfirmationDialogProps) => {
  const confirmAction = useCallback(() => {
    documentActions.removeFlag(flag.id)
  }, [flag.id])

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <Typography variant="dialogHeader" component="h3" className="header">
          Flag Deletion Confirmation
        </Typography>
        <Typography component="p" className="instructions">
          Please confirm if you would like to <b>delete</b> the following flag:
        </Typography>
        <Typography component="p">
          {formatDate(flag.dateOfService, "MM/dd/yyyy", true)}, {flag.providerName ? flag.providerName : ""}
        </Typography>
        <Typography component="p">Flag Category: {flag.category}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={() => onClose()}>
          Cancel
        </Button>
        <StyledConfirmButton variant="contained" onClick={confirmAction}>
          Confirm
        </StyledConfirmButton>
      </DialogActions>
    </StyledDialog>
  )
}

export const FlagsTableRow = ({ flag, displayControls = false, exhibitBuilderOn }: FlagsTableRowProps) => {
  const buttonId = `list-edit-flag-${flag.id}`

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const open = Boolean(anchorEl)
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleEdit = useCallback(() => {
    documentActions.editFlag(flag.id, buttonId)
    handleCloseMenu()
  }, [buttonId, flag, handleCloseMenu])

  const handleScrollToFlag = useCallback(() => {
    documentActions.setScrollToFlag(flag)
  }, [flag])

  const handleConfirmation = useCallback(() => {
    setIsDialogOpen(true)
    handleCloseMenu()
  }, [handleCloseMenu])

  return (
    <TableRow key={flag.id}>
      <TableCell>
        <StyledDateFlagButton onClick={handleScrollToFlag} variant="text">
          <FlagIcon sx={{ fontSize: "16px", color: "#E53935", marginRight: "4px" }} />
          <Typography variant="body2">{formatDate(flag.dateOfService, "MM/dd/yyyy", true)}</Typography>
        </StyledDateFlagButton>
      </TableCell>
      <TableCell>{flag.providerName}</TableCell>
      <TableCell>{flag.category}</TableCell>
      <TableCell>
        {flag.quote && <Box mb={0.75}>“{flag.quote}”</Box>}
        <Box>{flag.reason}</Box>
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            {flag.relations?.map(relation => (
              <React.Fragment key={`${relation.partitionId}_${relation.page}`}>
                <FlagRelation relation={relation} isUserExhibit={exhibitBuilderOn} />
                <br />
              </React.Fragment>
            ))}
          </Box>

          {displayControls ? (
            <>
              <StyledKabob onClick={handleClickMenu} id={buttonId}>
                <MoreVertIcon />
              </StyledKabob>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                transformOrigin={{
                  vertical: 0,
                  horizontal: 175,
                }}
              >
                <StyledMenuItem onClick={handleEdit}>Edit</StyledMenuItem>
                <StyledMenuItem onClick={handleConfirmation}>Remove</StyledMenuItem>
              </Menu>
              <DeleteFlagConfirmationDialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                flag={flag}
              />
            </>
          ) : null}
        </Box>
      </TableCell>
    </TableRow>
  )
}
