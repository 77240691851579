import { Box, Grid, Typography } from "@mui/material"
import styled from "@emotion/styled"
import { theme } from "app/theme"
import { REQUEST_METHODS } from "apiHelper"
import { download } from "api"
import { useFileUploader } from "common/file-uploader"
import FormFiles from "./FormFiles"
import { DeleteFileMutation, FileMetadata, SaveFileMutation } from "./types"
import { useConfirmation } from "hooks/useConfirmation"
import ConfirmDialog from "common/ConfirmDialog"

const StyledGrid = styled(Box)({
  display: "grid",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
  alignItems: "start",
})

interface FileManagementProps {
  title?: string
  isUploadingFiles: boolean
  setIsUploadingFiles: (isUploadingFiles: boolean) => void
  saveFileMutation: SaveFileMutation
  deleteFileMutation: DeleteFileMutation
  deleteFileAnalyticsLogHandler?: () => void
  downloadedFileAnalyticsLogHandler?: () => void
  deleteConfirmationConfig?: {
    title: string
    message: string
    confirmText?: string
    cancelText?: string
  }
}

export const FileManagement = ({
  title,
  isUploadingFiles,
  setIsUploadingFiles,
  saveFileMutation,
  deleteFileMutation,
  deleteFileAnalyticsLogHandler,
  downloadedFileAnalyticsLogHandler,
  deleteConfirmationConfig,
}: FileManagementProps) => {
  const fileUploader = useFileUploader()
  const { confirm, confirmationState, handleConfirm, handleCancel } = useConfirmation()

  const handleDeleteFile = async (file: FileMetadata) => {
    const pk = file.pk || file.id
    if (!pk) return

    let confirmationPromise: Promise<boolean> | null = null
    if (deleteConfirmationConfig) {
      confirmationPromise = confirm({
        title: deleteConfirmationConfig.title,
        message: deleteConfirmationConfig.message,
        confirmText: deleteConfirmationConfig.confirmText,
        cancelText: deleteConfirmationConfig.cancelText,
      })
    }

    const shouldDeleteFile = deleteConfirmationConfig ? await confirmationPromise : true

    if (shouldDeleteFile) {
      deleteFileMutation.mutate({ fileId: pk })
      fileUploader.unassignUploaded(pk)

      if (deleteFileAnalyticsLogHandler) deleteFileAnalyticsLogHandler()
    }
  }

  const handleViewFile = async (file: FileMetadata) => {
    window.open(file.file_url + "?as_attachment=false", "_blank")
  }

  const handleDownloadFile = async (file: FileMetadata) => {
    const options = {
      customUrl: file.file_url,
      method: REQUEST_METHODS.GET,
      blob: true,
      json: false,
    }

    if (downloadedFileAnalyticsLogHandler) downloadedFileAnalyticsLogHandler()

    return download(options, file.name)
  }

  return (
    <StyledGrid>
      <Grid container alignContent="center">
        <Typography variant="body1SemiBold" lineHeight={2}>
          {title}
        </Typography>
      </Grid>
      <FormFiles
        onDeleteFile={handleDeleteFile}
        fileUploader={fileUploader}
        saveFileMutation={saveFileMutation}
        isUploadingFiles={isUploadingFiles}
        setIsUploadingFiles={setIsUploadingFiles}
        onViewFile={handleViewFile}
        onDownloadFile={handleDownloadFile}
      />
      <ConfirmDialog
        open={confirmationState !== null}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        title={deleteConfirmationConfig?.title}
        body={deleteConfirmationConfig?.message}
        confirmText={deleteConfirmationConfig?.confirmText}
        cancelText={deleteConfirmationConfig?.cancelText}
      />
    </StyledGrid>
  )
}
