import styled from "@emotion/styled"
import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import React, { ChangeEventHandler, ReactNode, useCallback, useEffect, useState } from "react"
import { Outlet, useNavigate, useParams } from "react-router-dom"

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { useFirm } from "hooks/useFirm"
import { useDialog } from "../../../hooks/useDialog"
import { useSearchState } from "../../../hooks/useSearchState"
import useUser from "../../../hooks/useUser"

import {
  deleteSingleFeaturePermission,
  getContracts,
  updateFirm,
  updateFirmManagers,
  updateSingleFeaturePermission,
} from "../../../api"
import { queryKeys } from "../../../react-query/constants"

import { MainTitle } from "../../../app/styled"
import AttorneyTable from "./AttorneyTable"
import FirmManagers from "./FirmManagers"

import { useGetPermissionByName } from "hooks/useGetPermissionByName"
import LinkButton from "../../../common/buttons/LinkButton"
import TextButton from "../../../common/buttons/TextButton"
import { CONTRACT_INFORMATION, FIRM_DETAILS_LABELS, LIST_OF_ATTORNEYS } from "../../../common/constants"
import { BlockTabs, Tab, TabPanel } from "../../../common/tabs/BlockTabs"
import AttorneyForm from "../AttorneyForm"
import { ExtendedFirmDto, Manager } from "../Firm"
import { STATUS } from "../enums"
import { ContractForm } from "./ContractForm"

import { ConfirmDialog } from "common"
import { ProgressCheckbox } from "common/ProgressCheckbox"
import StateAutocomplete from "common/form-components/StateAutocomplete"
import AssigneePicker, { Segments } from "requests/AssigneePicker"
import { Contract, DetailedContract } from "../../../common/interfaces"
import { INTERNAL_ROLES } from "../../../common/models/roles"
import { ContractInformation } from "./ContractInformation"

import {
  canRoleEditFirmHubspotId,
  canRoleEditFirmManagers,
  canRoleManageContracts,
  canRoleSetAutoAnnotation,
  canRoleSetBasicPlusCreationOption,
  canRoleSetIsInternal,
  canRoleSetManualAnnotation,
} from "../permissions"

import { Info } from "@mui/icons-material"
import { Alert, TextField, Tooltip } from "@mui/material"
import { FEATURE_PERMISSIONS } from "permissions/enums"
import { usePermissions } from "permissions/usePermissions"
import { FeaturePermission } from "settings/FeaturePermissions/types"
import { formatDate } from "utils"
import CancelContractDialogs from "./CancelContractDialogs"
import FirmStatusSelector from "./FirmStatusSelector"

import { StatementOfWorkGeneration } from "./StatementOfWork"
import { useLocationStore } from "app/location/store"

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(4),
}))

const TopContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
})

export const DetailItem = styled(Box)({
  display: "flex",
  flexDirection: "row",
})

const Subtitle = styled(Box)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: "bold",
  lineHeight: 1.89,
  marginBottom: theme.spacing(1),
}))

export const MinorTitle = styled(Box)(({ theme }) => ({
  fontWeight: "bold",
  letterSpacing: "normal",
  fontSize: "14px",
  marginRight: theme.spacing(1),
}))

const SectionContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(5, 0),
}))

const CheckboxContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  margin: theme.spacing(2, 0),
  paddingBottom: "80px",
}))

interface CheckboxProps {
  checked: boolean
  onChange: (value: boolean) => void
  display: ReactNode
  isChanging: boolean
  disabled?: boolean
  dataTest?: string
  mt?: string
  mb?: string
}
const Checkbox = ({
  checked,
  onChange,
  display,
  isChanging,
  dataTest,
  disabled = false,
  mt = "auto",
  mb = "auto",
}: CheckboxProps) => {
  return (
    <Box display={"flex"} data-test={dataTest}>
      <ProgressCheckbox
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        label={
          <Box mt={mt} mb={mb} fontSize="12px">
            {display}
          </Box>
        }
        isChanging={isChanging}
      />
    </Box>
  )
}

export const Firm = (): JSX.Element => {
  const { id: firmId } = useParams()
  const {
    isOpen: isContractFormOpen,
    openDialog: openContractFormDialog,
    closeDialog: closeContractFormDialog,
  } = useDialog()
  const {
    isOpen: isIsInternalConfirmOpen,
    openDialog: openIsInternalConfirmDialog,
    closeDialog: closeIsInternalConfirmDialog,
  } = useDialog()
  const queryClient = useQueryClient()
  const { user } = useUser()
  const { firm, updateCache: updateFirmCache } = useFirm(firmId ? parseInt(firmId) : user.firmId)

  const canRoleManageContractPermission = canRoleManageContracts(user.role)
  const canRoleSetBasicPlusCreationPermission = canRoleSetBasicPlusCreationOption(user.role)
  const canRoleSetAutoAnnotationPermission = canRoleSetAutoAnnotation(user.role)
  const canRoleSetManualAnnotationPermission = canRoleSetManualAnnotation(user.role)
  const canRoleSetIsInternalPermission = canRoleSetIsInternal(user.role)

  const {
    smartAdvocateIntegrationEnabled,
    canGenerateReceiptsEnabled,
    canEditOrDeleteExpiredContractsEnabled,
  } = usePermissions({
    firmId: firmId,
    suspense: false,
  })

  const canViewContractLog =
    canRoleManageContractPermission || (canEditOrDeleteExpiredContractsEnabled && user.isInternal)

  const { data: contracts } = useQuery<Contract[]>(
    [queryKeys.firmContracts, firmId],
    async () => {
      return await getContracts({ firm_id: firmId })
    },
    {
      enabled: canViewContractLog,
    }
  )

  const navigate = useNavigate()
  const { showMessage } = useHandleMessages()
  const isViewingAttorneys = useLocationStore(location => location.pathname.includes("attorneys"))

  const [isCreatingNewAttorney, setIsCreatingNewAttorney] = useState(false)
  const [editingContractId, setEditingContractId] = useState<Nullable<number>>(null)
  const [isChangingCanClientView, setIsChangingCanClientView] = useState(false)
  const [isChangingCanCreateBasicPlus, setIsChangingCanCreateBasicPlus] = useState(false)
  const [isChangingEnableAutoAnnotations, setIsChangingEnableAutoAnnotations] = useState(false)
  const [isChangingEnableManualAnnotations, setIsChangingEnableManualAnnotations] = useState(false)
  const [isChangingIsInternal, setIsChangingIsInternal] = useState(false)
  const [isChangingEnableAddTitlePage, setIsChangingEnableAddTitlePage] = useState(false)
  const [hubspotId, setHubspotId] = useState<Nullable<number>>(firm?.hubspot_id ?? null)
  const [smartAdvocateUrl, setSmartAdvocateUrl] = useState<Nullable<string>>(firm?.smart_advocate_url ?? null)

  const [isChangingEnableAutomatedBillingTable, setIsChangingEnableAutomatedBillingTable] = useState(false)

  const DEFAULT_TAB = user.isRole(INTERNAL_ROLES.LEGALOPS_ADMIN) ? CONTRACT_INFORMATION : LIST_OF_ATTORNEYS
  const [currentTab, setCurrentTab] = useSearchState("tab", DEFAULT_TAB, "string")

  const [cancelFormOpen, setCancelFormOpen] = useState(false)
  const [newFirmAssignees, setNewFirmAssignees] = useState<Nullable<Manager[]>>(null)
  const handleCancelFormOpen = useCallback(() => setCancelFormOpen(true), [setCancelFormOpen])
  const handleCancelFormClose = useCallback(() => setCancelFormOpen(false), [setCancelFormOpen])

  const userCanEditManagers = canRoleEditFirmManagers(user.role)
  const userCanEditFirmHubspotId = canRoleEditFirmHubspotId(user.role)

  const resetAllChanging = useCallback(() => {
    setIsChangingCanClientView(false)
    setIsChangingCanCreateBasicPlus(false)
    setIsChangingEnableAutoAnnotations(false)
    setIsChangingEnableManualAnnotations(false)
    setIsChangingEnableAutomatedBillingTable(false)
    setIsChangingIsInternal(false)
    setIsChangingEnableAddTitlePage(false)
  }, [])

  const { data: featureCanCreateBasicPlus } = useGetPermissionByName(FEATURE_PERMISSIONS.CREATE_BASIC_PLUS)

  const updateFirmMutation = useMutation(updateFirm, {
    onSuccess: (firmData: ExtendedFirmDto) => {
      updateFirmCache({
        can_client_view_credits: firmData?.can_client_view_credits,
        can_create_basic_plus_requests: firmData?.can_create_basic_plus_requests,
        enable_annotations: firmData?.enable_annotations,
        enable_complete_annotation_pipeline: firmData?.enable_complete_annotation_pipeline,
        enable_automated_important_medical_passages_annotation:
          firmData?.enable_automated_important_medical_passages_annotation,
        enable_automated_provider_name_annotation: firmData?.enable_automated_provider_name_annotation,
        enable_automated_billing_table_annotation: firmData?.enable_automated_billing_table_annotation,
        enable_add_title_page: firmData?.enable_add_title_page,
        is_internal: firmData?.is_internal,
      } as Partial<ExtendedFirmDto>)
    },
    onError: error => {
      showMessage({
        type: "error",
        message: "There was an error updating the firm. Please contact eng if this continues.",
        error,
      })
    },
    onSettled: () => {
      resetAllChanging()
    },
  })

  const saveManagerRelation = useMutation(updateFirmManagers, {
    onSuccess: () => {
      showMessage({
        type: "success",
        message: "Successfully updated firm managers.",
      })
    },
    onError: error => {
      showMessage({
        type: "error",
        message: "There was an error updating the firm's managers. Please reload and try again.",
        error,
      })
    },
  })

  const updateSingleFeaturePermissionMutation = useMutation(updateSingleFeaturePermission, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.permissions])
    },
    onError: error => {
      showMessage({
        type: "error",
        message: "There was an error updating this permission. Please contact eng if this continues.",
        error,
      })
    },
    onSettled: () => {
      setIsChangingCanCreateBasicPlus(false)
    },
  })

  const deleteSingleFeaturePermissionMutation = useMutation(deleteSingleFeaturePermission, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.permissions])
    },
    onError: error => {
      showMessage({
        type: "error",
        message: "There was an error removing this permission. Please contact eng if this continues.",
        error,
      })
    },
    onSettled: () => {
      setIsChangingCanCreateBasicPlus(false)
    },
  })

  useEffect(() => {
    setHubspotId(firm?.hubspot_id ?? null)
    setSmartAdvocateUrl(firm?.smart_advocate_url ?? null)
  }, [firm])

  const handleChangeHubspotId = useCallback<ChangeEventHandler<HTMLInputElement>>(e => {
    if (e.target.value === "") {
      setHubspotId(null)
      return
    }
    const value = e.target.value.replace(/\D/g, "")
    const numericValue = Number(value)
    setHubspotId(numericValue)
  }, [])

  const handleOnBlurHubspotId = useCallback(() => {
    updateFirmMutation.mutate({
      data: {
        hubspot_id: hubspotId,
      },
      firmId,
    })
  }, [updateFirmMutation, hubspotId, firmId])

  const handleChangeSmartAdvocateUrl = useCallback<ChangeEventHandler<HTMLInputElement>>(e => {
    if (e.target.value === "") {
      setSmartAdvocateUrl(null)
      return
    }

    setSmartAdvocateUrl(e.target.value)
  }, [])

  const handleOnBlurSmartAdvocateUrl = useCallback(() => {
    updateFirmMutation.mutate({
      data: {
        smart_advocate_url: smartAdvocateUrl ?? null,
      },
      firmId,
    })
  }, [updateFirmMutation, smartAdvocateUrl, firmId])

  if (!firm) {
    return <></>
  }

  const handleOnEditClick = (id: Nullable<number>) => {
    if (id !== null) {
      setEditingContractId(id)
      openContractFormDialog()
    }
  }

  const handleOnCreateNewClick = () => {
    setEditingContractId(null)
    openContractFormDialog()
  }

  const handleToggleClientViewCredits = (value: boolean) => {
    setIsChangingCanClientView(true)
    updateFirmMutation.mutate({
      data: {
        can_client_view_credits: value,
      },
      firmId,
    })
  }

  const handlePermissionClick = (value: boolean, feature: FeaturePermission) => {
    const onSuccess = () =>
      updateFirmCache({
        feature_permissions: {
          ...firm.feature_permissions,
          [feature.name]: value,
        },
      })

    const onError = () =>
      showMessage({
        type: "error",
        message: `We were unable to update the feature flag '${feature.name}'.  Please refresh and try again, and if that fails, contact eng`,
      })

    if (value) {
      updateSingleFeaturePermissionMutation.mutate(
        {
          permissionId: feature.pk,
          data: {
            firm_id: firmId,
          },
        },
        {
          onSuccess,
          onError,
        }
      )
    } else {
      deleteSingleFeaturePermissionMutation.mutate(
        {
          permissionId: feature.pk,
          data: {
            firm_id: firmId,
          },
        },
        {
          onSuccess,
          onError,
        }
      )
    }
  }

  const handleToggleClientCreateBasicPlus = (value: boolean) => {
    if (!featureCanCreateBasicPlus) {
      showMessage({
        type: "error",
        message:
          "We were unable to fetch the feature flag for create_basic_plus.  Please refresh and try again, and if that fails, contact eng",
      })
      return
    }
    setIsChangingCanCreateBasicPlus(true)
    handlePermissionClick(value, featureCanCreateBasicPlus)
  }

  interface AutoCompleteOnChangeParams {
    event: React.ChangeEvent
    newValues: Manager[]
    reason: string
  }

  const saveFirmAssignees = (assignees: Manager[]) => {
    const ids = assignees.map(assignee => {
      return assignee.pk
    })
    updateFirmCache({
      managers: [...assignees],
    } as ExtendedFirmDto)
    saveManagerRelation.mutate({ firmId: firm.pk, data: ids })
  }

  function handleConfirmFirmAssignees() {
    saveFirmAssignees(newFirmAssignees ?? [])
    setNewFirmAssignees(null)
  }

  const handleChangeManagers = ({ newValues, reason }: AutoCompleteOnChangeParams) => {
    if (reason === "remove-option" || reason === "clear") {
      setNewFirmAssignees(newValues)
    } else {
      saveFirmAssignees(newValues)
    }
  }

  interface UpdateAnnotationData {
    enable_annotations?: boolean
    enable_complete_annotation_pipeline?: boolean
    enable_automated_billing_table_annotation?: boolean
    enable_automated_important_medical_passages_annotation?: boolean
    enable_automated_provider_name_annotation?: boolean
  }

  const handleToggleEnableAutoAnnotations = (value: boolean) => {
    setIsChangingEnableAutoAnnotations(true)
    updateFirmMutation.mutate({
      data: {
        enable_annotations: value,
      },
      firmId,
    })
  }

  const handleToggleEnableAddTitlePage = (value: boolean) => {
    setIsChangingEnableAddTitlePage(value)
    updateFirmMutation.mutate({
      data: {
        enable_add_title_page: value,
      },
      firmId,
    })
  }

  const saveIsInternal = (value: boolean) => {
    updateFirmMutation.mutate({
      data: {
        is_internal: value,
      },
      firmId,
    })
  }

  const handleCancelToggleIsInternal = () => {
    closeIsInternalConfirmDialog()
    setIsChangingIsInternal(false)
  }

  const handleConfirmToggleIsInternal = () => {
    saveIsInternal(true)
    closeIsInternalConfirmDialog()
    setIsChangingIsInternal(false)
  }

  const handleToggleIsInternal = (value: boolean) => {
    setIsChangingIsInternal(true)
    if (value) {
      openIsInternalConfirmDialog()
    } else {
      saveIsInternal(value)
    }
  }

  const handleToggleEnableManualAnnotations = (value: boolean) => {
    setIsChangingEnableManualAnnotations(true)
    const updateData: UpdateAnnotationData = {
      enable_complete_annotation_pipeline: value,
    }
    if (value === true) {
      updateData.enable_annotations = true
    }
    updateFirmMutation.mutate({
      data: updateData,
      firmId,
    })
  }

  const handleToggleEnableBillingTable = (value: boolean) => {
    setIsChangingEnableAutomatedBillingTable(true)
    const updateData: UpdateAnnotationData = {
      enable_automated_billing_table_annotation: value,
    }

    if (value === true) {
      updateData.enable_annotations = true
    }

    updateFirmMutation.mutate({
      data: updateData,
      firmId,
    })
  }

  const handleStateAutocompleteChange = async (
    _event: React.SyntheticEvent,
    value: string | string[] | null
  ) => {
    // update firm.operating_in_states cache so if someone clears then re-enters same value it still works
    updateFirmCache({
      operating_in_states: value,
    } as ExtendedFirmDto)

    try {
      await updateFirm({ data: { operating_in_states: value }, firmId })
    } catch (error) {
      showMessage({
        type: "error",
        message:
          "Your change could not be saved due to a server issue, please try again shortly or contact a dev if the error persists.",
        error,
      })
    }
  }

  const handleFirmStatusChange = (value: STATUS) => {
    updateFirmMutation.mutate({
      data: {
        status: value,
      },
      firmId,
    })
    updateFirmCache({
      status: value,
    })
  }

  const contract = firm?.current_contract
  const hasContract = !!contract
  const EMPTY_VALUE = `—`

  const creditsPerMonth = hasContract
    ? `${contract.monthly_credits - contract.current_month_credits_used} / ${contract.monthly_credits}`
    : EMPTY_VALUE
  const endOfThisMonth = hasContract ? contract.end_of_month : EMPTY_VALUE
  const availableCredits = hasContract
    ? `${contract.monthly_credits - contract.current_month_credits_used} + ${
        contract.rollover_credits
      } rollover`
    : EMPTY_VALUE

  return (
    <>
      <Container>
        <TopContainer>
          <Box>
            <DetailItem alignItems="baseline">
              <MainTitle mr={1}>{firm.name}</MainTitle>
              <Box ml={"auto"}>
                <LinkButton data-test="edit-firm" onClick={() => navigate(`/settings/firms/${firmId}/edit`)}>
                  Edit Firm
                </LinkButton>
              </Box>
            </DetailItem>
            {userCanEditManagers ? (
              <Box mb={2} mt={2} data-test="firm-manager-picker">
                <AssigneePicker
                  value={firm.managers}
                  segment={Segments.INTERNAL}
                  onChange={handleChangeManagers}
                  variant="outlined"
                />
                <Box pl={0.5}>
                  <Typography variant="caption">* Saves automatically</Typography>
                </Box>
              </Box>
            ) : (
              <DetailItem alignItems="center" data-test="firm-assignees">
                <MinorTitle mr={1}>Assigned to: </MinorTitle>
                <FirmManagers managers={firm?.managers || []} />
              </DetailItem>
            )}
            <Box>
              <FirmStatusSelector onChange={handleFirmStatusChange} value={firm.status} />
            </Box>
            {userCanEditFirmHubspotId && (
              <Box mb={2} mt={4}>
                <TextField
                  label="HubSpot ID"
                  value={hubspotId}
                  onChange={handleChangeHubspotId}
                  onBlur={handleOnBlurHubspotId}
                  data-test="firm-hubspotid-field"
                />
                <Box pl={0.5}>
                  <Typography variant="caption">* Saves automatically</Typography>
                </Box>
              </Box>
            )}
            <Box lineHeight={2.1} data-test="credit-information">
              <>
                <DetailItem data-test="credits-per-month">
                  <MinorTitle>Credits per month: </MinorTitle>
                  <Box>{creditsPerMonth}</Box>
                </DetailItem>
                <DetailItem data-test="ends-for-this-month">
                  <MinorTitle>Ends for this Month: </MinorTitle>
                  <Box>{hasContract ? formatDate(endOfThisMonth, "MM/dd/yyyy", true) : EMPTY_VALUE}</Box>
                </DetailItem>
                <DetailItem data-test="current-available-credits">
                  <MinorTitle>Current Available Credits: </MinorTitle>
                  <Box>{availableCredits}</Box>
                </DetailItem>
              </>
            </Box>
            <CheckboxContainer>
              {canRoleManageContractPermission && (
                <Checkbox
                  checked={!!firm.can_client_view_credits}
                  onChange={handleToggleClientViewCredits}
                  display={"Allow client to view monthly available credits"}
                  isChanging={isChangingCanClientView}
                  dataTest="clients-view-credits-checkbox"
                />
              )}

              {canRoleSetBasicPlusCreationPermission && (
                <Box data-test="clients-create-basic-plus-checkbox">
                  <ProgressCheckbox
                    checked={firm.feature_permissions.create_basic_plus}
                    onChange={handleToggleClientCreateBasicPlus}
                    label={
                      <Box mt="auto" mb="auto" fontSize="12px">
                        Allow client to create Basic+ requests
                      </Box>
                    }
                    isChanging={isChangingCanCreateBasicPlus}
                  />
                  {firm.feature_permissions.create_basic_plus &&
                    !!contract &&
                    !contract.pricing_models?.some(pm => pm.product_type === "basic_plus") && (
                      <Alert severity="warning">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          Missing Basic+ pricing info.
                          <Tooltip title="The current contract has no pricing info for Basic+. Please fill in the pricing info or disable Basic+ requests for this firm or they will encounter errors trying to submit requests.">
                            <Info sx={{ ml: 1 }} />
                          </Tooltip>
                        </Box>
                      </Alert>
                    )}
                </Box>
              )}

              {canRoleSetAutoAnnotationPermission && (
                <Checkbox
                  checked={!!firm.enable_annotations || !!firm.enable_complete_annotation_pipeline}
                  disabled={!!firm.enable_complete_annotation_pipeline}
                  onChange={handleToggleEnableAutoAnnotations}
                  display={"Enable auto annotations"}
                  isChanging={isChangingEnableAutoAnnotations}
                />
              )}
              {canRoleSetManualAnnotationPermission && (
                <>
                  <Checkbox
                    checked={!!firm.enable_complete_annotation_pipeline}
                    onChange={handleToggleEnableManualAnnotations}
                    display={"Enable complete annotation pipeline"}
                    isChanging={isChangingEnableManualAnnotations}
                  />
                  <Checkbox
                    checked={!!firm.enable_automated_billing_table_annotation}
                    disabled={!!firm.enable_automated_important_medical_passages_annotation}
                    onChange={handleToggleEnableBillingTable}
                    display={"Enable automated billing table annotation"}
                    isChanging={isChangingEnableAutomatedBillingTable}
                  />
                </>
              )}

              {canRoleSetIsInternalPermission && (
                <Checkbox
                  checked={!!firm.is_internal}
                  onChange={handleToggleIsInternal}
                  display={`Mark as "internal/test" firm`}
                  isChanging={isChangingIsInternal}
                />
              )}
              <Checkbox
                checked={!!firm.enable_add_title_page}
                onChange={handleToggleEnableAddTitlePage}
                display={`Apply title pages to all exhibits`}
                isChanging={isChangingEnableAddTitlePage}
              />
            </CheckboxContainer>
          </Box>
        </TopContainer>
        <Box mt={0.5}>
          <Box mb={2}>
            <MinorTitle>States that this firm operates in:</MinorTitle>
          </Box>
          <StateAutocomplete
            multiple
            value={firm.operating_in_states}
            id="firm-state-selector"
            onChange={handleStateAutocompleteChange}
          />
          <Box pl={0.5}>
            <Typography variant="caption">* Saves automatically</Typography>
          </Box>
        </Box>
        {smartAdvocateIntegrationEnabled && (
          <Box mt={2}>
            <Box mb={2}>
              <MinorTitle>Smart Advocate URL</MinorTitle>
            </Box>
            <TextField
              fullWidth
              onBlur={handleOnBlurSmartAdvocateUrl}
              onChange={handleChangeSmartAdvocateUrl}
              value={smartAdvocateUrl}
            />
            <Box pl={0.5}>
              <Typography variant="caption">Default Value: app.smartadvocate.com</Typography>
            </Box>
          </Box>
        )}
        <Box mt={4}>
          <BlockTabs
            currentTab={currentTab ?? DEFAULT_TAB}
            setCurrentTab={(tabName: string) => {
              if (tabName !== currentTab) {
                setCurrentTab(tabName)
              }
            }}
            tabs={[
              <Tab
                key={CONTRACT_INFORMATION}
                label={FIRM_DETAILS_LABELS[CONTRACT_INFORMATION]}
                value={CONTRACT_INFORMATION}
                disabled={!user.isRole(INTERNAL_ROLES.LEGALOPS_ADMIN) && !canGenerateReceiptsEnabled}
                data-test="contract-information-tab"
              />,
              <Tab
                key={LIST_OF_ATTORNEYS}
                label={FIRM_DETAILS_LABELS[LIST_OF_ATTORNEYS]}
                value={LIST_OF_ATTORNEYS}
                data-test="list-of-attorneys-tab"
              />,
            ]}
          >
            <>
              <TabPanel key={CONTRACT_INFORMATION} value={CONTRACT_INFORMATION}>
                {firmId && (
                  <Box mb={2}>
                    <StatementOfWorkGeneration firmId={+firmId} />
                  </Box>
                )}
                <Divider />
                {firmId && canViewContractLog && (
                  <ContractInformation
                    activeContract={hasContract ? (firm.current_contract as DetailedContract) : null}
                    contracts={contracts ?? []}
                    onEditActiveClick={handleOnEditClick}
                    onCreateNewClick={handleOnCreateNewClick}
                    onCancelAllClick={handleCancelFormOpen}
                  />
                )}
              </TabPanel>
              <TabPanel key={LIST_OF_ATTORNEYS} value={LIST_OF_ATTORNEYS}>
                <SectionContainer>
                  {isViewingAttorneys ? (
                    <Outlet />
                  ) : (
                    <>
                      <Subtitle>List of Attorneys</Subtitle>
                      <AttorneyTable attorneys={firm.attorneys} firmId={firmId ? +firmId : 0} />
                      <TextButton
                        disabled={isCreatingNewAttorney}
                        onClick={() => {
                          setIsCreatingNewAttorney(true)
                        }}
                        data-test="add-new-attorney-button"
                      >
                        + ADD NEW ATTORNEY
                      </TextButton>
                      {isCreatingNewAttorney && (
                        <AttorneyForm
                          data={undefined}
                          callback={() => {
                            setIsCreatingNewAttorney(false)
                          }}
                          navigateBack={false}
                        />
                      )}
                    </>
                  )}
                </SectionContainer>
              </TabPanel>
            </>
          </BlockTabs>
        </Box>
      </Container>
      <Dialog open={isContractFormOpen} onClose={closeContractFormDialog} maxWidth="lg">
        <ContractForm
          contract={
            editingContractId
              ? contracts?.find((contract: Contract) => contract.pk === editingContractId)
              : null
          }
          firmId={firm.pk ?? 0}
          onClose={closeContractFormDialog}
          onSubmitCallback={closeContractFormDialog}
          contracts={contracts ?? []}
        />
      </Dialog>
      <CancelContractDialogs
        cancelFormOpen={cancelFormOpen}
        handleCancelFormOpen={handleCancelFormOpen}
        handleCancelFormClose={handleCancelFormClose}
        firmId={firmId ?? ""}
      />
      <ConfirmDialog
        open={!!newFirmAssignees}
        onClose={() => setNewFirmAssignees(null)}
        onConfirm={handleConfirmFirmAssignees}
        title="Remove?"
        body="Are you sure you want to remove firm managers?"
      />

      <ConfirmDialog
        open={isIsInternalConfirmOpen}
        onClose={handleCancelToggleIsInternal}
        onConfirm={handleConfirmToggleIsInternal}
        title={`Set ${firm.name} as internal?`}
        body="This will remove it from metrics and mark it as a test firm. Are you sure you want to continue?"
      />
    </>
  )
}
