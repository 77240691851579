import { DocumentData } from "./types"
import { ExhibitPartition } from "exhibit-builder/store/types"

export type UserExhibitPartitionsSlice = Pick<DocumentData, "userExhibitPartitionMap">

export const userExhibitPartitionsSelectors = {
  getUserExhibitPartitionById:
    (id: ExhibitPartition["id"]) =>
    (state: UserExhibitPartitionsSlice): ExhibitPartition | undefined =>
      state.userExhibitPartitionMap[id],
}
