import { useCallback, useId, useState } from "react"
import { formatDate } from "utils"
import { useExhibitsPreview } from "documents/exhibits/useExhibitPreview"
import { useUserExhibitPreview } from "documents/exhibits/useUserExhibitPreview"
import { ExhibitCitation } from "common/citation/ExhibitCitation"
import { documentStoreSelectors, useDocumentStore } from "documents/store"
import { KeyboardArrowDown } from "@mui/icons-material"
import { AddIcdCodeButton } from "./AddIcdCodeButton"
import { StoreICDCodeReference } from "documents/store/types"
import {
  CollapseButton,
  Container,
  DateLink,
  Cell,
  Row,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  ReferenceChip,
} from "./styled"

const getAppointmentSearchParam = (appointmentId: string) => {
  const urlSearchParams = new URLSearchParams(window.location.search)

  urlSearchParams.set("appointment", appointmentId)

  return urlSearchParams.toString()
}

type IcdCodesTableProps = {
  exhibitBuilderOn: boolean
}

export function IcdCodesTable({ exhibitBuilderOn }: IcdCodesTableProps) {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const tableId = useId()
  const icdCodes = useDocumentStore(documentStoreSelectors.icdCodes)

  const openExhibitPreview = useExhibitsPreview()
  const openUserExhibitPreview = useUserExhibitPreview()

  const handlePreview = useCallback(
    (reference: StoreICDCodeReference) => {
      if (exhibitBuilderOn && reference.userExhibitId) {
        openUserExhibitPreview(reference.userExhibitId, reference.page)
        return
      }

      if (reference.exhibitId) {
        openExhibitPreview(reference.exhibitId, reference.page)
      }
    },
    [exhibitBuilderOn, openExhibitPreview, openUserExhibitPreview]
  )
  const handleCollapseClick = useCallback(() => {
    setIsCollapsed(collapsed => !collapsed)
  }, [])

  if (!icdCodes.length) {
    return
  }

  return (
    <Container>
      <AddIcdCodeButton exhibitBuilderOn={exhibitBuilderOn} />
      <CollapseButton
        aria-label="close table"
        onClick={handleCollapseClick}
        isCollapsed={isCollapsed}
        aria-expanded={!isCollapsed}
        aria-controls={tableId}
      >
        <KeyboardArrowDown />
      </CollapseButton>
      <Table>
        <TableHead hideBorder={isCollapsed}>
          <Row hideBorder>
            <TableHeader>ICD Code</TableHeader>
            <TableHeader>Description</TableHeader>
            <TableHeader>First Diagnosed</TableHeader>
            <TableHeader>Reference</TableHeader>
          </Row>
        </TableHead>
        <TableBody
          data-test="icd-code-table"
          isCollapsed={isCollapsed}
          id={tableId}
          aria-hidden={isCollapsed}
        >
          {icdCodes.map(({ code, description, reference, dateOfService, appointmentId }, index) => {
            const showRef = exhibitBuilderOn ? !!reference.userExhibitId : !!reference.exhibitId
            const handleOnClick = () => handlePreview(reference)

            return (
              <Row key={code} hideBorder={isCollapsed}>
                <Cell data-test="code">
                  <ReferenceChip label={code} />
                </Cell>
                <Cell>{description}</Cell>
                <Cell>
                  <DateLink to={{ search: getAppointmentSearchParam(appointmentId) }}>
                    {formatDate(dateOfService, "MM/dd/yyyy", true)}
                  </DateLink>
                </Cell>
                <Cell>
                  {showRef && (
                    <ExhibitCitation
                      key={index}
                      index={reference.exhibitIndex}
                      pages={[reference.page]}
                      onClick={handleOnClick}
                    />
                  )}
                </Cell>
              </Row>
            )
          })}
        </TableBody>
      </Table>
    </Container>
  )
}
