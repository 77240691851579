import { PropsWithChildren, useMemo } from "react"
import { withSuspense } from "common/withSuspense"
import { DocumentSkeleton } from "./skeletons/DocumentSkeleton"
import { ActionsContainer, DocumentHeaderContainer } from "./styled"
import { useParams } from "react-router-dom"
import { DocumentContextProvider } from "./context"
import { DocumentTitle } from "./DocumentTitle"
import { KiliButton, MedicalSummaryButton } from "./Buttons"
import { DocumentData } from "./DocumentData"
import { DocumentErrors } from "./DocumentErrors"
import { useDocumentStateStore } from "./store/documentState"
import { AlertModal, AlertModalProvider } from "exhibit-builder/AlertModal"

export const Document = withSuspense(
  function Document({ children }: PropsWithChildren): Nullable<JSX.Element> {
    const { id: documentId } = useParams()
    const contextValue = useMemo(() => (documentId ? { documentId } : null), [documentId])
    const showExtraButtons = useDocumentStateStore(state => state.showExtraButtons)
    const showAnnotationButton = useDocumentStateStore(state => state.showAnnotationButton)
    const title = useDocumentStateStore(state => state.title)

    if (!contextValue) return null

    return (
      <DocumentContextProvider value={contextValue}>
        <DocumentData>
          <AlertModalProvider>
            <div>
              {title && (
                <DocumentHeaderContainer>
                  <DocumentTitle />
                  <ActionsContainer>
                    {showAnnotationButton && <KiliButton documentId={documentId} />}
                    {showExtraButtons && <MedicalSummaryButton documentId={documentId} />}
                    <div id="page-actions-container" />
                  </ActionsContainer>
                </DocumentHeaderContainer>
              )}
              <div>{children}</div>
            </div>
            <AlertModal />
          </AlertModalProvider>
        </DocumentData>
        <DocumentErrors />
      </DocumentContextProvider>
    )
  },
  <DocumentSkeleton />
)
