import { useCallback } from "react"
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { ExhibitCitation } from "common/citation/ExhibitCitation"
import { useExhibitsPreview } from "documents/exhibits/useExhibitPreview"
import { useUserExhibitPreview } from "documents/exhibits/useUserExhibitPreview"
import { documentStoreSelectors, useDocumentStore } from "documents/store"
import { StoreICDCodeReference } from "documents/store/types"
import { isEmpty } from "lodash"
import { formatDate } from "utils"
import { ReferenceChip } from "documents/medical-summary/components/IcdCodeTable/styled"
import { StyledTableHeadCell, StyledViewTable } from "documents/styled"

type DiagnosticHighlightsProps = {
  exhibitBuilderOn: boolean
}

export const DiagnosticHighlights = ({ exhibitBuilderOn }: DiagnosticHighlightsProps) => {
  const icdCodes = useDocumentStore(documentStoreSelectors.filteredIcdCodes)
  const openExhibitPreview = useExhibitsPreview()
  const openUserExhibitPreview = useUserExhibitPreview()

  const handlePreview = useCallback(
    (reference: StoreICDCodeReference) => {
      if (exhibitBuilderOn && reference.userExhibitId) {
        openUserExhibitPreview(reference.userExhibitId, reference.page)
        return
      }

      if (reference.exhibitId) {
        openExhibitPreview(reference.exhibitId, reference.page)
      }
    },
    [exhibitBuilderOn, openExhibitPreview, openUserExhibitPreview]
  )

  return (
    <StyledViewTable>
      <TableHead>
        <TableRow>
          <StyledTableHeadCell width="10%">ICD Code</StyledTableHeadCell>
          <StyledTableHeadCell width="55%">Description</StyledTableHeadCell>
          <StyledTableHeadCell width="20%">First Diagnosed</StyledTableHeadCell>
          <StyledTableHeadCell width="15%">Reference</StyledTableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isEmpty(icdCodes) && (
          <TableRow>
            <TableCell align="center" colSpan={4}>
              <b>There are no icd codes for the selected filters</b>
            </TableCell>
          </TableRow>
        )}
        {icdCodes.map(({ code, description, reference, dateOfService }, index) => {
          const showRef = exhibitBuilderOn ? !!reference.userExhibitId : !!reference.exhibitId
          const handleOnClick = () => handlePreview(reference)
          return (
            <TableRow key={code}>
              <TableCell>
                <ReferenceChip label={code} />
              </TableCell>
              <TableCell>{description}</TableCell>
              <TableCell>{formatDate(dateOfService, "MM/dd/yyyy", true)}</TableCell>
              <TableCell>
                {showRef && (
                  <ExhibitCitation
                    key={index}
                    index={reference.exhibitIndex}
                    pages={[reference.page]}
                    onClick={handleOnClick}
                  />
                )}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </StyledViewTable>
  )
}
