import { useCallback } from "react"
import Box from "@mui/material/Box"
import RemoveIcon from "@mui/icons-material/Close"
import { InputField, SelectInput } from "common/form-components"

import {
  COVERAGE_TYPE_BODILY_INJURY,
  COVERAGE_TYPE_SELF_INSURED,
  DEFENDANT_TYPES,
  INDIVIDUAL,
  PRONOUN_SELECT_OPTIONS,
} from "requests/constants"
import { DefendantFormProps } from "../../types"
import { StyledEntityWrapper, StyledEntityRow, StyledRemoveIconButton } from "../styled"
import { theme } from "app/theme"
import { isAtFaultParty } from "."
import { DEFAULT_DEFENDANTS_VALUE } from "requests/RequestForm/constants"
import { useFormContext, useWatch } from "react-hook-form"
import { validators } from "common/form-components/formValidationRules"

const isBiPolicyOrSelfInsuredType = (policy_coverage_type: string): boolean => {
  return [COVERAGE_TYPE_BODILY_INJURY, COVERAGE_TYPE_SELF_INSURED].includes(policy_coverage_type)
}

const requiredField = validators.required()

export const DefendantForm = ({
  index,
  removeDefendant,
  readOnly,
  type,
  numberOfDefendants,
}: DefendantFormProps) => {
  const { control, getValues, setValue } = useFormContext()
  const policy_coverage_type = useWatch({ name: "policy_coverage_type" })

  const isBiPolicyOrSelfInsured = isBiPolicyOrSelfInsuredType(policy_coverage_type)
  const isFaultParty = isAtFaultParty(policy_coverage_type)
  const isIndividualBlPolicyOrSelfInsured = isBiPolicyOrSelfInsured && type === INDIVIDUAL
  const showFaultPartyOrLastName = isFaultParty || isIndividualBlPolicyOrSelfInsured

  const handleRemoveDefendant = useCallback(removeDefendant, [removeDefendant])
  const handleDefendantTypeChange = useCallback(() => {
    const defendants = getValues("defendants")
    const new_defendant_value = DEFAULT_DEFENDANTS_VALUE[0]
    new_defendant_value.type = defendants[index].type
    defendants[index] = new_defendant_value
    setValue("defendants", defendants, { shouldDirty: true })
  }, [getValues, index, setValue])

  return (
    <Box mb={1}>
      <StyledEntityWrapper>
        <StyledEntityRow>
          <SelectInput
            name={`defendants.${index}.type`}
            options={DEFENDANT_TYPES}
            label="Defendant Entity Type"
            control={control}
            readOnly={readOnly}
            size="small"
            onChange={handleDefendantTypeChange}
          />

          {isIndividualBlPolicyOrSelfInsured ? (
            <>
              <InputField
                control={control}
                name={`defendants.${index}.first_name`}
                data-test={`defendant-first-name-${index}`}
                type="text"
                label="Defendant First Name"
                variant="outlined"
                size="small"
                required
                rules={{
                  maxLength: { value: 50, message: "Ensure this field has no more than 50 characters." },
                  ...requiredField,
                }}
                showCharacterCounter
                InputProps={{
                  readOnly: readOnly,
                }}
                inputProps={{ "data-test": `defendant-first-name-input-${index}` }}
              />
            </>
          ) : (
            <>
              {isBiPolicyOrSelfInsured && (
                <InputField
                  key="defendant-name"
                  control={control}
                  name={`defendants.${index}.name`}
                  data-test={`defendant-name-${index}`}
                  type="text"
                  label="Defendant's Name"
                  variant="outlined"
                  required
                  rules={{ ...requiredField }}
                  size="small"
                  sx={{ margin: theme.spacing("auto", 0) }}
                />
              )}
            </>
          )}

          {showFaultPartyOrLastName && (
            <InputField
              required
              control={control}
              name={`defendants.${index}.last_name`}
              data-test={`defendant-last-name-${index}`}
              type="text"
              label={isBiPolicyOrSelfInsured ? "Defendant Last Name" : "At-Fault Party"}
              variant="outlined"
              size="small"
              rules={{
                maxLength: { value: 50, message: "Ensure this field has no more than 50 characters." },
                ...requiredField,
              }}
              showCharacterCounter
              InputProps={{
                readOnly: readOnly,
              }}
              inputProps={{
                "data-test": isBiPolicyOrSelfInsured
                  ? `defendant-last-name-input-${index}`
                  : `at-fault-party-input-${index}`,
              }}
            />
          )}

          <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
            {isIndividualBlPolicyOrSelfInsured && (
              <SelectInput
                name={`defendants.${index}.gender`}
                data-test={`defendant-gender`}
                options={PRONOUN_SELECT_OPTIONS}
                label="Defendant Pronoun"
                size="small"
                control={control}
                readOnly={readOnly}
                sx={{ flex: 1 }}
              />
            )}

            {numberOfDefendants > 1 && (
              <StyledRemoveIconButton
                onClick={() => {
                  handleRemoveDefendant(index)
                }}
                data-test={`defendant-delete-icon`}
              >
                <RemoveIcon />
              </StyledRemoveIconButton>
            )}
          </Box>
        </StyledEntityRow>
      </StyledEntityWrapper>
    </Box>
  )
}
