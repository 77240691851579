import { useCallback, useMemo, useState } from "react"
import { ExhibitFile } from "exhibit-builder/store/types"
import { exhibitBuilderActions } from "exhibit-builder/store"
import * as Sentry from "@sentry/react"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material"
import { DraggablePopover } from "evenup-ui/DraggablePopover"
import { SortableRangeInput } from "evenup-ui/SortableRangeInput/SortableRangeInput"
import { ActionButton, useAction } from "exhibit-builder/ActionButton"
import { RangeItem } from "evenup-ui/SortableRangeInput/types"
import { RecoverButton as StyledRecoverButton } from "./styled"

export function RecoverButton({
  file,
  startPage,
  endPage,
}: {
  file: ExhibitFile
  startPage?: number
  endPage?: number
}) {
  const { showErrorMessage } = useHandleMessages()
  const pageRange = useMemo(
    () => (startPage && endPage ? { start: startPage, end: endPage } : null),
    [startPage, endPage]
  )
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [selectedPageRanges, setSelectedPageRanges] = useState<RangeItem[]>([])
  const [recoverAll, setRecoverAll] = useState(false)

  const handleRecover = useCallback(
    async (fileId: ExhibitFile["id"]) => {
      try {
        const selectedPageRange: [number, number] = [
          Number(selectedPageRanges[0]?.startPage),
          Number(selectedPageRanges[0]?.endPage),
        ]
        const userExhibitPageRanges = recoverAll || !pageRange ? undefined : selectedPageRange

        const userExhibit = await exhibitBuilderActions.createUserExhibit({
          exhibitId: fileId,
          isNewExhibit: false,
          pageRange: userExhibitPageRanges,
        })
        exhibitBuilderActions.setScrollToIndex(userExhibit.index)
      } catch (err) {
        Sentry.captureException(err, { extra: { exhibitId: fileId } })
        showErrorMessage("Failed to recover file, please try again")
      }
    },
    [showErrorMessage, selectedPageRanges, recoverAll, pageRange]
  )

  const { isLoading, handleAction } = useAction({
    name: "recover",
    action: async () => {
      await handleRecover(file.id)
      setAnchorEl(null)
    },
  })

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (pageRange) {
      setAnchorEl(event.currentTarget)
    } else {
      handleAction()
    }
  }

  const initialPageRange = useMemo(() => (pageRange ? [pageRange] : []), [pageRange])

  return (
    <Box display="inline-block">
      <StyledRecoverButton size="small" color="secondary" onClick={handleClick} loading={isLoading}>
        Recover
      </StyledRecoverButton>
      {pageRange && (
        <DraggablePopover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          title={`Recover ${file.name}`}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "bottom", horizontal: "center" }}
          sx={{ maxWidth: 600 }}
          data-test="recover-popup"
        >
          <Box width="500px">
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  value={recoverAll}
                  onChange={event => setRecoverAll(event.target.checked)}
                  data-test="recover-all-checkbox"
                />
              }
              label={<Typography variant="body2">Recover all pages</Typography>}
            />
            <SortableRangeInput
              onChange={setSelectedPageRanges}
              range={pageRange}
              initialItems={initialPageRange}
              canAdd={false}
              canSort={false}
              disabled={recoverAll}
            />
            <Box display="flex" justifyContent="flex-end">
              <ActionButton isLoading={isLoading} label="Recover" onClick={handleAction} />
            </Box>
          </Box>
        </DraggablePopover>
      )}
    </Box>
  )
}
