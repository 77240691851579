import { useCallback, useState } from "react"
import { Box } from "@mui/material"
import { useShallow } from "zustand/react/shallow"
import {
  StyledAppointmentCell,
  StyledHeaderTableRow,
  StyledSectionName,
  StyledTreatmentInfoWrapper,
} from "./styled"
import TextButton from "common/buttons/TextButton"
import { AddNewMedicalSummary } from "./components/AddNewMedicalSummary"
import { providersSelectors } from "documents/store/providers"
import { documentStoreSelectors, useDocumentStore } from "documents/store"

interface ProviderSummarySectionProps {
  providerId: string
  readOnly?: boolean
  exhibitBuilderOn?: boolean
}

export const ProviderSummarySection = ({
  providerId,
  readOnly,
  exhibitBuilderOn,
}: ProviderSummarySectionProps) => {
  const provider = useDocumentStore(providersSelectors.getProviderById(providerId))
  const numberOfAppointments = useDocumentStore(
    useShallow(documentStoreSelectors.numberOfAppointments(providerId))
  )
  const providerDates = useDocumentStore(useShallow(documentStoreSelectors.providerDates(providerId)))

  const [open, setOpen] = useState(false)

  const handleClick = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <StyledHeaderTableRow data-test={`provider-summary-${providerId}`}>
      <StyledAppointmentCell>
        <StyledSectionName>{provider.name}</StyledSectionName>
      </StyledAppointmentCell>
      <StyledAppointmentCell>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <StyledTreatmentInfoWrapper>
            <Box>
              Treatment period: <strong>{providerDates ? providerDates.join(" - ") : "—"}</strong>
            </Box>
            <Box>
              Number of appointments: <strong>{numberOfAppointments}</strong>
            </Box>
          </StyledTreatmentInfoWrapper>
          {!readOnly && (
            <>
              <TextButton onClick={handleClick}>+ Add Appointment</TextButton>
              <AddNewMedicalSummary
                open={open}
                handleClose={handleClose}
                providerId={providerId}
                exhibitBuilderOn={!!exhibitBuilderOn}
              />
            </>
          )}
        </Box>
      </StyledAppointmentCell>
    </StyledHeaderTableRow>
  )
}
