import { useEffect, useMemo, useState } from "react"
import { Plaintiff, Provider } from "./store/types"
import { exhibitBuilderActions, useExhibitBuilderStore } from "./store"
import { UserExhibitList } from "./UserExhibitList/UserExhibitList"
import { Box, Tooltip } from "@mui/material"
import { CollapseButtons } from "./Collapse/CollapseButtons"
import { useListData } from "./UserExhibitList/useListData"
import { PROVIDER_KEY } from "./store/constants"
import { CollapseProvider } from "./Collapse/collapse"
import { useShallow } from "zustand/react/shallow"
import { EB_DOC_TYPE } from "./types"
import { ExactProviderMatchToggle } from "./Actions/Filters/ExactProviderMatchToggle"
import { AlertModalProvider } from "./AlertModal"
import LinkButton from "common/buttons/LinkButton"
import styled from "@emotion/styled"
import { Link } from "react-router-dom"

const StyledLinkButton = styled(LinkButton)`
  font-size: 14px;
`

export function ProviderUserExhibits({
  providerId,
  plaintiffId,
}: {
  providerId?: Provider["id"]
  plaintiffId?: Plaintiff["id"]
}) {
  const [exactProviderMatch, setExactProviderMatch] = useState(true)
  const userExhibitIds = useExhibitBuilderStore(useShallow(state => Object.keys(state.userExhibitMap)))
  const caseId = useExhibitBuilderStore(useShallow(state => state.caseId))

  const filters = useMemo(
    () => ({
      selectedProviders: providerId ? [providerId] : [],
      selectedPlaintiffs: plaintiffId ? [plaintiffId] : [],
      selectedDocTypes: [PROVIDER_KEY],
      exactProviderMatch,
    }),
    [providerId, plaintiffId, exactProviderMatch]
  )

  const listData = useListData(filters)

  useEffect(() => {
    exhibitBuilderActions.setReadOnly(true)
    exhibitBuilderActions.setExhibitBuilderType(EB_DOC_TYPE.DEMAND)
  }, [])

  if (!providerId || !plaintiffId || !listData.children.length) {
    return null
  }

  return (
    <AlertModalProvider>
      <CollapseProvider ids={userExhibitIds} defaultCollapsed={true}>
        <Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              <ExactProviderMatchToggle
                filters={filters}
                exactProviderMatch={exactProviderMatch}
                setExactProviderMatch={setExactProviderMatch}
              />
            </Box>
            <Tooltip
              placement="top"
              title="This provider has annotation relations and/or exhibits assigned to it. First reassign or
                  remove any assignments to this provider from Exhibit Builder, before deleting the provider
                  shell on this page."
            >
              <Link
                to={`/demands/${caseId}/files?providerId=${providerId}&plaintiffId=${plaintiffId}&exactProviderMatch=${exactProviderMatch}`}
              >
                <StyledLinkButton>Modify Provider In Exhibit Builder</StyledLinkButton>
              </Link>
            </Tooltip>
          </Box>
        </Box>
        <Box maxWidth="90%">
          <CollapseButtons />
          <UserExhibitList listData={listData} />
        </Box>
      </CollapseProvider>
    </AlertModalProvider>
  )
}
