import { isList, isListItem } from "common/form-components/rich-text/commands"
import { CustomEditor, EditorRoot } from "common/form-components/rich-text/CustomEditor"
import { getEditorRange } from "common/form-components/rich-text/queries"

export function replaceSummaryNode(
  editor: CustomEditor,
  summary: { id: PrimaryKey; content: EditorRoot }
): void {
  const [currentNodeEntry] = editor.nodes({
    at: getEditorRange(editor),
    match: node => isListItem(editor, node) && (node as Record<string, unknown>)["summary_id"] === summary.id,
  })

  if (!currentNodeEntry) return

  const [, path] = currentNodeEntry
  const listNode = summary.content[0]
  const newNode = isList(editor, listNode) && listNode.children.length ? listNode.children[0] : null

  if (!newNode) return

  editor.insertNode(newNode, { at: path })
  editor.removeNodes({ at: [...path.slice(0, -1), path[path.length - 1] + 1] })
}
