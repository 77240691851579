import { format, isValid, parse } from "date-fns"

export const DATE_FORMAT_STORAGE = "yyyy-MM-dd"
export const DATE_FORMAT_DISPLAY = "MM/dd/yyyy"
export const DEFAULT_MIN_DATE = new Date("1900/01/01")

/**
 * Parses a date string into a Date object based on the storage format.
 *
 * @param date - The date string to parse.
 * @returns The parsed Date object or null if the input is empty or invalid.
 */
export const parseStoredDate = (date: string): Date | null => {
  return date ? parse(date, DATE_FORMAT_STORAGE, new Date()) : null
}

/**
 * Formats a Date object into a string based on the storage format.
 *
 * @param date - The Date object to format.
 * @returns The formatted date string or an empty string if the input is null or invalid.
 */
export const formatDateForStorage = (date: Date | null): string => {
  if (!isValid(date)) return ""
  return date ? format(date, DATE_FORMAT_STORAGE) : ""
}
