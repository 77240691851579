import { exhibitBuilderService } from "./api/service"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { Box, FormControlLabel, Switch, Typography } from "@mui/material"
import useCase from "hooks/useCase"
import { AlertModal, AlertModalProvider, useAlertModal } from "./AlertModal"

interface ExhibitBuilderToggleProps {
  caseId: string
  useExhibitBuilder: boolean
  onToggle: (useExhibitBuilder: boolean) => void
}

function Toggle({ caseId, onToggle, useExhibitBuilder }: ExhibitBuilderToggleProps) {
  const { showErrorMessage } = useHandleMessages()

  const { updateCache: updateCaseCache } = useCase(caseId)

  const toggleExhibitBuilder = async () => {
    try {
      await exhibitBuilderService.useExhibitBuilder({
        caseId,
        useExhibitBuilder: !useExhibitBuilder,
      })
      onToggle(!useExhibitBuilder)

      updateCaseCache({ use_exhibit_builder: !useExhibitBuilder })
    } catch (err) {
      showErrorMessage({
        message: "Could not toggle exhibit builder. Please refresh and try again.",
        error: err,
      })
    }
  }

  const actionText = useExhibitBuilder ? "Turning off exhibit builder" : "Turning on exhibit builder"

  const { openModal } = useAlertModal({
    title: "Reset all Providers",
    content: (
      <Typography variant="body1">
        {actionText}{" "}
        <b>
          will permanently undo all exhibit actions and delete all provider summaries, bills, and ICD codes.
        </b>
        If you have made any edits that you want to conserve, please copy them off-portal before continuing.
      </Typography>
    ),
    closeLabel: "Cancel",
    confirmLabel: "Continue",
    onConfirm: toggleExhibitBuilder,
  })

  return (
    <Box>
      <FormControlLabel
        label="Exhibit Builder"
        control={
          <Switch
            data-test="exhibit-builder-toggle"
            color="secondary"
            checked={useExhibitBuilder}
            onChange={openModal}
          />
        }
      />
    </Box>
  )
}

export function ExhibitBuilderToggle(props: ExhibitBuilderToggleProps) {
  return (
    <AlertModalProvider>
      <Toggle {...props} />
      <AlertModal />
    </AlertModalProvider>
  )
}
