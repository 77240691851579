import { useCallback, useMemo, useState } from "react"
import { Box, ButtonProps } from "@mui/material"
import useUser from "hooks/useUser"
import DragDropFileUploader from "common/DragDropFileUploader"
import { deleteRequestDemandPackageFile, downloadRequestDemandPackageFiles } from "api"
import { makeStyles } from "tss-react/mui"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useFileUploader } from "common/file-uploader"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { Loading } from "common"

import { RequestViewDto } from "./types"
import { FileList } from "./Request"
import { compileFileErrorMessage, getErrorMessageFromJSONStr } from "common/utils"
import { queryKeys } from "react-query/constants"
import { requestService } from "api/services/request"
import { DEMAND_LETTER_KEY, DEMAND_PACKAGE_FILE_TYPES, DEMAND_PACKAGE_KEY } from "requests/constants"
import { getFileNameFromFirstPlaintiff } from "requests/plaintiffUtils"
import { ALL_ACCEPTED_FILE_TYPES } from "common/constants"
import { UploadedFileDto } from "common/types/UploadedFile"
import { FileToUpload } from "common/file-management/types"
import { useConfirm } from "hooks/useConfirm"
import { theme } from "app/theme"
import { PlaintiffDto } from "api/services/case/types"
import { isNameInUploadFile } from "./utils"

const DOCX = ".docx"

interface DemandPackageFileProps {
  request: RequestViewDto
  showEmptyState?: boolean
  fileTypes?: Record<string, string>
  primaryPlaintiff?: Nullable<PlaintiffDto>
}

function NewlineText({ text }: { text: string }) {
  const { classes } = useStyles()

  return <div className={`${classes.newLine}`}>{text}</div>
}

const useStyles = makeStyles()(theme => ({
  newLine: {
    whiteSpace: "pre-wrap",
  },
  centerItems: {
    display: "flex",
    flexGrow: "1",
  },
  listView: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
  },
  fieldContentContainer: {
    margin: theme.spacing(1, 0),
  },
  fieldTitle: {
    fontWeight: 700,
    fontSize: "1rem",
  },
}))

const getConfirmFileNameMismatchDialogProps = (primaryPlaintiff: Nullable<PlaintiffDto>) => {
  return {
    formContent: (
      <Box data-test="mismatch-plaintiff-name-dialog">
        The Primary Plaintiff of this/one or more files does not match the Primary Plaintiff of this case. Are
        you sure you want to continue uploading?
        <Box display="flex" mt={2}>
          Primary Plaintiff:
          <Box ml={1} fontWeight={700}>
            {primaryPlaintiff?.first_name} {primaryPlaintiff?.last_name}
          </Box>
        </Box>
      </Box>
    ),
    PaperProps: { style: { borderRadius: "8px", padding: "16px 24px" } },
    header: (
      <Box alignItems="center" display="flex" gap={1} fontSize={28}>
        <b>Plaintiff Name not in Files</b>
      </Box>
    ),
    confirmationButtonProps: { sx: { background: theme.palette.blue.primary } } as ButtonProps,
    cancelButtonProps: { variant: "outlined", color: "secondary" } as ButtonProps,
    confirmationButtonContent: "Continue",
  }
}

export const DemandPackageFile = ({
  request,
  showEmptyState = false,
  fileTypes = DEMAND_PACKAGE_FILE_TYPES,
  primaryPlaintiff = null,
}: DemandPackageFileProps) => {
  const { classes } = useStyles()
  const { user } = useUser()

  const queryClient = useQueryClient()
  const { uploadFiles, isUploading } = useFileUploader()
  const { showErrorMessage } = useHandleMessages()
  const [filesToUpload, setFilesToUpload] = useState<FileToUpload[]>([])
  const { confirm: confirmFileNameMismatch, dialog: confirmFileNameMismatchDialog } = useConfirm(
    getConfirmFileNameMismatchDialogProps(primaryPlaintiff)
  )

  const handleUploadError = useCallback(
    (fileName: string, reason: Nullable<string>) => {
      const message = compileFileErrorMessage(fileName, reason)

      showErrorMessage({ message: <NewlineText text={message} /> })
    },
    [showErrorMessage]
  )

  const uploadDemandPackageFile = useMutation(requestService.uploadDemandPackageFile, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.request])
      setFilesToUpload([])
    },
    onError: (error: { message: string }, { data }) => {
      const reason = getErrorMessageFromJSONStr(error ? error.message : null)

      handleUploadError(data.name, reason)
    },
  })

  const deleteDemandPackageFile = useMutation(deleteRequestDemandPackageFile, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.request])
    },
  })

  const getDocxFiles = useCallback(
    (documentTypes: Nullable<string[]> = null): FileToUpload[] => {
      const files = filesToUpload.filter(file => file.file.name.includes(DOCX)) ?? []
      if (!documentTypes) {
        return files
      }
      return files.filter(file => documentTypes.includes(file.fileType))
    },
    [filesToUpload]
  )

  const handleUploadDemandPackage = useCallback(async () => {
    const typesToFlag = [DEMAND_PACKAGE_KEY, DEMAND_LETTER_KEY]
    const docxFiles = getDocxFiles(typesToFlag)
    if (
      primaryPlaintiff &&
      docxFiles &&
      docxFiles.some(
        docxFile => !isNameInUploadFile(docxFile, primaryPlaintiff.first_name, primaryPlaintiff.last_name)
      )
    ) {
      const confirmed = await confirmFileNameMismatch()
      if (!confirmed) {
        return
      }
    }

    const { items: uploadResult } = await uploadFiles(filesToUpload.map(({ file }) => file))

    uploadResult.forEach((upload, index) => {
      const { file, fileType } = filesToUpload[index]
      const { name } = file

      if (!upload.success) {
        const reason = upload.reason ? String(upload.reason) : null

        return handleUploadError(name, reason)
      }

      uploadDemandPackageFile.mutate({
        requestId: request.pk,
        data: { uploadId: upload.uploadId, name, type: fileType },
      })
    })
  }, [
    request.pk,
    uploadDemandPackageFile,
    filesToUpload,
    uploadFiles,
    handleUploadError,
    getDocxFiles,
    confirmFileNameMismatch,
    primaryPlaintiff,
  ])

  const handleDeleteFile = useCallback(
    (file: UploadedFileDto) => {
      if (!user.isInternal) return

      deleteDemandPackageFile.mutate({ fileId: file.pk, requestId: request.pk })
    },
    [user.isInternal, deleteDemandPackageFile, request.pk]
  )

  const onAddHandler = useCallback(
    (newFiles: FileToUpload[]) => {
      if (!user.isInternal) return

      setFilesToUpload([...filesToUpload, ...newFiles])
    },
    [filesToUpload, user.isInternal]
  )

  const onChangeHandler = useCallback(
    (newFiles: FileToUpload[]) => {
      if (!user.isInternal) return

      setFilesToUpload(newFiles)
    },
    [user.isInternal]
  )

  const downloadAllFileName = useMemo(
    () => getFileNameFromFirstPlaintiff(request, "demand_package_files"),
    [request]
  )

  return (
    <Box className={`${classes.centerItems} ${classes.listView}`} data-test="demand-package-container">
      <FileList
        showEmptyState={showEmptyState}
        files={request.demand_package_files}
        fileTypes={fileTypes}
        downloadAllEndpoint={downloadRequestDemandPackageFiles}
        deleteMethod={handleDeleteFile}
        downloadAllFileName={downloadAllFileName}
        emptyMessage="No Demand Package"
        overrideOrder={false}
      />

      {user.isInternal && (
        <DragDropFileUploader
          fileOptions={fileTypes}
          acceptedTypes={ALL_ACCEPTED_FILE_TYPES}
          defaultType="demand_package"
          files={filesToUpload}
          onAdd={onAddHandler}
          onChange={onChangeHandler}
          onSave={handleUploadDemandPackage}
          data-test="drag-and-drop-file-uploader"
        />
      )}
      <Loading show={isUploading} label="Uploading files..." />
      {confirmFileNameMismatchDialog}
    </Box>
  )
}
