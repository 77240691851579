import { useCallback } from "react"
import { formatDateForStorage, parseStoredDate } from "utils/dateHelpers"

/**
 * Custom hook to handle date parsing and formatting for date picker fields.
 *
 * @param value - The current value of the date field (string).
 * @param onChange - The function to update the date field.
 * @returns An object containing the parsed Date object and a handler for date changes.
 */
export const useDateField = (
  value: string,
  onChange: (value: unknown) => void
): { parsedDate: Date | null; handleDateChange: (date: Date | null) => void } => {
  // Parse the date string to a Date object
  const parsedDate = parseStoredDate(value)

  /**
   * Handles the date change event from the date picker.
   *
   * @param date - The new Date object selected by the user.
   */
  const handleDateChange = useCallback(
    (date: Date | null) => {
      if (date?.toString() === "Invalid Date") {
        onChange(date)
      } else if (date) {
        const formattedDate = formatDateForStorage(date)
        onChange(formattedDate)
      } else {
        onChange("")
      }
    },
    [onChange]
  )

  return { parsedDate, handleDateChange }
}
