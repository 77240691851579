import { AnnotationStatus } from "demand/Files/types"
import { USER_EXHIBIT_TAG } from "exhibit-builder/constants"
import { EXHIBIT_GROUPING_VALUES, EXHIBIT_SORTING_VALUES } from "settings/Firm/enums"

export type GetState<T> = () => T
export type SetState<T> = (partialState: Partial<T> | ((state: T) => Partial<T>)) => void

/**
 * UI Layer Types
 */
export type Plaintiff = {
  id: string
  name: string
}
export type SubDocType = {
  key: string
  name: string
}
export type DocType = {
  key: string
  name: string
  subDocTypes: SubDocType[] | null
}
export type DocTypes = {
  [key: string]: DocType
}
export type Provider = {
  id: string
  name: string
  color: string
}
export type ExhibitFile = {
  id: string
  name: string
  numberOfPages: number | null
  unableToParse: boolean
}
export type ExhibitPartition = {
  id: string
  fileId: string
  startPage: number
  endPage: number
  userExhibitId: string
  index: number
  userExhibitStartPage?: Nullable<number>
  userExhibitEndPage?: Nullable<number>
  medicalRecords: MedicalRecord["id"][]
  medicalBills: MedicalBill["id"][]
}

export enum UserExhibitProcessingStatus {
  COMPLETE = "complete",
  ERROR = "error",
  IN_PROGRESS = "in_progress",
  NOT_STARTED = "not_started",
}
export type UserExhibit = {
  id: string
  name: string
  plaintiffId: Nullable<string>
  docType: string
  subDocType: Nullable<string>
  tag: Nullable<USER_EXHIBIT_TAG>
  sortingProviderId?: Nullable<string>
  partitionIds: ExhibitPartition["id"][]
  index: number
  processingStatus: UserExhibitProcessingStatus
  isHighlighted?: boolean
  fileType: string
  pageCount: number | string
  exhibitPagesLoading?: boolean
}
export type MedicalRecord = {
  id: string
  fileId: string
  providerId: Nullable<string>
  dateOfService: string
  startPage: number
  endPage: number | null
  type: "Medical Record"
  userExhibitPages?: { id: string; endPage: number; startPage: number; partitionId: string }[]
}
export type MedicalBill = {
  id: string
  fileId: string
  providerId: Nullable<string>
  dateOfFirstService: string
  dateOfLastService: string
  startPage: number
  endPage: number | null
  type: "Medical Bill"
}

export type ExhibitBuilderData = {
  exhibitPartitionMap: Record<ExhibitPartition["id"], ExhibitPartition>
  exhibitPartitionOrder: Record<UserExhibit["id"], ExhibitPartition["id"][] | undefined>
  files: Record<ExhibitFile["id"], ExhibitFile>
  plaintiffs: Record<Plaintiff["id"], Plaintiff>
  providers: Record<Provider["id"], Provider>
  docTypes: DocTypes
  recordsAndBillsMap: Record<(MedicalRecord | MedicalBill)["id"], MedicalRecord | MedicalBill | undefined>
  recordsAndBillsOrder: Record<ExhibitPartition["id"], (MedicalRecord | MedicalBill)["id"][] | undefined>
  userExhibitMap: Record<UserExhibit["id"], UserExhibit>
  userExhibitOrder: UserExhibit["id"][]
  annotationStatus: Record<ExhibitFile["id"], { status: AnnotationStatus | null }>
  updateCitationStatus: UpdateCitationStatus
  shouldUpdateSummaries?: boolean
}

export type DndUserExhibitListData =
  | DndVirtualListData
  | DndUserExhibitData
  | DndExhibitPartitionData
  | DndRecordOrBillData

export interface DndVirtualListData {
  uniqueKey: string
  type: "virtualList"
  children: DndUserExhibitData[]
}

export interface DndUserExhibitData {
  id: UserExhibit["id"]
  uniqueKey: string
  type: "userExhibit"
  children: DndExhibitPartitionData[]
}

export interface DndExhibitPartitionData {
  id: ExhibitPartition["id"]
  uniqueKey: string
  type: "exhibitPartition"
  userExhibitId: UserExhibit["id"]
  children: DndRecordOrBillData[]
  isPlaceholder: boolean
}

export interface DndRecordOrBillData {
  id: (MedicalRecord | MedicalBill)["id"]
  uniqueKey: string
  type: "recordOrBill"
  userExhibitId: UserExhibit["id"]
  partitionId: ExhibitPartition["id"]
}

export type UserExhibitPayload = Pick<UserExhibit, "id"> & Partial<UserExhibit>
export type CombineActionPayload = {
  anchorUserExhibitId: UserExhibit["id"]
  userExhibitsToCombine: UserExhibit["id"][]
  deleteOriginal: boolean
}

export type ReorderExhibitPartitionPayload = {
  id: ExhibitFile["id"]
  newIndex: number
  documentId: string
  userExhibitId: UserExhibit["id"]
}

export type ArrangeExhibitOptions = {
  sortingOption: EXHIBIT_SORTING_VALUES
  groupingOption:
    | EXHIBIT_GROUPING_VALUES.ONE_FILE
    | EXHIBIT_GROUPING_VALUES.PER_PROVIDER_AND_FILETYPE
    | EXHIBIT_GROUPING_VALUES.PER_PROVIDER
}

export enum UpdateCitationStatus {
  COMPLETE = "complete",
  ERROR = "error",
  IN_PROGRESS = "in_progress",
  NOT_STARTED = "not_started",
}
