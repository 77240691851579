import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { StyledButton } from "../styled"
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined"
import { Typography } from "@mui/material"
import { cloneElement, forwardRef, isValidElement, ReactElement, ReactNode } from "react"
import { useAction } from "../../ActionButton"
import { exhibitBuilderService } from "exhibit-builder/api/service"
import { useAlertModal } from "exhibit-builder/AlertModal"

export const Reset = forwardRef<HTMLButtonElement, { component?: ReactNode; onClick?: () => void }>(
  function Reset({ component, onClick, ...props }, ref) {
    const documentId = useExhibitBuilderStore(state => state.document.documentId)
    const type = useExhibitBuilderStore(state => state.exhibitBuilderType)
    const caseId = useExhibitBuilderStore(state => state.caseId)

    const { handleAction: resetChanges } = useAction({
      name: "reset all changes",
      action: async () => {
        await exhibitBuilderService.resetChanges({ documentId })
        return exhibitBuilderActions.initialize({ documentId, type, caseId })
      },
    })

    const { openModal } = useAlertModal({
      title: "Reset all Exhibits",
      content: (
        <Typography variant="body1">
          Please note, this action will revert all exhibit and file manipulations (extracts, deletes,
          combines) back to its original supporting files. All changes will be permanently lost. Annotation
          data will remain unchanged.
        </Typography>
      ),
      confirmLabel: "Confirm",
      onConfirm: async () => {
        await resetChanges()
        onClick?.()
      },
    })

    if (component && isValidElement(component)) {
      return cloneElement(component as ReactElement, { onClick: openModal })
    }

    return (
      <StyledButton
        ref={ref}
        startIcon={<CachedOutlinedIcon />}
        onClick={openModal}
        data-test="reset-button"
        {...props}
      >
        Reset All Changes
      </StyledButton>
    )
  }
)
