import { MissingDocIssueStatus } from "cases/CaseCheck/types"
import { BaseAnalyticsEvent } from "infrastructure/apm/types"

export enum CaseAnalyticsEventTypes {
  ViewedCase = "Viewed Case",
  CaseDetailsTabClicked = "Case Details Tab Clicked",
  ListFetched = "Case List Fetched",
  CaseOpened = "Case Opened from List",
  CaseInsightsFormViewed = "Case Insights Form Viewed",
  CaseInsightsPublishClicked = "Case Insights Publish Clicked",
  PlaintiffChanged = "Case View Plaintiff Changed",
  MissingDocsListSort = "Missing Docs List Sort",
  ViewedCaseCopilotCreatePage = "Viewed Case Details Create Page",
  ViewedEditCaseCopilotPage = "Viewed Edit Case Details Page",
  ClickedPlusCaseButton = "Clicked Plus Case Button",
  ClickedCaseCreateButton = "Clicked Case Create Button",
  ClickedEditOnCaseCopilot = "Clicked Edit on Case Details",
  ClickedCaseCopilotSaveButton = "Clicked Case Details Save Button ",
  ClickedRunCaseCheckButton = "Clicked Run Case Check Button",
  ClickedCancelCaseCheckButton = "Clicked Cancel Case Check Button",
  ClosedMissingDocsModal = "Closed Missing Docs Modal",
  ViewedMissingDocsModal = "Viewed Missing Docs Modal",
  ClickedSupportingEvidenceLink = "Clicked Supporting Evidence Link",
  ClickedMissingDocsTab = "Clicked Missing Docs Tab",
  MarkedActiveMissingDocsResults = "Marked Active Missing Docs Result(s)",
  ResolvedMissingDocResults = "Resolved Missing Doc Result(s)",
  DismissedMissingDocResults = "Dismissed Missing Doc Result(s)",
  UploadedDocumentsToCase = "Uploaded Documents to Case",
  DeletedCaseFile = "Deleted Case File",
  DownloadedCaseFile = "Downloaded Case File",
  DownloadedRequestedDocument = "Downloaded Requested Document",
  RequestedfromCaseCopilot = "Requested from Case Details",
}

export class CaseAnalyticEvent<T extends CaseAnalyticsEventTypes> extends BaseAnalyticsEvent {
  constructor(
    readonly name: T,
    readonly fields: CaseAnalyticsEventDetails[T]
  ) {
    super()
  }
}

export type CaseViewEventFields = {
  firm_id?: PrimaryKey
  firm_name?: string
  request_id?: PrimaryKey
  request_type?: string
  case_id?: string
  utm_campaign?: string
  utm_medium?: string
}

export type CaseViewFirmEvent = {
  firm_id: Nullable<number> | undefined
  firm_name?: string
}

export type MissingDocsList = "Provider" | "Date of Service"

export type CaseDetailTab =
  | "Overview"
  | "Plaintiff"
  | "Defendant"
  | "Injuries"
  | "Policy"
  | "Recipient"
  | "Notes"

export type CaseAnalyticsEventDetails = {
  [CaseAnalyticsEventTypes.ViewedCase]: CaseViewEventFields
  [CaseAnalyticsEventTypes.CaseDetailsTabClicked]: CaseViewEventFields & {
    tab: CaseDetailTab
  }
  [CaseAnalyticsEventTypes.ListFetched]: {
    page: number
    page_size: number
    search_plaintiff?: string
    document_type?: string
    firm_ids?: string
    case_type?: string
    search_attorney?: string
    creators?: string
    orderBy: string
  }
  [CaseAnalyticsEventTypes.CaseOpened]: {
    case_id: string
  }
  [CaseAnalyticsEventTypes.CaseInsightsFormViewed]: CaseViewEventFields
  [CaseAnalyticsEventTypes.CaseInsightsPublishClicked]: CaseViewEventFields
  [CaseAnalyticsEventTypes.PlaintiffChanged]: CaseViewEventFields & {
    plaintiff_id: string // TODO UUID
  }
  [CaseAnalyticsEventTypes.MissingDocsListSort]: CaseViewEventFields & {
    direction: "asc" | "desc"
    column: "provider_name" | "date_of_service"
  }
  [CaseAnalyticsEventTypes.ViewedEditCaseCopilotPage]: CaseViewEventFields
  [CaseAnalyticsEventTypes.ViewedCaseCopilotCreatePage]: CaseViewFirmEvent
  [CaseAnalyticsEventTypes.ClickedPlusCaseButton]: CaseViewFirmEvent
  [CaseAnalyticsEventTypes.ClickedCaseCreateButton]: CaseViewFirmEvent & {
    case_id: string
  }
  [CaseAnalyticsEventTypes.ClickedCaseCopilotSaveButton]: CaseViewEventFields
  [CaseAnalyticsEventTypes.ClickedEditOnCaseCopilot]: CaseViewEventFields
  [CaseAnalyticsEventTypes.ClickedRunCaseCheckButton]: CaseViewEventFields
  [CaseAnalyticsEventTypes.ClickedCancelCaseCheckButton]: CaseViewEventFields
  [CaseAnalyticsEventTypes.ClosedMissingDocsModal]: CaseViewEventFields
  [CaseAnalyticsEventTypes.ViewedMissingDocsModal]: CaseViewEventFields
  [CaseAnalyticsEventTypes.ClickedSupportingEvidenceLink]: CaseViewEventFields
  [CaseAnalyticsEventTypes.ClickedMissingDocsTab]: CaseViewEventFields & {
    tab: MissingDocIssueStatus
  }
  [CaseAnalyticsEventTypes.MarkedActiveMissingDocsResults]: CaseViewEventFields
  [CaseAnalyticsEventTypes.ResolvedMissingDocResults]: CaseViewEventFields
  [CaseAnalyticsEventTypes.DismissedMissingDocResults]: CaseViewEventFields
  [CaseAnalyticsEventTypes.UploadedDocumentsToCase]: CaseViewEventFields
  [CaseAnalyticsEventTypes.DeletedCaseFile]: CaseViewEventFields
  [CaseAnalyticsEventTypes.DownloadedCaseFile]: CaseViewEventFields
  [CaseAnalyticsEventTypes.DownloadedRequestedDocument]: CaseViewEventFields
  [CaseAnalyticsEventTypes.RequestedfromCaseCopilot]: CaseViewEventFields
}
