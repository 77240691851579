import { useCallback, useState } from "react"
import { useExhibitBuilderStore } from "exhibit-builder/store"
import { usePermissions } from "permissions/usePermissions"
import { importProviders } from "api"
import { providerMedicalSummariesService } from "api/services/provider-summaries"
import { Button } from "@mui/material"
import { LoadingIcon } from "review/styled"

export function ImportProviders() {
  const caseId = useExhibitBuilderStore(state => state.caseId)
  const { enableZeroClickSummariesEnabled } = usePermissions()
  const [isImporting, setIsImporting] = useState(false)

  const handleProvidersImport = useCallback(async () => {
    setIsImporting(true)
    try {
      await importProviders(caseId)
      providerMedicalSummariesService.generateAllSummaries({ caseId: parseInt(caseId) })
    } catch {
      // nothing to do, import failed, we already should have error in sentry
    }
    setIsImporting(false)
  }, [caseId])

  if (!enableZeroClickSummariesEnabled) {
    return <></>
  }

  return (
    <Button
      startIcon={isImporting && <LoadingIcon />}
      variant="outlined"
      color="secondary"
      onClick={handleProvidersImport}
      data-test="import-providers-button"
      disabled={isImporting}
    >
      Import Providers
    </Button>
  )
}
