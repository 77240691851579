import React, {
  ReactNode,
  // useEffect
} from "react"
import { useTimeSpendingAnalytic } from "infrastructure/timeSpending/hooks"
// import { usePageStore } from "common/pages/pageStore"
import { SyncWithDocumentStructureButton } from "common/SyncWithDocumentStructureButton"
import { useParams } from "react-router-dom"
// import { queryKeys, SILENT_QUERY_PARAMS } from "react-query/constants"
// import { documentsService } from "api/services/documents"
// import { useQuery } from "@tanstack/react-query"
import { MedchronExhibitBuilder } from "exhibit-builder/ExhibitBuilder"
import { useSyncWithDocumentStructure } from "hooks/useSyncWithDocumentStructure"

export const ExhibitManagement = React.memo(function DocumentExhibits(): ReactNode {
  const { id: documentId } = useParams()

  // const { data: medchronTile } = useQuery(
  //   [queryKeys.documentMedchronTile, documentId],
  //   () => {
  //     if (documentId) {
  //       return documentsService.getMedchronTile({ documentId: documentId })
  //     }
  //   },
  //   {
  //     ...SILENT_QUERY_PARAMS,
  //     enabled: !!documentId,
  //   }
  // )

  // useEffect(() => {
  //   if (medchronTile) {
  //     usePageStore.setState({
  //       showBack: true,
  //       backTitle: "Back",
  //       backHref: `/requests/${medchronTile.questionnaireId}`,
  //     }) // TODO: Get from medchronTile once available from BE
  //   }
  // }, [medchronTile])

  const {
    isSyncDocumentStructureRequired,
    isLoading: isSyncingDocumentStructure,
    handleSyncDocumentStructure,
  } = useSyncWithDocumentStructure({ documentId, fetchOnMount: !!documentId })

  useTimeSpendingAnalytic({ documentId })

  return (
    <>
      {documentId && (
        <>
          {isSyncDocumentStructureRequired && (
            <SyncWithDocumentStructureButton
              isLoading={isSyncingDocumentStructure}
              onSync={handleSyncDocumentStructure}
            />
          )}
          <MedchronExhibitBuilder documentId={documentId} />
        </>
      )}
    </>
  )
})
