import { isNil } from "lodash"

import { ExhibitCitation } from "common/citation/ExhibitCitation"
import { useUserExhibitPreviewById } from "documents/exhibits/useUserExhibitPreview"
import { ExhibitPartition } from "exhibit-builder/store/types"
import { useDocumentStore } from "documents/store"
import { PastMedicalVisitRelationBaseComponentProps } from "../types"

interface PastMedicalVisitRelationUserExhibitComponentProps
  extends PastMedicalVisitRelationBaseComponentProps {
  partition: ExhibitPartition
}

export const PastMedicalVisitRelationUserExhibitComponent = ({
  page,
  partition,
}: PastMedicalVisitRelationUserExhibitComponentProps) => {
  const userExhibitOrder = useDocumentStore(state => state.userExhibitOrder)
  const index = userExhibitOrder.indexOf(partition.userExhibitId)

  const openUserExhibitPreview = useUserExhibitPreviewById(partition.userExhibitId)
  const handleOpenPreview = (page: Nullable<number>) => openUserExhibitPreview(partition, page)

  const pages = isNil(page) ? [1] : [page]

  return <ExhibitCitation index={index} pages={pages} onClick={handleOpenPreview} />
}
