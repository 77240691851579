import { useState, useCallback } from "react"

interface ConfirmationOptions {
  title: string
  message: string
  confirmText?: string
  cancelText?: string
}

export const useConfirmation = () => {
  const [confirmationState, setConfirmationState] = useState<ConfirmationOptions | null>(null)
  const [resolver, setResolver] = useState<((value: boolean) => void) | null>(null)

  const confirm = useCallback((options: ConfirmationOptions): Promise<boolean> => {
    return new Promise(resolve => {
      setConfirmationState(options)
      setResolver(() => resolve)
    })
  }, [])

  const handleConfirm = useCallback(() => {
    if (resolver) {
      resolver(true)
      setConfirmationState(null)
      setResolver(null)
    }
  }, [resolver])

  const handleCancel = useCallback(() => {
    if (resolver) {
      resolver(false)
      setConfirmationState(null)
      setResolver(null)
    }
  }, [resolver])

  return {
    confirmationState,
    confirm,
    handleConfirm,
    handleCancel,
  }
}
