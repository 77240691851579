import { useCallback, useMemo, useRef, useState } from "react"
import { Box } from "@mui/material"
import { RichTextEditor } from "common/form-components/rich-text/RichTextEditor"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import { useConfirm } from "hooks/useConfirm"
import { getDeleteAppointmentDialogProps, revertChangesDialogProps } from "../constants"
import { StyledAppointmentCell, StyledDeleteButton, StyledEditorWrapper } from "../styled"
import { IcdCodeChange, IcdCodesStack } from "./IcdCodesStack"
import { Appointment, IcdCode } from "documents/types"
import { documentActions, useDocumentStore } from "documents/store"
import { appointmentsSelectors } from "documents/store/appointments"
import { providersSelectors } from "documents/store/providers"
import { CitationElement } from "common/form-components/rich-text/CustomEditor"
import { DEFAULT_VALUE } from "common/form-components/rich-text/defaultValue"
import {
  MedicalChronologyAnalyticEvent,
  MedicalChronologyAnalyticsEventTypes,
} from "infrastructure/apm/events/medicalChronologyEvents"
import { useShallow } from "zustand/react/shallow"
import { exhibitSelectors } from "documents/store/exhibits"
import { userExhibitsSelectors } from "documents/store/userExhibits"
import { useExhibitsPreview } from "documents/exhibits/useExhibitPreview"
import { useUserExhibitPreview } from "documents/exhibits/useUserExhibitPreview"
import { previewCitationReference, getCitationReferences } from "documents/medical-summary/utils"
import { flagsDataSelectors } from "documents/store/flags"
import { Flag, isFlagForSaving } from "documents/flags/types"
import { EditorFlagMarks } from "documents/flags/EditorFlagMarks"
import { usePermissions } from "permissions/usePermissions"
import { AppointmentFlagMarks } from "documents/flags/AppointmentFlagMarks"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { useQuery } from "@tanstack/react-query"
import { queryKeys, SILENT_QUERY_PARAMS } from "react-query/constants"
import { documentsService } from "api/services/documents"
import { AppointmentStatus } from "./AppointmentStatus"
import { DraftingRichTextEditor } from "./DraftingRichTextEditor"
import { GENERATED_SUMMARY_STATUSES } from "../../../api/services/documents/types"
import { StyledErrorWrapper } from "./styled"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import { UpdateCitationStatus } from "exhibit-builder/store/types"
import { useTheme } from "@emotion/react"

interface SummaryCellProps {
  appointmentId: Appointment["id"]
  readOnly?: boolean
}

export const SummaryCell = ({ appointmentId, readOnly }: SummaryCellProps) => {
  const theme = useTheme()

  const appointmentCellRef = useRef<Nullable<HTMLDivElement>>(null)
  const icdCodesStackRef = useRef<Nullable<HTMLDivElement>>(null)
  const flags = useDocumentStore(flagsDataSelectors.getFlags)
  const documentId = useDocumentStore(state => state.documentId)
  const requestId = useDocumentStore(state => state.questionnaireId)
  const updateCitationStatus = useDocumentStore(state => state.updateCitationStatus)
  const flagIds = useMemo(() => Object.keys(flags), [flags])
  const { medicalSummaryFlagsEnabled } = usePermissions()
  const appointment = useDocumentStore(appointmentsSelectors.getAppointmentById(appointmentId))
  const icdCodes = useDocumentStore(
    useShallow(state => appointment.icdCodes.map(icdCodeId => state.icdCodes[icdCodeId]))
  )
  const provider = useDocumentStore(
    providersSelectors.getProviderById(appointment.providerId as NonNullable<Appointment["providerId"]>)
  )

  const exhibits = useDocumentStore(useShallow(exhibitSelectors.getExhibitList))
  const userExhibits = useDocumentStore(useShallow(userExhibitsSelectors.getUserExhibitsList))

  const exhibitReferences = useMemo(
    () => getCitationReferences({ exhibits, userExhibits }),
    [exhibits, userExhibits]
  )

  const [editMode, setEditMode] = useState(false)
  const editModeRef = useRef(editMode)
  editModeRef.current = editMode

  const summaryContent = useRef(DEFAULT_VALUE)
  summaryContent.current = appointment.summary ?? appointment.generatedSummary ?? DEFAULT_VALUE

  const { data: medchronTile, isFetching: isMedchronTileLoading } = useQuery(
    [queryKeys.documentMedchronTile, documentId],
    () => {
      if (documentId) {
        return documentsService.getMedchronTile({ documentId: documentId })
      }
    },
    {
      enabled: !!documentId,
      ...SILENT_QUERY_PARAMS,
    }
  )

  const handleStartEdit = useCallback(() => {
    if (editMode || readOnly) return

    const selection = window.getSelection()

    if (selection && !selection.isCollapsed) return

    setEditMode(true)
  }, [editMode, readOnly])

  const handleSave = useCallback(() => {
    if (editModeRef.current) {
      documentActions.setAppointmentSummary({ appointmentId, summary: summaryContent.current })
    }
    setEditMode(false)
  }, [appointmentId])

  const deleteAppointmentDialogProps = useMemo(
    () =>
      getDeleteAppointmentDialogProps({
        providerName: provider.name,
        dateOfService: appointment.dateOfService,
      }),
    [provider.name, appointment.dateOfService]
  )

  const { confirm: confirmDelete, dialog: deleteAppointmentDialog } = useConfirm(deleteAppointmentDialogProps)
  const { confirm: confirmChanges, dialog: revertChangesDialog } = useConfirm(revertChangesDialogProps)

  const handleDeleteAppointment = useCallback(async () => {
    const confirmed = await confirmDelete()

    if (confirmed) {
      await documentActions.deleteAppointment(appointmentId)
    }
  }, [confirmDelete, appointmentId])

  const handleRegenerateSummary = useCallback(async () => {
    documentActions.regenerateAppointmentSummary({ appointmentId })
  }, [appointmentId])

  const handleAddFlag = useCallback(
    (flagData: Partial<Flag>, callback?: (flag: Flag) => void) => {
      if (isFlagForSaving(flagData)) {
        documentActions.createFlag({ ...flagData, appointment: appointmentId }, callback)
      }
    },
    [appointmentId]
  )

  const handleRevertChanges = useCallback(async () => {
    const confirmed = await confirmChanges()

    if (confirmed) {
      const summary = summaryContent.current
      summaryContent.current = appointment.generatedSummary ?? DEFAULT_VALUE

      setEditMode(false)
      documentActions.revertAppointmentSummary({ appointmentId }).catch(() => {
        summaryContent.current = summary
      })
    }
  }, [confirmChanges, appointmentId, appointment.generatedSummary])

  const handleIcdCodeChange = useCallback(
    ({ id, ...icdCode }: IcdCodeChange) => {
      if (!id) {
        documentActions.addAppointmentIcdCode({
          appointmentId,
          icdCode,
        })
      } else {
        documentActions.updateAppointmentIcdCode({
          appointmentId,
          icdCode: { id, ...icdCode },
        })
      }
    },
    [appointmentId]
  )

  const handleIcdCodeDelete = useCallback(
    (icdCodeId: IcdCode["id"]) => {
      documentActions.deleteAppointmentIcdCode({ appointmentId, icdCodeId })
    },
    [appointmentId]
  )

  const previewExhibit = useExhibitsPreview()
  const previewUserExhibit = useUserExhibitPreview()

  const handleCitationClick = useCallback(
    (citation: CitationElement, page: Nullable<number>) => {
      if (readOnly && medchronTile) {
        amplitudeApm.trackEvent(
          new MedicalChronologyAnalyticEvent(
            MedicalChronologyAnalyticsEventTypes.WebViewableMedicalChronologyExhibitReferenceClicked,
            {
              document_id: documentId,
              request_id: requestId,
              documentType: medchronTile.questionnaireType,
            }
          )
        )
      }

      previewCitationReference({
        citation,
        page,
        previewExhibit,
        previewUserExhibit,
      })
    },
    [documentId, medchronTile, readOnly, requestId, previewExhibit, previewUserExhibit]
  )

  const shouldHideSummary = useMemo(
    () =>
      [
        GENERATED_SUMMARY_STATUSES.NOT_REQUESTED,
        GENERATED_SUMMARY_STATUSES.DEDUPING_BPS,
        GENERATED_SUMMARY_STATUSES.PENDING,
      ].includes(appointment.generatedSummaryStatus),
    [appointment.generatedSummaryStatus]
  )

  return (
    <StyledAppointmentCell ref={appointmentCellRef} data-test="appointment-cell">
      {deleteAppointmentDialog}
      {revertChangesDialog}
      <EditorFlagMarks
        content={summaryContent.current}
        appointmentId={appointmentId}
        anchorRef={appointmentCellRef}
        editMode={editMode}
      />
      <AppointmentFlagMarks
        content={summaryContent.current}
        appointmentId={appointmentId}
        anchorRef={icdCodesStackRef}
        editorRef={appointmentCellRef}
      />
      {!isMedchronTileLoading && (
        <IcdCodesStack
          ref={icdCodesStackRef}
          icdCodes={icdCodes}
          onChange={handleIcdCodeChange}
          onDelete={handleIcdCodeDelete}
          readOnly={readOnly}
          exhibitBuilderOn={!!medchronTile?.useExhibitBuilder}
        />
      )}

      {!readOnly && (
        <AppointmentStatus
          appointmentId={appointment.id}
          handleRevertChanges={handleRevertChanges}
          handleRegenerateSummary={handleRegenerateSummary}
        />
      )}
      {!readOnly && updateCitationStatus === UpdateCitationStatus.ERROR && (
        <StyledErrorWrapper>
          <ErrorOutlineIcon sx={{ width: "22px", height: "22px" }} htmlColor={theme.palette.red.warning} />
          <span>Exhibit Reference Update Failed</span>
        </StyledErrorWrapper>
      )}
      {editMode ? (
        <DraftingRichTextEditor
          appointment={appointment}
          exhibitReferences={exhibitReferences}
          flagIds={flagIds}
          handleCitationClick={handleCitationClick}
          handleRevertChanges={handleRevertChanges}
          handleDeleteAppointment={handleDeleteAppointment}
          handleSave={handleSave}
          handleAddFlag={handleAddFlag}
        />
      ) : (
        !shouldHideSummary && (
          <>
            <StyledEditorWrapper
              readOnly={readOnly}
              style={{ paddingTop: readOnly && !icdCodes.length ? "0px" : undefined }}
              onClick={handleStartEdit}
            >
              {medicalSummaryFlagsEnabled ? (
                <RichTextEditor
                  readonly
                  value={summaryContent.current}
                  withCitations
                  citations={exhibitReferences}
                  onCitationClick={handleCitationClick}
                  withFlags
                  flags={flagIds}
                  onCreateFlag={handleAddFlag}
                />
              ) : (
                <RichTextEditor
                  readonly
                  value={summaryContent.current}
                  withCitations
                  citations={exhibitReferences}
                  onCitationClick={handleCitationClick}
                />
              )}
            </StyledEditorWrapper>
            {!readOnly && (
              <StyledDeleteButton onClick={handleDeleteAppointment}>
                <DeleteIcon fontSize="small" fontWeight={700} />
                <Box fontWeight={700} ml={0.5}>
                  Delete Appointment
                </Box>
              </StyledDeleteButton>
            )}
          </>
        )
      )}
    </StyledAppointmentCell>
  )
}
