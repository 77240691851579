import "@emotion/react"
import { red, green, blueGrey } from "@mui/material/colors"
import { createTheme, Theme as MuiTheme } from "@mui/material/styles"

const typographyVariants: Array<
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "body1SemiBold"
  | "body1"
  | "body2SemiBold"
  | "body2Medium"
  | "body2"
  | "helper"
  | "helperSemiBold"
  | "listSubHeader"
  | "button"
  | "labelBadge"
  | "dialogHeader"
> = [
  "h1",
  "h2",
  "h3",
  "h4",
  "body1SemiBold",
  "body1",
  "body2SemiBold",
  "body2Medium",
  "body2",
  "helper",
  "helperSemiBold",
  "listSubHeader",
  "button",
  "labelBadge",
  "dialogHeader",
]

const LINE_HEIGHT = 1.4
const REGULAR_FONT_WEIGHT = 400
const MEDIUM_FONT_WEIGHT = 500
const SEMI_BOLD_FONT_WEIGHT = 600
const BOLD_FONT_WEIGHT = 700
const HEADER_FONT_FAMILY = ["Onest", "sans-serif"].join(",")

// NOTE! These values were set by our design team
const typographyVariantMap: {
  [key in
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "body1"
    | "body2"
    | "body1SemiBold"
    | "body2SemiBold"
    | "body2Medium"
    | "helper"
    | "helperSemiBold"
    | "listSubHeader"
    | "button"
    | "labelBadge"
    | "dialogHeader"]: {
    fontSize: string
    fontWeight: number
    lineHeight: number
    fontFamily?: string
  }
} = {
  h1: {
    fontSize: "30px",
    fontWeight: BOLD_FONT_WEIGHT,
    lineHeight: LINE_HEIGHT,
    fontFamily: HEADER_FONT_FAMILY,
  },
  h2: {
    fontSize: "24px",
    fontWeight: BOLD_FONT_WEIGHT,
    lineHeight: LINE_HEIGHT,
    fontFamily: HEADER_FONT_FAMILY,
  },
  h3: {
    fontSize: "20px",
    fontWeight: BOLD_FONT_WEIGHT,
    lineHeight: LINE_HEIGHT,
    fontFamily: HEADER_FONT_FAMILY,
  },
  h4: {
    fontSize: "18px",
    fontWeight: BOLD_FONT_WEIGHT,
    lineHeight: LINE_HEIGHT,
    fontFamily: HEADER_FONT_FAMILY,
  },
  body1SemiBold: {
    fontSize: "16px",
    fontWeight: SEMI_BOLD_FONT_WEIGHT,
    lineHeight: LINE_HEIGHT,
  },
  body1: {
    fontSize: "16px",
    fontWeight: REGULAR_FONT_WEIGHT,
    lineHeight: LINE_HEIGHT,
  },
  body2SemiBold: {
    fontSize: "14px",
    fontWeight: SEMI_BOLD_FONT_WEIGHT,
    lineHeight: LINE_HEIGHT,
  },
  body2Medium: {
    fontSize: "14px",
    fontWeight: MEDIUM_FONT_WEIGHT,
    lineHeight: LINE_HEIGHT,
  },
  body2: {
    fontSize: "14px",
    fontWeight: REGULAR_FONT_WEIGHT,
    lineHeight: LINE_HEIGHT,
  },
  helper: {
    fontSize: "12px",
    fontWeight: REGULAR_FONT_WEIGHT,
    lineHeight: LINE_HEIGHT,
  },
  helperSemiBold: {
    fontSize: "12px",
    fontWeight: SEMI_BOLD_FONT_WEIGHT,
    lineHeight: LINE_HEIGHT,
  },
  listSubHeader: {
    fontSize: "14px",
    fontWeight: MEDIUM_FONT_WEIGHT,
    lineHeight: LINE_HEIGHT,
  },
  button: {
    fontSize: "14px",
    fontWeight: MEDIUM_FONT_WEIGHT,
    lineHeight: LINE_HEIGHT,
  },
  labelBadge: {
    fontSize: "12px",
    fontWeight: MEDIUM_FONT_WEIGHT,
    lineHeight: LINE_HEIGHT,
  },
  dialogHeader: {
    fontSize: "24px",
    fontWeight: BOLD_FONT_WEIGHT,
    lineHeight: LINE_HEIGHT,
  },
}

// THEME FOR NEW MUI v5
declare module "@mui/material/styles/createPalette" {
  interface Palette {
    blue: {
      primary: string
      main: string
      light: string
      banner: string
      contentBox: string
      contentBoxHover: string
      bannerStroke: string
      info: string
      dark: string
    }
    dragging: {
      main: string
    }
    label: {
      ignore: string
      postpone: string
      action: string
      info: string
      warning: string
      pending: string
    }
    disabled: {
      main: string
      background: string
      buttonBackground: string
      border: string
      darker: string
      darkGrey: string
      blue: string
    }
    button: {
      grey: string
      darkGrey: string
    }
    mint: {
      main: string
      hover: string
      text: string
    }
    annotation: {
      pre_annotating: string
      annotating: string
      auto_complete: string
      complete: string
      deleted: string
      error: string
    }
    red: {
      warning: string
      warningHover: string
      warningBackground: string
      600: string
    }
    green: {
      success: string
      successBackground: string
    }
    warmColor: {
      warningOrange: string
      warningOrangeBackground: string
    }
    selectableCard: {
      fontColor: string
      selectedBorderColor: string
      primaryBgColor: string
      secondaryBgColor: string
      boxShadowColor: string
      dividerColor: string
      iconBgColor: string
      listItemColor: string
    }
    estimate: {
      infoEmphasis: string
    }
    polarity: {
      positive: string
      negative: string
    }
    graph: {
      barPrimary: string
      barPrimaryBorder: string
      linePrimary: string
      scatterPrimary: string
      scatterWarning: string
    }
    progressBar: {
      empty: string
      filled: string
    }
    evenUpText: {
      primary: string
      secondary: string
    }
    evenUpIcon: {
      primary: string
    }
  }
  interface PaletteOptions {
    blue: {
      primary: string
      main: string
      light: string
      banner: string
      contentBox: string
      contentBoxHover: string
      bannerStroke: string
      info: string
      dark: string
    }
    dragging: {
      main: string
    }
    label: {
      ignore: string
      postpone: string
      action: string
      info: string
      warning: string
      pending: string
    }
    disabled: {
      main: string
      background: string
      buttonBackground: string
      border: string
      darker: string
      darkGrey: string
      blue: string
    }
    button: {
      grey: string
      darkGrey: string
    }
    mint: {
      main: string
      hover: string
      text: string
    }
    annotation: {
      pre_annotating: string
      annotating: string
      auto_complete: string
      complete: string
      deleted: string
      error: string
    }
    red: {
      warning: string
      warningHover: string
      warningBackground: string
      600: string
    }
    green: {
      success: string
      successBackground: string
    }
    warmColor: {
      warningOrange: string
      warningOrangeBackground: string
    }
    selectableCard: {
      fontColor: string
      selectedBorderColor: string
      primaryBgColor: string
      secondaryBgColor: string
      boxShadowColor: string
      dividerColor: string
      iconBgColor: string
      listItemColor: string
    }
    estimate: {
      infoEmphasis: string
    }
    polarity: {
      positive: string
      negative: string
    }
    graph: {
      barPrimary: string
      barPrimaryBorder: string
      linePrimary: string
      scatterPrimary: string
      scatterWarning: string
    }
    progressBar: {
      empty: string
      filled: string
    }
    // I don't love this pattern but since we are in a weird intemdiary state between using the UI kit James defined and the new MUI v5 theme, I'm going to keep it for now
    evenUpText: {
      primary: string
      secondary: string
    }
    evenUpIcon: {
      primary: string
    }
  }
}

// Needed to make the TS happy with new custom fontWeights for our theme
declare module "@mui/material/styles/createTypography" {
  interface TypographyOptions {
    fontWeightSemiBold?: number
  }
  interface Typography {
    fontWeightSemiBold: number
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body1SemiBold: React.CSSProperties
    body2SemiBold: React.CSSProperties
    body2Medium: React.CSSProperties
    helper: React.CSSProperties
    helperSemiBold: React.CSSProperties
    labelBadge: React.CSSProperties
  }

  // Allows configuration using `createTheme`
  interface TypographyVariantsOptions {
    body1SemiBold?: React.CSSProperties
    body2SemiBold?: React.CSSProperties
    body2Medium?: React.CSSProperties
    helper?: React.CSSProperties
    helperSemiBold?: React.CSSProperties
    labelBadge?: React.CSSProperties
  }

  interface TypeBackground {
    default: string
    paper: string
    paperLight: string
    paperDark: string
    paperAIGradient: string
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body1SemiBold: true
    body2SemiBold: true
    body2Medium: true
    helper: true
    helperSemiBold: true
    listSubHeader: true
    labelBadge: true
    dialogHeader: true
  }
}

// THEME for v5
export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000, // default is 900
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: ['"Inter"', "sans-serif"].join(","),
    fontWeightLight: 300, // default
    fontWeightRegular: 400, // default
    fontWeightMedium: 500, // default
    fontWeightSemiBold: 600, // custom
    fontWeightBold: 700, // default
  },
  palette: {
    primary: {
      main: "#041D28",
      dark: "#02141C",
    },
    secondary: {
      main: "#4370F5",
    },
    blue: {
      primary: "#002EB9",
      main: "#4370F5",
      light: "#D5E4EE",
      banner: "#9DB6FF20",
      bannerStroke: "#9DB6FF",
      contentBox: "#F3F6FE",
      contentBoxHover: "#9DB6FF50",
      info: "#0288D1",
      dark: "#001C70",
    },
    error: {
      main: red.A400,
    },
    success: {
      main: green[600],
      light: "#4CAF50",
    },
    background: {
      default: "#FFFFFF",
      paperDark: "#FDFDFD",
      paperLight: "#FFFFFF",
      paperAIGradient: "linear-gradient(180deg, #FFFFFF 0%, #F3F6FE 100%)",
    },
    info: {
      main: "#4370F5",
    },
    dragging: {
      main: blueGrey.A100,
    },
    label: {
      ignore: "#000000",
      postpone: "#7D7D7D",
      action: "#5F0A87",
      info: "#3CD5AD",
      warning: "#B71C1C",
      pending: "#00B1FF",
    },
    disabled: {
      main: "#A0A0A0",
      background: "#F5F5F5",
      buttonBackground: "#E0E0E0",
      border: "#D8D8D8",
      darkGrey: "#757575",
      darker: "#979797",
      blue: "#E5EAFD",
    },
    button: {
      grey: "#EBEBE6",
      darkGrey: "#424242",
    },
    mint: {
      main: "rgb(60, 213, 173)",
      hover: "rgba(60, 213, 173, 0.3)",
      text: "#009b77",
    },
    annotation: {
      pre_annotating: "#f57c00",
      annotating: "#715c8e",
      auto_complete: "#00a186",
      complete: "#00a186",
      deleted: "#000000",
      error: "#a10086",
    },
    red: {
      warning: "#D50000",
      warningHover: "#D50000",
      warningBackground: "#FBE5E5",
      600: "#E53935",
    },
    green: {
      success: "#0EAB69",
      successBackground: "#E7F7F0",
    },
    warmColor: {
      warningOrange: "#F6860E",
      warningOrangeBackground: "#FEF3E7",
    },
    selectableCard: {
      fontColor: "#09244B",
      selectedBorderColor: "#002EB9",
      primaryBgColor: "#F9FAFF",
      secondaryBgColor: "#F5F8FE",
      boxShadowColor: "rgba(67,112,245,0.25)",
      dividerColor: "#979797",
      iconBgColor: "rgba(67, 112, 245, 0.06)",
      listItemColor: "#51555F",
    },
    estimate: {
      infoEmphasis: "rgba(157, 182, 255, 0.5)",
    },
    polarity: {
      positive: "#0B8E57",
      negative: "#DD1616",
    },
    graph: {
      barPrimary: "#AEBEF7",
      barPrimaryBorder: "#5E7DEF",
      linePrimary: "#002EB9",
      scatterPrimary: "#5E7DEF",
      scatterWarning: "#FB8C00",
    },
    progressBar: {
      empty: "#D9D9D9",
      filled: "#0EAB69",
    },
    evenUpText: {
      primary: "#191919",
      secondary: "#545454",
    },
    evenUpIcon: {
      primary: "#757575",
    },
  },
  shape: {
    borderRadius: 3,
  },
  components: {
    MuiTypography: {
      variants: typographyVariants.map(type => {
        const { fontSize, fontWeight, lineHeight, fontFamily } = typographyVariantMap[type]
        return {
          props: { variant: type },
          style: {
            fontFamily: fontFamily ?? undefined,
            fontWeight,
            fontSize,
            lineHeight,
          },
        }
      }),
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "0.875rem",
          lineHeight: 1.43,
          letterSpacing: "0.01071em",
        },
        html: `
          "-webkit-font-smoothing": "auto",
        `,
      },
    },
    MuiInputBase: {
      defaultProps: {
        disableInjectingGlobalStyles: true,
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthXl: {
          paddingLeft: 0,
          paddingRight: 0,
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: { root: { backgroundImage: "unset" } },
    },
    MuiFab: {
      styleOverrides: {
        root: ({ theme }) => ({
          position: "fixed",
          bottom: theme.spacing(2),
          right: theme.spacing(2),
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: "none",
          ...(ownerState.variant === "outlined" &&
            ownerState.color === "secondary" && {
              color: theme.palette.blue.primary,
            }),
        }),
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
})

declare module "@emotion/react" {
  // eslint-disable-next-line
  export interface Theme extends MuiTheme {}
}
