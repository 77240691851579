import { Box, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import FileDropzone, { FileDropzoneProps } from "common/form-components/files/FileDropzone"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { LoadingSpinner, StyledFormControl } from "./styled"
import { useId, useState } from "react"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { ExhibitFile } from "exhibit-builder/store/types"
import { useShallow } from "zustand/react/shallow"
import * as Sentry from "@sentry/react"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { useFileUploader } from "common/file-uploader"
import Button from "evenup-ui/Button"
import { useUploadExhibit } from "exhibit-builder/hooks/useUploadExhibit"

export function NewExhibit() {
  const [selectedFile, setSelectedFile] = useState<Nullable<ExhibitFile["id"]>>(null)
  const dropdownLabelId = useId()
  const exhibitFiles = useExhibitBuilderStore(useShallow(state => state.files))
  const caseId = useExhibitBuilderStore(state => state.caseId)
  const documentId = useExhibitBuilderStore(state => state.document.documentId)
  const { uploadExhibit } = useUploadExhibit()
  const [isLoading, setIsLoading] = useState(false)
  const { showErrorMessage } = useHandleMessages()
  const { uploadFiles } = useFileUploader()

  const createUserExhibit = async ({
    exhibitId,
    isNewExhibit,
    isPlaceholder,
  }: {
    exhibitId?: ExhibitFile["id"]
    isNewExhibit?: boolean
    isPlaceholder?: boolean
  }) => {
    try {
      await exhibitBuilderActions.createUserExhibit({
        exhibitId,
        isNewExhibit,
        isPlaceholder,
      })
    } catch (err) {
      showErrorMessage("Failed to create exhibit, please refresh and try again.")
      Sentry.captureException(err, { extra: { exhibitId } })
      throw err
    }
  }

  const handleFileChange = async (event: SelectChangeEvent) => {
    setSelectedFile(event.target.value)
    setIsLoading(true)

    await createUserExhibit({ exhibitId: event.target.value })

    setIsLoading(false)
    setSelectedFile(null)
  }

  const handleDrop: FileDropzoneProps["onDrop"] = async files => {
    setIsLoading(true)

    const uploads = await uploadFiles(files as File[])

    const uploadExhibitResults = await Promise.allSettled(
      uploads.items.map(file => {
        if (!file.uploadId) {
          return
        }

        const formData = new FormData()
        formData.append("name", file.name)
        formData.append("section", "providers")
        formData.append("upload_id", file.uploadId)
        formData.append("type", "other")

        return uploadExhibit({ data: formData, caseId, documentId })
      })
    )

    const createExhibitResults = await Promise.allSettled(
      uploadExhibitResults.map(async result => {
        if (result.status === "fulfilled") {
          const exhibit = await result.value?.json()
          if (exhibit) {
            return createUserExhibit({ exhibitId: exhibit.pk, isNewExhibit: true })
          }
        } else {
          throw result
        }
      })
    )

    const failedFiles: string[] = []
    createExhibitResults.forEach((result, index) => {
      if (result.status === "rejected") {
        failedFiles.push(files[index].name)
      }
    })

    setIsLoading(false)

    if (failedFiles.length) {
      showErrorMessage(`Failed to create exhibit for ${failedFiles.join(", ")}`)
    }
  }

  const handleCreatePlaceholder = async () => {
    setIsLoading(true)
    try {
      await createUserExhibit({ isPlaceholder: true })
    } catch (err) {
      showErrorMessage("Failed to create placeholder exhibit, please refresh and try again.")
      Sentry.captureException(err)
    }
    setIsLoading(false)
  }

  const label = "Recover existing files"

  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <FileDropzone onDrop={handleDrop} disabled={isLoading} />
      </DndProvider>

      <Box display="flex" alignItems="center" gap={1}>
        <Button disabled={isLoading} onClick={handleCreatePlaceholder}>
          Create Placeholder
        </Button>
        <StyledFormControl>
          <InputLabel size="small" id={dropdownLabelId} disabled={isLoading}>
            {label}
          </InputLabel>
          <Select
            data-test="exhibit-file-dropdown"
            color="secondary"
            size="small"
            labelId={dropdownLabelId}
            label={label}
            value={selectedFile || ""}
            onChange={handleFileChange}
            disabled={isLoading}
          >
            {Object.values(exhibitFiles).map(file => (
              <MenuItem key={file.id} value={file.id}>
                {file.name}
              </MenuItem>
            ))}
          </Select>
          {isLoading && <LoadingSpinner size={24} color="secondary" />}
        </StyledFormControl>
      </Box>
    </div>
  )
}
