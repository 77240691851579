import { useEffect, useState, useCallback } from "react"

type UseStickyActionsBarProps = {
  scrollYHeight?: number
}

const DEFAULT_SCROLL_Y = 100

export const useStickyActionsBar = (
  scrollYHeight: UseStickyActionsBarProps["scrollYHeight"] = DEFAULT_SCROLL_Y
) => {
  const [showStickyHeader, setShowStickyHeader] = useState(false)

  const handleScroll = useCallback(() => {
    const scrollY = window.scrollY
    if (scrollY > scrollYHeight) {
      if (!showStickyHeader) {
        setShowStickyHeader(true)
      }
    } else {
      if (showStickyHeader) {
        setShowStickyHeader(false)
      }
    }
  }, [scrollYHeight, showStickyHeader])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, [handleScroll])

  return { showStickyHeader }
}
