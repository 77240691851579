import { useCallback, useEffect, useRef, useState, ReactNode } from "react"
import Button from "@mui/material/Button"
import { ProviderSummaryTable } from "./ProviderSummaryTable"
import { AddNewMedicalSummary } from "./components/AddNewMedicalSummary"
import { documentStateActions } from "documents/store/documentState"
import { usePageStore } from "common/pages/pageStore"
import { useDocumentStore, documentActions } from "documents/store"
import { VerticalCenterBox } from "common/FlexBox"
import { SavingIndicator } from "documents/components/SavingIndicator"
import { StyledHeaderButtonsWrapper, ActionsContainer } from "./styled"
import { REFETCH_SUMMARY_STATUSES } from "./constants"
import { useQuery } from "@tanstack/react-query"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { documentsService } from "api/services/documents"
import { pick } from "lodash"
import { IcdCodesTable } from "./components/IcdCodeTable/IcdCodeTable"
import { usePermissions } from "permissions/usePermissions"
import { FlagsController } from "documents/flags/FlagsController"
import { FlagsList } from "documents/flags/FlagsList"
import { createPortal } from "react-dom"
import { MedChronFilters } from "documents/components/MedChronFilters"
import { BASE_OUTER_PAGE_ID } from "common/pages/BasePage"
import { AppointmentTagLinks } from "./components/AppointmentTagLinks"
import { useTimeSpendingAnalytic } from "infrastructure/timeSpending/hooks"
import {
  MedicalChronologyAnalyticEvent,
  MedicalChronologyAnalyticsEventTypes,
} from "infrastructure/apm/events/medicalChronologyEvents"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { timeSpending } from "infrastructure/timeSpending"
import { INTAKE_STATUSES } from "requests/constants"
import { DOCUMENT_TYPES } from "requests/enums"
import { DocumentGenerateButton } from "documents/exhibits/DocumentGenerateButton"
import { useStickyActionsBar } from "exhibit-builder/hooks/useStickyActionsBar"
import { StickyHeaderActions } from "exhibit-builder/styled"
import { UpdateSummariesButton } from "exhibit-builder/UpdateSummariesButton"
import { UPDATE_SUMMARIES_MODAL_DESCRIPTION } from "./constants"

const REFETCH_TIMEOUT = 30_000

const sendAnalytic = async (documentId: string, questionnaireId: PrimaryKey, status: INTAKE_STATUSES) => {
  const exhibitManagementTimeSpent = await timeSpending.getTimeSpending({ documentId })

  if (exhibitManagementTimeSpent) {
    amplitudeApm.trackEvent(
      new MedicalChronologyAnalyticEvent(
        MedicalChronologyAnalyticsEventTypes.ExhibitManagementPageTimeSpentUntilGoToMedicalSummary,
        {
          document_id: documentId,
          request_id: questionnaireId,
          time: exhibitManagementTimeSpent,
          request_status: status,
        }
      )
    )
  }
}

export const MedicalSummary = (): ReactNode => {
  const documentId = useDocumentStore(state => state.documentId)
  const questionnaireId = useDocumentStore(state => state.questionnaireId)
  const [openNewSummaryDialog, setOpenNewSummaryDialog] = useState(false)
  const { medicalChronologyIcdCodeTableEnabled, medchronAppointmentTagsEnabled } = usePermissions()

  const { showStickyHeader } = useStickyActionsBar()

  const [shouldRefetch, setShouldRefetch] = useState(false)
  const hasPendingSummariesRef = useRef(true)
  const hasPendingSummaries = useDocumentStore(
    state =>
      !documentId ||
      (hasPendingSummariesRef.current &&
        Object.values(state.appointments).some(appointment =>
          REFETCH_SUMMARY_STATUSES.has(appointment.generatedSummaryStatus)
        ))
  )
  hasPendingSummariesRef.current = hasPendingSummaries

  const { data: medchronTile } = useQuery(
    [queryKeys.documentMedchronTile, documentId],
    () => {
      if (documentId) {
        return documentsService.getMedchronTile({ documentId: documentId })
      }
    },
    {
      ...SILENT_QUERY_PARAMS,
      enabled: !!documentId,
    }
  )

  const { data: documentData } = useQuery(
    [queryKeys.documentData, documentId, "refetch"],
    () => documentsService.getDocumentEntities({ documentId }),
    {
      meta: SILENT_QUERY_PARAMS.meta,
      refetchInterval: REFETCH_TIMEOUT,
      enabled: shouldRefetch,
    }
  )

  const isExhibitBuilderOn = !!medchronTile?.useExhibitBuilder

  useTimeSpendingAnalytic({
    type: MedicalChronologyAnalyticsEventTypes.MedicalSummaryPageTimeSpent,
    documentId,
  })

  useEffect(() => {
    if (!hasPendingSummaries) {
      setShouldRefetch(false)
      return
    }

    const timeout = setTimeout(() => setShouldRefetch(true), REFETCH_TIMEOUT)
    return () => clearTimeout(timeout)
  }, [hasPendingSummaries])

  useEffect(() => {
    if (!documentData) return

    for (const appointmentId in documentData.appointments) {
      documentActions.setAppointmentData(
        appointmentId,
        pick(documentData.appointments[appointmentId], ["generatedSummaryStatus", "generatedSummary"])
      )
    }
  }, [documentData])

  useEffect(() => {
    documentStateActions.setTitle("Medical Summary Review")
    documentStateActions.setShowExtraButtons(false)
    usePageStore.setState({
      showBack: true,
      backTitle: "Back to Exhibit Arrangement",
      backHref: `/documents/${documentId}/exhibit-management`,
    })

    if (medchronTile) {
      documentStateActions.setTitleTags([
        medchronTile.questionnaireType === DOCUMENT_TYPES.PREMIUM_MEDCHRON ? "Premium" : "Standard",
      ])
      sendAnalytic(documentId, questionnaireId, medchronTile.questionnaireStatus)
    }

    return () => {
      documentStateActions.setTitleTags([])
      documentStateActions.unsetTitle()
    }
  }, [documentId, questionnaireId, medchronTile])

  const handleOpenDialog = useCallback(() => {
    setOpenNewSummaryDialog(true)
  }, [])

  const handleCloseDialog = useCallback(() => {
    setOpenNewSummaryDialog(false)
  }, [])

  const portalSource = document.getElementById(BASE_OUTER_PAGE_ID)
  const pageActionsContainer = document.getElementById("page-actions-container")

  return (
    <>
      {portalSource &&
        createPortal(
          <MedChronFilters displayReviewActions>
            {medchronAppointmentTagsEnabled && (
              <AppointmentTagLinks>
                <Button
                  size="medium"
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={handleOpenDialog}
                >
                  Add New Summary
                </Button>
              </AppointmentTagLinks>
            )}
          </MedChronFilters>,
          portalSource
        )}
      {pageActionsContainer && (
        <>
          {createPortal(
            <ActionsContainer>
              {medchronTile && (
                <DocumentGenerateButton
                  text="Generate Medchron"
                  requestId={questionnaireId}
                  requestStatus={medchronTile.questionnaireStatus}
                />
              )}
              {isExhibitBuilderOn && (
                <UpdateSummariesButton
                  modalDescription={UPDATE_SUMMARIES_MODAL_DESCRIPTION}
                  documentId={documentId}
                />
              )}
            </ActionsContainer>,
            pageActionsContainer
          )}
          {isExhibitBuilderOn &&
            createPortal(
              <StickyHeaderActions show={showStickyHeader}>
                <UpdateSummariesButton
                  modalDescription={UPDATE_SUMMARIES_MODAL_DESCRIPTION}
                  documentId={documentId}
                  size="small"
                />
              </StickyHeaderActions>,
              document.querySelector(".sticky-header-container") as HTMLElement
            )}
        </>
      )}

      <FlagsController>
        <FlagsList exhibitBuilderOn={isExhibitBuilderOn} />
        {medicalChronologyIcdCodeTableEnabled && <IcdCodesTable exhibitBuilderOn={isExhibitBuilderOn} />}
        <StyledHeaderButtonsWrapper>
          <Button size="medium" variant="outlined" color="secondary" onClick={handleOpenDialog}>
            Add New Summary
          </Button>
        </StyledHeaderButtonsWrapper>
        <ProviderSummaryTable displayReviewActions exhibitBuilderOn={isExhibitBuilderOn} />
        <VerticalCenterBox sx={{ justifyContent: "flex-end", gap: 2, marginTop: 3 }}>
          <SavingIndicator />
        </VerticalCenterBox>
        <AddNewMedicalSummary
          open={openNewSummaryDialog}
          handleClose={handleCloseDialog}
          exhibitBuilderOn={isExhibitBuilderOn}
        />
      </FlagsController>
    </>
  )
}
