import { useCallback, useEffect, useState, useRef } from "react"
import { useSearchParams } from "react-router-dom"
import { exhibitBuilderActions, useExhibitBuilderStore } from "../store"
import * as Sentry from "@sentry/react"
import { Alert, IconButton } from "@mui/material"
import styled from "@emotion/styled"
import CloseIcon from "@mui/icons-material/Close"
import { PROVIDER_KEY, UNASSIGNED_KEY } from "../store/constants"
import { useShallow } from "zustand/react/shallow"
import { Plaintiff } from "exhibit-builder/store/types"

const StyledAlert = styled(Alert)(({ theme }) => ({
  border: `1px solid ${theme.palette.info.main}`,
  margin: theme.spacing(2, 0, 0),
  width: "80%",
  alignItems: "center",
}))

export const PLAINTIFF_ID_PARAM = "addProviderPlaintiff"

export function useAddProviderLink({ plaintiffId }: { plaintiffId: Plaintiff["id"] }) {
  const caseId = useExhibitBuilderStore(useShallow(state => state.caseId))

  return `/demands/${caseId}/files?${PLAINTIFF_ID_PARAM}=${plaintiffId}`
}

export function AddProvider() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isCreating, setIsCreating] = useState(false)
  const [shouldShow, setShouldShow] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const documentId = useExhibitBuilderStore(useShallow(state => state.document.documentId))
  const plaintiffIdRef = useRef<string | null>(null)

  const createPlaceholderExhibit = useCallback(async (plaintiffId: Plaintiff["id"]) => {
    setIsCreating(true)

    try {
      const userExhibit = await exhibitBuilderActions.createUserExhibit({ isPlaceholder: true })
      await exhibitBuilderActions.updateUserExhibit({
        id: userExhibit.id,
        docType: PROVIDER_KEY,
        subDocType: UNASSIGNED_KEY,
        plaintiffId: plaintiffId,
      })
    } catch (error) {
      setError("Failed to create placeholder exhibit, please refresh the page and try again.")
      Sentry.captureException("Error creating provider", {
        extra: { error, plaintiffId: plaintiffId },
      })
    }
    setIsCreating(false)
  }, [])

  useEffect(() => {
    const plaintiffId = searchParams.get(PLAINTIFF_ID_PARAM)

    if (documentId && plaintiffId && plaintiffId !== plaintiffIdRef.current) {
      plaintiffIdRef.current = plaintiffId
      setShouldShow(true)

      createPlaceholderExhibit(plaintiffId).then(() => {
        searchParams.delete(PLAINTIFF_ID_PARAM)
        setSearchParams(searchParams, { replace: true })
      })
    }
  }, [createPlaceholderExhibit, searchParams, setSearchParams, documentId])

  if (!shouldShow) {
    return null
  }

  return (
    <>
      {error && !isCreating && (
        <StyledAlert data-test="add-provider-error" severity="error">
          {error}
        </StyledAlert>
      )}
      {!error && (
        <StyledAlert
          data-test="add-provider-alert"
          severity="info"
          action={
            <IconButton onClick={() => setShouldShow(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {isCreating
            ? "Creating placeholder exhibit..."
            : "New Placeholder exhibit was created. Please assign the provider information required."}
        </StyledAlert>
      )}
    </>
  )
}
