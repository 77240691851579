import { DndProps, SortableNestedList } from "common/nested-list/SortableNestedList"
import { useCallback, useEffect, useMemo, useState } from "react"
import { v4 } from "uuid"
import Button from "evenup-ui/Button"
import { RangeItem } from "./types"
import { ListItem } from "./ListItem"
import { SortableListContainer } from "../../exhibit-builder/UserExhibitList/ActionPopup/styled"

function createItem(): RangeItem {
  return {
    id: v4(),
    startPage: "",
    endPage: "",
    errors: { startPage: "", endPage: "" },
  }
}

export function validatePageRange(startPage: string, endPage: string, range: { start: number; end: number }) {
  const errors = {
    startPage: "",
    endPage: "",
  }

  if (startPage && (Number(startPage) > range.end || Number(startPage) < range.start)) {
    errors.startPage = "Page not in range"
  }

  if (endPage && (Number(endPage) > range.end || Number(endPage) < range.start)) {
    errors.endPage = "Page not in range"
  }

  if (startPage && endPage && Number(endPage) < Number(startPage)) {
    errors.endPage = "Page must be greater than start page"
  }

  return errors
}

const initializeItems = (initialItems?: { start: number; end: number }[]) => {
  return (
    initialItems?.map(item => ({
      ...createItem(),
      startPage: item.start.toString(),
      endPage: item.end.toString(),
    })) || [createItem()]
  )
}

export function SortableRangeInput({
  disabled,
  onChange,
  range,
  initialItems,
  canAdd = true,
  canSort = true,
}: {
  disabled?: boolean
  range: { start: number; end: number }
  onChange: (ranges: RangeItem[]) => void
  initialItems?: { start: number; end: number }[]
  canAdd?: boolean
  canSort?: boolean
}) {
  const [items, setItems] = useState(initializeItems(initialItems))

  const addItem = () => {
    setItems([...items, createItem()])
  }

  const updateItem = useCallback(
    (id: string, startPage: string, endPage: string) => {
      setItems(
        items.map(item =>
          item.id === id
            ? { ...item, startPage, endPage, errors: validatePageRange(startPage, endPage, range) }
            : item
        )
      )
    },
    [items, range]
  )

  const removeItem = useCallback(
    (id: string) => {
      setItems(items.filter(item => item.id !== id))
    },
    [items]
  )

  const reorderList: DndProps<RangeItem>["onUpdate"] = items => {
    setItems(items.map((item, index) => ({ ...item, index })))
  }

  useEffect(() => {
    onChange(items)
  }, [items, onChange])

  const contentProps = useMemo(
    () => ({ onChange: updateItem, onRemove: removeItem, canDeleteRange: items.length > 1, disabled }),
    [updateItem, removeItem, items, disabled]
  )

  useEffect(() => {
    setItems(initializeItems(initialItems))
  }, [range, initialItems])

  return (
    <SortableListContainer>
      <SortableNestedList
        items={items}
        uniqueKey="id"
        ItemContentComponent={ListItem}
        PlaceholderComponent={() => null}
        contentProps={contentProps}
        {...(canSort ? { readonly: false, onUpdate: reorderList } : { readonly: true })}
      />

      {canAdd && (
        <Button textButton color="secondary" onClick={addItem} disabled={disabled}>
          + Add another page range
        </Button>
      )}
    </SortableListContainer>
  )
}
