import { DndExhibitPartitionData, UserExhibitProcessingStatus } from "exhibit-builder/store/types"
import {
  StyledSortableNestedListItem,
  StyledMedChronPartitionItem,
  StyledRecordPagesList,
  StyledRecordPages,
  StyledRecordPagesListContainer,
  StyledRecordPagesListHeader,
  UserExhibitLink,
} from "./styled"
import { SortableNestedListItemProps } from "common/nested-list/SortableNestedListItem"
import { useExhibitBuilderStore } from "exhibit-builder/store"
import { EB_DOC_TYPE } from "exhibit-builder/types"
import { PropsWithChildren, useCallback } from "react"
import { filesSelectors } from "exhibit-builder/store/files/filesSelectors"
import { useShallow } from "zustand/react/shallow"
import { useUserExhibitPreview } from "exhibit-builder/useUserExhibitPreview"

export const ExhibitPartitionItem = function ExhibitPartitionItem({
  children,
  ...props
}: PropsWithChildren<SortableNestedListItemProps<DndExhibitPartitionData>>) {
  const type = useExhibitBuilderStore(state => state.exhibitBuilderType)
  const records = props.item.children
  const { id: partitionId, userExhibitId } = props.item["ITEM_REF"]
  const userExhibit = useExhibitBuilderStore(useShallow(state => state.userExhibitMap[userExhibitId]))
  const previewUserExhibit = useUserExhibitPreview(userExhibitId)
  const recordIds = records.map(record => record["ITEM_REF"].id)
  const { partition, recordPagesMap } = useExhibitBuilderStore(
    useShallow(filesSelectors.getRecordUserExhibitPages({ recordIds, userExhibitId, partitionId }))
  )

  const onPreview = useCallback(
    (startPage: number) => {
      return () => {
        if (startPage) {
          previewUserExhibit(startPage)
        }
      }
    },
    [previewUserExhibit]
  )

  if (type === EB_DOC_TYPE.DEMAND) {
    return (
      <div data-test="exhibit-partition-item">
        <StyledSortableNestedListItem {...props} backgroundColor="white">
          {children}
        </StyledSortableNestedListItem>
      </div>
    )
  }

  if (type === EB_DOC_TYPE.MEDCHRON) {
    return (
      <div data-test="medchron-exhibit-partition-item">
        <StyledMedChronPartitionItem {...props}>
          {children}
          <StyledRecordPagesListContainer>
            <StyledRecordPagesListHeader variant="listSubHeader" fontWeight="bold">
              <div>Exhibit Pages</div>
              <div>
                (
                {userExhibit &&
                !userExhibit.exhibitPagesLoading &&
                userExhibit.processingStatus === UserExhibitProcessingStatus.COMPLETE
                  ? `${partition.userExhibitStartPage} - ${partition.userExhibitEndPage}`
                  : "--"}
                )
              </div>
            </StyledRecordPagesListHeader>
            <StyledRecordPagesList>
              {recordIds.map(recordId => {
                const startPage = recordPagesMap[recordId]?.startPage
                const endPage = recordPagesMap[recordId]?.endPage
                return (
                  <StyledRecordPages key={`exhibit-pages-${recordId}`}>
                    <UserExhibitLink variant="body2" fontWeight="bold" onClick={onPreview(startPage)}>
                      {userExhibit &&
                      (userExhibit.exhibitPagesLoading ||
                        userExhibit.processingStatus !== UserExhibitProcessingStatus.COMPLETE)
                        ? "--"
                        : startPage && endPage
                          ? `${startPage}-${endPage}`
                          : "Not Found"}
                    </UserExhibitLink>
                  </StyledRecordPages>
                )
              })}
            </StyledRecordPagesList>
          </StyledRecordPagesListContainer>
        </StyledMedChronPartitionItem>
      </div>
    )
  }
}
