import { useState } from "react"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"
import DragIndicator from "@mui/icons-material/DragIndicator"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { queryKeys } from "../../react-query/constants"
import { Draggable } from "react-beautiful-dnd"

import { ActionButton } from "app/styled"
import { deleteFutureExpense } from "../../api"
import { useFormContext } from "../context"
import { RichTextEditor } from "common/form-components/rich-text/RichTextEditor"
import { FutureMedicalItem, InnerLayout, GridLayout, InnerContent } from "./FutureExpenseStyled"
import { ActionButtonContainer } from "missing-docs/ActionButton"
import { amountInDollars } from "../../utils"
import { ConfirmDialog } from "common"

const useStyles = makeStyles()(theme => ({
  editButton: {
    marginRight: theme.spacing(1),
  },
  floatRight: {
    float: "right",
  },
}))

export function FutureExpenseView({ futureExpense, handleEdit, index, isExpanded, onExpandedClick, last }) {
  const { caseId } = useFormContext()
  const queryClient = useQueryClient()
  const { classes } = useStyles()

  const [isPendingDelete, setIsPendingDelete] = useState(false)

  const deleteMutation = useMutation(deleteFutureExpense, {
    onSuccess: () => queryClient.invalidateQueries([queryKeys.futureExpense, caseId]),
  })

  const deleteExpense = async () => {
    const pk = futureExpense.pk
    await deleteMutation.mutateAsync({ caseId, pk })
  }

  const handleOnDeleteClick = () => {
    setIsPendingDelete(true)
  }

  return (
    <>
      <Draggable key={futureExpense.pk} draggableId={String(futureExpense.pk)} index={index}>
        {draggableProvided => (
          <FutureMedicalItem
            key={futureExpense.pk}
            data-test="expense-row"
            last={last}
            {...draggableProvided.draggableProps}
            ref={draggableProvided.innerRef}
          >
            <GridLayout>
              <Box {...draggableProvided.dragHandleProps}>
                <DragIndicator />
              </Box>
              <Box>{futureExpense.procedure_name}</Box>
              <Box align="right">{amountInDollars(futureExpense.cost)}</Box>
              <Box align="right">{futureExpense.annual_frequency}</Box>
              <Box align="right">{futureExpense.number_of_years}</Box>
              <Box align="right">
                {amountInDollars(
                  futureExpense.cost * futureExpense.annual_frequency * futureExpense.number_of_years
                )}
              </Box>
              <Box>
                <ActionButtonContainer className={classes.floatRight}>
                  <ActionButton data-test="edit-expense" onClick={() => handleEdit(futureExpense.pk)}>
                    Edit
                  </ActionButton>
                  <ActionButton data-test="delete-expense" onClick={() => handleOnDeleteClick()}>
                    Delete
                  </ActionButton>
                </ActionButtonContainer>
              </Box>
              <IconButton
                sx={{ width: "40px", height: "40px", padding: "0px", marginLeft: "auto" }}
                onClick={onExpandedClick}
              >
                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </GridLayout>
            <Collapse in={isExpanded}>
              <InnerLayout>
                <InnerContent>
                  <Box fontWeight="bold" mb={1}>
                    Notes, external source links, etc
                  </Box>
                  {futureExpense.description ? (
                    <RichTextEditor value={futureExpense.description} readonly={true} />
                  ) : (
                    <Box>No additional notes.</Box>
                  )}
                </InnerContent>
              </InnerLayout>
            </Collapse>
          </FutureMedicalItem>
        )}
      </Draggable>
      <ConfirmDialog
        open={isPendingDelete}
        onClose={() => {
          setIsPendingDelete(false)
        }}
        onConfirm={async () => {
          await deleteExpense()
          setIsPendingDelete(false)
        }}
        title="Delete?"
        body="Are you sure you want to delete this future medical expense?"
        confirmText="Delete"
      />
    </>
  )
}
