import { BottomButtonGroup } from "../BottomButtonGroup"
import { UserExhibit } from "exhibit-builder/store/types"
import { exhibitService } from "api/services/exhibit"
import { useExhibitBuilderStore } from "exhibit-builder/store"
import { useShallow } from "zustand/react/shallow"
import { Box, Typography } from "@mui/material"
import { USER_EXHIBIT_FILE_TYPES } from "exhibit-builder/constants"
import { download, downloadExhibit } from "api"
import { ActionButton, useAction } from "exhibit-builder/ActionButton"
import { useCallback } from "react"
import { useHandleMessages } from "common/messages/useHandleMessages"
import * as Sentry from "@sentry/react"

export function Download({ id, onClose }: { id: UserExhibit["id"]; onClose: () => void }) {
  const userExhibit = useExhibitBuilderStore(useShallow(state => state.userExhibitMap[id]))
  const firstExhibit = useExhibitBuilderStore(
    useShallow(state => {
      const partitionId = state.exhibitPartitionOrder[userExhibit.id]?.[0]

      if (!partitionId) {
        return null
      }

      const exhibitId = state.exhibitPartitionMap[partitionId].fileId
      return state.files[exhibitId]
    })
  )
  const caseId = useExhibitBuilderStore(useShallow(state => state.caseId))
  const { showErrorMessage } = useHandleMessages()

  const handleDownload = useCallback(async () => {
    if (userExhibit.fileType !== USER_EXHIBIT_FILE_TYPES.PDF) {
      if (!firstExhibit) {
        Sentry.captureException("Unable to find exhibit file to download", {
          extra: { userExhibitId: id },
        })
        showErrorMessage({ message: "Unable to find exhibit file to download" })
        return
      }

      await downloadExhibit({
        caseId,
        exhibitId: firstExhibit.id,
        fileName: firstExhibit.name,
        downloadFile: true,
      })
    } else {
      const fileName = userExhibit.name.endsWith(".pdf") ? userExhibit.name : `${userExhibit.name}.pdf`
      const res = await exhibitService.getUserExhibit({ id })
      await download({ customUrl: res.url, json: false, blob: true }, fileName)
    }
  }, [caseId, firstExhibit, id, userExhibit, showErrorMessage])

  const { handleAction, isLoading } = useAction({
    name: "download exhibit",
    action: handleDownload,
  })

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="caption">This action will download a copy of this entire exhibit.</Typography>
      </Box>
      <BottomButtonGroup
        onClose={onClose}
        actionButton={<ActionButton onClick={handleAction} isLoading={isLoading} label="Download" />}
      />
    </Box>
  )
}
