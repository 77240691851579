import { forwardRef, useCallback } from "react"
import { CircularProgress } from "@mui/material"
import GetApp from "@mui/icons-material/GetApp"
import {
  downloadExhibits as downloadExhibitsAPI,
  downloadMedchronExhibits as downloadMedchronExhibitsAPI,
} from "api"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { useState } from "react"
import { StyledButton } from "./styled"
import { useExhibitBuilderStore } from "exhibit-builder/store"
import { EB_DOC_TYPE } from "exhibit-builder/types"

export const useDownloadAllExhibits = ({ caseId, documentId }: { caseId?: string; documentId?: string }) => {
  const [downloadingExhibits, setDownloadingExhibits] = useState(false)
  const exhibitBuilderType = useExhibitBuilderStore(state => state.exhibitBuilderType)
  const { showErrorMessage } = useHandleMessages()

  const handleDownloadExhibits = useCallback(async () => {
    setDownloadingExhibits(true)
    try {
      if (exhibitBuilderType === EB_DOC_TYPE.MEDCHRON) {
        await downloadMedchronExhibitsAPI({ documentId })
      } else {
        await downloadExhibitsAPI({ caseId })
      }
    } catch (error) {
      showErrorMessage({
        message: "Could not download exhibits. Try again shortly and file an issue if your problem persists.",
        error,
      })
    } finally {
      setDownloadingExhibits(false)
    }
  }, [caseId, showErrorMessage, exhibitBuilderType, documentId])

  return { handleDownloadExhibits, downloadingExhibits }
}

export const DownloadAll = forwardRef<HTMLButtonElement, { caseId: string }>(function DownloadAll(
  { caseId, ...props },
  ref
) {
  const { handleDownloadExhibits, downloadingExhibits } = useDownloadAllExhibits({ caseId })

  return (
    <StyledButton
      ref={ref}
      startIcon={downloadingExhibits ? <CircularProgress size={16} /> : <GetApp />}
      disableElevation
      onClick={handleDownloadExhibits}
      disabled={downloadingExhibits}
      {...props}
    >
      Download All
    </StyledButton>
  )
})
