import React, { PropsWithChildren, useCallback, useContext } from "react"
import { makeStyles } from "tss-react/mui"
import DragIndicator from "@mui/icons-material/DragIndicator"
import { useDraggable, useDroppable } from "@dnd-kit/core"
import { DROP_TARGET_TYPE } from "./constants"
import { VerticalCenterBox } from "../FlexBox"
import { ProjectionItemWithChildren } from "./types"
import { SortableContext } from "./SortableContext"

const useStyles = makeStyles()(theme => ({
  dropIndicator: {
    height: "0px",
    boxShadow: "0 0 0 1px rgba(0,0,0,0.03)",
    transform: `translateY(${theme.spacing(-1)})`,
    position: "relative",
  },
  activeDropIndicator: {
    zIndex: 100,
    boxShadow: `0 0 0 2px ${theme.palette.dragging.main}`,
  },
  draggableListItem: {
    opacity: 1,
    transition: "0.2s opacity ease-out",
  },
  dragging: {
    opacity: 0.2,
  },
  nestedListItem: {
    padding: theme.spacing(1, 3),
    margin: theme.spacing(2, 0),
    border: "1px solid lightgray",
    borderRadius: "2px",
    background: theme.palette.background.default,
  },
  nestedListItemContent: {
    "& > div > svg": {
      transition: `50ms color ease`,
    },
    "&:hover > div > svg": {
      color: theme.palette.grey["700"],
    },
  },
  dragHandle: {
    cursor: "grab",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    transition: "50ms background ease",
    "& > svg": {
      height: "1.5em",
    },
    "&:hover": {
      background: theme.palette.grey["200"],
    },
  },
  dragHandleDisabled: {
    cursor: "not-allowed",
    opacity: 0.5,
    "&, &:hover": {
      background: "none",
    },
  },
  innerContent: {
    flexGrow: 1,
  },
}))

interface DropIndicatorProps {
  isOver: boolean
  canDrop: boolean
  isDragging: boolean
}

export const DropIndicator = React.forwardRef<HTMLDivElement, DropIndicatorProps>(function DropIndicator(
  { isOver, isDragging, canDrop },
  ref
): Nullable<React.ReactElement> {
  const { classes, cx } = useStyles()

  if (isDragging || !canDrop) {
    return null
  }

  return (
    <div
      ref={ref}
      className={cx(classes.dropIndicator, isOver && classes.activeDropIndicator)}
      data-type="drop-indicator"
    />
  )
})

export interface SortableNestedListItemProps<TData> {
  item: ProjectionItemWithChildren<TData>
  isChild: boolean
  inline?: boolean

  content: React.ReactElement

  backgroundColor?: string
  className?: string

  readonly: boolean
}

export function SortableNestedListItem<TData>({
  item,
  children,
  className,
  content,
  backgroundColor,
}: PropsWithChildren<SortableNestedListItemProps<TData>>): JSX.Element {
  const { classes, cx } = useStyles()
  const { id } = item
  const draggable = useDraggable({ id })
  const sortableContext = useContext(SortableContext)

  const canDrag = sortableContext.canDrag(id)
  const canDrop = sortableContext.canDrop(id)
  const { readonly } = sortableContext

  const bottomDroppable = useDroppable({
    data: { type: DROP_TARGET_TYPE.AFTER, id },
    id: `droppable-${id}-bottom`,
    disabled: draggable.isDragging || !canDrop,
  })

  const topDroppable = useDroppable({
    data: { type: DROP_TARGET_TYPE.BEFORE, id },
    id: `droppable-${id}-top`,
    disabled: draggable.isDragging || !canDrop,
  })

  const selfDroppable = useDroppable({
    data: { type: DROP_TARGET_TYPE.SELF, id },
    id: `droppable-${id}-self`,
    disabled: !draggable.isDragging,
  })

  const setNodeRef = useCallback(
    (element: HTMLDivElement) => {
      selfDroppable.setNodeRef(element)
      draggable.setNodeRef(element)
    },
    [selfDroppable, draggable]
  )

  return (
    <div data-test="exhibits-list-item" className={className} data-type="list-item-root">
      {!readonly && (
        <DropIndicator
          isDragging={draggable.isDragging}
          canDrop={canDrop}
          isOver={topDroppable.isOver}
          ref={topDroppable.setNodeRef}
        />
      )}
      <div
        ref={setNodeRef}
        className={cx(classes.draggableListItem, draggable.isDragging && classes.dragging)}
        data-type="list-item-draggable"
      >
        <div
          className={classes.nestedListItem}
          style={{ backgroundColor: backgroundColor }}
          data-type="list-item"
        >
          <VerticalCenterBox
            sx={{ width: "100%" }}
            className={classes.nestedListItemContent}
            data-type="list-item-content"
          >
            {!readonly && (
              <>
                {canDrag ? (
                  <div
                    {...draggable.listeners}
                    {...draggable.attributes}
                    className={classes.dragHandle}
                    data-test="drag-indicator"
                    data-type="drag-indicator"
                  >
                    <DragIndicator />
                  </div>
                ) : (
                  <div
                    className={cx(classes.dragHandle, classes.dragHandleDisabled)}
                    data-test="drag-indicator"
                    data-type="drag-indicator"
                  >
                    <DragIndicator />
                  </div>
                )}
              </>
            )}

            <div className={classes.innerContent}>{content}</div>
          </VerticalCenterBox>
          <div data-type="list-item-children" data-is-empty={!item.children.length}>
            {children}
          </div>
        </div>
      </div>
      {!readonly && (
        <DropIndicator
          isDragging={draggable.isDragging}
          canDrop={canDrop}
          isOver={bottomDroppable.isOver}
          ref={bottomDroppable.setNodeRef}
        />
      )}
    </div>
  )
}
