import { PropsWithChildren, useEffect } from "react"
import { useDocumentContext } from "./context"
import { useQueryClient } from "@tanstack/react-query"
import { documentActions, resetDocumentData, setDocumentData } from "./store"
import { useSuspenseQuery } from "@suspensive/react-query"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { documentsService } from "api/services/documents"
import { exhibitBuilderService } from "exhibit-builder/api/service"
import { exhibitBuilderActions } from "exhibit-builder/store"
import { useSubscribe } from "message-broker/useSubscribe"
import { MESSAGE_TOPIC } from "message-broker/topics"
import { MedicalChronologyNeedsRegenerationPayload } from "message-broker/types"

export function DocumentData({ children }: PropsWithChildren): Nullable<JSX.Element> {
  const { documentId } = useDocumentContext()
  const queryClient = useQueryClient()

  const { data } = useSuspenseQuery(
    [queryKeys.documentData, documentId],
    () => documentsService.getDocumentEntities({ documentId }),
    { meta: SILENT_QUERY_PARAMS.meta }
  )

  const { data: medchronTile } = useSuspenseQuery(
    [queryKeys.documentMedchronTile, documentId],
    () => documentsService.getMedchronTile({ documentId }),
    {
      ...SILENT_QUERY_PARAMS,
      enabled: !!documentId,
    }
  )

  const tileUseExhibitBuilder = !!medchronTile?.useExhibitBuilder

  const { data: userExhibitData } = useSuspenseQuery(
    [queryKeys.documentStoreUserExhibitData, documentId],
    () => exhibitBuilderService.getExhibitBuilderData({ documentId }),
    {
      enabled: tileUseExhibitBuilder,
    }
  )

  useSubscribe(
    MESSAGE_TOPIC.MEDICAL_CHRONOLOGY_NEEDS_REGENERATION,
    async ({ data }) => {
      const payload = (data.payload || {}) as MedicalChronologyNeedsRegenerationPayload

      if (!documentId || payload.documentId !== documentId) return

      exhibitBuilderActions.toggleUpdateSummaries(payload.shouldUpdateSummaries)
    },
    { enabled: tileUseExhibitBuilder }
  )

  useEffect(() => {
    documentActions.setDocumentId(documentId)
  }, [documentId])

  useEffect(() => {
    setDocumentData(data)
  }, [data])

  useEffect(() => {
    if (!userExhibitData) return

    const {
      userExhibitMap,
      userExhibitOrder,
      exhibitPartitionMap: userExhibitPartitionMap,
      exhibitPartitionOrder: userExhibitPartitionOrder,
      updateCitationStatus,
    } = userExhibitData
    documentActions.setUserExhibitData({
      userExhibitMap,
      userExhibitOrder,
      userExhibitPartitionMap,
      userExhibitPartitionOrder,
      updateCitationStatus,
    })
  }, [userExhibitData])

  useEffect(() => {
    if (documentId) {
      return () => {
        resetDocumentData()
        requestAnimationFrame(() => {
          queryClient.removeQueries([queryKeys.documentData, documentId])
        })
      }
    }
  }, [queryClient, documentId])

  useEffect(() => {
    if (!tileUseExhibitBuilder) return

    exhibitBuilderActions.checkShouldRegenerateAppointments({ documentId })
  }, [tileUseExhibitBuilder, documentId])

  return <>{children}</>
}
