import { useCallback, useMemo, useContext, useState, useEffect, useRef, memo } from "react"
import Tooltip from "@mui/material/Tooltip"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import { makeStyles } from "tss-react/mui"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { SECTIONS } from "missing-docs/constants"
import { amountInDollars, reorderImmutable, dateDisplay } from "utils"
import DraggableFile from "common/form-components/files/DraggableFile"
import { AUTO_IMPORT, EXHIBIT, PARTITIONED_EXHIBIT, UPDATE_TYPES } from "demand/Providers/constants"
import MissingDocumentSection from "demand/MissingDocumentSection"
import { MEDICAL_FILE_OPTIONS } from "demand/constants"
import { LeftNavContext, useFormContext } from "demand/context"

import { getFilteredCodes } from "./utils"
import {
  Wrapper,
  UploadedFiles,
  NoItems,
  AppointmentsGrid,
  Label,
  Italic,
  CodeList,
  CodeChip,
  SectionHeading,
} from "./styled"
import { useProviderVariables } from "./useProviderVariables"
import { ReadOnlyCaseEditor } from "demand/components/CaseEditor"
import Appointments from "./Annotation/Appointments/Appointments"
import Bills from "./Annotation/Bills/Bills"
import IcdCodes from "./Annotation/IcdCodes/IcdCodes"
import InjuryDetails from "./Annotation/InjuryDetails/InjuryDetails"
import {
  getDateUpdatedByRecordType,
  getUpdatesByRecordType,
} from "demand/Providers/Provider/providerUpdatesUtils"
import SectionContainer from "./SectionContainer"
import { INJURY_DETAIL_RECORD_MAX_HEIGHT } from "./Annotation/InjuryDetails/styled"
import { SLATE_EDITOR_DATA_TYPE_ATTRIBUTE } from "common/form-components/rich-text/constants"
import { FixedSizeList } from "react-window"
import { BlackTooltip } from "common/Tooltip"
import { noop } from "lodash"
import { usePermissions } from "permissions/usePermissions"
import { ObjectiveTests } from "./ProviderForm/ObjectiveTests/ObjectiveTests"
import { InterventionalTreatments } from "./ProviderForm/InterventionalTreatments"
import { ReviewEditorProvider } from "review/ReviewEditor"
import { COMPONENTS_KEYS } from "documents/constants"
import { isComponentInDocumentStructure } from "documents/utils"
import { ProviderUserExhibits } from "exhibit-builder/ProviderUserExhibits"
import useCase from "hooks/useCase"
import { Summaries } from "./Summaries/Summaries"
import { useProviderSummariesStore } from "./Summaries/store"
import { getProvider } from "api"
import { ProvidersStoreContext } from "../store/context"
import { v4 } from "uuid"
import { PROVIDER_SUMMARY_STATUS } from "api/services/summaries/types"
import { UPDATE_INJURY_DETAILS } from "../store/reducer"

const useStyles = makeStyles()(theme => ({
  billsGrid: {
    display: "flex",
    gap: theme.spacing(1),
  },
  wordBreakAll: {
    flexGrow: 1,
    wordBreak: "break-word",
  },
  label: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}))

const BillRenderer = memo(function BillRenderer({ data: { bills, hasCollateralSourceRule }, index, style }) {
  const { classes } = useStyles()

  if (!bills) return null

  const bill = bills[index]

  const exhibitTitle = bill?.exhibit?.name || bill?.partition?.name || bill?.user_exhibit?.name

  return (
    <Box className={classes.billsGrid} style={style}>
      <BlackTooltip placement="bottom" title={bill.description}>
        <Box className={classes.label} width={200}>
          {bill.description}
        </Box>
      </BlackTooltip>
      <Box className={classes.label} width={90}>
        {amountInDollars(bill.billed_amount ?? 0)}
      </Box>
      {!hasCollateralSourceRule && (
        <Box className={classes.label} width={90}>
          {amountInDollars(bill.adjusted_amount ?? 0)}
        </Box>
      )}
      <BlackTooltip placement="bottom" title={exhibitTitle}>
        <Box className={classes.label} width={200}>
          {exhibitTitle}
        </Box>
      </BlackTooltip>
    </Box>
  )
})

const ProviderDetails = ({
  provider,
  onExhibitReorder,
  onExhibitDelete,
  onExhibitDownload,
  onPartitionDelete,
  onPartitionDownload,
  hasCollateralSourceRule,
  missingExhibits,
  onAppendExhibit,
  caseId,
  updates,
  openPDFViewerWindow,
  documentStructureContentComponents,
}) => {
  const { classes } = useStyles()
  const { leftNavTabIsExpanded } = useContext(LeftNavContext)
  const editorFormRef = useRef(null)
  const [maxHeight, setMaxHeight] = useState(INJURY_DETAIL_RECORD_MAX_HEIGHT)
  const { caseObj } = useCase(caseId)

  const { dispatch } = useContext(ProvidersStoreContext)

  const showObjectiveTests = isComponentInDocumentStructure(
    documentStructureContentComponents,
    COMPONENTS_KEYS.OBJECTIVE_TESTS_TABLE
  )
  const showInterventionalTreatment = isComponentInDocumentStructure(
    documentStructureContentComponents,
    COMPONENTS_KEYS.INTERVENTIONAL_TREATMENTS_TABLE
  )

  const { enableZeroClickSummariesEnabled } = usePermissions()

  const handleExhibitDelete = exhibit => () => {
    onExhibitDelete(exhibit)
  }
  const handleExhibitDownload = exhibit => () => {
    return onExhibitDownload(exhibit)
  }
  const handlePartitionDelete = partition => {
    return onPartitionDelete(partition)
  }
  const handlePartitionDownload = partition => () => {
    return onPartitionDownload(partition)
  }

  const getUpdatesDateUpdatedByRecordType = useCallback(
    recordType => getDateUpdatedByRecordType(updates, recordType),
    [updates]
  )

  const icdCodes = getFilteredCodes(provider?.icd_codes)
  const cptCodes = getFilteredCodes(provider?.cpt_codes)
  const variables = useProviderVariables(provider)
  const injuryDetailsUpdates = useMemo(() => {
    return getUpdatesByRecordType(updates, UPDATE_TYPES.INJURY_DETAILS)
  }, [updates])

  const hasPartitionProvider = !!provider.partition_provider
  const hasFiles = !!provider?.exhibits?.length

  const billRowData = useMemo(
    () => ({
      bills: provider.bills,
      hasCollateralSourceRule,
    }),
    [provider.bills, hasCollateralSourceRule]
  )

  useEffect(() => {
    if (editorFormRef.current) {
      const editoWrapper = editorFormRef.current.querySelector(
        `[data-type=${SLATE_EDITOR_DATA_TYPE_ATTRIBUTE}]`
      )

      if (editoWrapper) {
        setMaxHeight(editoWrapper.clientHeight)
      }
    }
  }, [provider.details_json])

  const numberOfAppointments = useMemo(() => {
    return (provider.is_one_day_appointment ? "1" : provider.visit_count) ?? "N/A"
  }, [provider])

  const defaultEditorKey = provider.templated_sections?.[0]?.template?.pk ?? "provider-injury-details"
  const [editorKey, setEditorKey] = useState(defaultEditorKey)
  const shouldUpdateInjuryDetails = useProviderSummariesStore(
    state =>
      state.medicalSummaries.size > 0 &&
      Array.from(state.medicalSummaries.values()).every(
        summary => !summary.stale && summary.status === PROVIDER_SUMMARY_STATUS.SUCCESS
      )
  )

  useEffect(() => {
    if (shouldUpdateInjuryDetails) {
      getProvider({ caseId, providerId: provider.pk }).then(({ details_json }) => {
        if (details_json) {
          dispatch({
            type: UPDATE_INJURY_DETAILS,
            payload: { providerId: provider.pk, value: details_json, customContent: details_json },
          })
          setEditorKey(v4())
        }
      })
    }
  }, [shouldUpdateInjuryDetails, caseId, provider.pk, dispatch])

  useEffect(() => {
    setEditorKey(v4())
  }, [defaultEditorKey])

  // REVIEW EDITOR DETAILS
  const { caseSectionReviewEnabled } = usePermissions({ suspense: false })
  const { currentPlaintiff } = useFormContext()
  const editor = (
    <ReadOnlyCaseEditor
      key={editorKey}
      readonly
      value={provider.details_json || null}
      variables={variables}
    />
  )

  return (
    <Wrapper>
      {hasFiles && (
        <DragDropContext
          onDragEnd={({ destination, source }) => {
            // dropped outside the list or moved to same position
            if (!destination || destination.index === source.index) return

            const newExhibits = reorderImmutable(provider.exhibits, source.index, destination.index)
            onExhibitReorder(newExhibits)
          }}
        >
          <Droppable droppableId={`provider-exhibits-view-${provider.pk}`}>
            {droppableProvided => (
              <UploadedFiles {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                {provider.exhibits.map((exhibit, index) => {
                  const isPartitionedExhibit = exhibit.exhibitType === PARTITIONED_EXHIBIT
                  return (
                    <DraggableFile
                      exhibit={exhibit}
                      index={index}
                      key={exhibit.pk}
                      id={exhibit.pk}
                      fileName={exhibit.name}
                      fileType={exhibit.type}
                      onDelete={
                        !isPartitionedExhibit
                          ? handleExhibitDelete(exhibit)
                          : () => {
                              handlePartitionDelete(exhibit)
                            }
                      }
                      onDownload={
                        !isPartitionedExhibit
                          ? handleExhibitDownload(exhibit)
                          : handlePartitionDownload(exhibit)
                      }
                      fileTypeMap={MEDICAL_FILE_OPTIONS}
                      editable={false}
                      tagLabel={isPartitionedExhibit ? AUTO_IMPORT : ""}
                      onClick={(exhibitId, exhibitType) => {
                        if (exhibitType === EXHIBIT) {
                          return openPDFViewerWindow({ exhibitId })
                        }
                        if (exhibitType === PARTITIONED_EXHIBIT) {
                          return openPDFViewerWindow({
                            exhibitId: exhibit?.exhibit_id,
                            partitionId: exhibit.pk,
                            page: exhibit?.start_page,
                          })
                        }
                      }}
                      highlightOnHover={!!exhibit.exhibitType}
                      exhibitType={exhibit.exhibitType}
                    />
                  )
                })}
              </UploadedFiles>
            )}
          </Droppable>
        </DragDropContext>
      )}

      {caseObj?.use_exhibit_builder && (
        <ProviderUserExhibits
          providerId={provider.partition_provider?.matter_provider_id}
          plaintiffId={provider.partition_provider?.matter_plaintiff_id}
        />
      )}
      {!caseObj?.use_exhibit_builder && !provider?.exhibits?.length && (
        <Box mb={6}>
          <NoItems variant="outlined">No attached files. Edit provider to add some.</NoItems>
        </Box>
      )}
      <Box mb={2}>
        <MissingDocumentSection
          missingDocs={missingExhibits}
          section={SECTIONS.PROVIDERS}
          title="Missing Documents List"
          providerId={provider.pk}
          canEditExistingProvider={false}
          onApproveCallback={data => {
            onAppendExhibit(data, provider.pk)
          }}
        />
      </Box>
      <Divider />
      <SectionContainer>
        <Box>
          <SectionHeading mb={2}>Appointments</SectionHeading>
          <AppointmentsGrid>
            <Label>Date of first appointment</Label>
            <Label>Date of last appointment</Label>
            <Label>No. of visits</Label>
            <Box>{provider.is_ongoing_appointment ? <Italic>Treatment is ongoing</Italic> : ""}</Box>
            <Box>{dateDisplay(provider.first_contact, "N/A")}</Box>
            <Box>{dateDisplay(provider.last_contact, "N/A")}</Box>
            <Box>{numberOfAppointments}</Box>
            <Box />
          </AppointmentsGrid>
        </Box>
        {hasPartitionProvider && (
          <Box>
            <Appointments
              providerId={provider.pk}
              caseId={caseId}
              currentFirstAppointment={provider.first_contact}
              currentLastAppointment={provider.last_contact}
              currentNumberOfVisits={provider.visit_count}
              dateUpdated={getUpdatesDateUpdatedByRecordType(UPDATE_TYPES.APPOINTMENTS)}
              onEntryClick={openPDFViewerWindow}
            />
          </Box>
        )}
      </SectionContainer>
      <SectionContainer>
        <Box>
          <SectionHeading mb={2}>Bills</SectionHeading>
          {!!provider?.bills && (
            <Box>
              <Box className={classes.billsGrid} mb={1}>
                <Label width={200}>Bill description</Label>
                <Label width={90}>Billed</Label>
                {!hasCollateralSourceRule && <Label width={90}>Adjusted</Label>}
                <Label width={200}>File</Label>
              </Box>

              <FixedSizeList
                height={provider.bills.length > 20 ? 515 : provider.bills.length * 25}
                itemCount={provider.bills.length}
                itemSize={25}
                itemData={billRowData}
              >
                {BillRenderer}
              </FixedSizeList>
            </Box>
          )}
          {!provider?.bills?.length && (
            <Box mt={1}>
              <NoItems variant="outlined">No bills. Edit provider to add some.</NoItems>
            </Box>
          )}
        </Box>
        {hasPartitionProvider && (
          <Box>
            <Bills
              providerId={provider.pk}
              caseId={caseId}
              currentBills={provider?.bills ?? []}
              dateUpdated={getUpdatesDateUpdatedByRecordType(UPDATE_TYPES.BILLS)}
              onEntryClick={openPDFViewerWindow}
            />
          </Box>
        )}
      </SectionContainer>
      <Divider />
      <SectionContainer>
        <Box>
          <SectionHeading mb={2}>Injury Details from Provider</SectionHeading>
          <Box mb={3}>
            <Label mb={1}>ICD code/injuries</Label>
            {!!icdCodes.length && (
              <CodeList>
                {icdCodes.map(icd_code => (
                  <Tooltip placement="top" arrow title={icd_code.description} key={icd_code.pk}>
                    <CodeChip label={`${icd_code.code} - ${icd_code.description}`} />
                  </Tooltip>
                ))}
              </CodeList>
            )}
            {!icdCodes.length && (
              <NoItems variant="outlined">No ICD codes added. Edit provider to add some.</NoItems>
            )}
          </Box>
          <Box mb={5}>
            <Label mb={1}>CPT code/treatments</Label>
            {!!cptCodes.length && (
              <CodeList>
                {cptCodes.map(cpt_code => (
                  <Tooltip placement="top" arrow title={cpt_code.description} key={cpt_code.pk}>
                    <CodeChip label={`${cpt_code.code} - ${cpt_code.description}`} />
                  </Tooltip>
                ))}
              </CodeList>
            )}
            {!cptCodes.length && (
              <NoItems variant="outlined">No CPT codes added. Edit provider to add some.</NoItems>
            )}
          </Box>
        </Box>
        {hasPartitionProvider && (
          <Box>
            <IcdCodes
              caseId={caseId}
              providerId={provider.pk}
              currentIcdCodes={icdCodes}
              dateUpdated={getUpdatesDateUpdatedByRecordType(UPDATE_TYPES.ICD_CODES)}
              onEntryClick={openPDFViewerWindow}
              dispatch={noop}
            />
          </Box>
        )}
      </SectionContainer>
      <SectionContainer>
        <Box
          className={classes.wordBreakAll}
          ref={editorFormRef}
          width={leftNavTabIsExpanded || !hasPartitionProvider ? "100%" : "50%"}
        >
          <Label mb={2}>Injury details</Label>
          {caseSectionReviewEnabled && currentPlaintiff ? (
            <ReviewEditorProvider
              templateType="provider"
              plaintiffId={currentPlaintiff.id}
              providerId={provider.pk}
              reviewRequestId={provider.current_review_run}
            >
              {editor}
            </ReviewEditorProvider>
          ) : (
            editor
          )}
        </Box>

        {hasPartitionProvider && enableZeroClickSummariesEnabled && (
          <Box width="30%" minWidth="470px">
            <Summaries readonly />
          </Box>
        )}

        {hasPartitionProvider && !enableZeroClickSummariesEnabled && (
          <Box width={leftNavTabIsExpanded ? "100%" : "50%"}>
            <InjuryDetails
              maxHeight={maxHeight}
              caseId={caseId}
              providerId={provider.pk}
              providerName={provider.name}
              onEntryClick={openPDFViewerWindow}
              updatedPassages={injuryDetailsUpdates}
              generatorKeys={provider.templated_sections?.[0]?.template?.possible_generator_keys}
            />
          </Box>
        )}
      </SectionContainer>

      {showObjectiveTests && (
        <Box width="100%" mb={4}>
          <SectionHeading mb={2}>Objective Tests</SectionHeading>
          <ObjectiveTests data={provider?.objective_tests ?? []} provider={provider} />
        </Box>
      )}

      {showInterventionalTreatment && (
        <Box width="100%">
          <SectionHeading mb={2}>Interventional Treatment</SectionHeading>
          <InterventionalTreatments
            interventionalTreatments={provider?.interventional_treatments ?? []}
            providerExhibits={provider.exhibits}
            providerExhibitPartitions={provider.exhibit_partitions}
          />
        </Box>
      )}
    </Wrapper>
  )
}

export default ProviderDetails
