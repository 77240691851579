import { useEffect, useState, useMemo, useCallback } from "react"

import { FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useSuspenseQuery } from "@suspensive/react-query"

import Box from "@mui/material/Box"
import Alert from "@mui/material/Alert"

import { DOCUMENT_TYPES } from "requests/enums"

import useUser from "hooks/useUser"
import { useFirm } from "hooks/useFirm"

import { FileUploaderProviderProps, withFileUploadProvider } from "common/file-uploader"
import { STALE_TIMEOUT, queryKeys } from "react-query/constants"
import { fetchRequest_DEPRECATED, getFirms } from "api"
import { CASE_TYPES, INITIAL_STATE_REQUIRED } from "../constants"
import { RequestContextProvider } from "../context"
import { getEditDisabledMessageByStatus } from "../utils"
import { canUserEditRequest } from "../permissions/requestAction"
import { STEP_CODES } from "./constants"
import { SinglePageRequestForm } from "./SinglePageRequestForm/SinglePageRequestForm"
import { FirmDto } from "settings/Firm/Firm"
import { useGetIdFromParams } from "./utils"
import { RequestFile } from "requests/types"
import { SinglePageRequestFormContainer } from "./SinglePageRequestForm/components/styled"
import { RequestViewDto } from "requests/ViewRequest/types"
import { useLocation, useNavigate } from "react-router-dom"
import { createCaseRequest, queryKeyFetchCase } from "cases/api/caseView"
import { useHandleMessages } from "common/messages/useHandleMessages"
import * as Sentry from "@sentry/browser"
import { Loading } from "common"
import { amplitudeApm } from "infrastructure/apm/amplitude"
import { CaseAnalyticEvent, CaseAnalyticsEventTypes } from "infrastructure/apm/events/caseEvents"

const requestFormUploadOptions: FileUploaderProviderProps = {
  viewProps: { uploadBoxHidden: true, uploadBoxPosition: "bottom-left" },
}

export const RequestForm = withFileUploadProvider(() => {
  const requestId = useGetIdFromParams("id")
  const { user } = useUser()
  const { firm } = useFirm(user.firmId)
  const location = useLocation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { showSuccessMessage, showErrorMessage } = useHandleMessages()

  const [isUploadingFiles, setIsUploadingFiles] = useState(false)

  // If the requestId is undefined, we are creating a new request
  const isNewRequest = requestId === undefined

  const initialStep = isNewRequest ? STEP_CODES.DOCUMENT_TYPE : STEP_CODES.CLIENT_INFORMATION
  const [activeStep, setActiveStep] = useState<STEP_CODES>(initialStep)

  // Note, try to avoid resetting this query key (e.g. on successful save)
  // because it causes requestData to change and re-renders the entire form
  const { data: requestData } = useSuspenseQuery(
    [queryKeys.requestEdit, requestId],
    fetchRequest_DEPRECATED,
    {
      enabled: !!requestId,
      staleTime: 0,
      cacheTime: 0, // forces react query to always serve fresh data
      refetchOnMount: true,
    }
  )

  const initialFirmId = user.isExternal ? user.firmId : ""
  const canEdit = useMemo(() => !requestData || canUserEditRequest(user, requestData), [user, requestData])
  const methods = useForm({
    defaultValues: requestId ? requestData : { ...INITIAL_STATE_REQUIRED, firm_id: initialFirmId },
    // This triggers form field validation on change, as side effect it will clear out "manual" errors (from API)
    // on every input change, since those errors are not part of the form validation rules
    mode: "onChange",
  })

  const { control, setValue, register: registerField } = methods

  const files = useWatch({ name: "files", control })
  const intakeStatus = useWatch({ name: "intake_status", control })
  const requestPk = useWatch({ name: "pk", control, defaultValue: requestData?.pk })

  const totalPages = useMemo(
    () =>
      files.reduce((total: number, file: RequestFile) => {
        return total + file.number_of_pages
      }, 0),
    [files]
  )

  const { data: firms } = useQuery([queryKeys.firms], async () => getFirms(), {
    staleTime: STALE_TIMEOUT.DEFAULT,
  })

  const firmsOptions = useMemo(
    () =>
      firms?.map((firm: FirmDto) => {
        return { key: firm.pk, display: firm.name }
      }),
    [firms]
  )

  const { mutate: handleCreateRequest, isLoading: isCreatingRequest } = useMutation(
    ({ casePk, documentType }: { casePk: string; documentType: DOCUMENT_TYPES }) =>
      createCaseRequest(casePk, documentType),
    {
      onSuccess: (response, { casePk: case_id }) => {
        const {
          pk: request_id,
          type: request_type,
          firm: { pk: firm_id, name: firm_name },
        } = response
        amplitudeApm.trackEvent(
          new CaseAnalyticEvent(CaseAnalyticsEventTypes.RequestedfromCaseCopilot, {
            firm_id,
            firm_name,
            request_id,
            request_type,
            case_id,
          })
        )

        // We need to invalidate the cached case data
        queryClient.invalidateQueries(queryKeyFetchCase(case_id))

        showSuccessMessage({
          message: "Request successfully created.",
          timeout: 5_000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        })

        navigate(`/requests/${request_id}/edit`, {
          replace: true,
        })
      },
      onError: error => {
        Sentry.captureException(error)
        showErrorMessage({
          message: "Error creating request.",
        })
      },
    }
  )

  const handleDocumentSelect = useCallback(
    (selectedDocument: DOCUMENT_TYPES) => {
      const searchParams = new URLSearchParams(location.search)
      const casePk = searchParams.get("case")

      if (casePk) {
        handleCreateRequest({ casePk, documentType: selectedDocument })
        return
      }

      setActiveStep(STEP_CODES.CLIENT_INFORMATION)

      setValue("type", selectedDocument, { shouldDirty: true })
      if ([DOCUMENT_TYPES.BASIC_PLUS, DOCUMENT_TYPES.SIMPLE].includes(selectedDocument)) {
        setValue("case_type", CASE_TYPES.motor_vehicle.key, { shouldDirty: true })
      }
    },
    [setValue, setActiveStep, location, handleCreateRequest]
  )

  const handleFormKeyDown = useCallback((event: React.KeyboardEvent<HTMLFormElement>) => {
    const target = event.target as HTMLFormElement
    if ((event.code === "Enter" || event.code === "NumpadEnter") && target.type !== "textarea") {
      event.preventDefault()
      event.currentTarget?.blur()
    }
  }, [])

  useEffect(() => {
    // register fields that are not part of the form or need to be set before the form is loaded
    // this avoid silent failure when using methods like resetField()
    registerField("pk")
    registerField("type")
    registerField("case_type")
    registerField("firm")
    registerField("intake_status")
    registerField("submitter")
    registerField("updated_at")
  }, [registerField])

  if (!canEdit) {
    return (
      <Box>
        <Alert severity="error">{getEditDisabledMessageByStatus(intakeStatus)}</Alert>
      </Box>
    )
  }

  const plaintiffsArrayProps = useFieldArray<RequestViewDto, "plaintiffs">({
    name: "plaintiffs",
    control,
  })

  return (
    <FormProvider {...methods}>
      <SinglePageRequestFormContainer>
        <RequestContextProvider
          value={{
            activeStep,
            setActiveStep,
            requestId: requestPk,
            matterId: requestData?.matter_id,
            firm,
            firmsOptions,
            isUploadingFiles,
            setIsUploadingFiles,
            totalPages,
            canEdit,
            plaintiffsArrayProps,
            handleDocumentSelect,
          }}
        >
          <form onKeyDown={handleFormKeyDown} data-test="request-form">
            <SinglePageRequestForm />
          </form>
        </RequestContextProvider>
      </SinglePageRequestFormContainer>
      <Loading show={isCreatingRequest} label="Creating Request..." />
    </FormProvider>
  )
}, requestFormUploadOptions)

export default RequestForm
