import { useCallback } from "react"
import { DropdownContainer, DropdownLabel, ExhibitProviderContainer, ExhibitProviderDisplay } from "./styled"
import { EditableSelect } from "evenup-ui/EditableSelect"
import CircleIcon from "@mui/icons-material/Circle"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { useShallow } from "zustand/react/shallow"
import { filesSelectors } from "exhibit-builder/store/files/filesSelectors"
import { UserExhibit } from "exhibit-builder/store/types"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { useProviders } from "exhibit-builder/hooks/useDeleteProvider"

export function ExhibitProviderDropdown({ id, label }: { id: UserExhibit["id"]; label: string }) {
  const userExhibit = useExhibitBuilderStore(filesSelectors.getUserExhibitById(id))
  const isReadOnly = useExhibitBuilderStore(useShallow(state => state.isReadOnly))
  const { showInfoMessage } = useHandleMessages()
  const sortingProvider = useExhibitBuilderStore(
    useShallow(state => (userExhibit.sortingProviderId ? state.providers[userExhibit.sortingProviderId] : ""))
  )
  const handleProviderCreate = useCallback((name: string) => exhibitBuilderActions.createProvider(name), [])

  const { deleteProvider, canDeleteProvider, providers } = useProviders()

  const handleProviderRename = useCallback(
    (providerId: string, name: string) => exhibitBuilderActions.renameProvider({ providerId, name }),
    []
  )

  const handleSortingProviderChange = useCallback(
    async (providerId: string | null) => {
      const { updatedRecordsAndBillsMap, oldProviderName, newProviderName } =
        await exhibitBuilderActions.updateSortingProvider(id, providerId)

      if (providerId) {
        const userExhibitsByRecords = filesSelectors.getUserExhibitsByRecords(
          Object.keys(updatedRecordsAndBillsMap)
        )(useExhibitBuilderStore.getState())

        if (userExhibitsByRecords.size > 1) {
          const exhibitNumbers = Array.from(userExhibitsByRecords)
            .map(userExhibit => `#${userExhibit.index + 1}`)
            .join(", ")
          showInfoMessage(
            `Exhibit ${exhibitNumbers}:  Import providers "${oldProviderName}" were updated with "${newProviderName}"`
          )
        }
      }
    },
    [id, showInfoMessage]
  )

  return (
    <DropdownContainer data-test="exhibit-provider">
      <DropdownLabel>{label} Provider:</DropdownLabel>
      <ExhibitProviderContainer>
        <EditableSelect
          readOnly={isReadOnly}
          sx={{ width: "100%" }}
          value={userExhibit.sortingProviderId ?? ""}
          options={providers}
          onChange={handleSortingProviderChange}
          onCreate={handleProviderCreate}
          onRename={handleProviderRename}
          onDelete={deleteProvider}
          canDelete={canDeleteProvider}
          emptyValueLabel="Unassigned"
          startAdornment={
            sortingProvider ? (
              <ExhibitProviderDisplay>
                <CircleIcon htmlColor={sortingProvider?.color} fontSize="small" />
                {sortingProvider?.name}
              </ExhibitProviderDisplay>
            ) : (
              "Unassigned"
            )
          }
        />
      </ExhibitProviderContainer>
    </DropdownContainer>
  )
}
