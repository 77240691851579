import { Button, CircularProgress } from "@mui/material"
import { useAction } from "exhibit-builder/ActionButton"
import { exhibitBuilderActions } from "exhibit-builder/store"
import { UserExhibit } from "exhibit-builder/store/types"

export function DeleteUserExhibitButton({ id }: { id: UserExhibit["id"] }) {
  const { handleAction, isLoading } = useAction({
    name: "Delete User Exhibit",
    action: () => exhibitBuilderActions.deleteUserExhibit(id),
  })

  return (
    <Button onClick={handleAction} disabled={isLoading} data-test="delete-user-exhibit-button">
      Delete {isLoading && <CircularProgress size={20} color="secondary" />}
    </Button>
  )
}
