import { useCallback, useMemo } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import { providerTemplateService } from "api/services/provider-template"
import Autocomplete, { AutocompleteRenderInputParams } from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import { queryClient } from "react-query/queryClient"
import { SILENT_QUERY_PARAMS, queryKeys } from "react-query/constants"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { caseService } from "api/services/case"
import { Provider } from "demand/Providers/types"
import { ProviderTemplateServiceDeserializer } from "api/services/provider-template/serializers"
import { ProviderTemplateDefinition } from "common/types/providerTemplates"

interface ProviderTemplateSelectorProps {
  disabled: boolean
  provider: Provider
  caseId: PrimaryKey
}

export function ProviderTemplateSelector({
  disabled,
  provider,
  caseId,
}: ProviderTemplateSelectorProps): Nullable<JSX.Element> {
  const { showErrorMessage } = useHandleMessages()
  const { isFetching, data } = useQuery(
    [queryKeys.providerTemplates, 1, 999999],
    () =>
      providerTemplateService.getProviderTemplatesList({
        page: 1,
        pageSize: 999999,
        orderBy: "template_name",
      }),
    SILENT_QUERY_PARAMS
  )
  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField {...params} label="Provider template type" variant="outlined" />
    ),
    []
  )

  const getOptionLabel = useCallback(
    (option: Nullable<ProviderTemplateDefinition>): string =>
      (option as any).template_name || option?.templateName || "", // eslint-disable-line @typescript-eslint/no-explicit-any

    []
  )

  const isOptionEqualToValue = useCallback(
    (option: Nullable<ProviderTemplateDefinition>, value: Nullable<ProviderTemplateDefinition>) => {
      if (option === null || value === null) {
        return option === value
      }

      if (!provider.templated_sections?.[0]?.template) {
        return false
      }

      return option.id === provider.templated_sections[0].template?.pk
    },
    [provider.templated_sections]
  )

  const { mutate: selectTemplate } = useMutation(caseService.selectCaseProviderTemplate, {
    mutationKey: [queryKeys.providers, caseId, provider.pk, "select_template"],
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.providers, caseId, provider.pk])
      queryClient.invalidateQueries([queryKeys.steps, caseId])
    },
    onError: error => {
      showErrorMessage({ message: "There was an error while trying to update provider.", error })
    },
  })

  const { mutate: createTemplate } = useMutation(caseService.createCaseProviderTemplate, {
    mutationKey: [queryKeys.providers, caseId, provider.pk, "create_template"],
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.providers, caseId, provider.pk])
      queryClient.invalidateQueries([queryKeys.steps, caseId])
    },
    onError: error => {
      showErrorMessage({ message: "There was an error while trying to update provider.", error })
    },
  })

  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_: any, selectedValue: Nullable<ProviderTemplateDefinition>) => {
      const selectedTemplateId: Nullable<PrimaryKey> = selectedValue ? selectedValue.id : null
      if (!provider.templated_sections?.[0]) {
        createTemplate({
          caseId,
          providerId: provider.pk,
          data: { templateId: selectedTemplateId },
        })
        return
      }

      if (selectedTemplateId === provider.templated_sections[0].template?.pk) return

      selectTemplate({
        caseId,
        providerId: provider.pk,
        templateSectionId: provider.templated_sections[0].pk,
        data: { templateId: selectedTemplateId },
      })
    },
    [caseId, provider.pk, selectTemplate, provider.templated_sections, createTemplate]
  )

  const value = useMemo(() => {
    if (provider?.templated_sections?.[0]?.template) {
      return ProviderTemplateServiceDeserializer.definitionFromJSON(provider.templated_sections[0].template)
    }

    return null
  }, [provider])

  return (
    <Autocomplete<ProviderTemplateDefinition | null>
      value={value}
      disabled={disabled}
      loading={isFetching}
      options={data?.items || []}
      blurOnSelect
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={handleChange}
      getOptionLabel={getOptionLabel}
      style={{ width: 300 }}
      renderInput={renderInput}
    />
  )
}
