import { useDocumentStore } from "documents/store"
import { partitionsSelectors } from "documents/store/partitions"
import { userExhibitPartitionsSelectors } from "documents/store/userExhibitPartitions"
import { FlagRelationComponent, FlagRelationUserExhibitComponent } from "./components"
import { FlagRelationProps } from "./types"

export const FlagRelation = ({ relation, isUserExhibit }: FlagRelationProps) => {
  const userExhibitPartition = useDocumentStore(
    userExhibitPartitionsSelectors.getUserExhibitPartitionById(String(relation.partitionId))
  )
  const partition = useDocumentStore(partitionsSelectors.getPartitionById(relation.partitionId))

  if (isUserExhibit) {
    if (!userExhibitPartition) return null

    return <FlagRelationUserExhibitComponent relation={relation} partition={userExhibitPartition} />
  } else {
    if (!partition) return null

    return <FlagRelationComponent relation={relation} partition={partition} />
  }
}
