import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { Button, IconButton, toolbarClasses, MenuItem, Typography, Box } from "@mui/material"
import { DynamicColorChip } from "common/DynamicColorChip"

interface StyledProviderSummaryTableHeaderCellProps {
  width?: number
}

interface StyledAppointmentDateProps {
  readOnly?: boolean
}

interface StyledEditorWrapperProps {
  editMode?: boolean
  readOnly?: boolean
  openAiSummaryBox?: boolean
}

const WIDTH_PROVIDER = 200
const WIDTH_DATE = 150

const gridLayout = css({
  display: "grid",
  gridTemplateColumns: `${WIDTH_PROVIDER + WIDTH_DATE}px 1fr`,
  placeItems: "start",
})

const headerGridLayout = css({
  display: "grid",
  gridTemplateColumns: `${WIDTH_PROVIDER}px ${WIDTH_DATE}px 1fr`,
  placeItems: "start",
})

export const StyledHeaderTableRow = styled("div")(gridLayout, () => ({
  background: "rgba(33, 150, 243, 0.04)",
  display: "grid",
  gridTemplateColumns: `${WIDTH_PROVIDER}px 1fr`,

  "&:not(:first-of-type)": {
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },
}))

export const StyledHeaderButtonsWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "20px",
  height: "50px",
  overflow: "hidden",
}))

export const StyledTreatmentInfoWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  gap: theme.spacing(0.5),
}))

export const StyledProviderDateFieldWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
  flexDirection: "row",
  justifyContent: "flex-start",
  gap: theme.spacing(0.5),
}))

export const StyledEditorMedicalSummaryWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",

  "& > [data-type='slate-editor-wrapper']": {
    width: "100%",
  },
}))

export const StyledProviderDateWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  gap: theme.spacing(0.25),
}))

export const StyledSectionName = styled("div")(({ theme }) => ({
  fontSize: theme.spacing(2),
  fontWeight: 700,
  width: theme.spacing(19),
}))

export const StyledProviderSummaryTable = styled("div")(({ theme }) => ({
  width: "100%",
  borderRadius: `0 0 ${theme.spacing(0.5)} ${theme.spacing(0.5)}`,
  border: `1px solid #d8d8d8`,
}))

export const StyledProviderSummaryTableHead = styled("div")(headerGridLayout, ({ theme }) => ({
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "22px",
  color: "#636C79",
  border: `1px solid #d8d8d8`,
  borderRadius: `${theme.spacing(0.5)} ${theme.spacing(0.5)} 0 0`,
  borderBottom: "none",
}))

export const StyledAppointmentWrapper = styled("div")(gridLayout, ({ theme }) => ({
  background: "#ffffff00",
  transition: theme.transitions.create(["background"], {
    easing: theme.transitions.easing.easeIn,
    duration: theme.transitions.duration.short,
  }),

  "&[data-active='true']": {
    background: "#edf7ff",
  },
}))

export const StyledProviderDateCellWrapper = styled("div")(() => ({
  width: `${WIDTH_PROVIDER + WIDTH_DATE}px`,
}))

export const StyledProviderSummaryTableHeaderCell = styled("div")<StyledProviderSummaryTableHeaderCellProps>(
  ({ width, theme }) => ({
    padding: theme.spacing(1.5, 0),
    ":first-of-type": {
      width: width ? `${width + 24}px` : "auto",
      paddingLeft: theme.spacing(3),
    },
  })
)

export const StyledAppointmentCell = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
  padding: "12px 0",
  ":first-of-type": {
    paddingLeft: theme.spacing(1.5),
  },
  ":last-of-type": {
    paddingRight: theme.spacing(6.5),
  },
}))

export const StyledAppointmentProviderCell = styled(StyledAppointmentCell)(({ theme }) => ({
  display: "inline-block",
  width: "100%",
  marginTop: theme.spacing(2),
}))

export const StyledAppointmentDateCell = styled("div")(() => ({
  display: "inline-block",
}))

export const StyledReviewActionCellWrapper = styled(StyledAppointmentCell)(() => ({
  paddingTop: "0",
}))

export const StyledReviewActionCell = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(2),
}))

export const StyledReviewActionMenuItem = styled(MenuItem)<{ width?: string }>(({ width = "220px" }) => ({
  width,
  maxWidth: "100%",
}))

export const StyledTagList = styled("ul")(({ theme }) => ({
  padding: "0",
  listStyleType: "none",
  color: theme.palette.disabled.main,
}))

export const StyledTagItem = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(0.5),
}))

export const StyledDeleteButton = styled(Button)(({ theme }) => ({
  display: "flex",
  position: "relative",
  marginTop: theme.spacing(1),
  width: "fit-content",
}))
StyledDeleteButton.defaultProps = {
  color: "error",
  size: "small",
}

export const StyledDeleteTimeButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(-4),
  bottom: 0,
}))

export const TimeWrapper = styled("div")(({ theme }) => ({
  cursor: "pointer",
  gap: theme.spacing(0.5),
  color: "rgba(0, 0, 0, 0.38)",
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(0.5),
  position: "relative",
  top: theme.spacing(-0.5),
  left: theme.spacing(-1),

  ":hover": {
    background: "#091E420F",
  },
}))

export const StyledAppointmentDate = styled("div")<StyledAppointmentDateProps>(({ theme, readOnly }) => ({
  display: "flex",
  cursor: readOnly ? "default" : "pointer",
  gap: theme.spacing(0.5),
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(0.5, 1),
  position: "relative",
  marginRight: "auto",
  top: theme.spacing(-0.5),
  left: theme.spacing(-1),

  ":hover": {
    background: readOnly ? "" : "#091E420F",
  },
}))

export const StyledEditorWrapper = styled("div")<StyledEditorWrapperProps>(
  ({ theme, editMode, readOnly, openAiSummaryBox }) => ({
    border: editMode ? `1px solid ${theme.palette.common.black}` : "none",
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    marginBottom: "-2px",
    cursor: !editMode ? "text" : "auto",

    minWidth: openAiSummaryBox ? "60%" : "auto",
    maxWidth: openAiSummaryBox ? "100%" : "auto",

    flexGrow: "1",
    right: openAiSummaryBox ? "35%" : "auto",
    position: openAiSummaryBox ? "relative" : "static",
    display: openAiSummaryBox ? "flex" : "block",
    flexDirection: openAiSummaryBox ? "column" : "row",
    ":hover": {
      background: editMode || readOnly ? "none" : "#091E420F",
    },

    "& > [data-type='slate-editor-wrapper']": {
      border: "none",
    },

    "& [data-type='slate-editor']": {
      padding: "0px",
    },

    "& [data-type='slate-editor-toolbar']": {
      borderBottom: "none",
    },

    [`& .${toolbarClasses.root}`]: {
      marginTop: theme.spacing(-1),
    },
  })
)

export const StyledAiSummaryWrapper = styled("div")<StyledEditorWrapperProps>(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRight: "0px",
  padding: theme.spacing(2, 2),
  backgroundColor: "#f5f5f5",
  marginBottom: "50px",
  right: "35%",
  top: "50px",
  position: "relative",
  minWidth: "60%",
  borderTopLeftRadius: "0",
}))

export const StyledAppointmentProviderName = styled(DynamicColorChip)(({ theme, color }) => ({
  position: "relative",
  maxWidth: `calc(100% - ${theme.spacing(4)})`,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  "&:hover": {
    backgroundColor: color,
  },
}))

export const ActionsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  whiteSpace: "nowrap",
  alignItems: "center",
}))
