import * as Sentry from "@sentry/react"
import { EB_DOC_TYPE } from "exhibit-builder/types"
import { SetState } from "./types"
import { exhibitBuilderService } from "exhibit-builder/api/service"

export type DocumentSlice = {
  isReadOnly: boolean
  caseId: string
  exhibitBuilderType: EB_DOC_TYPE | null
  document: {
    loading: boolean
    error: string | null
    documentId: string
    isReordering: boolean
  }
  scrollToIndex: number | null
  shouldUpdateSummaries: boolean
}

export const documentSlice: DocumentSlice = {
  isReadOnly: false,
  caseId: "",
  exhibitBuilderType: null,
  document: {
    loading: false,
    error: null,
    documentId: "",
    isReordering: false,
  },
  scrollToIndex: null,
  shouldUpdateSummaries: false,
}

export const documentSelectors = {
  getDemandLoading: (state: DocumentSlice) => state.document.loading,
}

export const documentActions = (set: SetState<DocumentSlice>) => {
  const setIsLoading = (loading: boolean) => {
    set(({ document }) => ({ document: { ...document, loading } }))
  }
  const setDocumentId = (documentId: string) =>
    set(({ document }) => ({ document: { ...document, documentId } }))

  const setIsReordering = (isReordering: boolean) => {
    set(({ document }) => ({ document: { ...document, isReordering } }))
  }

  const setCaseId = (caseId: string) => set({ caseId })

  const setExhibitBuilderType = (exhibitBuilderType: EB_DOC_TYPE | null) => set({ exhibitBuilderType })

  const setReadOnly = (isReadOnly: boolean) => set({ isReadOnly })

  const setScrollToIndex = (scrollToIndex: number | null) => set({ scrollToIndex })

  const toggleUpdateSummaries = (shouldUpdateSummaries: boolean) => set({ shouldUpdateSummaries })

  const checkShouldRegenerateAppointments = ({ documentId }: { documentId: string }) => {
    exhibitBuilderService
      .getShouldRegenerateAppointments({ documentId })
      .then(({ shouldRegenerateAppointments }: { shouldRegenerateAppointments: boolean }) => {
        toggleUpdateSummaries(shouldRegenerateAppointments)
      })
      .catch(error => {
        Sentry.captureMessage("Failed to check if appointments should be regenerated", {
          extra: { documentId, error },
        })
      })
  }

  return {
    setCaseId,
    setIsLoading,
    setDocumentId,
    setIsReordering,
    setExhibitBuilderType,
    setReadOnly,
    setScrollToIndex,
    checkShouldRegenerateAppointments,
    toggleUpdateSummaries,
  }
}
