import { FindInPage } from "@mui/icons-material"
import { SvgIconOwnProps, IconButton } from "@mui/material"
import { openWindow } from "common/windows"
import { useUserExhibitPreview } from "exhibit-builder/useUserExhibitPreview"
import { MESSAGE_TOPIC } from "message-broker/topics"
import { PreviewExhibitPayload } from "message-broker/types"
import { usePublish } from "message-broker/usePublish"
import { getExhibitPreviewPath } from "pdf-preview/utils"
import { useCallback } from "react"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(() => ({
  citationButton: {
    padding: "0",
  },
}))

export const CitationButton = ({
  caseId,
  exhibitId,
  partitionId,
  page,
  userExhibitId,
  sx,
}: PreviewExhibitPayload & Pick<SvgIconOwnProps, "sx">) => {
  const { classes } = useStyles()

  const publish = usePublish()
  const previewUserExhibit = useUserExhibitPreview(userExhibitId || "")

  const handleOpenPreview = useCallback(() => {
    const payload: PreviewExhibitPayload = {
      caseId: String(caseId),
      exhibitId,
      partitionId,
      page,
    }
    publish(MESSAGE_TOPIC.PREVIEW_EXHIBIT, payload).catch(() => {
      const path = getExhibitPreviewPath(payload)
      if (path) {
        openWindow(`${path.base}/${path.route}`)
      }
    })
  }, [publish, caseId, exhibitId, partitionId, page])

  const handlePreview = useCallback(() => {
    userExhibitId ? previewUserExhibit(page) : handleOpenPreview()
  }, [userExhibitId, page, previewUserExhibit, handleOpenPreview])

  return (
    <IconButton className={classes.citationButton} onClick={handlePreview} sx={sx}>
      <FindInPage />
    </IconButton>
  )
}
