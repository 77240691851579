import { USER_EXHIBIT_TAG } from "exhibit-builder/constants"
import { EB_DOC_TYPE } from "exhibit-builder/types"
import { UserExhibit } from "./types"

export function isPlaceholderUserExhibit(userExhibit: UserExhibit) {
  return userExhibit.tag === USER_EXHIBIT_TAG.PLACEHOLDER
}

export function isMedChronType(type: EB_DOC_TYPE | null) {
  return type === EB_DOC_TYPE.MEDCHRON
}
