import { useCallback, useContext, useRef } from "react"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Close from "@mui/icons-material/Close"
import { makeStyles } from "tss-react/mui"
import { SECTIONS } from "missing-docs/constants"
import MissingDocumentSection from "demand/MissingDocumentSection"
import * as actionTypes from "demand/Providers/store/reducer"
import { validateFile } from "demand/validation"
import { UPDATE_PROVIDER_NAME } from "demand/Providers/store/reducer"
import { useHandleMessages } from "common/messages/useHandleMessages"
import { getErrorMessageFromJSONStr } from "common/utils"
import { useFormContext } from "demand/context"
import { EXHIBIT, PARTITIONED_EXHIBIT, UPDATE_TYPES } from "demand/Providers/constants"
import {
  getDateUpdatedByRecordType,
  getUpdatesByRecordType,
} from "demand/Providers/Provider/providerUpdatesUtils"
import { queryClient } from "react-query/queryClient"
import { queryKeys } from "react-query/constants"
import { BillsSection } from "./BillsSection/BillsSection"
import { InjuryDetailsSection } from "./InjuryDetailsSection/InjuryDetailsSection"
import { AppointmentSection } from "./AppointmentSection"
import { FilesSection } from "./FilesSection"
import { updateImmutable } from "../utils"
import { ProviderTemplateSelector } from "./ProviderTemplateSelector"
import { StyledInputWrapper } from "./styled"
import { getInitialExhibitsMap } from "./utils"
import { ObjectiveTestsSection } from "./ObjectiveTests/ObjectiveTestsSection"
import { InterventionalTreatmentsSection } from "./InterventionalTreatments"
import { COMPONENTS_KEYS } from "documents/constants"
import { isComponentInDocumentStructure } from "documents/utils"
import { ProviderUserExhibits } from "exhibit-builder/ProviderUserExhibits"
import useCase from "hooks/useCase"
import { ProvidersStoreContext } from "demand/Providers/store/context"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { Tooltip } from "@mui/material"

const useStyles = makeStyles()(theme => ({
  providerOpenTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 3, 0, 6),
    fontSize: "18px",
    fontWeight: "bold",
    letterSpacing: "-.36px",
    color: theme.palette.getContrastText(theme.palette.blue.main),
    backgroundColor: theme.palette.blue.main,
  },
  providerNameInput: {
    maxWidth: "540px",
    flexGrow: 1,
  },
  rightButtonGroup: {
    display: "flex",
    gap: theme.spacing(2),
  },
  newLine: {
    whiteSpace: "pre-wrap",
  },
  deleteButton: {
    backgroundColor: theme.palette.red.warning,
    "&:hover": {
      backgroundColor: theme.palette.red.warningHover,
    },
  },
}))

export const ProviderForm = ({
  provider,
  validationErrors,
  onChange,
  onDelete,
  onCancel,
  onBlur,
  onSave,
  hasCollateralSourceRule,
  missingExhibits,
  onAppendExhibit,
  onExhibitReorder,
  onExhibitDelete,
  onExhibitDownload,
  onPartitionDownload,
  onPartitionDelete,
  uploadExhibit,
  disabled,
  updateExhibitMutation,
  updatePartitionMutation,
  openPDFViewerWindow,
  updates,
  documentStructureContentComponents,
  isEmpty,
}) => {
  const { caseId } = useFormContext()
  const { dispatch } = useContext(ProvidersStoreContext)
  const { showMessage } = useHandleMessages()
  const { classes } = useStyles()
  const exhibitsBeforeChanging = useRef(getInitialExhibitsMap(provider.exhibits))
  const { caseObj } = useCase(caseId)

  const showObjectiveTests = isComponentInDocumentStructure(
    documentStructureContentComponents,
    COMPONENTS_KEYS.OBJECTIVE_TESTS_TABLE
  )
  const showInterventionalTreatment = isComponentInDocumentStructure(
    documentStructureContentComponents,
    COMPONENTS_KEYS.INTERVENTIONAL_TREATMENTS_TABLE
  )

  const handleChangeName = event => {
    dispatch({
      type: UPDATE_PROVIDER_NAME,
      payload: { providerId: provider.pk, name: event.target.value },
    })
  }

  const handleFilesToUploadChange = newFiles => {
    dispatch({
      type: actionTypes.UPDATE_FILES_TO_UPLOAD,
      payload: { id: provider.pk, filesToUpload: newFiles },
    })
  }

  const handleDrop = files => {
    dispatch({ type: actionTypes.ADD_FILES_TO_UPLOAD, payload: { id: provider.pk, files } })
  }

  const handleFileCancel = fileFormId => {
    if (
      provider.bills?.some(bill => bill.file_to_upload_id === fileFormId) &&
      !confirm(
        "One or more bills have this file attached. If you delete it you will need to choose a new file for those bills. Would you like to delete this file?"
      )
    ) {
      return
    }

    dispatch({
      type: actionTypes.DELETE_FILE_TO_UPLOAD,
      payload: { id: provider.pk, fileFormId },
    })
  }

  function NewlineText(props) {
    const text = props.text
    return <div className={`${classes.newLine}`}>{text}</div>
  }

  const handleFileUpload = async (file, index) => {
    const fileValidation = validateFile(file)

    if (fileValidation) {
      dispatch({
        type: actionTypes.SET_FILE_VALIDATION,
        payload: { validationError: fileValidation, index, providerPk: provider.pk },
      })

      return
    }

    dispatch({
      type: actionTypes.FILE_UPLOAD_START,
      payload: { formId: file.formId, providerPk: provider.pk },
    })

    let exhibit = null

    try {
      exhibit = await uploadExhibit({ file, providerId: provider.pk })
    } catch (error) {
      dispatch({
        type: actionTypes.FILE_UPLOAD_ERROR,
        payload: { formId: file.formId, providerPk: provider.pk, error },
      })
      const reason = getErrorMessageFromJSONStr(error ? error.message : null)
      const message = reason ? <NewlineText text={reason} /> : `Error uploading file ${file.name}`
      showMessage({ type: "error", message, error })

      return
    }

    dispatch({
      type: actionTypes.FILE_UPLOAD_SUCCESS,
      payload: { caseId, formId: file.formId, providerPk: provider.pk, exhibit },
    })
  }

  const handleExhibitsChange = newExhibits => {
    dispatch({
      type: actionTypes.UPDATE_EXHIBITS,
      payload: { caseId, providerPk: provider.pk, exhibits: newExhibits },
    })
  }

  const handleExhibitSave = async (exhibit, index) => {
    // fileToUpload and exhibit share the same name + type requirements
    const validationError = validateFile(exhibit)

    if (validationError) {
      dispatch({
        type: actionTypes.EXHIBIT_SAVE_ERROR,
        payload: { validationError, index, providerPk: provider.pk },
      })
      return
    }

    dispatch({
      type: actionTypes.EXHIBIT_SAVE_START,
      payload: { providerPk: provider.pk, exhibitPk: exhibit.pk },
    })

    let savedExhibit

    try {
      savedExhibit = await updateExhibitMutation.mutateAsync({
        caseId,
        exhibitId: exhibit.pk,
        data: {
          case_id: caseId,
          pk: exhibit.pk,
          name: exhibit.name,
          type: exhibit.type,
        },
      })
    } catch (error) {
      // no possible validation errors since exhibit name is validated by front end and type is a select with no blank options
      dispatch({
        type: actionTypes.EXHIBIT_SAVE_ERROR,
        payload: { validationError: null, index, providerPk: provider.pk },
      })

      showMessage({
        type: "error",
        message:
          "Error updating exhibit. Please try again shortly and if your problem persists contact a dev.",
        error,
      })

      return
    }

    dispatch({
      type: actionTypes.EXHIBIT_SAVE_SUCCESS,
      payload: {
        caseId,
        providerPk: provider.pk,
        exhibitPk: exhibit.pk,
        exhibit: savedExhibit,
        exhibitType: PARTITIONED_EXHIBIT,
      },
    })

    const newExhibits = [...provider.exhibits]

    newExhibits[index] = savedExhibit

    onChange(updateImmutable(provider, { exhibits: newExhibits }))

    queryClient.invalidateQueries([queryKeys.exhibits])

    showMessage({
      type: "success",
      message: "Exhibit Saved Successfully",
    })
  }

  const handleExhibitEditing = exhibitPk => {
    const currentlyEdingiExhibit = provider.exhibits.find(({ pk }) => exhibitPk === pk)

    if (currentlyEdingiExhibit) {
      exhibitsBeforeChanging.current = {
        ...exhibitsBeforeChanging.current,
        [currentlyEdingiExhibit.pk]: currentlyEdingiExhibit,
      }
    }

    dispatch({
      type: actionTypes.SET_EXHIBIT_EDITING,
      payload: {
        providerPk: provider.pk,
        exhibitPk,
        editing: true,
        exhibitType: EXHIBIT,
      },
    })
  }

  const handlePartitionsChange = newExhibits => {
    dispatch({
      type: actionTypes.UPDATE_EXHIBITS,
      payload: { caseId, providerPk: provider.pk, exhibits: newExhibits },
    })
  }

  const handlePartitionSave = async (partition, index) => {
    // fileToUpload and exhibit share the same name + type requirements
    const validationError = validateFile(partition)

    if (validationError) {
      dispatch({
        type: actionTypes.EXHIBIT_SAVE_ERROR,
        payload: { validationError, index, providerPk: provider.pk },
      })
      return
    }

    dispatch({
      type: actionTypes.EXHIBIT_SAVE_START,
      payload: { providerPk: provider.pk, exhibitPk: partition.pk, exhibitType: PARTITIONED_EXHIBIT },
    })

    let savedPartition

    try {
      savedPartition = await updatePartitionMutation.mutateAsync({
        exhibitId: partition.exhibit_id,
        partitionId: partition.pk,
        data: {
          pk: partition.pk,
          name: partition.name,
          type: partition.type,
        },
      })
    } catch (error) {
      // no possible validation errors since exhibit name is validated by front end and type is a select with no blank options
      dispatch({
        type: actionTypes.EXHIBIT_SAVE_ERROR,
        payload: { validationError: null, index, providerPk: provider.pk },
      })

      showMessage({
        type: "error",
        message:
          "Error updating partition. Please try again shortly and if your problem persists contact a dev.",
        error,
      })

      return
    }

    savedPartition.exhibitType = PARTITIONED_EXHIBIT

    dispatch({
      type: actionTypes.EXHIBIT_SAVE_SUCCESS,
      payload: {
        caseId,
        providerPk: provider.pk,
        exhibitPk: partition.pk,
        exhibit: savedPartition,
        exhibitType: EXHIBIT,
      },
    })

    const newExhibits = [...provider.exhibits]

    newExhibits[index] = savedPartition

    onChange(updateImmutable(provider, { exhibits: newExhibits }))
    showMessage({
      type: "success",
      message: "Exhibit Saved Successfully",
    })
  }

  const handlePartitionEditing = partitionId => {
    dispatch({
      type: actionTypes.SET_EXHIBIT_EDITING,
      payload: {
        providerPk: provider.pk,
        exhibitPk: partitionId,
        editing: true,
        exhibitType: PARTITIONED_EXHIBIT,
      },
    })
  }

  const handleCancelEditing = partitionId => {
    const exhibitBeforeChanging = exhibitsBeforeChanging.current[partitionId]

    if (exhibitBeforeChanging) {
      dispatch({
        type: actionTypes.RESET_EXHIBIT,
        payload: {
          providerPk: provider.pk,
          exhibitPk: partitionId,
          exhibit: exhibitBeforeChanging,
        },
      })
    }
  }

  const handleApprove = useCallback(
    data => onAppendExhibit(data, provider.pk),
    [provider.pk, onAppendExhibit]
  )

  const handleProviderDelete = provider => () => onDelete(provider)

  const getUpdatesDateUpdatedByRecordType = useCallback(
    recordType => getDateUpdatedByRecordType(updates, recordType),
    [updates]
  )

  return (
    <Box data-test="provider-form">
      <Box className={classes.providerOpenTitle}>
        <Box>{`Editing: ${provider.name ?? "Provider"}`}</Box>
        <IconButton disabled={disabled} color="inherit" onClick={onCancel}>
          <Close />
        </IconButton>
      </Box>
      <Box p={6}>
        <StyledInputWrapper>
          {caseObj?.use_exhibit_builder ? (
            <>
              <ProviderTemplateSelector disabled={disabled} provider={provider} caseId={caseId} />
              {isEmpty && (
                <Tooltip
                  title="This is a permanent action. Please copy over any necessary work you want to keep before deleting this provider shell."
                  placement="top"
                >
                  <Button
                    variant="text"
                    startIcon={<DeleteOutlineIcon />}
                    sx={{ color: "red.600" }}
                    onClick={handleProviderDelete(provider)}
                  >
                    Remove Provider Shell
                  </Button>
                </Tooltip>
              )}
            </>
          ) : (
            <>
              <TextField
                label="Provider Name"
                value={provider.name ?? ""}
                data-test="provider-name"
                onChange={handleChangeName}
                variant="outlined"
                inputProps={{ name: "name" }}
                className={classes.providerNameInput}
                disabled={disabled}
                error={Boolean(validationErrors["name"])}
                helperText={validationErrors["name"]}
              />
              <ProviderTemplateSelector disabled={disabled} provider={provider} caseId={caseId} />
            </>
          )}
        </StyledInputWrapper>
        {caseObj?.use_exhibit_builder ? (
          <ProviderUserExhibits
            providerId={provider.partition_provider?.matter_provider_id}
            plaintiffId={provider.partition_provider?.matter_plaintiff_id}
          />
        ) : (
          <FilesSection
            providerId={provider.pk}
            disabled={disabled}
            filesToUpload={provider.filesToUpload ?? []}
            filesToUploadValidationErrors={validationErrors.filesToUpload}
            exhibits={provider.exhibits ?? []}
            exhibitsValidationErrors={validationErrors.exhibits}
            onFilesToUploadChange={handleFilesToUploadChange}
            onDrop={handleDrop}
            onFileCancel={handleFileCancel}
            onFileUpload={handleFileUpload}
            onExhibitReorder={onExhibitReorder}
            onExhibitDelete={onExhibitDelete}
            onExhibitDownload={onExhibitDownload}
            onExhibitsChange={handleExhibitsChange}
            onExhibitCancel={handleCancelEditing}
            onExhibitSave={handleExhibitSave}
            onExhibitEdit={handleExhibitEditing}
            onPartitionDownload={onPartitionDownload}
            onPartitionDelete={onPartitionDelete}
            onPartitionChange={handlePartitionsChange}
            onPartitionCancel={handleCancelEditing}
            onPartitionEdit={handlePartitionEditing}
            onPartitionSave={handlePartitionSave}
            openPDFViewerWindow={openPDFViewerWindow}
          />
        )}

        <Box mt={2}>
          <MissingDocumentSection
            missingDocs={missingExhibits}
            section={SECTIONS.PROVIDERS}
            title="Missing Documents List"
            providerId={provider.pk}
            canEditExistingProvider={false}
            canSelectProvider={Boolean(provider.pk)}
            onApproveCallback={handleApprove}
          />
        </Box>

        <AppointmentSection
          dispatch={dispatch}
          disabled={disabled}
          provider={provider}
          validationErrors={validationErrors}
          annotationDateUpdated={getUpdatesDateUpdatedByRecordType(UPDATE_TYPES.APPOINTMENTS)}
          onAnnotationClick={openPDFViewerWindow}
        />
        <BillsSection
          dispatch={dispatch}
          validationErrors={validationErrors}
          provider={provider}
          hasCollateralSourceRule={hasCollateralSourceRule}
          disabled={disabled}
          annotationDateUpdated={getUpdatesDateUpdatedByRecordType(UPDATE_TYPES.BILLS)}
          onAnnotationClick={openPDFViewerWindow}
        />
        <InjuryDetailsSection
          caseId={caseId}
          disabled={disabled}
          provider={provider}
          onBlur={onBlur}
          dispatch={dispatch}
          annotationDateUpdated={getUpdatesDateUpdatedByRecordType(UPDATE_TYPES.ICD_CODES)}
          onAnnotationClick={openPDFViewerWindow}
          updatedPassages={getUpdatesByRecordType(updates, UPDATE_TYPES.INJURY_DETAILS)}
        />

        {showObjectiveTests && (
          <ObjectiveTestsSection disabled={disabled} provider={provider} dispatch={dispatch} />
        )}

        {showInterventionalTreatment && (
          <InterventionalTreatmentsSection disabled={disabled} provider={provider} dispatch={dispatch} />
        )}

        <Box display="flex" justifyContent="space-between">
          {!caseObj?.use_exhibit_builder && (
            <Box>
              <Button
                className={classes.deleteButton}
                variant="contained"
                color="secondary"
                data-test="delete-provider"
                disableElevation
                disabled={disabled}
                onClick={handleProviderDelete(provider)}
              >
                {provider.deleting ? "Deleting" : "Delete Provider"}
              </Button>
            </Box>
          )}
          <Box className={classes.rightButtonGroup}>
            <Button
              variant="contained"
              disableElevation
              disabled={disabled}
              onClick={onCancel}
              color="inherit"
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              data-test="save-provider"
              disableElevation
              disabled={disabled}
              onClick={onSave}
            >
              {provider.saving ? "Saving" : "Save"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
