import { FormControlLabel, Switch, Typography } from "@mui/material"
import { useListData } from "exhibit-builder/UserExhibitList/useListData"
import { FiltersSlice } from "exhibit-builder/store/filters"
import { isEqual } from "lodash"
import { useMemo } from "react"

export function ExactProviderMatchToggle({
  filters,
  exactProviderMatch,
  setExactProviderMatch,
}: {
  filters: Partial<FiltersSlice["filters"]>
  exactProviderMatch: boolean
  setExactProviderMatch: (value: boolean) => void
}) {
  const listDataWithExactMatch = useListData(
    useMemo(() => ({ ...filters, exactProviderMatch: true }), [filters])
  )
  const listDataWithoutExactMatch = useListData(
    useMemo(() => ({ ...filters, exactProviderMatch: false }), [filters])
  )

  const shouldShowExactMatchToggle = useMemo(() => {
    return !isEqual(listDataWithExactMatch, listDataWithoutExactMatch)
  }, [listDataWithExactMatch, listDataWithoutExactMatch])

  if (!shouldShowExactMatchToggle) {
    return null
  }

  return (
    <FormControlLabel
      sx={{ mx: 0, my: 1 }}
      label={<Typography variant="caption">Hide all unrelated provider relations</Typography>}
      control={
        <Switch
          size="small"
          data-test="hide-unrelated"
          color="secondary"
          checked={exactProviderMatch}
          onChange={(_, checked) => {
            setExactProviderMatch(checked)
          }}
        />
      }
    />
  )
}
