import { Box, Button, Typography, colors } from "@mui/material"
import { useExhibitBuilderStore } from "../store"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { getDeletedPageRanges } from "./getDeletedPages"
import { RenderPdfPayload } from "message-broker/types"
import { ExhibitFile } from "exhibit-builder/store/types"
import { MESSAGE_TOPIC } from "message-broker/topics"
import { getPdfPreviewPath } from "pdf-preview/utils"
import { openWindow } from "common/windows"
import { usePublish } from "message-broker/usePublish"
import { useGetExhibitUrl } from "exhibit-builder/hooks/useGetExhibitUrl"
import { useShallow } from "zustand/react/shallow"
import { EB_DOC_TYPE } from "exhibit-builder/types"
import { DeletedPartition, DeletedRecord } from "./styled"
import { RecoverButton } from "./RecoverButton"
import { providerSelectors } from "exhibit-builder/store/providers"

export function DeleteFiles() {
  const files = useExhibitBuilderStore(state => state.files)
  const type = useExhibitBuilderStore(state => state.exhibitBuilderType)
  const providers = useExhibitBuilderStore(useShallow(providerSelectors.getSortedProviders))
  const deletedPageRanges = useExhibitBuilderStore(state => getDeletedPageRanges(state))
  const caseId = useExhibitBuilderStore(state => state.caseId)
  const { getDownloadUrl } = useGetExhibitUrl()
  const publish = usePublish()

  const preview = ({
    fileId,
    startPage,
    endPage,
    deletedPages,
  }: {
    fileId: ExhibitFile["id"]
    startPage: number
    endPage: number
    deletedPages: [number, number]
  }) => {
    const file = files[fileId]

    const payload: RenderPdfPayload = {
      url: getDownloadUrl({ caseId, fileId, startPage, endPage }),
      fileName: file.name,
      pageNumberAdjustment: startPage - 1,
      page: startPage,
      deletedPages: [deletedPages],
      withCredentials: true,
    }

    publish(MESSAGE_TOPIC.RENDER_PDF, payload).catch(() => {
      const path = getPdfPreviewPath(payload)
      if (path) {
        openWindow(`${path.base}/${path.route}`)
      }
    })
  }

  if (!deletedPageRanges.length) {
    return null
  }

  return (
    <Box data-test="deleted-files" mt={10} mb={4}>
      <Box pb={0.5} mb={1}>
        <Box color={colors.red[700]} display="flex" alignItems="center" gap={1}>
          <InfoOutlinedIcon fontSize="small" />
          <Typography variant="body1SemiBold" display="flex">
            Deleted Original Files
          </Typography>
        </Box>
        <Typography variant="body2" mt={1}>
          The pages listed below, originally included during the intake process, are now permanently deleted
          from the exhibit builder flow.
          <br />
          Please confirm that this is intentional and not an error.
        </Typography>
      </Box>

      {deletedPageRanges.flatMap(item => {
        const file = files[item.fileId]
        const range = item.pageRange

        return (
          <Box key={`${file.id}-${range?.startPage}-${range?.endPage}`} mb={1} data-test="deleted-file">
            <DeletedPartition
              type={type}
              fileName={file.name}
              onPreview={() =>
                range &&
                preview({
                  fileId: item.fileId,
                  startPage: range.startPage,
                  endPage: range.endPage,
                  deletedPages: [range.startPage, range.endPage || range.startPage],
                })
              }
              startPage={range?.startPage}
              endPage={range?.endPage}
              totalPages={file.numberOfPages}
              hideDateOfService={!item.droppedRelations.length}
              renderCustomContent={() => {
                return (
                  <Box key={`${range?.startPage}-${range?.endPage}`} textAlign="right">
                    <RecoverButton file={file} startPage={range?.startPage} endPage={range?.endPage} />
                    <Button
                      size="small"
                      download
                      href={getDownloadUrl({
                        caseId,
                        fileId: file.id,
                        startPage: range?.startPage,
                        endPage: range?.endPage,
                        asAttachment: true,
                      })}
                    >
                      Download
                    </Button>
                  </Box>
                )
              }}
            />
            {type === EB_DOC_TYPE.DEMAND &&
              range &&
              item.droppedRelations.map(relation => {
                return (
                  <DeletedRecord
                    key={relation.id}
                    providers={providers}
                    provider={relation.providerId}
                    documentType={relation.type === "Medical Bill" ? "Bills" : "Records"}
                    startPage={relation.startPage}
                    endPage={relation.endPage ?? relation.startPage}
                    onPreview={() =>
                      preview({
                        fileId: item.fileId,
                        startPage: relation.startPage,
                        endPage: relation.endPage || relation.startPage,
                        deletedPages: [range.startPage, range.endPage || range.startPage],
                      })
                    }
                    dateOfService={
                      relation.type === "Medical Bill"
                        ? { start: relation.dateOfFirstService, end: relation.dateOfLastService }
                        : relation.dateOfService
                    }
                    onDateChange={() => {}}
                    readOnly
                  />
                )
              })}
          </Box>
        )
      })}
    </Box>
  )
}
