import { memo, useCallback } from "react"
import { exhibitBuilderActions, useExhibitBuilderStore } from "exhibit-builder/store"
import { filesSelectors } from "exhibit-builder/store/files/filesSelectors"
import { PartitionListItem } from "exhibit-builder/Exhibit/PartitionListItem"
import { ExhibitPartition, MedicalBill, MedicalRecord, UserExhibit } from "exhibit-builder/store/types"
import { useShallow } from "zustand/react/shallow"
import { useExhibitPartitionPreview } from "exhibit-builder/useUserExhibitPreview"
import { EB_DOC_TYPE } from "exhibit-builder/types"
import { useProviders } from "exhibit-builder/hooks/useDeleteProvider"

export const RecordOrBillItem = memo(function RecordOrBillItem({
  id,
  userExhibitId,
  partitionId,
}: {
  id: (MedicalRecord | MedicalBill)["id"]
  userExhibitId: UserExhibit["id"]
  partitionId: ExhibitPartition["id"]
}) {
  const type = useExhibitBuilderStore(useShallow(state => state.exhibitBuilderType))
  const item = useExhibitBuilderStore(useShallow(filesSelectors.getRecordOrBillById(id)))
  const isReadOnly = useExhibitBuilderStore(useShallow(state => state.isReadOnly))
  const { canDeleteProvider, deleteProvider, providers } = useProviders()

  const isMedChron = type === EB_DOC_TYPE.MEDCHRON

  const handleProviderRename = useCallback(
    (providerId: string, name: string) =>
      exhibitBuilderActions.renameProvider({
        providerId,
        name,
        toggleSummaries: isMedChron,
      }),
    [isMedChron]
  )
  const handleProviderChange = useCallback(
    (providerId: Nullable<string>) => {
      if (!item) {
        return
      }

      return exhibitBuilderActions.updateMedicalRecordOrBill({ ...item, providerId }, userExhibitId)
    },
    [item, userExhibitId]
  )

  const handleDateChange = useCallback(
    (date: Nullable<string> | { start: string; end: string }) => {
      if (!item) {
        return
      }

      if (item.type === "Medical Bill" && typeof date === "object" && date?.start && date?.end) {
        return exhibitBuilderActions.updateMedicalRecordOrBill(
          {
            ...item,
            dateOfFirstService: date.start,
            dateOfLastService: date.end,
          },
          userExhibitId
        )
      }

      if (item.type === "Medical Record" && typeof date === "string") {
        return exhibitBuilderActions.updateMedicalRecordOrBill(
          {
            ...item,
            dateOfService: date,
          },
          userExhibitId
        )
      }
    },
    [item, userExhibitId]
  )

  const preview = useExhibitPartitionPreview({
    partitionId,
    page: item?.startPage,
    showFileNamePrefix: isMedChron,
  })

  if (!item) {
    return null
  }

  return (
    <PartitionListItem
      data-test="record-or-bill-item"
      type={type}
      provider={item.providerId}
      providers={providers}
      startPage={item.startPage}
      endPage={item.endPage ?? item.startPage}
      documentType={item.type === "Medical Bill" ? "Bills" : "Records"}
      onDateChange={handleDateChange}
      onProviderChange={handleProviderChange}
      onProviderCreate={exhibitBuilderActions.createProvider}
      onProviderRename={handleProviderRename}
      onPreview={preview}
      onProviderDelete={deleteProvider}
      canDeleteProvider={canDeleteProvider}
      dateOfService={
        item.type === "Medical Bill"
          ? { start: item.dateOfFirstService, end: item.dateOfLastService }
          : item.dateOfService
      }
      readOnly={isReadOnly}
    />
  )
})
